import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { loadTravelQuotes, updateTravelGuarantee } from '../../../../features/insuranceRequests/insuranceRequestsActions';
import {
  AddButton, BackButton, GuaranteesLeftSide, GuaranteesModalBody, GuaranteesModalContent, GuaranteesModalHeader, GuaranteesRightSide, GuaranteesTextContainer, GuaranteesTitleBox, GuarnateesModalInfortuniStyles, InputCircle, InputContainer, InputText,
} from './GuaranteesModalTravel.styles';
import PageLoading from '../../elements/PageLoading';
import quotationsAxe from '../../../../services/axios-client/quotationsAxeClient';

function Modal({
  loadingDataset, formData, register, closeModal, updateDataSet,
}) {
  return (
    <div className="modal-content my-2">
      <div className="modal-header">
        <h1 className="modal-title fs-5">Aggiungi Dati Aggiuntivi</h1>
        <button type="button" className="btn-close" id="button-close" aria-label="Close" onClick={() => closeModal('filter')} />
        <button type="button" className="btn-close d-none" id="button-close-empty" aria-label="Close" onClick={() => closeModal('close')} />
      </div>
      {loadingDataset ? <PageLoading /> : (
        <div className="modal-body">
          <form action="">
            <div className="mb-3">
              <label htmlFor="formGroupExampleInput" className="form-label">Beneficiario</label>
              <select className="form-select" defaultValue="" aria-label="Beneficiario" {...register('infortuni_data.value')}>
                <option disabled value="">Seleziona Beneficiario</option>
                <option value="1">Eredi legittimi o testamentari</option>
                <option value="2">Altra persona</option>
              </select>
            </div>
            {formData.infortuni_data.value === '2' ? (
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput2" className="form-label">Nome e Cognome Beneficiario</label>
                <input type="text" {...register('infortuni_data.beneficario')} className="form-control" placeholder="Nome beneficiario" />
              </div>
            ) : ''}
            <input type="hidden" {...register('infortuni_data.contractor_id')} className="form-control" placeholder="Nome beneficiario" />
            <input type="hidden" {...register('infortuni_data.insured_id')} className="form-control" placeholder="Nome beneficiario" />
            <div className="mb-3">
              <button type="button" className="btn btn-success" onClick={() => updateDataSet()}>Aggiungi</button>
            </div>
          </form>
        </div>
      )}

    </div>
  );
}

const schema = yup.object({
  infortuni_data: yup.object({
    value: yup.string().required(),
    beneficario: yup.string().nullable(),
    contractor_id: yup.number().nullable(),
    insured_id: yup.number().nullable(),
  }),
});

function GuaranteesModalTravel({
  setOpenModal, quote, requestToken, fetchData,
}) {
  const [modalExtensions, setModalExtensions] = useState([]);
  const [loadingDataset, setLoadingDataset] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const {
    register, watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      infortuni_data: {
        value: '',
        beneficario: '',
        contractor_id: '',
        insured_id: '',
      },
    },
  });

  const formData = watch();

  const closeGuaranteesModal = async () => {
    if (modalExtensions.length === 0) {
      await quotationsAxe.put(`/guarantees/travel/${requestToken}/update-dataset`, { infortuni_data: null });
      loadTravelQuotes(requestToken);
    }
    setOpenModal(false);
  };

  // ? Mondo Infortuni = 000001_EA1358
  // ? Europe Infortuni = 000001_EA1244

  const addGuarantees = (quote_id) => {
    updateTravelGuarantee(quote_id, modalExtensions, requestToken);
    if (fetchData) {
      fetchData();
    }
  };

  const updateGuarantees = (val) => {
    if (modalExtensions.find((ext) => ext === val)) {
      if (quote.quotation_data_travel_europeassistance.coverages.find((el) => el.coverage_id === val).title === 'Bagaglio') {
        const bagaglioTop = quote.quotation_data_travel_europeassistance.coverages.find((cov) => cov.title === 'Bagaglio TOP');
        if (modalExtensions.includes(bagaglioTop.coverage_id)) {
          let newArr = [];
          newArr = modalExtensions.filter((item) => item !== bagaglioTop.coverage_id);
          setModalExtensions([...newArr, val]);
        } else {
          setModalExtensions(modalExtensions.filter((item) => item !== val));
          if ((val === '000001_EA1358' && !modalExtensions.find((ext) => ext === '000001_EA1358')) || (val === '000001_EA1244' && !modalExtensions.find((ext) => ext === '000001_EA1244'))) {
            // document.getElementById('open-modal').click();
            setShowForm(true);
          }
        }
        return;
      }

      if (quote.quotation_data_travel_europeassistance.coverages.find((el) => el.coverage_id === val).title === 'Bagaglio TOP') {
        const bagaglio = quote.quotation_data_travel_europeassistance.coverages.find((cov) => cov.title === 'Bagaglio');
        if (modalExtensions.includes(bagaglio.coverage_id)) {
          let newArr = [];
          newArr = modalExtensions.filter((item) => item !== bagaglio.coverage_id);
          setModalExtensions([...newArr, val]);
        } else {
          setModalExtensions(modalExtensions.filter((item) => item !== val));
        }
      } else {
        setModalExtensions(modalExtensions.filter((item) => item !== val));
        if ((val === '000001_EA1358' && !modalExtensions.find((ext) => ext === '000001_EA1358')) || (val === '000001_EA1244' && !modalExtensions.find((ext) => ext === '000001_EA1244'))) {
          // document.getElementById('open-modal').click();
          setShowForm(true);
        }
      }
      return;
    }

    if (quote.quotation_data_travel_europeassistance.coverages.find((el) => el.coverage_id === val).title === 'Bagaglio') {
      const bagaglioTop = quote.quotation_data_travel_europeassistance.coverages.find((cov) => cov.title === 'Bagaglio TOP');
      if (modalExtensions.includes(bagaglioTop.coverage_id)) {
        let newArr = [];
        newArr = modalExtensions.filter((item) => item !== bagaglioTop.coverage_id);
        setModalExtensions([...newArr, val]);
      } else {
        setModalExtensions([...modalExtensions, val]);
        if ((val === '000001_EA1358' && !modalExtensions.find((ext) => ext === '000001_EA1358')) || (val === '000001_EA1244' && !modalExtensions.find((ext) => ext === '000001_EA1244'))) {
          // document.getElementById('open-modal').click();
          setShowForm(true);
        }
      }
      return;
    }

    if (quote.quotation_data_travel_europeassistance.coverages.find((el) => el.coverage_id === val).title === 'Bagaglio TOP') {
      const bagaglio = quote.quotation_data_travel_europeassistance.coverages.find((cov) => cov.title === 'Bagaglio');
      if (modalExtensions.includes(bagaglio.coverage_id)) {
        let newArr = [];
        newArr = modalExtensions.filter((item) => item !== bagaglio.coverage_id);
        setModalExtensions([...newArr, val]);
      } else {
        setModalExtensions(modalExtensions.filter((item) => item !== bagaglio.coverage_id));
      }
    } else {
      setModalExtensions([...modalExtensions, val]);
      if ((val === '000001_EA1358' && !modalExtensions.find((ext) => ext === '000001_EA1358')) || (val === '000001_EA1244' && !modalExtensions.find((ext) => ext === '000001_EA1244'))) {
        // document.getElementById('open-modal').click();
        setShowForm(true);
      }
    }
  };

  const closeModal = (val) => {
    if (val === 'close') {
      document.getElementById('button-close-empty').click();
      return;
    }

    if (val === 'filter') {
      let newExt = [];
      newExt = modalExtensions.filter((ext) => ext !== '000001_EA1358');
      newExt = modalExtensions.filter((ext) => ext !== '000001_EA1244');
      setModalExtensions(newExt);
      document.getElementById('button-close').click();
    }
  };

  const updateDataSet = () => {
    setLoadingDataset(true);
    quotationsAxe.put(`/guarantees/travel/${requestToken}/update-dataset`, formData);
    // closeModal('close');
    setShowForm(false);
    setLoadingDataset(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const newArr = [];

    quote.quotation_data_travel_europeassistance.coverages.forEach((ext) => {
      if (ext.enabled === 1) {
        quote.quotation_coverages.forEach((q) => {
          if (ext.coverage_id === q.guarantees_slug) {
            newArr.push(q.guarantees_slug);
          }
        });
      }
    });

    setModalExtensions(newArr);
  }, [quote]);

  return (
    <GuarnateesModalInfortuniStyles>
      <GuaranteesModalBody>
        <GuaranteesModalHeader>
          <GuaranteesLeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeGuaranteesModal()} />
            <GuaranteesTextContainer>
              <GuaranteesTitleBox>
                <h3>Aggiungi Garanzie</h3>
              </GuaranteesTitleBox>
              <p>
                Seleziona le garanzie che desideri aggiungere al tuo pacchetto assicurativo principale.
              </p>
            </GuaranteesTextContainer>
          </GuaranteesLeftSide>
          <GuaranteesRightSide>
            <BackButton onClick={() => closeGuaranteesModal()}>
              Indietro
            </BackButton>
            <AddButton onClick={() => {
              addGuarantees(quote.id);
              closeGuaranteesModal();
            }}
            >
              Aggiungi
            </AddButton>
          </GuaranteesRightSide>
        </GuaranteesModalHeader>
        <GuaranteesModalContent>
          <div className="row">
            <div className="col-12 col-md-12">
              {quote.quotation_data_travel_europeassistance.coverages.filter((i) => i.enabled === 1).map((coverage, index) => (
                <React.Fragment key={index}>
                  <div className="d-flex align-items-start justify-content-start gap-2">
                    <InputContainer value={coverage.coverage_id} onClick={quote.is_aquista !== 1 ? () => { } : () => updateGuarantees(coverage.coverage_id)}>
                      <div>
                        <InputCircle className={modalExtensions.find((ext) => ext === coverage.coverage_id) ? 'selected' : ''} />
                      </div>
                      <InputText>
                        <div>{coverage.title}</div>
                        {' '}
                      </InputText>
                    </InputContainer>
                    {((coverage.coverage_id === '000001_EA1358' || coverage.coverage_id === '000001_EA1244') && (modalExtensions.find((ext) => ext === '000001_EA1358') || modalExtensions.find((ext) => ext === '000001_EA1244'))) && <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faEdit} onClick={() => openForm()} />}
                  </div>
                  {(showForm && (modalExtensions.find((ext) => ext === '000001_EA1358') || modalExtensions.find((ext) => ext === '000001_EA1244')) && (coverage.coverage_id === '000001_EA1358' || coverage.coverage_id === '000001_EA1244')) && (
                    <Modal
                      loadingDataset={loadingDataset}
                      formData={formData}
                      register={register}
                      closeModal={closeModal}
                      updateDataSet={updateDataSet}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </GuaranteesModalContent>
      </GuaranteesModalBody>
    </GuarnateesModalInfortuniStyles>
  );
}

export default GuaranteesModalTravel;
