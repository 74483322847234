import caller from './authSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { generalAlertError, generalAlertSuccess } from '../../common/utils/alerts';
import StorageService from '../../services/StorageService';

const Storage = new StorageService();

export const requestForgotPassword = async (data, successCallback) => {
  caller.forgotPasswordPending();

  const response = await managementAxe.post('/auth/forgot', data);

  if (response.status === 400) {
    caller.forgotPasswordRejected('La mail non e stata trovata');
    return;
  }

  if (response.status !== 200) {
    caller.forgotPasswordRejected('Errore interno');
    return;
  }

  caller.forgotPasswordFulfilled();
  successCallback();
};

export const resetForgotPassword = () => caller.forgotPasswordReset();

export const requestResetPassword = async (token, data, successfullCallback) => {
  caller.resetPasswordPending();

  const response = await managementAxe.post(`/auth/reset/${token}`, data);

  if (response.status === 400) {
    if (response.data.error === 'invalid_token') {
      caller.resetPasswordRejected('Il link non e valido');
      return;
    }

    if (response.data.error === 'token_expired') {
      caller.resetPasswordRejected('Collegamento scaduto');
      return;
    }
  }

  if (response.status !== 200) {
    caller.resetPasswordRejected('Errore interno');
    return;
  }

  successfullCallback();
};

export const resetResetPassword = () => caller.resetPasswordReset();

export const changePassword = async (data, callback) => {
  caller.changePasswordPending();

  const response = await managementAxe.put('/auth/change/password', data);

  if (response.status !== 200) {
    if (response.status === 400 && response.data.error === 'invalid_current_password') {
      caller.changePasswordRejected('La password attuale non e correto');
      return;
    }

    caller.changePasswordRejected('Errore interno');
    return;
  }

  callback();
};

export const resetChangePassword = () => caller.changePasswordReset();

export const disconnectOtherSessions = async () => {
  const response = await managementAxe.put('/auth/sessions/logout');

  if (response.status !== 200) {
    generalAlertError('Errore interno');
    return;
  }

  Storage.setRefreshToken(response.data.refresh_token);
  generalAlertSuccess('Altre sessioni sono state disconnesse');
};

export const getAvailableProducts = async () => {
  caller.userProductsPending();
  try {
    const response = await managementAxe.get('/auth/available-products');
    if (response.status !== 200) {
      generalAlertError('Non Trovati Prodoti per utente!');
      caller.userProductsRejected('Non Trovati Prodoti per utente!');
      return;
    }

    caller.userProductsSuccess(response.data);
  } catch (error) {
    generalAlertError('Errore Interno');
    caller.userProductsRejected('Errore Interno!');
  }
};
