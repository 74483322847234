import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Loader } from '@googlemaps/js-api-loader';
import UploadInput from '../../common/components/UploadInput';
import FileWrapper from '../../common/components/FileWrapper';

import {
  addNewAccident,
} from '../../features/accidents/accidentsActions';
import { generalAlertError } from '../../common/utils/alerts';
import { MapContainerStyles } from '../BrokersPage/NewBroker.styles';
import { OptionModalStyles } from './OptionModal.styles';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { Label } from '../../common/formInputs/styles/common';

const schema = yup.object({
  files: yup.array().min(1, 'Devi caricare almeno 1 file'),
  note: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),

});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export function ReportAccident({
  quotationId, selectOption, location, collectData, search,
}) {
  const isOpenedByCommand = location.state !== undefined && location.state?.type === 'command'
    && location.state.data?.page === 'accident';

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
    reset: resetForm,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: isOpenedByCommand ? {
      files: [],
      note: location.state.data.note ?? '',
      address: location.state.data.address ?? '',
      latitude: 41.902782,
      longitude: 12.496366,
    } : {
      files: [],
      note: '',
      address: '',
      latitude: 41.902782,
      longitude: 12.496366,
    },
  });

  const fileInputHandler = (files) => {
    const formData = getValues();

    if (files.length > 10 - formData.files.length) {
      generalAlertError('Non puoi caricare piu di 10 documenti');
      return;
    }

    const filesUpdated = [...formData.files];

    let files_counter = 0;

    Object.values(files).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        filesUpdated.push({
          file_type: `${reader.result.split(',')[0]},`,
          original_filename: file.name,
          content: reader.result,
        });
        files_counter++;
        if (files_counter === files.length) {
          setValue('files', filesUpdated, asInputOptions);
        }
      };
    });
  };

  const resetEverything = () => {
    resetForm();
    selectOption(null);
  };

  const submitData = (formData) => {
    addNewAccident(quotationId, formData, resetEverything, collectData, search);
  };

  const removeFileHandler = (file_index) => {
    const filesUpdated = [...getValues().files];
    filesUpdated.splice(file_index, 1);
    setValue('files', filesUpdated, asInputOptions);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const answers = watch();

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load()
      .then((google) => {
        const myLatLng = {
          lat: answers.latitude,
          lng: answers.longitude,
        };

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 15,
        });

        new google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!',
        });

        const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          fields: ['formatted_address', 'geometry', 'name'],
          types: ['address'],
          componentRestrictions: { country: 'it' },
        });

        if (isOpenedByCommand && location.state.data.address !== '') {
          setTimeout(() => {
            document.getElementById('autocomplete').focus();
          }, 2000);
        }

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (!place.geometry) {
            setValue('address', '');
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 15,
              center: place.geometry.location,
            });

            new google.maps.Marker({
              position: place.geometry.location,
              map,
            });

            setValue('address', place.formatted_address);
            setValue('latitude', place.geometry.location.lat());
            setValue('longitude', place.geometry.location.lng());
          }
        });
      });

    return () => {
      if (loader) {
        loader.reset();
        delete window.google;
        Loader.instance = undefined;
      }
    };
  }, []);

  return (
    <OptionModalStyles>
      <div className="option-modal-content">
        <h1 className="option-modal-title">Denuncia Sinistro</h1>
        <form onSubmit={handleSubmit(submitData)} className="report-form-container">
          <div className="flex-column mt-60 align-items-center justify-content-center gap-2">
            <UploadInput
              uploadHandler={fileInputHandler}
              text={
                getValues().files.length >= 10
                  ? 'Non puoi più caricare'
                  : 'Puoi caricare un massimo di 10 file'
              }
              invalid={getValues().files.length >= 10}
              className="mt-3 pl-4"
            />
            <div className="general-input-error">
              {errors.files?.message}
            </div>
            <FormInputTextarea
              placeholder="Note"
              label="Note"
              registration={register('note')}
              rows="3"
              error={errors.note?.message}
              valid={isValid('note')}
            />
            <h6 className="mt-3" hidden={getValues().files.length === 0}>
              Documenti caricati
            </h6>

            <FileWrapper
              files={getValues().files}
              removeFileAction={removeFileHandler}
            />

            <div className="row">
              <h3 className="text-center">Inserisci indirizzo del sinistro</h3>
              <Label>Indirizzo</Label>
              <input
                name="address"
                id="autocomplete"
                placeholder="Inserisci Indirizzo..."
                className={`form-control ${errors.address?.message ? 'is-invalid' : ''}`}
                {...register('address')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
              <div className="invalid-feedback">
                {errors.address?.message}
              </div>
              <MapContainerStyles>
                <div id="map" />
              </MapContainerStyles>
            </div>
            <div className="option-modal-actions">
              <button
                className="option-modal-button option-modal-button-close"
                onClick={() => selectOption(null)}
              >
                Chiudi
              </button>
              <button
                type="submit"
                className="option-modal-button option-modal-button-submit"
              >
                Invia
              </button>
            </div>
          </div>
        </form>
      </div>
    </OptionModalStyles>
  );
}
