import React from 'react';

import { BACKEND_BASE_URL } from '../../../../config';
import {
  LeftCol,
  LogoPriceContainer,
  LogoResultContainer,
  PriceContainer,
  PriceResult,
  QuoteInformationResultContainer,
  QuoteInformationResultItem,
  RiskCardSelectedStyles,
} from './RiskCardSelected.styles';

function RiskCardSelected({ quote, type, requestInfo }) {
  return (
    <RiskCardSelectedStyles
      className={quote.is_aquista === 1 ? 'selected' : ''}
    >
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img
                src={BACKEND_BASE_URL + quote.company.logo}
                alt={quote.company.name}
              />
            </LogoResultContainer>
            {quote.amount != 0 && (
              <PriceContainer>
                <PriceResult>
                  <h5>Prezzo annuo:</h5>
                  <h2>
                    &euro;
                    {parseFloat(quote.amount).toFixed(2)}
                  </h2>
                </PriceResult>
                {quote.payment_frequency === 2 && (
                  <PriceResult>
                    <h5>Prezzo Semestrale:</h5>
                    <h6>Primo Pagamento</h6>
                    <h2>
                      &euro;
                      {(
                        Number(quote.amount) / quote.payment_frequency
                      ).toLocaleString('it-IT', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h2>
                  </PriceResult>
                )}
              </PriceContainer>
            )}
          </LogoPriceContainer>
          {/* <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer> */}
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>{type.name}</p>
            </QuoteInformationResultItem>
            {((quote.risk_massimale !== null
              && quote.risk_massimale !== undefined
              && quote.risk_massimale != 0)
              || (quote.massimale !== null
                && quote.massimale !== undefined
                && quote.massimale != 0)) && (
                <QuoteInformationResultItem>
                  <h6>Massimale:</h6>
                  <p>
                    {quote.risk_massimale !== null
                  && quote.risk_massimale !== undefined
                  && quote.risk_massimale != 0
                      ? quote.risk_massimale
                      : quote.massimale !== null
                      && quote.massimale !== undefined
                      && quote.massimale != 0
                        ? quote.massimale
                        : ''}
                    {requestInfo && requestInfo.product_id != 11 && '€'}
                  </p>
                </QuoteInformationResultItem>
            )}
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </LeftCol>
        {/* <RightCol /> */}
      </div>
    </RiskCardSelectedStyles>
  );
}

export default RiskCardSelected;
