import React from 'react';
import { apiDownloadDocument, documentMethodManualUpload } from '../../../../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../../../utils/alerts';
import {
  ActionsWrapper, DocumentFileActions, DocumentInfo, DocumentInfoTitle, FileInfo, StatusBadge,
} from '../SignatureType.styles';
import downloadIcon from '../../../../../assets/redesign_icons/download.svg';
import uploadIcon from '../../../../../assets/redesign_icons/upload.svg';
import fileIcon from '../../../../../assets/redesign_icons/file.svg';

function HouseDocuments({
  signable, insuranceRequest, quote, collectQuoteData, signableDocs,
}) {
  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  // const downloadOptionalDocument = async (documentId) => {
  //   const response = await apiDownloadOptionalDocument(documentId);
  //   const alink = document.createElement('a');
  //   alink.target = '_blank';
  //   alink.href = response.data.data.contents;
  //   alink.download = response.data.data.filename;
  //   alink.click();
  // };

  const fireSignedDocUpload = (docId) => {
    const inputElement = document.getElementById(`required-doc-${docId}`);
    if (inputElement) {
      inputElement.click();
    } else {
      console.log(`No input found for required document: ${docId}`);
    }
  };

  const docs = [
    ...insuranceRequest.documents.filter((filterDoc) => filterDoc.signable_document === signable),
    ...quote.documents.filter((filterDoc) => filterDoc.signable_document === signable),
  ];

  return (
    <>
      {docs.length === 0 && (
      <p>Nessun documento caricato</p>
      )}

      {signableDocs.map((document, index) => {
        const doc = docs.find((d) => d.document_type_id === document.id);
        return (
          <DocumentInfo key={index}>
            <DocumentInfoTitle>
              <FileInfo>
                <img src={fileIcon} alt={doc.original_filename} width={20} />
                <p>{doc.original_filename}</p>
              </FileInfo>
              {doc.signable_document === 1 && doc.is_signed_valid === null && doc.signed_document_filename === null
                ? (
                  <StatusBadge background="#FFEAB4" color="#FF8A00">
                    Attesa Firma
                  </StatusBadge>
                ) : ''}
              {doc.signable_document === 1 && doc.signed_document_filename !== null && doc.is_signed_valid === null ? (
                <StatusBadge background="#009232" color="#ffffff">
                  Firma in verifica
                </StatusBadge>
              ) : ''}
              {doc.signable_document === 1 && doc.is_signed_valid === 1 ? (
                <StatusBadge background="#99DBB9" color="#009232">
                  Firmato
                </StatusBadge>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.is_signed_valid === 0 ? (
                <StatusBadge background="#FFB4B4" color="#F00">
                  Firma non valida
                </StatusBadge>
              ) : (
                ''
              )}

            </DocumentInfoTitle>
            <DocumentFileActions>
              <p>
                {doc.is_signed_valid === null && doc.motivation === null && doc.signed_document_filename !== null
                  ? 'Il nostro backoffice sta verificando il documento' : ''}
                {doc.is_signed_valid === 0 && doc.motivation !== null && doc.signed_document_filename === null
                  ? <span className="text-danger">{doc.motivation}</span> : ''}
                {doc.is_signed_valid === 1 && doc.signed_document_filename !== null
                  ? <span className="text-success">Documento Validato</span> : ''}
                {doc.is_signed_valid === null && doc.signed_document_filename === null
                  ? 'Scarica il documento e caricalo firmato' : ''}
              </p>
              <ActionsWrapper>
                <div className="action-button" onClick={() => downloadDocument(doc.id)}>
                  <img src={downloadIcon} alt="Scarica" width={30} />
                  <p>Scarica</p>
                </div>
                <div className="action-button" onClick={doc.signed_document_filename === null ? () => fireSignedDocUpload(doc.id) : () => { }}>
                  <img src={uploadIcon} alt="Carica" width={30} />
                  <p>Carica</p>
                  <input type="file" name="" id={`required-doc-${doc.id}`} className="d-none" onChange={(e) => uploadSignedDocument(doc.id, e.target.files)} />
                </div>
              </ActionsWrapper>
            </DocumentFileActions>
          </DocumentInfo>
        );
      })}
    </>
  );
}

export default HouseDocuments;
