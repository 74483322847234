import React from 'react';
import GlobalAssistencaStradaleModal from '../../Modal/GlobalAssistencaStradaleModal';
import GlobalAssistanceTutelaModal from '../../Modal/GlobalAssistanceTutelaModal';
import GlobalAssistanceInfortuniModal from '../../Modal/GlobalAssistanceInfortuniModal';
import GlobalAssistanceCristalliModal from '../../Modal/GlobalAssistanceCristalliModal';
import EuropeAssistencaStradaleModal from '../../Modal/EuropeAssistencaStradaleModal';
import EuropeAssistanceTutelaModal from '../../Modal/EuropeAssistanceTutelaModal';
import EuropeAssistanceCristalliModal from '../../Modal/EuropeAssistanceCristalliModal';
import EuropeAssistanceCollisioneModal from '../../Modal/EuropeAssistanceCollisioneModal';
import TutelaLegaleInfoModal from '../../Modal/TutelaLegaleInfoModal';

function ModalsImported({
  showModalAssistenza,
  setShowModalAssistenza,
  showModalGlobalTutela,
  setShowModalGlobalTutela,
  showModalGlobalInfortuni,
  setShowModalGlobalInfortuni,
  showModalGlobalCristalli,
  setShowModalGlobalCristalli,
  showModalEuropeAssistenza,
  setShowModalEuropeAssistenza,
  showModalEuropeTutela,
  setShowModalEuropeTutela,
  showModalEuropeCristalli,
  setShowModalEuropeCristalli,
  showModalEuropeCollisione,
  setShowModalEuropeCollisione,
  showModalTutelaArag,
  setShowModalTutelaArag,
  quote,
}) {
  return (
    <>
      {showModalAssistenza && (
        <GlobalAssistencaStradaleModal
          quotation={quote}
          setShowModal={setShowModalAssistenza}
        />
      )}
      {showModalGlobalTutela && (
        <GlobalAssistanceTutelaModal
          quotation={quote}
          setShowModal={setShowModalGlobalTutela}
        />
      )}
      {showModalGlobalInfortuni && (
        <GlobalAssistanceInfortuniModal
          quotation={quote}
          setShowModal={setShowModalGlobalInfortuni}
        />
      )}
      {showModalGlobalCristalli && (
        <GlobalAssistanceCristalliModal
          quotation={quote}
          setShowModal={setShowModalGlobalCristalli}
        />
      )}
      {showModalEuropeAssistenza && (
        <EuropeAssistencaStradaleModal
          quotation={quote}
          setShowModal={setShowModalEuropeAssistenza}
        />
      )}
      {showModalEuropeTutela && (
        <EuropeAssistanceTutelaModal
          quotation={quote}
          setShowModal={setShowModalEuropeTutela}
        />
      )}
      {showModalEuropeCristalli && (
        <EuropeAssistanceCristalliModal
          quotation={quote}
          setShowModal={setShowModalEuropeCristalli}
        />
      )}
      {showModalEuropeCollisione && (
        <EuropeAssistanceCollisioneModal
          quotation={quote}
          setShowModal={setShowModalEuropeCollisione}
        />
      )}
      {showModalTutelaArag && (
        <TutelaLegaleInfoModal
          quotation={quote}
          setShowModal={setShowModalTutelaArag}
        />
      )}
    </>
  );
}

export default ModalsImported;
