import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  changeAgendsView,
  changeNavigationDate,
  getAgendsByWeek,
} from '../../features/dashboard/dashboardActions';
import { WidgetStyles } from './Widgets.styles';
import LoadingGif from '../../assets/images/loading.gif';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

function AgendaWidget() {
  const navigate = useNavigate();

  const state = useSelector((store) => store.dashboard.dashboard);

  const {
    agends, agends_view, agends_date, agends_loading,
  } = state;

  const agendaSentToPurchase = (ag) => {
    if (ag.product_id === 1) {
      navigate(`/quotes/auto/purchase/${ag.request_token}`);
    } else if (ag.product_id === 2) {
      navigate(`/quotes/moto/purchase/${ag.request_token}`);
    } else if (ag.product_id === 3) {
      navigate(`/quotes/autocarro/purchase/${ag.request_token}`);
    } else if (ag.product_id === 4) {
      navigate(`/quotes/profession/purchase/${ag.request_token}`);
    } else if (ag.product_id === 5) {
      navigate(`/quotes/metlife/purchase/${ag.request_token}`);
    } else if (ag.product_id === 6) {
      navigate(`/quotes/infodrive/purchase/${ag.request_token}`);
    } else if (ag.product_id === 8) {
      navigate(`/quotes/travel/purchase/${ag.request_token}`);
    } else if (ag.product_id === 9) {
      navigate(`/quotes/tutela-legale/purchase/${ag.request_token}`);
    } else if (ag.product_id === 10) {
      navigate(`/quotes/commercial/purchase/${ag.request_token}`);
    } else if (ag.product_id === 11) {
      navigate(`/quotes/casa/purchase/${ag.request_token}`);
    }
  };

  // const agendaSentToRequest = (ag) => {
  //   if (ag.product_id === 1) {
  //     navigate(`/quotes/${ag.request_token}`);
  //   } else if (ag.product_id === 3) {
  //     navigate(`/quotes/${ag.request_token}`);
  //   } else if (ag.product_id === 4) {
  //     navigate(`/quotes/profession/${ag.request_token}`);
  //   } else if (ag.product_id === 5) {
  //     navigate(`/quotes/metlife/${ag.request_token}`);
  //   } else if (ag.product_id === 6) {
  //     navigate(`/quotes/infodrive/${ag.request_token}`);
  //   }
  // };

  const agendaSentToReminder = (id) => {
    navigate(`/reminders/${id}/edit`);
  };

  const rangeChanged = (value) => {
    const dateFormat = 'YYYY-MM-DD';
    let initial;
    let ending;
    if (value.start !== undefined) {
      initial = moment(value.start).format(dateFormat);
      ending = moment(value.end).format(dateFormat);
    } else if (value.length === 1) {
      initial = moment(value[0]).format(dateFormat);
      ending = moment(value[0]).format(dateFormat);
    } else {
      initial = moment(value[0]).format(dateFormat);
      ending = moment(value[6]).format(dateFormat);
    }

    getAgendsByWeek(initial, ending);
  };

  const viewChanged = (newView) => {
    changeAgendsView(newView);
  };

  const navigationChange = (newDate) => {
    changeNavigationDate(newDate);
  };

  const getAgendType = (type) => {
    const translations = {
      quotation_expiring: 'Scadenza Poliza',
      payment_expiring: 'Scadenza Pagamento',
      quotation_renewal: 'Rinnovo',
      semestrale_expiring: 'Semestrale In Scadenza',
      reminder: 'Memo',
    };

    return translations[type] ?? null;
  };

  const getAgendColor = (type) => {
    const translations = {
      quotation_expiring: 'orange',
      payment_expiring: 'coral',
      quotation_renewal: 'var(--greenia-color)',
      reminder: 'blue',
    };

    return translations[type] ?? 'var(--greenia-color)';
  };

  const clickFunction = (ad) => {
    if (ad.request_token !== null) {
      return agendaSentToPurchase(ad);
    }
    return null;
  };

  const eventSelected = ({ data }) => {
    if (data.type === 'reminder') {
      agendaSentToReminder(data.reminder_id);
      return;
    }

    clickFunction(data);
  };

  const getCustomerBasicInfo = (customer) => {
    if (customer.gender === 'G') {
      return customer.business_name;
    }

    return `${customer.name} ${customer.surname}`;
  };

  const processAgends = () => {
    const events = [];

    agends.forEach((ag) => {
      let ev;
      if (ag.type === 'reminder') {
        ev = {
          title: ag.title,
          allDay: true,
          start: moment(ag.date).toDate(),
          end: moment(ag.date).toDate(),
          color: getAgendColor(ag.type),
          data: ag,
        };
      } else {
        ev = {
          title: `${
            getAgendType(ag.type) !== null ? `${getAgendType(ag.type)}: ` : ''
          }${
            ag.customer !== undefined
              ? getCustomerBasicInfo(ag.customer)
              : ag.title
          }`,
          allDay: true,
          start: moment(ag.date).toDate(),
          end: moment(ag.date).toDate(),
          color: getAgendColor(ag.type),
          data: ag,
        };
      }

      events.push(ev);
    });

    return events;
  };

  const processedAgends = processAgends();

  return (
    <WidgetStyles className="custom-card agendas-card h-100">
      <div
        className="custom-card-header custom-agenda-card-header"
        style={{ position: 'relative' }}
      >
        <h3>Agenda</h3>

        <div
          className="agenda-button"
          onClick={() => navigate('/reminders/create')}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>Crea memo</span>
        </div>
      </div>
      <div className="d-flex h--flex-column justify-content-between p-2">
        {agends_loading && (
          <div className="text-center">
            <img className="loading-gif" src={LoadingGif} alt="loading-gif" />
            <br />
            Attendere prego
          </div>
        )}

        {!agends_loading && (
          <div style={{ overflowY: 'auto' }}>
            <Calendar
              localizer={localizer}
              messages={{
                allDay: 'Tutto il giorno',
                previous: 'Precedente',
                next: 'Prossima',
                today: 'Oggi',
                month: 'Mese',
                week: 'Settimana',
                day: 'Giorno',
                agenda: 'Agenda',
                date: 'Data',
                time: 'Ora',
                event: 'Evento',
              }}
              events={processedAgends}
              startAccessor="start"
              endAccessor="end"
              date={agends_date}
              view={agends_view}
              style={{ height: 400 }}
              onRangeChange={rangeChanged}
              onView={viewChanged}
              onNavigate={navigationChange}
              onSelectEvent={eventSelected}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: event.color,
                },
              })}
            />
          </div>
        )}
      </div>
    </WidgetStyles>
  );
}

export default AgendaWidget;
