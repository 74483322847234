import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import PageLoading from '../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../config';
import {
  FailedQuoteTitle,
  FailedQuotesContainer,
  RcQuotesStyles,
} from './RcQuotes.styles';
import {
  loadMotoQuotes,
  toggleMotoCoveragesAction,
  updateMotoQuotesData,
  resetMotoQuotes,
  addQuoteMotoToCart,
  removeQuoteMotoFromCart,
} from '../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import MotoInfoCard from '../common/components/redesignComponents/Cards/MotoInfoCard';
import { EstimateContainer } from '../common/components/redesignComponents/common/Redesign.styles';
import GuaranteesMotoModal from '../common/components/redesignComponents/Modal/GuaranteesMotoModal';
import ErrorComponent from '../common/components/ErrorComponent';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRcMotoQuote } from '../common/utils/joyrideCallbacks';
// import { RcMotoQuoteSteps } from '../common/utils/joyrideSteps';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { primaQuoteTypes, quotationTypes } from '../common/constants';
import {
  RespCivileModal,
  RiskButton,
  RiskButtonRemove,
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import RiskCardSelected from '../common/components/redesignComponents/Cards/RiskCardSelected';
import AragCard from '../common/components/redesignComponents/Cards/AragCard';
import {
  riskCardPrimaStyles,
  riskCardStyles,
} from '../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import SelectedQuotesCardsPrima from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsPrima';
import ButtonComponentLogicPrima from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicPrima';
import ModalQuotesPrima from '../common/components/redesignComponents/ResultPageComponents/ModalQuotesPrima';
import QuotationCard from '../common/reusable/QuotationCard';
import QuotationCardResult from '../common/reusable/QuotationCardResult';

function RcMotoQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const [showGuaranteesModal, setShowGuaranteesModal] = useState(false);
  const { updateMarkupLoading } = useSelector(
    (store) => store.insuranceRequests.infodrive_quotes,
  );

  const clearQuotesIntervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const [intervalQuotes, setIntervalQuotes] = useState(null);
  const state = useSelector((store) => store.insuranceRequests.moto_quotes);
  const {
    loading,
    requestInfo,
    failedQuotes,
    coverages,
    error,
    quotations,
    processingQuote,
    tutelaLegaleQuote,
    addingQuote,
  } = state;
  // const [showSellingDistanceModal, setShowSellingDistanceModal] = useState(false);
  const [counter, setCounter] = useState(180);
  // const [currentQuotes, setCurrentQuotes] = useState('diretta');
  // ? diretta = Greenia Companies
  // ? indiretta = Preventivass Companies
  // ? quotazioni = All Preventivass Quotes

  // const handleCurrentQuotesDisplay = (val) => {
  //   setCurrentQuotes(val);
  // };

  // const [steps, setSteps] = useState([]);

  const guaranteesPrima = (quote) => {
    const guarantees = quote.quotation_data_prima_guarantees
      .map((guarantee) => guarantee.slug)
      .filter((g) => g != 'rca' && g != 'furto_incendio');
    if (
      quote.quotation_data_prima_guarantees.find(
        (qg) => qg.slug == 'furto_incendio',
      ) != undefined
    ) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  const updateCoverages = (item) => {
    toggleMotoCoveragesAction(item.id);
  };

  const includesCoverage = (key) => coverages !== null && coverages.includes(key);

  // const getSellingDistanceLink = () => `${SELLING_DISTANCE_URL}/request/${requestInfo.distance_token}/motorcycle/results`;

  // const copySellingDistanceLink = async () => {
  //   try {
  //     const link = getSellingDistanceLink();

  //     await navigator.clipboard.writeText(link);
  //     generalAlertSuccess('Il collegamento e stato copiato');
  //   } catch (err) {
  //     // console.log(err);
  //   }
  // };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newResultMoto') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newResultMoto')]);

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('newResultMoto');
  //   setState({ run: true });
  // };

  useEffect(() => {
    loadMotoQuotes(requestToken);

    return () => {
      resetMotoQuotes();
    };
  }, []);

  const clearQuotesInterval = () => {
    if (intervalQuotes !== null) {
      clearInterval(intervalQuotes);
      setIntervalQuotes(null);
    }
  };

  clearQuotesIntervalRef.current = clearQuotesInterval;
  requestInfoRef.current = requestInfo;

  useEffect(() => {
    const iq = setInterval(() => {
      const requestInfo = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadMotoQuotes(requestToken);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesIntervalRef.current();
        }
      }
    }, 10000);

    setIntervalQuotes(iq);

    return () => {
      clearQuotesIntervalRef.current();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateMotoQuotesData(requestInfo);
    }
  }, [requestInfo]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const modifyData = () => {
    navigate('/quote/motorcycle', {
      state: { requestToken },
    });
  };

  // const leadSourceName = {
  //   0: 'Intermediario',
  //   1: 'Sito publico',
  // };

  useEffect(() => {
    if (requestInfo && requestInfo.is_expired === true) {
      navigate('/quote/motorcycle', {
        state: { requestToken },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [requestInfo]);

  const fetchQuotes = () => {
    loadMotoQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteMotoToCart(quoteId, fetchQuotes, modifyData));
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/moto/purchase/${requestToken}`);
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteMotoFromCart(quoteId, fetchQuotes, modifyData));
  };

  // useEffect(() => {
  //   RcMotoQuoteSteps(quotations, setSteps);
  // }, [quotations]);

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;
    let primaGuaranteesPrices = 0;

    if (processingQuote !== null) {
      if (processingQuote.company_id === 2) {
        quoteInProccessAmount = parseFloat(
          processingQuote.quotation_data_prima.quotation_data_prima_guarantees.find(
            (price) => price.slug === 'rca',
          ).quotation_data_prima_guarantee_prices[0].discounted_to_display,
        );
      } else {
        quoteInProccessAmount = parseFloat(processingQuote.amount);
      }
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    if (processingQuote.company_id === 2) {
      processingQuote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    return (
      quoteInProccessAmount
      + tutelaInProccessAmount
      + primaGuaranteesPrices
    ).toFixed(2);
  };

  if (loading || updateMarkupLoading) {
    return <PageLoading />;
  }

  return error !== null ? (
    <ErrorComponent error={error} />
  ) : (
    <>
      {/* <Joyride
            steps={steps}
            run={run}
            continuous
            showBeacon={showBeacon}
            locale={{
              back: 'Indietro',
              close: 'Chiudi',
              last: 'Fine',
              next: 'Prossima',
              open: 'Apri il modale',
              restart: 'Riavvia il tour ',
              skip: 'Salta',
            }}
            styles={{
              options: joyrideOptions,
              buttonNext: {
                backgroundColor: '#00a651',
                outline: 'none',
              },
              buttonBack: {
                color: '#00a651',
                outline: 'none',
              },
              beaconInner: {
                backgroundColor: '#00a651',
              },
              beaconOuter: {
                borderColor: '#00a651',
                backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
              },
              buttonSkip: {
                outline: 'none',
              },
              buttonClose: {
                outline: 'none',
              },
            }}
            callback={(data) => handleCallbackRcMotoQuote(data, steps, setState)}
            showSkipButton
            showProgress
          /> */}
      {showGuaranteesModal && (
        <GuaranteesMotoModal
          setShowGuaranteesModal={setShowGuaranteesModal}
          includesCoverage={includesCoverage}
          updateCoverages={updateCoverages}
          coverages={coverages}
          requestToken={requestToken}
        />
      )}
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0 result-moto-first-step">
              <MotoInfoCard
                insuranceRequest={requestInfo}
                modificaDati={modifyData}
              />
            </div>
            <div className="col-12 col-xxl-8 result-moto-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null && tutelaLegaleQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === processingQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(processingQuote.amount).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {tutelaLegaleQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {tutelaLegaleQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === tutelaLegaleQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(tutelaLegaleQuote.amount).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {processingQuote !== null
                        && processingQuote.company_id === 2
                        && processingQuote.guarantees.map((guarantee, index) => (
                          <li key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>
                                Prima.it
                                <span className="text-success">
                                  {' '}
                                  (
                                  {
                                    primaQuoteTypes.find(
                                      (type) => type.id === guarantee.guarantees_slug,
                                    ).name
                                  }
                                  )
                                </span>
                              </h5>
                              <h5 className="fw-bold">
                                {parseFloat(guarantee.amount).toFixed(2)}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestSave()}
                      >
                        Aquista
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <EstimateContainer>
                <div className="d-flex gap-2 justify-content-end">
                  {/* <div>
                        <h4>
                          Preventivi assicurazione
                          {' '}
                          <b>Moto</b>
                        </h4>
                        <p>
                          Inizio Copertura:
                          {' '}
                          {requestInfo.vehicle !== null
                            && new Date(
                              requestInfo.policy_effective_date,
                            ).toLocaleDateString('it-IT', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          <br />
                          Provenienza:
                          {' '}
                          {leadSourceName[requestInfo.lead_source]}
                        </p>
                      </div> */}

                  {/* <div>
                        {localStorage.getItem('newResultMoto') === 'done' && (
                          <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                            <button className="btn btn-success px-2 py-1">Tutorial</button>
                          </div>
                        )}
                      </div> */}
                </div>
                {/* <div className="d-flex align-items-center justify-content-start flex-column flex-xxl-row mt-2">
                      {isInfodriveAvailable() === true
                                        && hasInfodrive === 0 && <AddInfodrive onClick={() => getInfodriveQuote(requestToken)}>Aggiungi Prodotto Infodrive</AddInfodrive>}
                      <div className="selling-distance mt-2 mt-xxl-0 result-moto-third-step">
                        {requestInfo.selling_point === 0
                          && (
                            <>
                              <SellingDistanceButton className="btn btn-success m-2" onClick={() => setShowSellingDistanceModal(true)}>Crea link per vendita a distanza</SellingDistanceButton>
                              {showSellingDistanceModal === true
                                && (
                                  <SellingDistanceModal
                                    requestToken={requestToken}
                                    closeModal={() => setShowSellingDistanceModal(false)}
                                    loadResults={() => loadMotoQuotes(requestToken)}
                                  />
                                )}

                            </>
                          )}
                        {requestInfo.selling_point === 1
                          && (
                            <div className="d-flex align-items-center">
                              <div className="btn btn-success m-2" onClick={() => copySellingDistanceLink()} title="Copia collegamento per il cliente">
                                <FontAwesomeIcon icon={faCopy} />
                              </div>
                              <SellingDistanceLink>{getSellingDistanceLink()}</SellingDistanceLink>
                            </div>
                          )}
                      </div>
                    </div> */}
              </EstimateContainer>
            </div>
          </div>

          <RiskContainer className="mb-4 ">
            {processingQuote !== null && processingQuote.company_id === 2
              ? [{ id: 'res_civile', name: 'Responsabilita Civile' }].map(
                (type, index) => (
                  <React.Fragment key={index}>
                    {quotations && quotations[type.id] && (
                    <RiskCard className={riskCardStyles(requestInfo, type)}>
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      {processingQuote !== null
                            && type.id === 'res_civile' && (
                              <>
                                {processingQuote
                                  && processingQuote.company_id === 2 && (
                                    <div className="col-12 mb-2">
                                      <QuotationCardResult
                                        requestInfo={requestInfo}
                                        processingQuote={processingQuote}
                                      />
                                    </div>
                                )}
                              </>
                      )}

                      {processingQuote !== null
                            && type.id === 'tutela_legale' && (
                              <>
                                {tutelaLegaleQuote === null ? (
                                  <div
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${type.id}`}
                                    style={{ cursor: 'pointer' }}
                                    className="alert alert-secondary"
                                  >
                                    Seleziona Quotazione!
                                  </div>
                                ) : (
                                  <div className="col-12 mb-2">
                                    <RiskCardSelected
                                      quote={tutelaLegaleQuote}
                                      type={type}
                                    />
                                  </div>
                                )}
                              </>
                      )}
                      <div className="d-flex justify-content-end ms-auto gap-2">
                        {type.id === 'res_civile'
                              && processingQuote !== null && (
                                <RiskButtonRemove
                                  className="btn btn-danger"
                                  onClick={() => removeItemFromCart(processingQuote.id)}
                                >
                                  Rimuovi
                                </RiskButtonRemove>
                        )}

                        {type.id === 'tutela_legale'
                            && tutelaLegaleQuote !== null ? (
                              <RiskButtonRemove
                                className="btn btn-danger"
                                onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}
                              >
                                Rimuovi
                              </RiskButtonRemove>
                          ) : type.id === 'tutela_legale'
                              && tutelaLegaleQuote === null
                              && processingQuote !== null ? (
                                <RiskButton
                                  className="btn btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${type.id}`}
                                >
                                  Aggiungi
                                </RiskButton>
                            ) : (
                              ''
                            )}

                        {requestInfo.processing_quotation_id === null
                              && type.id !== 'res_civile' && (
                                <div className="alert alert-warning py-2 px-2">
                                  Seleziona Prima La Responsibilita Civile
                                </div>
                        )}

                        {type.id === 'res_civile' && (
                        <RiskButton
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                        >
                          Aggiungi
                        </RiskButton>
                        )}
                      </div>
                    </RiskCard>
                    )}
                  </React.Fragment>
                ),
              )
              : quotationTypes.map((type, index) => (
                <React.Fragment key={index}>
                  {quotations && quotations[type.id] && (
                  <RiskCard
                    className={`mt-3 result-moto-third-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${
                      requestInfo.processing_quotation_id === null
                          && type.id !== 'res_civile'
                        ? 'bg-secondary bg-opacity-25'
                        : ''
                    } ${
                      requestInfo.processing_quotation_id !== null
                          && type.id === 'res_civile'
                        ? 'bg-success bg-opacity-25'
                        : ''
                    } ${
                      requestInfo.tutela_legale_quote_id !== null
                          && type.id === 'tutela_legale'
                        ? 'bg-success bg-opacity-25'
                        : ''
                    }`}
                  >
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    {processingQuote !== null
                          && type.id === 'res_civile' && (
                            <>
                              {processingQuote
                                && processingQuote.company_id === 2 && (
                                  <div className="col-12 mb-2">
                                    <QuotationCardResult
                                      requestInfo={requestInfo}
                                      processingQuote={processingQuote}
                                    />
                                  </div>
                              )}
                            </>
                    )}

                    {processingQuote !== null
                          && type.id === 'tutela_legale' && (
                            <>
                              {tutelaLegaleQuote === null ? (
                                <div
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${type.id}`}
                                  style={{ cursor: 'pointer' }}
                                  className="alert alert-secondary"
                                >
                                  Seleziona Quotazione!
                                </div>
                              ) : (
                                <div className="col-12 mb-2">
                                  <RiskCardSelected
                                    quote={tutelaLegaleQuote}
                                    type={type}
                                  />
                                </div>
                              )}
                            </>
                    )}
                    <div className="d-flex justify-content-end ms-auto gap-2">
                      {type.id === 'res_civile'
                            && processingQuote !== null && (
                              <RiskButtonRemove
                                className="btn btn-danger"
                                onClick={() => removeItemFromCart(processingQuote.id)}
                              >
                                Rimuovi
                              </RiskButtonRemove>
                      )}

                      {type.id === 'tutela_legale'
                          && tutelaLegaleQuote !== null ? (
                            <RiskButtonRemove
                              className="btn btn-danger"
                              onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}
                            >
                              Rimuovi
                            </RiskButtonRemove>
                        ) : type.id === 'tutela_legale'
                            && tutelaLegaleQuote === null
                            && processingQuote !== null ? (
                              <RiskButton
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target={`#${type.id}`}
                              >
                                Aggiungi
                              </RiskButton>
                          ) : (
                            ''
                          )}

                      {requestInfo.processing_quotation_id === null
                            && type.id !== 'res_civile' && (
                              <div className="alert alert-warning py-2 px-2">
                                Seleziona Prima La Responsibilita Civile
                              </div>
                      )}

                      {type.id === 'res_civile' && (
                      <RiskButton
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target={`#${type.id}`}
                      >
                        Aggiungi
                      </RiskButton>
                      )}
                    </div>
                  </RiskCard>
                  )}
                </React.Fragment>
              ))}

            {processingQuote !== null && processingQuote.company_id === 2 && (
              <>
                {processingQuote.quotation_data_prima.quotation_data_prima_guarantees.map(
                  (quote) => (
                    <>
                      {quote.slug !== 'rca' && (
                        <RiskCard
                          className={riskCardPrimaStyles(
                            primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            ),
                            processingQuote,
                          )}
                        >
                          <div className="p-3 me-auto">
                            <h5>
                              {
                                primaQuoteTypes.find(
                                  (type) => type.id === quote.slug,
                                ).name
                              }
                            </h5>
                          </div>
                          <SelectedQuotesCardsPrima
                            processingQuote={processingQuote}
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                          />

                          <ButtonComponentLogicPrima
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                            processingQuote={processingQuote}
                            requestInfo={requestInfo}
                          />
                        </RiskCard>
                      )}
                    </>
                  ),
                )}
              </>
            )}
          </RiskContainer>

          {quotationTypes.map((type, index) => (
            <RespCivileModal
              className="modal fade"
              id={type.id}
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              key={index}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {type.name}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="buttonClose"
                    />
                  </div>
                  <div className="modal-body">
                    {addingQuote && (
                      <div className="d-flex align-items-center justify-content-between flex-column">
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">
                              Aggiungendo...
                            </span>
                          </div>
                        </div>
                        <div className="ms-2">Aggiungendo...</div>
                      </div>
                    )}
                    {addingQuote === false && (
                      <div className="row mb-4">
                        <div className="row purchase-quote-eight-step">
                          {addingQuote === false
                          && quotations
                          && quotations[type.id]
                            ? quotations[type.id].map((quote, index) => (
                              <React.Fragment key={index}>
                                {quote.company_id === 2 && (
                                <div className="col-12 mb-2">
                                  <QuotationCard
                                    quote={quote}
                                    selectQuotation={selectQuotation}
                                    requestInfo={requestInfo}
                                    guaranteesPrima={guaranteesPrima}
                                  />
                                </div>
                                )}
                              </React.Fragment>
                            ))
                            : ''}

                          {type.id === 'tutela_legale'
                          && quotations
                          && quotations[type.id]
                            ? quotations[type.id].map((quote, index) => (
                              <React.Fragment key={index}>
                                {quote.company_id === 15 ? (
                                  <AragCard
                                    quotation={quote}
                                    selectQuotation={selectQuotation}
                                    requestInfo={requestInfo}
                                    type={type}
                                  />
                                ) : (
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuotation}
                                    requestInfo={requestInfo}
                                  />
                                )}
                              </React.Fragment>
                            ))
                            : ''}

                          {quotations
                            && quotations[type.id]
                            && quotations[type.id].length === 0 && (
                              <h5 className="fw-bold">
                                Nessuna Quotazione Trovata
                              </h5>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger px-4"
                      data-bs-dismiss="modal"
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </RespCivileModal>
          ))}

          {processingQuote !== null
            && processingQuote.company_id === 2
            && primaQuoteTypes.map((type, index) => (
              <ModalQuotesPrima
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                requestInfo={requestInfo}
                requestToken={requestToken}
                guaranteesPrima={guaranteesPrima}
              />
            ))}
          {failedQuotes !== null && failedQuotes.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </>
  );
}

export default RcMotoQuotes;
