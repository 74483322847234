import React, { useRef } from 'react';
import { RespCivileModal } from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import QuotationCard from '../../../common/reusable/QuotationCard';

function AncillariAutoModal({
  insuranceRequest,
  addingQuote,
  quotations,
  selectQuotation,
  type,
}) {
  const closeButtonRef = useRef(null);

  const closeButton = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click(); // Close the modal

      closeButtonRef.current = null;
    }
  };

  const selectQuote = (quoteId) => {
    selectQuotation(quoteId, closeButton);
  };

  return (
    <RespCivileModal
      className="modal fade"
      id={type.id}
      tabIndex="-1"
      aria-labelledby={type.name}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={type.name}>
              {type.id === 'res_civile' && insuranceRequest.product.id === 11
                ? 'Assistenza casa e famiglia'
                : type.id === 'res_civile' && insuranceRequest.product.id === 13 ? 'Assistenza' : type.name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="buttonCloseHard"
              ref={closeButtonRef}
            />
          </div>
          <div className="modal-body">
            {addingQuote && (
            <div className="d-flex align-items-center justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Aggiungendo...</span>
                </div>
              </div>
              <div className="ms-2">Aggiungendo...</div>
            </div>
            )}
            {addingQuote === false && quotations[type.id]
              ? quotations[type.id].map((quote, index) => (
                <div className="mt-3" key={index}>
                  <QuotationCard
                    requestInfo={insuranceRequest}
                    quote={quote}
                    selectQuotation={selectQuote}
                  />
                </div>
              ))
              : addingQuote === false && (
              <p>
                Nessuna Quotazione
                {type.name}
                {' '}
                Trovata.
              </p>
              )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger px-4"
              data-bs-dismiss="modal"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </RespCivileModal>
  );
}

export default AncillariAutoModal;
