import styled from 'styled-components';

export const EuropeAssistencaInfoModalStyles = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: 900px) {
    overflow-y: hidden;
  }
`;

export const ModalBody = styled.div`
  width: 80%;
  height: 80%;
  background-color: #ffffff;
  border-radius: 50px;

  @media only screen and (max-width: 900px) {
    width: 90%;
    height: 90%;
    overflow-y: hidden;
  }
`;

export const ModalHeader = styled.div`
  padding: 20px 30px;
  background: var(--grey-light-0, #fff);
  box-shadow: 0px 5.69277px 0px 0px rgba(46, 53, 85, 0.04);
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  margin: 10px 0;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 5%;

  @media only screen and (max-width: 900px) {
    margin-right: 0;
    gap: 15px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    p {
      font-size: 14px;
    }
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 0;
    color: var(--grey-dark-6, #1a1921);
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 79.699px */
  }

  p {
    color: var(--grey-dark-2, #616d87);
    font-family: Poppins;
    font-size: 26.566px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 39.849px */
    margin-bottom: 0;
  }

  img {
    max-width: 30px;
  }

  @media only screen and (max-width: 900px) {
    h3 {
      font-size: 18px;
    }

    img {
      max-width: 20px;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 20px 30px;
  overflow-y: auto;
  height: 76%;

  h3 {
    margin-bottom: 0;
    color: var(--grey-dark-6, #1a1921);
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0;

    b {
      font-weight: 600;
    }
  }

  .card-extra-information {
    overflow-y: hidden;
    height: auto;

    h3 {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 25px;
    height: 70%;
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }

    .card-extra-information {
      padding-bottom: 20px;
    }
  }
`;
