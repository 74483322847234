import axe from '../../services/axios-client/axiosClient';
import { generalAlertError, generalAlertErrorToast } from '../../common/utils/alerts';
import caller from './formsSlice';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';

const getFormData = () => quotationsAxe.get('/form-data/service-models');

const getMunicipalities = () => quotationsAxe.get('/form-data/communes');

const getPrivacyTerms = () => quotationsAxe.get('/form-data/data-privacy');

const getVehicleBrands = () => quotationsAxe.get('/form-data/vehicle-makes');

const getStates = () => quotationsAxe.get('/form-data/states');

const getProfessions = () => quotationsAxe.get('/form-data/professions');

const getVehicleActivities = () => quotationsAxe.get('/form-data/vehicle-activities');

const getTravelStates = () => quotationsAxe.get('/form-data/travel-states');

const getMotoBrands = () => quotationsAxe.get('/form-data/get-moto-brands');
/**
 * Auto Form
 */

export const autoSubmitAnswers = async (answersData, navigate) => {
  caller.autoFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsAxe.post(
    '/quotations/vehicles/quote',
    answersData,
  );

  caller.autoFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(answersData));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/${res.data.request_token}`);
};

export const motoSubmitAnswers = async (answersData, navigate) => {
  caller.motoFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsAxe.post(
    '/quotations/motorcycles/quote',
    answersData,
  );

  caller.motoFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(answersData));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/moto/${res.data.request_token}`);
};

export const autoChangeIndex = async (newIndex) => {
  caller.autoFormDataUpdated({ index: newIndex });
};

export const motoChangeIndex = async (newIndex) => {
  caller.motoFormDataUpdated({ index: newIndex });
};

export const autoGetVehicleInformation = async (vehiclePlate, getQuickInfo) => {
  caller.autoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-vehicle-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      getQuickInfo();
      return;
    }

    if (response.status !== 200) {
      getQuickInfo();
      return;
    }

    caller.autoFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    getQuickInfo();
    // generalAlertError(
    //   "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    // );
  }
};

export const motoGetVehicleInformation = async (vehiclePlate, getQuickInfo) => {
  caller.motoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-moto-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.motoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      getQuickInfo();
      return;
    }

    if (response.status !== 200) {
      // generalAlertError(
      //   "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
      // );
      getQuickInfo();
      // updateFormData({
      //   renew_check: false,
      //   new_risks_check: false,
      //   complete_manually: true,
      // });
      return;
    }

    caller.motoFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.motoFormDataUpdated({
      isLoadingFastQuote: false,
    });
    getQuickInfo();
    // generalAlertError(
    //   "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    // );
  }
};

export const ancillariAutoGetVehicleInformation = async (vehiclePlate, getQuickInfo) => {
  caller.ancillariAutoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-vehicle-ancillari-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.ancillariAutoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      getQuickInfo();
      return;
    }

    if (response.status !== 200) {
      getQuickInfo();
      return;
    }

    caller.ancillariAutoFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.ancillariAutoFormDataUpdated({
      isLoadingFastQuote: false,
    });
    getQuickInfo();
    // generalAlertError(
    //   "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    // );
  }
};

export const autoGetVehicleInformationWithSpeechToText = async (
  vehiclePlate,
) => {
  caller.autoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-vehicle-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 500) {
      generalAlertError(
        "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
      );
      return;
    }
    if (response.status == 400) {
      if (response.data.error === 'could_not_log_in') {
        generalAlertError(
          "Ops, c'è un problema. Rivolgiti al tuo consulente Greenia",
        );
      } else if (response.data.error === 'ania_locked') {
        generalAlertError(
          'Impossibile ottenere informazioni Ania. Riprovare più tardi.',
        );
      } else if (response.data.error === 'vehicle_data_not_exist') {
        generalAlertError(
          'La targa del veicolo non è stata trovata. Per favore compila manualmente!',
        );
      } else {
        generalAlertError('La targa del veicolo non è stata trovata');
      }
      return;
    }

    if (response.status !== 200) {
      generalAlertError(
        "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
      );
      return;
    }

    caller.autoFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });
    generalAlertError(
      "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    );
  }
};

export const autoCancelFastQuote = () => {
  caller.autoFormDataUpdated({
    fastQuoteVehicleInfo: null,
  });
};

export const ancillariAutoCancelFastQuote = () => {
  caller.ancillariAutoFormDataUpdated({
    fastQuoteVehicleInfo: null,
  });
};

export const autoSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.autoFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const autoGetInitialData = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [getStates(), getVehicleBrands(), getFormData(), getPrivacyTerms()],
  );

  caller.autoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.autoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const motoGetInitialData = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [getStates(), getMotoBrands(), getFormData(), getPrivacyTerms()],
  );

  caller.motoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.motoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autoUpdateFormData = (data) => {
  caller.autoFormDataUpdated(data);
};

export const autoGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.autoFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const autoGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const autoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.autoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const ancillariAutoGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.ancillariAutoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.ancillariAutoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const ancillariAutoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.ancillariAutoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.ancillariAutoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.ancillariAutoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const autoGetQuickInfo = async (vehiclePlate, updateFormData) => {
  caller.autoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await quotationsAxe.get(`/form-data/quick-info/auto/${vehiclePlate}`);

    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 200) {
      updateFormData(response.data.data, true);
    } else {
      generalAlertErrorToast('Non sono stati trovati dati! Per favore compila manualmente!');
      updateFormData({
        complete_manually: true,
        renew_check: false,
        new_risks_check: false,
      });
    }
  } catch (err) {
    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });
  }
};

export const motoGetQuickInfo = async (motoPlate, updateFormData) => {
  caller.motoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await quotationsAxe.get(`/form-data/quick-info/moto/${motoPlate}`);

    caller.motoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 200) {
      updateFormData(response.data.data, true);
    } else {
      generalAlertErrorToast('Non sono stati trovati dati! Per favore compila manualmente!');
      updateFormData({
        complete_manually: true,
        renew_check: false,
        new_risks_check: false,
      });
    }
  } catch (err) {
    caller.motoFormDataUpdated({
      isLoadingFastQuote: false,
    });
  }
};

export const ancillariAutoGetQuickInfo = async (vehiclePlate, updateFormData) => {
  caller.ancillariAutoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await quotationsAxe.get(`/form-data/quick-info/auto/${vehiclePlate}`);

    caller.ancillariAutoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 200) {
      updateFormData(response.data.data, true);
    } else {
      generalAlertErrorToast('Non sono stati trovati dati! Per favore compila manualmente!');
      updateFormData({
        complete_manually: true,
      });
    }
  } catch (err) {
    caller.ancillariAutoFormDataUpdated({
      isLoadingFastQuote: false,
    });
  }
};

export const resetAutoForm = () => {
  caller.resetAutoForm();
};

/**
 * Autocarro Form
 */

export const autocarroSubmitAnswers = async (answersData, navigate) => {
  caller.autocarroFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsAxe.post(
    '/quotations/vehicles/quote',
    answersData,
  );

  caller.autocarroFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(answersData));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/${res.data.request_token}`);
};

export const autocarroChangeIndex = async (newIndex) => {
  caller.autocarroFormDataUpdated({ index: newIndex });
};

export const autocarroGetVehicleInformation = async (vehiclePlate, getQuickInfo) => {
  caller.autocarroFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-vehicle-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      getQuickInfo();
      return;
    }

    if (response.status !== 200) {
      getQuickInfo();
      return;
    }

    caller.autocarroFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });
    // generalAlertError(
    //   "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    // );
    getQuickInfo();
  }
};

export const autocarroGetVehicleInformationWithSpeechToText = async (
  vehiclePlate,
) => {
  caller.autocarroFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await axe.get(
      `/fast-quote/get-vehicle-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      generalAlertError('La targa del veicolo non è stata trovata');
    }
    if (response.status == 400) {
      if (response.data.error === 'could_not_log_in') {
        generalAlertError(
          "Ops, c'è un problema. Rivolgiti al tuo consulente Greenia",
        );
      } else if (response.data.error === 'ania_locked') {
        generalAlertError(
          'Impossibile ottenere informazioni Ania. Riprovare più tardi.',
        );
      } else {
        generalAlertError('La targa del veicolo non è stata trovata');
      }
      return;
    }

    if (response.status !== 200) {
      generalAlertError(
        "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
      );
      return;
    }

    caller.autocarroFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });
    generalAlertError(
      "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    );
  }
};

export const autocarroCancelFastQuote = () => {
  caller.autocarroFormDataUpdated({
    fastQuoteVehicleInfo: null,
  });
};

export const autocarroSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.autocarroFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const autocarroGetQuickInfo = async (vehiclePlate, updateFormData) => {
  caller.autocarroFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await quotationsAxe.get(`/form-data/quick-info/auto/${vehiclePlate}`);

    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 200) {
      updateFormData(response.data.data, true);
    } else {
      generalAlertErrorToast('Non sono stati trovati dati! Per favore compila manualmente!');
      updateFormData({
        complete_manually: true,
        renew_check: false,
        new_risks_check: false,
      });
    }
  } catch (err) {
    caller.autocarroFormDataUpdated({
      isLoadingFastQuote: false,
    });
  }
};

export const autocarroGetInitialData = async () => {
  const [
    statesRes,
    brandsRes,
    formDataRes,
    dataPrivacyRes,
    vehicleActivitiesRes,
  ] = await Promise.all([
    getStates(),
    getVehicleBrands(),
    getFormData(),
    getPrivacyTerms(),
    getVehicleActivities(),
  ]);

  caller.autocarroFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    vehicleActivities: vehicleActivitiesRes.data.data.map((act) => ({
      id: act.code,
      name: act.description,
    })),
  });

  const municipalitiesRes = await getMunicipalities();

  caller.autocarroFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autocarroUpdateFormData = (data) => {
  caller.autocarroFormDataUpdated(data);
};

export const autocarroGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.autocarroFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const autocarroGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.autocarroFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.autocarroFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const autocarroGetVehicleVersions = async (
  model_id,
  imm_vehicle_year,
) => {
  caller.autocarroFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.autocarroFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.autocarroFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const resetAutocarroForm = () => {
  caller.resetAutocarroForm();
};

/**
 * Profession Form
 */
export const professionSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.professionFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const professionGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes, professionsRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
    getProfessions(),
  ]);

  caller.professionFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    professions: professionsRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.professionFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const professionUpdateFormData = (data) => {
  caller.professionFormDataUpdated(data);
};

export const professionGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.professionFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const professionSubmitAnswers = async (data, navigate) => {
  caller.professionFormDataUpdated({
    loading: true,
  });

  const res = await quotationsAxe.post('/quotations/professions/quote', data);
  navigate(`/quotes/profession/${res.data.data.request_token}`);
};

export const resetProfessionForm = () => {
  caller.resetProfessionForm();
};

/**
 * Infortuni Form
 */
export const infortuniSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.infortuniFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const infortuniGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.infortuniFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.infortuniFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const infortuniUpdateFormData = (data) => {
  caller.infortuniFormDataUpdated(data);
};

export const infortuniGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.infortuniFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const infortuniChangeIndex = async (newIndex) => {
  caller.infortuniFormDataUpdated({ index: newIndex });
};

export const infortuniSubmitAnswers = async (data) => {
  caller.infortuniFormDataUpdated({
    loading: true,
  });

  const res = await quotationsAxe.post('/quotations/infortuni/metlife', data);
  caller.infortuniFormDataUpdated({
    loading: false,
    requestToken: res.data.data,
  });
};

export const resetInfortuniForm = () => {
  caller.resetInfortuniForm();
};

/*
  InfoDrive
*/

export const infodriveGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes, brandsRes] = await Promise.all(
    [getStates(), getFormData(), getPrivacyTerms(), getVehicleBrands()],
  );

  caller.infodriveFromDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    brands: brandsRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.infodriveFromDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const infodriveFromDataUpdated = (data) => {
  caller.infodriveFromDataUpdated(data);
};

export const infodriveSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.infodriveFromDataUpdated({
    searchResults: res.data.data,
  });
};

export const infodriveGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.infodriveFromDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const infoDriveChangeIndex = async (newIndex) => {
  caller.infodriveFromDataUpdated({ index: newIndex });
};

export const infoDriveSubmitAnswers = async (data, navigate) => {
  caller.infodriveFromDataUpdated({
    isSubmitting: true,
    loading: true,
  });

  const res = await quotationsAxe.post('/quotations/infodrive/quote', data);

  caller.infodriveFromDataUpdated({
    isSubmitting: false,
    loading: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.data) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/quotes/infodrive/${res.data.data}`);
};

export const infoDriveGetVehicleModels = async (make_id) => {
  caller.infodriveFromDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-models', {
    params: { make_id },
  });
  caller.infodriveFromDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const resetInfodriveForm = () => {
  caller.resetInfoDriveForm();
};

/**
 * Travel Form
 */

export const travelGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.travelFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.travelFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });

  const travelStatesRes = await getTravelStates();

  caller.travelFormDataUpdated({
    travel_states: travelStatesRes.data.data,
  });
};

export const resetTravelForm = () => {
  caller.resetTravelForm();
};

export const travelUpdateFormData = (data) => {
  caller.travelFormDataUpdated(data);
};

export const travelSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.travelFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const travelGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.travelFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const travelSubmitAnswers = async (data, navigate) => {
  caller.travelFormDataUpdated({
    loading: true,
  });
  const res = await quotationsAxe.post('/quotations/travel/quote', data);

  caller.travelFormDataUpdated({
    loading: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(data));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/travel/${res.data.request_token}`);
};

export const resetMotoForm = () => {
  caller.resetMotoForm();
};

export const motoUpdateFormData = (data) => {
  caller.motoFormDataUpdated(data);
};

export const motoGetVehicleModels = async (make_id) => {
  caller.motoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get(
    `/form-data/get-moto-models/${make_id}`,
    {},
  );

  caller.motoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const motoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.motoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsAxe.get('/form-data/get-moto-version', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.motoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.motoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const motoCancelFastQuote = () => {
  caller.motoFormDataUpdated({
    fastQuoteVehicleInfo: null,
  });
};

export const motoSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.motoFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const motoGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.motoFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

/**
 * Commercial Form
 */
export const commercialChangeStep = async (step) => {
  caller.commercialFormDataUpdated({ step });
};

export const commercialInitialData = async () => {
  const [statesRes, municipalitiesRes] = await Promise.all([
    getStates(),
    getMunicipalities(),
  ]);

  caller.commercialFormDataUpdated({
    loading: false,
    municipalities: municipalitiesRes.data.data,
    states: statesRes.data.data,
  });
};

export const resetCommercialForm = () => {
  caller.resetCommercialForm();
};

// tutela legale
export const tutelaLegaleGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.tutelaLegaleFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.tutelaLegaleFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const tutelaLegaleUpdateFormData = (data) => {
  caller.tutelaLegaleFormDataUpdated(data);
};

export const resetTutelaLegaleForm = () => {
  caller.resetTutelaLegaleForm();
};

export const tutelalegaleSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.tutelaLegaleFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const tutelaLegaleGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.tutelaLegaleFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const tutelaLegaleSubmitAnswers = async (data, navigate) => {
  caller.tutelaLegaleFormDataUpdated({
    loading: true,
  });

  const res = await quotationsAxe.post('/quotations/tutela-legale/quote', data);
  navigate(`/quotes/tutela-legale/${res.data.data.request_token}`);
};

/** HOUSE PRODUCT * */

export const houseGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.houseFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.houseFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const resetHouseForm = () => {
  caller.resetHouseForm();
};

export const houseUpdateFormData = (data) => {
  caller.houseFormDataUpdated(data);
};

export const houseSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.houseFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const houseGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.houseFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const houseSubmitAnswers = async (data, navigate) => {
  caller.houseFormDataUpdated({
    loading: true,
  });
  const res = await quotationsAxe.post('/quotations/house/quote', data);

  caller.houseFormDataUpdated({
    loading: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(data));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/casa/${res.data.request_token}`);
};

/** Salute PRODUCT * */

export const saluteGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.saluteFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.saluteFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const resetSaluteForm = () => {
  caller.resetSaluteForm();
};

export const saluteUpdateFormData = (data) => {
  caller.saluteFormDataUpdated(data);
};

export const saluteSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.saluteFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const saluteGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.saluteFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const saluteSubmitAnswers = async (data, navigate) => {
  caller.saluteFormDataUpdated({
    loading: true,
  });
  const res = await quotationsAxe.post('/quotations/salute/quote', data);

  caller.saluteFormDataUpdated({
    loading: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(data));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/salute/${res.data.request_token}`);
};

/** ANCILLARI AUTO PRODUCT * */

export const ancillariAutoGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes, brandsRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
    getVehicleBrands(),
  ]);

  caller.ancillariAutoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    brands: brandsRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.ancillariAutoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const resetAncillariAutoForm = () => {
  caller.resetAncillariAutoForm();
};

export const ancillariAutoUpdateFormData = (data) => {
  caller.ancillariAutoFormDataUpdated(data);
};

export const ancillariAutoSearchActiveClient = async (search) => {
  const res = await axe.get('/customers/search/by-name', {
    params: { search },
  });
  caller.ancillariAutoFormDataUpdated({
    searchResults: res.data.data,
  });
};

export const ancillariAutoGetCustomerData = async (customerId) => {
  const res = await axe.get(`/active-customers/${customerId}`);
  const resBody = res.data;
  caller.ancillariAutoFormDataUpdated({
    selectedClient: resBody.data,
    selectedClientOldData: {
      ...resBody.data,
      date_of_birth:
        resBody.data.date_of_birth !== null
          ? convertToItalianDate(resBody.data.date_of_birth)
          : null,
    },
    selectedClientId: customerId,
  });
};

export const ancillariAutoSubmitAnswers = async (data, navigate) => {
  caller.ancillariAutoFormDataUpdated({
    loading: true,
  });
  const res = await quotationsAxe.post('/quotations/ancillari-auto/quote', data);

  caller.ancillariAutoFormDataUpdated({
    loading: false,
  });

  if (res.status === 400) {
    generalAlertError(
      res.data.error.map((detail) => detail.message).join('\n'),
    );
    return;
  }

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  localStorage.setItem('UI_request', JSON.stringify(data));
  localStorage.setItem('SE_response', JSON.stringify(res.data));
  navigate(`/quotes/ancillari-auto/${res.data.request_token}`);
};

export const ancillariAutoChangeIndex = async (newIndex) => {
  caller.ancillariAutoFormDataUpdated({ index: newIndex });
};
