import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AdriaticInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer, TitleBox,
} from './AdriaticInfoModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function TutelaLegaleInfoModal({
  quotation, setShowModal,
}) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <AdriaticInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>
          {quotation.tax_total !== null && (
            <>
              <h3>Garanzie</h3>
              <table className="custom-table-compact">
                <thead>
                  <tr>
                    <th>Garanzia</th>
                    <th>Massimale</th>
                    <th style={{ textAlign: 'right' }}>Prezzo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="custom-table-row-compact">
                    <td>{quotation.product_name}</td>
                    <td>
                      {Number(quotation.massimale).toLocaleString(
                        'it-IT',
                        { minimumFractionDigits: 2 },
                      )}
                      €
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {Number(quotation.amount).toLocaleString('it-IT', {
                        minimumFractionDigits: 2,
                      })}
                      €
                    </td>
                  </tr>
                  <tr className="custom-table-row-compact">
                    <td>
                      <b>Totale</b>
                    </td>
                    <td />
                    <td style={{ textAlign: 'right' }}>
                      <b>
                        {Number(quotation.amount).toLocaleString(
                          'it-IT',
                          { minimumFractionDigits: 2 },
                        )}
                        €
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <div dangerouslySetInnerHTML={{ __html: quotation?.information }} />
          </div>
        </ModalContent>
      </ModalBody>
    </AdriaticInfoModalStyles>
  );
}

export default TutelaLegaleInfoModal;
