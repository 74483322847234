import Swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

export const generalAlertSuccessToast = (message) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertErrorToast = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Errore',
    color: '#ffffff',
    background: '#FF8585',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertSuccess = (message, action = null) => {
  if (action) {
    Swal.fire({
      icon: 'success',
      title: 'Successo',
      text: message,
    }).then(() => { action(); });
  } else {
    Swal.fire({
      icon: 'success',
      title: 'Successo',
      text: message,
    });
  }
};

export const generalAlertError = (message, errorTitle) => {
  Swal.fire({
    icon: 'error',
    title: errorTitle ?? 'Errore',
    text: message,
  });
};

export const confirmAlert = (message, action, cancelAction = () => { }, title = null) => {
  Swal.fire({
    title: title || 'Sei Sicuro?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancella!',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    } else {
      cancelAction();
    }
  });
};

export const confirmAlertFastQuote = (message, action, secondAction) => {
  Swal.fire({
    title: 'Fast Quote',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#00a651',
    confirmButtonText: 'Procedi',
    cancelButtonText: 'No',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    }

    if (isConfirm.isDismissed) {
      secondAction();
    }
  });
};

export const confirmAlertForBackofficeBrokerQuote = (
  message,
  action,
  navigate,
  requestToken,
) => {
  Swal.fire({
    title: 'Sei sicuro di procedere?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff0000',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancella!',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    } else {
      navigate(`/quotes/${requestToken}`);
    }
  });
};
