import styled from 'styled-components';

export const PrimaCardStyles = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &.selected {
    border: 2px solid #00a551;
  }

  @media only screen and (max-width: 1200px) {
    padding: 15px 20px;
    flex-direction: column;
  }
`;

export const LeftCol = styled.div``;
export const RightCol = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const LogoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }
`;
export const LogoContainer = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 130px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;

export const LogoResultContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 70px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const Price = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #00a551;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 9999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const PriceResult = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #00a551;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const CompanyInformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
`;

export const CompanyInfoElements = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    color: #1a1921;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }

  img {
    max-width: 20px;
  }

  @media only screen and (max-width: 1200px) {
    img {
      max-width: 15px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export const QuoteInformationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 15px;
    margin-top: 15px;
  }
`;

export const QuoteInformationResultContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
    margin-top: 10px;
  }
`;

export const QuoteInformationItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const QuoteInformationResultItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const AquistaButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
  }
`;

export const AddGuaranteesButton = styled.button`
  border-radius: 15px;
  background: #dff3ea;
  border: 1px solid #dff3ea;
  padding: 12px 40px;
  color: #00a651;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #00a651;
    color: #00a651;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 12px;
    padding: 6px 15px;
    border-radius: 10px;
  }
`;

export const AquistaButton = styled.button`
  border-radius: 15px;
  background: #00a651;
  border: 1px solid #00a651;
  padding: 12px 40px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: transparent;
    border: 1px solid #00a651;
    color: #00a651;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 12px;
    padding: 6px 15px;
    border-radius: 10px;
  }
`;

export const SelectedGuaranteesContainer = styled.div`
  height: 100%;
  width: 100%;
  h2 {
    margin-bottom: 0;
    color: var(--common-black, #1a1921);
    text-align: right;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 15px;
    h2 {
      font-size: 18px;
      text-align: start;
    }
  }
`;

export const GuaranteesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 5px;
  margin-top: auto;
  height: 100%;
`;

export const GuaranteesListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  h6 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #00a551;
    }
  }

  p {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 14px;

      &::before {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #00a551;
      }
    }

    p {
      font-size: 16px;
    }
  }
`;
