import React from 'react';
import { LogoContainer } from '../QuotationCard.styles';
import { BACKEND_BASE_URL } from '../../../config';

function LogoComponent({ quote }) {
  return (
    <LogoContainer>
      {quote.company && (
        <img
          src={BACKEND_BASE_URL + quote.company.logo}
          alt={`${quote.company.name} Logo`}
        />
      )}
      {quote.preventivas_company && (
        <img
          src={`${BACKEND_BASE_URL}/preventivass/companies/${quote.preventivas_company.logo_filename}`}
          alt={`${quote.preventivas_company.company_name} Logo`}
        />
      )}
    </LogoContainer>
  );
}

export default LogoComponent;
