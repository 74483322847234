import styled from 'styled-components';

export const RcQuotesStyles = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 1400px;
    margin: auto;

    .failed-quotations-block {
        margin-top: 120px;
    }
    .failed-quotations-block img {
        width: 100%;
        display: block;
        aspect-ratio: 6/3;
        object-fit: contain;
        mix-blend-mode: multiply;
    }
`;

export const RiskButtonRemove = styled.button`
  border-radius: 15px;
    /* background: #00a651; */
    border: 1px solid #DD4D44;
    padding: 10px 30px;
    /* color: #ffffff; */
    /* width: 75%; */
    transition: all 300ms ease-in-out;

    &:hover {
      /* background: transparent; */
      border: 1px solid #DD4D44;
      /* color: #00a651; */
    }
`;

export const RiskButton = styled.button`
  border-radius: 15px;
    /* background: #00a651; */
    border: 1px solid #00a651;
    padding: 10px 30px;
    /* color: #ffffff; */
    /* width: 75%; */
    transition: all 300ms ease-in-out;

    &:hover {
      /* background: transparent; */
      border: 1px solid #00a651;
      /* color: #00a651; */
    }
`;

export const RespCivileModal = styled.div`

  @media screen and (min-width: 800px) {
   .modal-dialog{
    max-width: 1000px;
   }
    
  }
`;
