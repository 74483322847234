import React from 'react';

function CompanyQualityComponent({ quote }) {
  return (
    <>
      {quote.company_quality && (
        <div className="row mb-3">
          <div className="col-12">
            <div
              className={`p-2 badge ${
                quote.company_quality.color === 'green'
                  ? 'bg-success'
                  : quote.company_quality.color === 'yellow'
                    ? 'bg-warning'
                    : 'bg-danger'
              }`}
            >
              <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                Indice Di Qualità:
                {' '}
                <b className="fs-6">
                  {parseFloat(quote.company_quality.coefficiente).toFixed(2)}
                </b>
              </p>
            </div>
          </div>
        </div>
      )}
      {quote.preventivas_company_quality && (
        <div className="row mb-3">
          <div className="col-12">
            <div
              className={`p-2 badge ${
                quote.preventivas_company_quality.color === 'green'
                  ? 'bg-success'
                  : quote.preventivas_company_quality.color === 'yellow'
                    ? 'bg-warning'
                    : 'bg-danger'
              }`}
            >
              <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                Indice Di Qualità:
                {' '}
                <b className="fs-6">
                  {parseFloat(
                    quote.preventivas_company_quality.coefficiente,
                  ).toFixed(2)}
                </b>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyQualityComponent;
