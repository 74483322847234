import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import {
  RcQuotesStyles,
  FailedQuoteTitle,
  FailedQuotesContainer,
} from '../../RcQuotes.styles';
import {
  loadMotoQuotes,
  updateMotoQuotesData,
  resetMotoQuotes,
  addQuoteMotoToCartBackoffice,
  updateSortByMoto,
  removeQuoteMotoBackofficeFromCart,
} from '../../../features/backofficeResults/backofficeResultsActions';
// import { generalAlertError } from '../../../common/utils/alerts';
import MotoInfoCard from '../../../common/components/redesignComponents/Cards/MotoInfoCard';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import GuaranteesMotoModal from '../components/common/Modal/GuaranteesMotoModal';
import { coveragesUtil } from '../../../utils/coverages';
import { primaQuoteTypes, quotationTypes } from '../../../common/constants';
import { GuaranteesDefaultStateStyles } from '../../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { confirmAlert } from '../../../common/utils/alerts';
import {
  RiskButton,
  RiskButtonRemove,
  RiskCard,
  RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import ModalQuotesBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ModalQuotesBackoffice';
import PrimaResultCard from '../../../common/components/redesignComponents/Cards/PrimaResultCard';
import RiskCardSelected from '../../../common/components/redesignComponents/Cards/RiskCardSelected';
import {
  riskCardPrimaStyles,
  riskCardStyles,
} from '../../../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';

import ModalQuotesPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ModalQuotesPrimaBackoffice';
import SelectedQuotesCardsPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsPrimaBackoffice';
import ButtonComponentLogicPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicPrimaBackoffice';

function BackOfficeMotoQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const [showGuaranteesModal, setShowGuaranteesModal] = useState(false);
  const clearQuotesIntervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const [intervalQuotes, setIntervalQuotes] = useState(null);
  const state = useSelector((store) => store.backofficeResults.moto_quotes);
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    // quotationAquistaLoading,
    quotations,
    processingQuote,
    tutelaLegaleQuote,
    addingQuote,
    assistenzaStradaleQuote,
    infortuniConducenteQuote,
    cristalliQuote,
    rivalsaQuote,
    sortBy,
    preventivas_quotes,
  } = state;
  const [counter, setCounter] = useState(180);
  const [currentQuotes, setCurrentQuotes] = useState('diretta');

  const handleCurrentQuotesDisplay = (val) => {
    setCurrentQuotes(val);
  };

  const guaranteesPrima = (quote) => {
    const guarantees = quote.quotation_data_prima_guarantees
      .map((guarantee) => guarantee.slug)
      .filter((g) => g != 'rca' && g != 'furto_incendio');
    if (
      quote.quotation_data_prima_guarantees.find(
        (qg) => qg.slug == 'furto_incendio',
      ) != undefined
    ) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  // const updateCoverages = (item) => {
  //   toggleMotoCoveragesAction(item.id);
  // };

  // const includesCoverage = (key) => coverages !== null && coverages.includes(key);

  useEffect(() => {
    loadMotoQuotes(requestToken);

    return () => {
      resetMotoQuotes();
    };
  }, []);

  const clearQuotesInterval = () => {
    if (intervalQuotes !== null) {
      clearInterval(intervalQuotes);
      setIntervalQuotes(null);
    }
  };

  const refresh = () => loadMotoQuotes(requestToken);

  clearQuotesIntervalRef.current = clearQuotesInterval;
  requestInfoRef.current = requestInfo;

  const sortQuotes = (sortBy) => {
    updateSortByMoto(sortBy);
  };

  useEffect(() => {
    const iq = setInterval(() => {
      const requestInfo = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadMotoQuotes(requestToken);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesIntervalRef.current();
        }
      }
    }, 10000);

    setIntervalQuotes(iq);

    return () => {
      clearQuotesIntervalRef.current();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateMotoQuotesData(requestInfo);
    }
  }, [requestInfo]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const modifyData = () => {
    navigate(`/backoffice/quote/${requestToken}/moto`);
  };

  const fetchQuotes = () => {
    loadMotoQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteMotoToCartBackoffice(quoteId, fetchQuotes));
  };

  // const handleRequestSave = () => {
  //   if (processingQuote === null) {
  //     generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
  //     return;
  //   }

  //   navigate(`/quotes/moto/purchase/${requestToken}`);
  // };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteMotoBackofficeFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let primaGuaranteesPrices = 0;

    if (processingQuote !== null) {
      if (processingQuote.company_id === 2) {
        quoteInProccessAmount = parseFloat(
          processingQuote.quotation_data_prima.quotation_data_prima_guarantees.find(
            (price) => price.slug === 'rca',
          ).quotation_data_prima_guarantee_prices[0].discounted_to_display,
        );
      } else {
        quoteInProccessAmount = parseFloat(processingQuote.amount);
      }
    }

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    if (processingQuote.company_id === 2) {
      processingQuote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    return (quoteInProccessAmount + primaGuaranteesPrices).toFixed(2);
  };

  if (loading) {
    return <PageLoading />;
  }

  const coverages = coveragesUtil.calculateCommonCoverages(
    requestInfo?.product_id ?? 2,
    quotes ?? [],
  );

  return (
    <>
      <div className="row form-control">
        {showGuaranteesModal && (
          <GuaranteesMotoModal
            setShowGuaranteesModal={setShowGuaranteesModal}
            coverages={coverages}
            requestToken={requestToken}
          />
        )}

        <RcQuotesStyles>
          <div className="resultsPage">
            <div className="row">
              <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0">
                <MotoInfoCard
                  insuranceRequest={requestInfo}
                  modificaDati={modifyData}
                />
              </div>
              <div className="col-12 col-xxl-8">
                <GuaranteesDefaultStateStyles>
                  <div className="container mt-4">
                    {processingQuote === null && tutelaLegaleQuote === null ? (
                      <h5 className="fw-bold">
                        Seleziona Le Quotazioni Che Vuoi Aquistare
                      </h5>
                    ) : (
                      <>
                        <ul className="list-unstyled">
                          {processingQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {processingQuote.company.name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id === processingQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(processingQuote.amount).toFixed(
                                    2,
                                  )}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {tutelaLegaleQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {tutelaLegaleQuote.company.name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id
                                          === tutelaLegaleQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(tutelaLegaleQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {assistenzaStradaleQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {assistenzaStradaleQuote.company !== null
                                    ? assistenzaStradaleQuote.company.name
                                    : assistenzaStradaleQuote
                                      .preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id
                                          === assistenzaStradaleQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(assistenzaStradaleQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {infortuniConducenteQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {infortuniConducenteQuote.company !== null
                                    ? infortuniConducenteQuote.company.name
                                    : infortuniConducenteQuote
                                      .preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id
                                          === infortuniConducenteQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(infortuniConducenteQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {rivalsaQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {rivalsaQuote.company !== null
                                    ? rivalsaQuote.company.name
                                    : rivalsaQuote.preventivas_company
                                      .company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id === rivalsaQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(rivalsaQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {cristalliQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {cristalliQuote.company !== null
                                    ? cristalliQuote.company.name
                                    : cristalliQuote.preventivas_company
                                      .company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id === cristalliQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(cristalliQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {processingQuote !== null
                            && processingQuote.company_id === 2
                            && processingQuote.guarantees.map(
                              (guarantee, index) => (
                                <li key={index}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      Prima.it
                                      <sub>
                                        {' '}
                                        (
                                        {
                                          primaQuoteTypes.find(
                                            (type) => type.id
                                              === guarantee.guarantees_slug,
                                          ).name
                                        }
                                        )
                                      </sub>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(guarantee.amount)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ),
                            )}
                          <hr />
                          <li>
                            <div className="d-flex justify-content-end align-items-center">
                              <h5 className="me-5 fw-bold">Totale:</h5>
                              <h5 className="fw-bold">
                                {' '}
                                {getTotal()}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        </ul>
                        {/* <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-success rounded-4 px-5 py-2">
                              Aquista
                            </button>
                          </div> */}
                      </>
                    )}
                  </div>
                </GuaranteesDefaultStateStyles>
                {/* <GuaranteesDefaultState
                  setShowGuaranteesModal={setShowGuaranteesModal}
                  quotes={quotes}
                /> */}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <h4>
                    Preventivi assicurazione
                    {' '}
                    <b>Moto</b>
                  </h4>
                  <p>
                    Inizio Copertura:
                    {' '}
                    {requestInfo.vehicle !== null
                      && new Date(
                        requestInfo.policy_effective_date,
                      ).toLocaleDateString('it-IT', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      })}
                    <br />
                  </p>
                </EstimateContainer>
              </div>
            </div>

            <RiskContainer className="mb-4 ">
              {processingQuote !== null && processingQuote.company_id === 2
                ? [{ id: 'res_civile', name: 'Responsabilita Civile' }].map(
                  (type, index) => (
                    <React.Fragment key={index}>
                      {quotations && quotations[type.id] && (
                      <RiskCard
                        className={riskCardStyles(requestInfo, type)}
                      >
                        <div className="p-3 me-auto">
                          <h5>{type.name}</h5>
                        </div>
                        {processingQuote !== null
                              && type.id === 'res_civile' && (
                                <>
                                  {processingQuote
                                    && processingQuote.company_id === 2 && (
                                      <div className="col-12 mb-2">
                                        <PrimaResultCard
                                          requestInfo={requestInfo}
                                          processingQuote={processingQuote}
                                        />
                                      </div>
                                  )}
                                </>
                        )}

                        {processingQuote !== null
                              && type.id === 'tutela_legale' && (
                                <>
                                  {tutelaLegaleQuote === null ? (
                                    <div
                                      data-bs-toggle="modal"
                                      data-bs-target={`#${type.id}`}
                                      style={{ cursor: 'pointer' }}
                                      className="alert alert-secondary"
                                    >
                                      Seleziona Quotazione!
                                    </div>
                                  ) : (
                                    <div className="col-12 mb-2">
                                      <RiskCardSelected
                                        quote={tutelaLegaleQuote}
                                        type={type}
                                      />
                                    </div>
                                  )}
                                </>
                        )}
                        <div className="d-flex justify-content-end ms-auto gap-2">
                          {type.id === 'res_civile'
                                && processingQuote !== null && (
                                  <RiskButtonRemove
                                    className="btn btn-danger"
                                    onClick={() => removeItemFromCart(processingQuote.id)}
                                  >
                                    Rimuovi
                                  </RiskButtonRemove>
                          )}

                          {type.id === 'tutela_legale'
                              && tutelaLegaleQuote !== null ? (
                                <RiskButtonRemove
                                  className="btn btn-danger"
                                  onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}
                                >
                                  Rimuovi
                                </RiskButtonRemove>
                            ) : type.id === 'tutela_legale'
                                && tutelaLegaleQuote === null
                                && processingQuote !== null ? (
                                  <RiskButton
                                    className="btn btn-success"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${type.id}`}
                                  >
                                    Aggiungi
                                  </RiskButton>
                              ) : (
                                ''
                              )}

                          {requestInfo.processing_quotation_id === null
                                && type.id !== 'res_civile' && (
                                  <div className="alert alert-warning py-2 px-2">
                                    Seleziona Prima La Responsibilita Civile
                                  </div>
                          )}

                          {type.id === 'res_civile' && (
                          <RiskButton
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target={`#${type.id}`}
                          >
                            Aggiungi
                          </RiskButton>
                          )}
                        </div>
                      </RiskCard>
                      )}

                      {processingQuote !== null
                          && processingQuote.company_id === 2 && (
                            <>
                              {processingQuote.quotation_data_prima.quotation_data_prima_guarantees.map(
                                (quote) => (
                                  <>
                                    {quote.slug !== 'rca' && (
                                      <RiskCard
                                        className={riskCardPrimaStyles(
                                          primaQuoteTypes.find(
                                            (type) => type.id === quote.slug,
                                          ),
                                          processingQuote,
                                        )}
                                      >
                                        <div className="p-3 me-auto">
                                          <h5>
                                            {
                                              primaQuoteTypes.find(
                                                (type) => type.id === quote.slug,
                                              ).name
                                            }
                                          </h5>
                                        </div>
                                        <SelectedQuotesCardsPrimaBackoffice
                                          processingQuote={processingQuote}
                                          type={primaQuoteTypes.find(
                                            (type) => type.id === quote.slug,
                                          )}
                                        />

                                        <ButtonComponentLogicPrimaBackoffice
                                          type={primaQuoteTypes.find(
                                            (type) => type.id === quote.slug,
                                          )}
                                          processingQuote={processingQuote}
                                          requestInfo={requestInfo}
                                        />
                                      </RiskCard>
                                    )}
                                  </>
                                ),
                              )}
                            </>
                      )}
                    </React.Fragment>
                  ),
                )
                : quotationTypes.map((type, index) => (
                  <React.Fragment key={index}>
                    {quotations && quotations[type.id] && (
                    <RiskCard className={riskCardStyles(requestInfo, type)}>
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      {processingQuote !== null
                            && type.id === 'res_civile' && (
                              <>
                                {processingQuote
                                  && processingQuote.company_id === 2 && (
                                    <div className="col-12 mb-2">
                                      <PrimaResultCard
                                        requestInfo={requestInfo}
                                        processingQuote={processingQuote}
                                      />
                                    </div>
                                )}
                              </>
                      )}

                      {processingQuote !== null
                            && type.id === 'tutela_legale' && (
                              <>
                                {tutelaLegaleQuote === null ? (
                                  <div
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${type.id}`}
                                    style={{ cursor: 'pointer' }}
                                    className="alert alert-secondary"
                                  >
                                    Seleziona Quotazione!
                                  </div>
                                ) : (
                                  <div className="col-12 mb-2">
                                    <RiskCardSelected
                                      quote={tutelaLegaleQuote}
                                      type={type}
                                    />
                                  </div>
                                )}
                              </>
                      )}
                      <div className="d-flex justify-content-end ms-auto gap-2">
                        {type.id === 'res_civile'
                              && processingQuote !== null && (
                                <RiskButtonRemove
                                  className="btn btn-danger"
                                  onClick={() => removeItemFromCart(processingQuote.id)}
                                >
                                  Rimuovi
                                </RiskButtonRemove>
                        )}

                        {type.id === 'tutela_legale'
                            && tutelaLegaleQuote !== null ? (
                              <RiskButtonRemove
                                className="btn btn-danger"
                                onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}
                              >
                                Rimuovi
                              </RiskButtonRemove>
                          ) : type.id === 'tutela_legale'
                              && tutelaLegaleQuote === null
                              && processingQuote !== null ? (
                                <RiskButton
                                  className="btn btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#${type.id}`}
                                >
                                  Aggiungi
                                </RiskButton>
                            ) : (
                              ''
                            )}

                        {requestInfo.processing_quotation_id === null
                              && type.id !== 'res_civile' && (
                                <div className="alert alert-warning py-2 px-2">
                                  Seleziona Prima La Responsibilita Civile
                                </div>
                        )}

                        {type.id === 'res_civile' && (
                        <RiskButton
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                        >
                          Aggiungi
                        </RiskButton>
                        )}
                      </div>
                    </RiskCard>
                    )}
                  </React.Fragment>
                ))}
            </RiskContainer>

            {quotationTypes.map((type, index) => (
              <ModalQuotesBackoffice
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={sortQuotes}
                handleCurrentQuotesDisplay={handleCurrentQuotesDisplay}
                currentQuotes={currentQuotes}
                requestInfo={requestInfo}
                counter={counter}
                quotations={quotations}
                requestToken={requestToken}
                refresh={refresh}
                selectQuotation={selectQuotation}
                guaranteesPrima={guaranteesPrima}
                preventivas_quotes={preventivas_quotes}
              />
            ))}

            {processingQuote !== null
              && processingQuote.company_id === 2
              && primaQuoteTypes.map((type, index) => (
                <ModalQuotesPrimaBackoffice
                  processingQuote={processingQuote}
                  key={index}
                  type={type}
                  addingQuote={addingQuote}
                  requestInfo={requestInfo}
                  requestToken={requestToken}
                  guaranteesPrima={guaranteesPrima}
                />
              ))}

            {/* <div className="row mb-4">
              <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                <button
                  className={`btn ${currentQuotes === 'diretta'
                    ? 'btn-success'
                    : 'btn-outline-success'}`}
                  onClick={() => handleCurrentQuotesDisplay('diretta')}
                >
                  Compagnie Dirette
                </button>
                <button
                  className={`btn ${currentQuotes === 'indiretta'
                    ? 'btn-success'
                    : 'btn-outline-success'}`}
                  onClick={() => handleCurrentQuotesDisplay('indiretta')}
                >
                  Compagnie Indirette
                </button>

              </div>
              {quotes
                && (currentQuotes === 'diretta' || currentQuotes === 'indiretta')
                && quotes.map((quote, index) => (currentQuotes === 'diretta' ? (
                  <React.Fragment key={index}>
                    {quote.company_id === 1 && (
                      <div className="col-12 mb-2" key={quote.id}>
                        <AdriaticCard
                          requestToken={requestToken}
                          requestInfo={requestInfo}
                          key={quote.id}
                          quote={quote.quotation_data_adriatic}
                          quotation={quote}
                          company={quote.company}
                          saved_quote={quote.saved_quotation}
                        />
                      </div>
                    )}
                    {quote.company_id === 2 && (
                      <div className="col-12 mb-2">
                        <PrimaCardMoto
                          requestToken={requestToken}
                          requestInfo={requestInfo}
                          key={quote.id}
                          quotation={quote}
                          saved_quote={quote.saved_quotation}
                          guaranteesPrima={guaranteesPrima}
                          quotationAquistaLoading={quotationAquistaLoading}
                        />
                      </div>
                    )}
                  </React.Fragment>
                ) : currentQuotes === 'indiretta' ? (
                  <>
                    {quote.company_id === null && (
                      <div className="col-12 mb-2">
                        <PreventivassCard
                          requestInfo={requestInfo}
                          key={quote.id}
                          quotation={quote}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )))}
            </div> */}

            {failedQuotes !== null && failedQuotes.length > 0 && (
              <div className="row mt-5">
                <FailedQuoteTitle>
                  Queste compagnie non hanno risposto con una quotazione.
                </FailedQuoteTitle>
                {failedQuotes.map((quote, index) => (
                  <div className="col-3" key={index}>
                    <FailedQuotesContainer>
                      <img
                        src={BACKEND_BASE_URL + quote.company.logo}
                        alt={`${quote.company.name} Logo`}
                      />
                    </FailedQuotesContainer>
                  </div>
                ))}
              </div>
            )}
          </div>
        </RcQuotesStyles>
      </div>
    </>
  );
}

export default BackOfficeMotoQuotes;
