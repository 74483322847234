import styled from 'styled-components';

export const UppyContainer = styled.div`
  .uppy-Dashboard-inner {
    height: 200px !important;
    max-width: 300px;
    background-color: #fff;
    border: 2px dashed #00A551;
    border-radius: 15px;
  }

  .uppy-Dashboard-AddFiles-info {
    display: block;
    padding-top: 10px !important;
  }

  .uppy-Dashboard-AddFiles {
    border: none;
  }

  .uppy-Dashboard-note {
    color: #00A551;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .uppy-Dashboard-AddFiles-list {
    display: none;
  }

  .uppy-Dashboard-AddFiles-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
  }

  .uppy-Dashboard-AddFiles-title::before {
    content: url(${(props) => props.icon});
    transform: scale(0.7);
  }

   .uppy-Dashboard-AddFiles-title::after {
    content: 'sfoglia.';
  }

  .uppy-Dashboard-browse {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: none;
    color: transparent;
  }

  @media (max-width: 530px) {
    .uppy-Dashboard-inner {
      max-width: 300px;
    }

    .uppy-Dashboard-AddFiles-title {
      font-size: 10px;
    }

    .uppy-Dashboard-AddFiles-title::before {
      transform: scale(0.5);
    }

    .uppy-Dashboard-browse {
      font-size: 12px;
    }

    .uppy-Dashboard-note {
      font-size: 12px;
    }
  }

  @media (min-width: 531px) and (max-width: 768px) {
    .uppy-Dashboard-inner {
      max-width: 230px;
    }

    .uppy-Dashboard-AddFiles-title {
      font-size: 10px;
    }

    .uppy-Dashboard-AddFiles-title::before {
      transform: scale(0.5);
    }

    .uppy-Dashboard-browse {
      font-size: 12px;
    }

    .uppy-Dashboard-note {
      font-size: 12px;
    }
  }
`;
