import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from '../components/Layout/Layout';
import { RcQuotesStyles } from './BackOfficeVehicleQuotes.styles';
import SaluteInfoCard from '../../../common/components/redesignComponents/Cards/SaluteInfoCard';
import { GuaranteesDefaultStateStyles } from '../../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../../../common/constants';
import {
  addBackOfficeQuoteToCart, loadBackOfficeQuotes, removeBackOfficeQuoteFromCart, resetBackOfficeQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import PageLoading from '../../../common/components/elements/PageLoading';
import { confirmAlert } from '../../../common/utils/alerts';
import { RiskContainer } from '../../HouseProduct/styles/HomeResultPage.styles';
import SaluteModalButtons from '../../SaluteProduct/components/SaluteModalButtons';
import SaluteModal from '../../SaluteProduct/components/SaluteModal';
import { FailedQuotesContainer, FailedQuoteTitle } from '../../RcQuotes.styles';
import { BACKEND_BASE_URL } from '../../../config';
import CustomModalSalute from '../../../common/components/elements/CustomModalSalute';
import SaluteCard from '../../../common/components/redesignComponents/Cards/SaluteCard';

function BackOfficeSaluteQuotes() {
  // const navigate = useNavigate();
  const { requestToken } = useParams();
  const state = useSelector((store) => store.backofficeResults.back_office_quotes);
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    processingQuote,
    addingQuote,
    customer,
    toSelect,
    risk_quotations,
    quote_id_to_add,
  } = state;
  // const modifyData = () => {
  //   navigate(`/backoffice/quote/${requestToken}/tutela-legale`);
  // };
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    loadBackOfficeQuotes(requestToken);

    return () => {
      resetBackOfficeQuotes();
    };
  }, []);
  const [showModal, setShowModal] = useState(false);

  const refresh = () => loadBackOfficeQuotes(requestToken);

  if (loading) {
    return <PageLoading />;
  }

  const fetchQuotes = () => {
    loadBackOfficeQuotes(requestToken);
  };

  const selectQuotation = (quoteId, closeButton) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addBackOfficeQuoteToCart(quoteId, fetchQuotes, closeButton, setShowModal, quote_id_to_add));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeBackOfficeQuoteFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };
  return (
    <Layout>
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0">
              <SaluteInfoCard customer={customer} insuranceRequest={requestInfo} />
            </div>
            {showModal && (
            <CustomModalSalute show={showModal} onClose={() => setShowModal(false)} title="Seleziona una di questa quotaziona prima!">
              {toSelect.map((tp, index) => (
                <React.Fragment key={index}>
                  {addingQuote && <PageLoading />}
                  {addingQuote === false && quotes[tp] ? quotes[tp].map((quote, index) => (
                    <div className="mt-3" key={index}>
                      <SaluteCard requestInfo={requestInfo} quote={quote} selectQuotation={selectQuotation} />
                    </div>
                  )) : addingQuote === false && (
                  <p>
                    Nessuna Quotazione
                    {' '}
                    Trovata.
                  </p>
                  )}
                </React.Fragment>
              ))}
            </CustomModalSalute>
            )}
            <div className="col-12 col-xxl-8">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>
                            {processingQuote.company.name}
                            {' '}
                            <span className="text-success">
                              (
                              {processingQuote.product_name}
                              )
                            </span>
                          </h5>
                          <h5 className="fw-bold">
                            {parseFloat(processingQuote.amount).toFixed(2)}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {risk_quotations && risk_quotations[type.id]
                              ? risk_quotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                          quotationTypes.find(
                                            (item) => item.id === quote.quote_type,
                                          ).name
                                        }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-home-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotes && quotes[type.id] && (
                <SaluteModalButtons
                  insuranceRequest={requestInfo}
                  processingQuote={processingQuote}
                  type={type}
                  removeItemFromCart={removeItemFromCart}
                  riskQuotations={risk_quotations}
                />
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
          {quotationTypes.map((type, index) => (
            <SaluteModal
              insuranceRequest={requestInfo}
              type={type}
              key={index}
              addingQuote={addingQuote}
              quotations={quotes}
              setOpenModal={setOpenModal}
              openModal={openModal}
              requestToken={requestToken}
              refresh={refresh}
              selectQuotation={selectQuotation}
            />
          ))}
          {failedQuotes !== null && failedQuotes?.length > 0 && (
          <div className="row mt-5">
            <FailedQuoteTitle>
              Queste compagnie non hanno risposto con una quotazione.
            </FailedQuoteTitle>
            {failedQuotes.map((quote, index) => (
              <div className="col-3" key={index}>
                <FailedQuotesContainer>
                  <img
                    src={BACKEND_BASE_URL + quote.company.logo}
                    alt={`${quote.company.name} Logo`}
                  />
                </FailedQuotesContainer>
              </div>
            ))}
          </div>
          )}
        </div>
      </RcQuotesStyles>
    </Layout>
  );
}

export default BackOfficeSaluteQuotes;
