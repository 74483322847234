import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  auto: {
    loading: true,
    loadingQuotes: false,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],

    driverForm: false,
    ownerForm: false,
  },
  autocarro: {
    loading: true,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],
    vehicleActivities: [],

    driverForm: false,
    ownerForm: false,
  },
  moto: {
    loading: true,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],
    vehicleActivities: [],

    driverForm: false,
    ownerForm: false,
  },
  profession: {
    loading: true,
    states: [],
    municipalities: [],
    professions: [],
    selectedProfession: null,
    formData: {},
    dataPrivacy: [],
  },
  infortuni: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    dataPrivacy: [],

    pack: '',
    isActive: '',
    index: 1,
    isEntry: false,
    requestToken: '',
  },
  infodrive: {
    loading: true,
    states: [],
    municipalities: [],
    vehicleModels: [],
    brands: [],
    isLoadingVehicleModels: false,
    index: 1,
    formData: {
      qualifications: [],
      professions: [],
      marital_statuses: [],
    },
    dataPrivacy: [],
  },
  travel: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
    travel_states: [],
  },
  salute: {
    loading: true,
    states: [],
    municipalities: [],
    formData: {},
    dataPrivacy: [],
  },
};

export const formsBackofficeSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    autoFormDataUpdated: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    resetAutoForm: (state) => {
      state.auto = { ...initialState.auto };
    },
    autocarroFormDataUpdated: (state, { payload }) => {
      state.autocarro = { ...state.autocarro, ...payload };
    },
    resetAutocarroForm: (state) => {
      state.autocarro = { ...initialState.autocarro };
    },
    motoFormDataUpdated: (state, { payload }) => {
      state.moto = { ...state.moto, ...payload };
    },
    resetMotoForm: (state) => {
      state.moto = { ...initialState.moto };
    },
    professionFormDataUpdated: (state, { payload }) => {
      state.profession = { ...state.profession, ...payload };
    },
    resetProfessionForm: (state) => {
      state.profession = { ...initialState.profession };
    },
    infortuniFormDataUpdated: (state, { payload }) => {
      state.infortuni = { ...state.infortuni, ...payload };
    },
    resetInfortuniForm: (state) => {
      state.infortuni = { ...initialState.infortuni };
    },
    infodriveFromDataUpdated: (state, { payload }) => {
      state.infodrive = { ...state.infodrive, ...payload };
    },
    resetInfoDriveForm: (state) => {
      state.infodrive = { ...initialState.infodrive };
    },
    travelFormDataUpdated: (state, { payload }) => {
      state.travel = { ...state.travel, ...payload };
    },
    resetTravelForm: (state) => {
      state.travel = { ...initialState.travel };
    },
    saluteFormDataUpdated: (state, { payload }) => {
      state.salute = { ...state.salute, ...payload };
    },
    resetSaluteForm: (state) => {
      state.salute = { ...initialState.salute };
    },
  },
});

export default callerCreator(formsBackofficeSlice.actions);
