import React, { useEffect } from 'react';
import { SaluteModalStyles } from './CustomModalSalute.styles';

function CustomModalSalute({
  show, onClose, title, children,
}) {
  // Close modal if clicking outside of the modal content
  const handleClickOutside = (event) => {
    if (event.target.classList.contains('custom-modal-overlay')) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <SaluteModalStyles className="custom-modal-overlay d-flex justify-content-center align-items-center">
      <div className="custom-modal-content p-4 bg-white rounded shadow">
        <div className="modal-header d-flex justify-content-between align-items-center">
          <h5 className="modal-title mb-0">{title}</h5>
          <button className="btn-close" aria-label="Close" onClick={onClose} />
        </div>
        <div className="modal-body mt-3">{children}</div>
        <div className="modal-footer d-flex justify-content-end mt-4">
          <button className="btn btn-secondary me-2" onClick={onClose}>
            Chiudi
          </button>
        </div>
      </div>
    </SaluteModalStyles>
  );
}

export default CustomModalSalute;
