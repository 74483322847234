import React from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  LeftCol,
  LogoPriceContainer,
  PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import {
  LogoResultContainer,
  PriceResult,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';

function RiskCardPrimaResult({ quote, processingQuote }) {
  // const includesCoverage = (key) => modalCoverages !== null && modalCoverages.includes(key);

  return (
    <ProfessionCardStyles className="selected">
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img
                src={BACKEND_BASE_URL + processingQuote.company.logo}
                alt={processingQuote.company.name}
              />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>Prezzo annuo:</h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(
                    quote.quotation_data_prima_guarantee_prices[0].discounted,
                  ).toFixed(2)}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{processingQuote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Limit:</h6>
              <p>{quote.limits_name}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol />
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCardPrimaResult;
