import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import PageLoading from '../common/components/elements/PageLoading';
import {
  addQuoteToCart,
  professionQuotesGetQuotes,
  removeQuoteFromCart,
  resetProfessionQuotes,
} from '../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import { ProfessionQuotesStyles } from './RcProfessionQuotes.styles';
import ProfessionInfoCard from '../common/components/redesignComponents/Cards/ProfessionInfoCard';
import { EstimateContainer } from '../common/components/redesignComponents/common/Redesign.styles';
// import { useTourContext } from '../context/useTourContext';
import ErrorComponent from '../common/components/ErrorComponent';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRcProfessionQuotes } from '../common/utils/joyrideCallbacks';
// import { rcProfessionQuotesSteps } from '../common/utils/joyrideSteps';
import {
  RespCivileModal,
  RiskButton,
  RiskButtonRemove,
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../common/constants';
import ProfessionRiskCard from '../common/components/redesignComponents/Cards/ProfessionRiskCard';
import AragCard from '../common/components/redesignComponents/Cards/AragCard';
import ProcessingQuoteSelectedProfession from '../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedProfession';
import TutelaSelectedProfession from '../common/components/redesignComponents/ResultPageComponents/TutelaSelectedProfession';
import QuotationCard from '../common/reusable/QuotationCard';

const docs = {
  1: 'questionario_hcc_ingegneri.pdf',
  2: 'questionario_hcc_architetto.pdf',
  3: 'questionario_hcc_avvocato.pdf',
  4: 'questionario_hcc_Commercialista.pdf',
  5: 'questionario_hcc_consulente_del_lavoro.pdf',
  6: 'questionario_hcc_agente_immobiliare.pdf',
  7: 'questionario_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'questionario_hcc_amministratore_condominio.pdf',
  9: 'questionario_hcc_geometri.pdf',
  10: 'questionario_hcc_mediatore_creditizi.pdf',
  11: 'questionario_hcc_periti.pdf',
  12: 'questionario_hcc_tributarista.pdf',
  13: '',
  14: 'questionario_hcc_ag_commercio.pdf',
  15: 'questionario_hcc_agronomi.pdf',
  16: 'questionario_hcc_societa_di_edp.pdf',
  17: 'questionario_hcc_chimichi.pdf',
  18: 'questionario_hcc_dpo.pdf',
  19: 'questionario_hcc_geologi.pdf',
  20: 'questionario_hcc_miscellaneous.pdf',
  21: 'questionario_hcc_miscellaneous_energetica.pdf',
  22: 'questionario_hcc_psicologo.pdf',
  23: 'questionario_hcc_revisore_legale_dei_conti.pdf',
  24: 'questionario_hcc_singolo_componente_mediazione.pdf',
  25: 'questionario_hcc_tecnologo_alimentare.pdf',
  26: 'questionario_hcc_conformita.pdf',
};

const set_informativi = {
  1: 'set_informativo_hcc_ingegneri.pdf',
  2: 'set_informativo_hcc_architetto.pdf',
  3: 'set_informativo_hcc_avvocato.pdf',
  4: 'set_informativo_hcc_Commercialista.pdf',
  5: 'set_informativo_hcc_consulente_del_lavoro.pdf',
  6: 'set_informativo_hcc_agente_immobiliare.pdf',
  7: 'set_informativo_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'set_informativo_hcc_amministratore_condominio.pdf',
  9: 'set_informativo_hcc_geometri.pdf',
  10: 'set_informativo_hcc_mediatore_creditizi.pdf',
  11: 'set_informativo_hcc_periti.pdf',
  12: 'set_informativo_hcc_tributarista.pdf',
  13: '',
  14: 'set_informativo_hcc_ag_commercio.pdf',
  15: 'set_informativo_hcc_agronomi.pdf',
  16: 'set_informativo_hcc_societa_di_edp.pdf',
  17: 'set_informativo_hcc_chimichi.pdf',
  18: 'set_informativo_hcc_dpo.pdf',
  19: 'set_informativo_hcc_geologi.pdf',
  20: 'set_informativo_hcc_miscellaneous.pdf',
  21: 'set_informativo_hcc_miscellaneous_energetica.pdf',
  22: 'set_informativo_hcc_psicologo.pdf',
  23: 'set_informativo_hcc_revisore_legale_dei_conti.pdf',
  24: '',
  // "24": "set_informativo_hcc_singolo_componente_mediazione.pdf",
  25: 'set_informativo_hcc_tecnologo_alimentare.pdf',
  26: 'set_informativo_hcc_conformita.pdf',
};

const selectableExtensions = ['tlb', 'tls', 'tlg'];

export default function RcProfessionQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector(
    (store) => store.insuranceRequests.profession_quotes,
  );
  const {
    loading,
    extensions,
    userData,
    profession,
    customer,
    quotations,
    addingQuote,
    processingQuote,
    riskQuotations,
    error,
  } = state;

  // const [showSellingDistanceModal, setShowSellingDistanceModal] = useState(false);

  const refresh = () => professionQuotesGetQuotes(requestToken);
  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('quoteNewResult') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('quoteNewResult')]);

  const fetchQuotes = () => {
    professionQuotesGetQuotes(requestToken);
  };

  const modifyData = () => {
    navigate('/quote/profession', {
      state: { requestToken },
    });
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteToCart(quoteId, fetchQuotes, modifyData));
  };

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('quoteNewResult');
  //   setState({ run: true });
  // };

  useEffect(() => {
    professionQuotesGetQuotes(requestToken);

    return () => {
      resetProfessionQuotes();
    };
  }, []);

  useEffect(() => {
    if (userData && userData.is_expired === true) {
      navigate('/quote/profession', {
        state: { requestToken },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [userData]);

  // useEffect(() => {
  //   rcProfessionQuotesSteps(quotations, setSteps);
  // }, [quotations]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  // const getSellingDistanceLink = () => `${SELLING_DISTANCE_URL}/request/${distance_token}/professions/results`;

  // const copySellingDistanceLink = async () => {
  //   const link = getSellingDistanceLink();

  //   await navigator.clipboard.writeText(link);
  //   generalAlertSuccess('Il collegamento e stato copiato');
  // };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteFromCart(quoteId, fetchQuotes, modifyData));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount;
    return total.toFixed(2);
  };

  const handleRequestPurchase = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/profession/purchase/${requestToken}`);
  };

  return (
    <>
      {/* <Joyride
        steps={steps}
        run={run}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        callback={(data) => handleCallbackRcProfessionQuotes(data, steps, setState)}
        showSkipButton
        showProgress
        disableScrolling
      /> */}
      {/* {openModal && <GuaranteesModalProfession setOpenModal={setOpenModal} profession={profession} extensions={extensions} requestToken={requestToken} selectableExtensions={selectableExtensions} />} */}
      <ProfessionQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-profession-first-step">
              <ProfessionInfoCard
                modificaDati={modifyData}
                customer={customer}
                insuranceRequest={userData}
              />
            </div>
            <div className="col-12 col-xxl-8 result-profession-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company.name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === processingQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(processingQuote.amount).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {riskQuotations && riskQuotations[type.id]
                              ? riskQuotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}

                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestPurchase()}
                      >
                        Aquista
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
              {/* <GuaranteesDefaultState setShowGuaranteesModal={setOpenModal} quotes={userData.quotations} extensions={extensions} extension_price={profession.extension_price} /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <EstimateContainer>
                <div className="d-flex gap-2 justify-content-end ">
                  <div>
                    {/* {localStorage.getItem('quoteNewResult') === 'done' && (
                      <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                        <button className="btn btn-success px-2 py-1">Tutorial</button>
                      </div>
                    )} */}
                  </div>
                </div>
              </EstimateContainer>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-profession-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotations && quotations[type.id] && (
                  <RiskCard
                    className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${
                      userData.processing_quotation_id === null
                      && type.id !== 'res_civile'
                        ? 'bg-secondary bg-opacity-25'
                        : ''
                    } ${
                      userData.processing_quotation_id !== null
                      && type.id === 'res_civile'
                        ? 'bg-success bg-opacity-25'
                        : ''
                    } ${
                      riskQuotations && riskQuotations[type.id] !== undefined
                        ? 'bg-success bg-opacity-25'
                        : ''
                    }`}
                  >
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    {processingQuote !== null && type.id === 'res_civile' && (
                      <ProcessingQuoteSelectedProfession
                        processingQuote={processingQuote}
                        requestInfo={userData}
                        refresh={refresh}
                        profession={profession}
                      />
                    )}

                    {riskQuotations
                      && riskQuotations[type.id] !== undefined
                      && riskQuotations[type.id].map((quote, idx) => (
                        <React.Fragment key={idx}>
                          {processingQuote !== null
                            && processingQuote.company_id !== 2
                            && type.id === quote.quote_type && (
                              <TutelaSelectedProfession
                                tutelaLegaleQuote={quote}
                                type={type}
                              />
                          )}
                        </React.Fragment>
                      ))}

                    {riskQuotations
                      && riskQuotations[type.id] === undefined
                      && type.id !== 'res_civile'
                      && processingQuote !== null && (
                        <div
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                          style={{ cursor: 'pointer' }}
                          className="alert alert-secondary"
                        >
                          Seleziona Quotazione!
                        </div>
                    )}

                    <div className="d-flex justify-content-end ms-auto gap-2">
                      {riskQuotations
                        && riskQuotations[type.id] !== undefined
                        && type.id !== 'res_civile'
                        && riskQuotations[type.id].map((quote, idx) => (
                          <React.Fragment key={idx}>
                            {type.id === quote.quote_type && (
                              <RiskButtonRemove
                                className="btn btn-danger"
                                onClick={() => removeItemFromCart(quote.id)}
                              >
                                Rimuovi
                              </RiskButtonRemove>
                            )}
                          </React.Fragment>
                        ))}

                      {riskQuotations
                        && riskQuotations[type.id] === undefined
                        && processingQuote !== null
                        && type.id !== 'res_civile' && (
                          <RiskButton
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target={`#${type.id}`}
                          >
                            Aggiungi
                          </RiskButton>
                      )}

                      {type.id === 'res_civile' && processingQuote !== null && (
                        <RiskButtonRemove
                          className="btn btn-danger"
                          onClick={() => removeItemFromCart(processingQuote.id)}
                        >
                          Rimuovi
                        </RiskButtonRemove>
                      )}

                      {userData.processing_quotation_id === null
                        && type.id !== 'res_civile' && (
                          <div className="alert alert-warning py-2 px-2">
                            Seleziona Prima La Responsibilita Civile
                          </div>
                      )}

                      {type.id === 'res_civile' && (
                        <RiskButton
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                        >
                          Aggiungi
                        </RiskButton>
                      )}
                    </div>
                  </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
        </div>
        {quotationTypes.map((type, index) => (
          <RespCivileModal
            className="modal fade"
            id={type.id}
            tabIndex="-1"
            aria-labelledby={type.name}
            aria-hidden="true"
            key={index}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={type.name}>
                    {type.name}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="buttonClose"
                  />
                </div>
                <div className="modal-body">
                  {addingQuote && (
                    <div className="d-flex align-items-center justify-content-between flex-column">
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">
                            Aggiungendo...
                          </span>
                        </div>
                      </div>
                      <div className="ms-2">Aggiungendo...</div>
                    </div>
                  )}
                  {addingQuote === false && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <div className="mt-3" key={index}>
                        {quote.quote_type === 'res_civile' ? (
                          <QuotationCard
                            requestInfo={userData}
                            quote={quote}
                            set_informativi={set_informativi}
                            docs={docs}
                            extensions={extensions}
                            requestToken={requestToken}
                            selectableExtensions={selectableExtensions}
                            profession={profession}
                            selectQuotation={selectQuotation}
                          />
                        ) : quote.company_id === 15 ? (
                          <AragCard
                            quotation={quote}
                            requestInfo={userData}
                            type={type}
                            selectQuotation={selectQuotation}
                          />
                        ) : (
                          <ProfessionRiskCard
                            quote={quote}
                            requestInfo={userData}
                            selectQuotation={selectQuotation}
                          />
                        )}
                      </div>
                    ))
                    : addingQuote === false && (
                    <p>
                      Nessuna Quotazione
                      {type.name}
                      {' '}
                      Trovata.
                    </p>
                    )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger px-4"
                    data-bs-dismiss="modal"
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </RespCivileModal>
        ))}
      </ProfessionQuotesStyles>
    </>
  );
}
