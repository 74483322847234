import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import {
  RcQuotesStyles, RespCivileModal, RiskButton, RiskButtonRemove,
} from './BackOfficeVehicleQuotes.styles';
import {
  addBackOfficeQuoteToCart,
  loadBackOfficeQuotes,
  removeBackOfficeQuoteFromCart,
  resetBackOfficeQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import { Layout } from '../components/Layout/Layout';
import { FailedQuoteTitle, FailedQuotesContainer } from '../../RcQuotes.styles';
import { quotationTypes } from '../../../common/constants';
import { GuaranteesDefaultStateStyles } from '../../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { confirmAlert } from '../../../common/utils/alerts';
import {
  RiskCard,
  RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import TutelaLegaleInfoCard from '../../../common/components/redesignComponents/Cards/TutelaLegaleInfoCard';
import ProcessingQuoteSelectedTutelaLegale from '../../../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedTutelaLegale';
import AragCard from '../../../common/components/redesignComponents/Cards/AragCard';

function BackOfficeTutelaLegaleQuotes() {
  // const navigate = useNavigate();
  const { requestToken } = useParams();
  const state = useSelector((store) => store.backofficeResults.back_office_quotes);
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    processingQuote,
    addingQuote,
    riskQuotes,
    customer,
    risk_quotations,
  } = state;
  // const modifyData = () => {
  //   navigate(`/backoffice/quote/${requestToken}/tutela-legale`);
  // };

  useEffect(() => {
    loadBackOfficeQuotes(requestToken);

    return () => {
      resetBackOfficeQuotes();
    };
  }, []);

  const refresh = () => loadBackOfficeQuotes(requestToken);

  if (loading) {
    return <PageLoading />;
  }

  const fetchQuotes = () => {
    loadBackOfficeQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addBackOfficeQuoteToCart(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeBackOfficeQuoteFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotes && riskQuotes[type.id] !== undefined) {
        for (let i = 0; i < riskQuotes[type.id].length; i++) {
          const item = riskQuotes[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (
      quoteInProccessAmount
      + risksTotalAmount
    ).toFixed(2);
  };

  return (
    <Layout>
      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0">
              <TutelaLegaleInfoCard customer={customer} insuranceRequest={requestInfo} />
            </div>
            <div className="col-12 col-xxl-8">
              <GuaranteesDefaultStateStyles>
                <div className="container mt-4">
                  {processingQuote === null ? (
                    <h5 className="fw-bold">
                      Seleziona Le Quotazioni Che Vuoi Aquistare
                    </h5>
                  ) : (
                    <>
                      <ul className="list-unstyled">
                        {processingQuote !== null && (
                          <li>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>
                                {processingQuote.company !== null
                                  ? processingQuote.company.name
                                  : processingQuote.preventivas_company
                                    .company_name}
                                {' '}
                                <sub>
                                  (
                                  {
                                    quotationTypes.find(
                                      (item) => item.id === processingQuote.quote_type,
                                    ).name
                                  }
                                  )
                                </sub>
                              </h5>
                              <h5 className="fw-bold">
                                {parseFloat(processingQuote.amount).toFixed(2)}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        )}
                        {quotationTypes
                          .filter((t) => t.id !== 'res_civile')
                          .map((type, index) => (
                            <React.Fragment key={index}>
                              {riskQuotes && riskQuotes[type.id]
                                ? riskQuotes[type.id].map((quote, i) => (
                                  <li key={i}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h5>
                                        {quote.company !== null
                                          ? quote.company.name
                                          : quote.preventivas_company
                                            .company_name}
                                        {' '}
                                        <span className="text-success">
                                          (
                                          {
                                              quotationTypes.find(
                                                (item) => item.id === quote.quote_type,
                                              ).name
                                            }
                                          )
                                        </span>
                                      </h5>
                                      <h5 className="fw-bold">
                                        {parseFloat(quote.amount).toFixed(2)}
                                        {' '}
                                        €
                                      </h5>
                                    </div>
                                  </li>
                                ))
                                : ''}
                            </React.Fragment>
                          ))}
                        <hr />
                        <li>
                          <div className="d-flex justify-content-end align-items-center">
                            <h5 className="me-5 fw-bold">Totale:</h5>
                            <h5 className="fw-bold">
                              {' '}
                              {getTotal()}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </GuaranteesDefaultStateStyles>
            </div>
          </div>
          <RiskContainer className="mb-3 pb-3 result-profession-third-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotes && quotes[type.id] && (
                  <RiskCard className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${requestInfo.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} ${requestInfo.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} ${(risk_quotations && risk_quotations[type.id] !== undefined) ? 'bg-success bg-opacity-25' : ''}`}>
                    <div className="p-3 me-auto">
                      <h5>{type.name }</h5>
                    </div>
                    {processingQuote !== null && <ProcessingQuoteSelectedTutelaLegale processingQuote={processingQuote} requestInfo={requestInfo} refresh={refresh} />}

                    <div className="d-flex justify-content-end ms-auto gap-2">
                      {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}
                      {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
                    </div>
                  </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>

          {quotationTypes.map((type, index) => (
            <RespCivileModal className="modal fade" id={type.id} tabIndex="-1" aria-labelledby={type.name} aria-hidden="true" key={index}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={type.name}><h5>{type.id == 'res_civile' ? 'Tutela Legale' : type.name }</h5></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" />
                  </div>
                  <div className="modal-body">
                    {addingQuote && (
                    <div className="d-flex align-items-center justify-content-between flex-column">
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Aggiungendo...</span>
                        </div>
                      </div>
                      <div className="ms-2">Aggiungendo...</div>
                    </div>
                    )}
                    {addingQuote === false && quotes[type.id] ? (
                      [...quotes[type.id]] // Create a copy of the array
                        .sort((a, b) => a.amount - b.amount) // Sort the copy
                        .map((quote, index) => (
                          <div className="mt-3" key={index}>
                            <AragCard
                              quotation={quote}
                              requestInfo={requestInfo}
                              type={type}
                              selectQuotation={selectQuotation}
                            />
                          </div>
                        ))
                    ) : addingQuote === false ? (
                      <p>
                        Nessuna Quotazione
                        {' '}
                        {type.name}
                        {' '}
                        Trovata.
                      </p>
                    ) : null}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
                  </div>
                </div>
              </div>
            </RespCivileModal>
          ))}

          {failedQuotes !== null && failedQuotes?.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </Layout>
  );
}

export default BackOfficeTutelaLegaleQuotes;
