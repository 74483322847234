import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LoginStyles } from './Login.styles';

import { attemptLogin } from '../features/user/userActions';

import GreeniaLogo from '../assets/images/greenia-logo.webp';
import Password from '../assets/images/password.png';
import User from '../assets/images/user.png';

const schema = yup.object({
  email: yup
    .string()
    .required('Il campo è obbligatorio'),
  password: yup
    .string()
    .required('Il campo è obbligatorio'),
});

export default function Login() {
  const navigate = useNavigate();
  const userState = useSelector((store) => store.user);
  useEffect(() => {
    if (userState.token) {
      navigate('/');
    }
  }, [userState.token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const submitData = ({ email, password }) => {
    attemptLogin({ email, password });
  };

  return (
    <LoginStyles>
      <div className="Auth-form-container d-flex justify-content-center bg-img">

        <form onSubmit={handleSubmit(submitData)} className="Auth-form container-form">
          <div className="Auth-form-content">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" width={170} height={57} src={GreeniaLogo} alt="Greenia Logo" />
            </div>
            <h3 className="Auth-form-title text-dark fs-5">Accedi al tuo account</h3>
            {userState.error !== null
              && <div className="general-form-error">{userState.error}</div>}
            <div className="form-group mt-3">
              <label htmlFor="email">Email</label>
              <div className="input-group inp-login">
                <img className="input-img" src={User} alt="" />
                <input
                  {...register('email')}
                  autoComplete="off"
                  id="email"
                  className="form-control inpt mt-1 rounded"
                />
                <div className="general-input-error">{errors.email?.message}</div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="password">Password</label>
              <div className="input-group inp-login">
                <img className="input-img" src={Password} alt="" />
                <input
                  {...register('password')}
                  type="password"
                  id="password"
                  className="form-control mt-1 inpt rounded"
                />
                <div className="general-input-error">{errors.password?.message}</div>
              </div>
            </div>
            <div className="mt-3">
              {userState.loading
                ? <button className="btn btn-success active btn-secondary w-100">Attendere prego...</button>
                : <button type="submit" className="btn btn-success active fw-bold text-uppercase w-100">Accedi</button>}
            </div>
            <p className="forgot-password justify-content-center d-grid gap-2 mt-3 mt-2">
              <Link to="/auth/forgot" className="text-dark">Hai dimenticato la password?</Link>
            </p>
          </div>
        </form>
      </div>
    </LoginStyles>
  );
}
