import React from 'react';
import {
  RiskButton,
  RiskButtonRemove,
} from '../Cards/ProfessionInfoCard.styles';

function ButtonComponentLogicBackoffice({
  type,
  processingQuote,
  removeItemFromCart,
  requestInfo,
  riskQuotations,
}) {
  return (
    <div className="d-flex justify-content-end ms-auto gap-2">
      {type.id === 'res_civile' && processingQuote !== null && (
        <RiskButtonRemove
          className="btn btn-danger"
          onClick={() => removeItemFromCart(processingQuote.id)}
        >
          Rimuovi
        </RiskButtonRemove>
      )}

      {riskQuotations
        && riskQuotations[type.id] !== undefined
        && riskQuotations[type.id].map((quote, idx) => (
          <React.Fragment key={idx}>
            {type.id === quote.quote_type && (
              <RiskButtonRemove
                className="btn btn-danger"
                onClick={() => removeItemFromCart(quote.id)}
              >
                Rimuovi
              </RiskButtonRemove>
            )}
          </React.Fragment>
        ))}

      {riskQuotations
        && riskQuotations[type.id] === undefined
        && processingQuote !== null
        && type.id !== 'res_civile' && (
          <RiskButton
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target={`#${type.id}`}
          >
            Aggiungi
          </RiskButton>
      )}

      {requestInfo.processing_quotation_id === null
        && type.id !== 'res_civile' && (
          <div className="alert alert-warning py-2 px-2">
            Seleziona Prima La Responsibilita Civile
          </div>
      )}
      {type.id === 'res_civile' && (
        <RiskButton
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target={`#${type.id}`}
        >
          Aggiungi
        </RiskButton>
      )}
    </div>
  );
}

export default ButtonComponentLogicBackoffice;
