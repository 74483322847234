import styled from 'styled-components';

export const DashboardThemeStyles = styled.div`
    .page-content{
        width: calc(100% - 300px);
        margin-left: auto;
    }
    .page-content-items{
        padding: 30px;
    }
    
    @media screen and (max-width: 991px) {
        .page-content {
            width: 100%;
            margin-left: auto;
        }

      .page-content-items {
        padding: 10px;
      }
    }
`;

export const SmallChat = styled.div`
    position: fixed;
    bottom: 15px;
    right: 25px;
    z-index: 1059;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
        background-color: #00A551;
        margin: 0;
        margin-top: auto;
        padding: 10px;
        padding-left: 30px;
        font-size: 12px;
        margin-left: -30px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        color: white;
    }
    
    img {
        background-color: #00A551;
        padding: 10px;
        border-radius: 100px;
        z-index: 999;
    }
    /* .bottom-right {
        width: 50px;
        position: fixed;
        bottom: 15px;  
        right: 25px;    
        z-index: 1000;  
    } */
`;
