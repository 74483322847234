import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  GlobalAssistencaCristalliInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer, TitleBox,
} from './GlobalAssistanceCristalliModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function GlobalAssistanceCristalliModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <GlobalAssistencaCristalliInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <p className="mt-3">
              Calibratura
            </p>
            <br />
            <p>
              Danni materiali e diretti per
              {' '}
              <b>rottura del parabrezza, lunotto posteriore, cristalli laterali e quelli del tetto</b>
              {' '}
              , dovuta a
              {' '}
              <b> grandine</b>
              {' '}
              oppure a causa accidentale non riconducibile, quest’ultima, ad “altri” eventi naturali (es. tempesta, allagamento, ecc) e/o catastrofali (es. uragano, terremoto, maremoto, alluvione/ inondazione, ecc), o eventi sociopolitici, atti vandalici, furto compiuto o tentato, urto, collisione e ribaltamento, fino alla concorrenza del massimale assicurato, da intendersi per ogni evento, a prescindere dal numero dei cristalli danneggiati.
            </p>
            <p>
              <b>FUORI NETWORK:</b>
              {' '}
              scoperto del 20% con il minimo non indennizzabile di € 120.
            </p>

            <p>
              <b>IN NETWORK:</b>
              {' '}
              nessuno scoperto
            </p>

          </div>
        </ModalContent>
      </ModalBody>
    </GlobalAssistencaCristalliInfoModalStyles>
  );
}

export default GlobalAssistanceCristalliModal;
