import moment from 'moment';
import React from 'react';

function CompletedQuoteScreenInfoAncillariAuto({ rc_quotation, risk_quotations }) {
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card border mb-2 polizze-emessa-status-step-three">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              {rc_quotation.risk_information.product_name}
            </h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Compagnia: </b>
                {rc_quotation.company.name}
              </li>
              <li className="list-group-item">
                <b>Stato:</b>
                {' '}
                {rc_quotation.status === 7 && 'Polizza Emmesa'}
              </li>
              <li className="list-group-item">
                <b>Data Decorrenza:</b>
                {' '}
                {moment(rc_quotation.insurance_request.policy_effective_date)
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </li>
              <li className="list-group-item">
                <b>Frequenza Pagamento:</b>
                {' '}
                {rc_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
              </li>
              <li className="list-group-item">
                <b>Pagato:</b>
                {' '}
                {rc_quotation.amount_paid === null
                  ? '0.00 €'
                  : parseFloat(rc_quotation.amount_paid).toFixed(2)}
                {' '}
                &euro;
              </li>
              <li className="list-group-item">
                <b>Da Pagare:</b>
                {' '}
                {rc_quotation.amount_paid === rc_quotation.amount
                  ? '0.00 €'
                  : (
                    Math.ceil(rc_quotation.amount * 100) / 100
                  - rc_quotation.amount_paid
                  ).toFixed(2)}
              </li>
              <li className="list-group-item">
                <b>Prossimo Pagamento:</b>
                {' '}
                {moment(rc_quotation.insurance_request.policy_effective_date)
                  .add(Math.floor(365 / rc_quotation.payment_frequency), 'days')
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </li>
              <li className="list-group-item">
                <b>Premio:</b>
                {' '}
                {parseFloat(rc_quotation.amount).toFixed(2)}
                {' '}
                &euro;
              </li>
              <li className="list-group-item">
                <b>Provvigioni:</b>
                {' '}
                {parseFloat(rc_quotation.commision).toFixed(2)}
                {' '}
                &euro;
              </li>
              <li className="list-group-item">
                <b>Brokeraggio:</b>
                {' '}
                {rc_quotation.brokerage_amount.toFixed(2)}
                {' '}
                &euro;
              </li>
              <li className="list-group-item">
                <b>Totale:</b>
                {' '}
                {(rc_quotation.amount + rc_quotation.brokerage_amount).toFixed(
                  2,
                )}
                {' '}
                &euro;
              </li>
            </ul>
          </div>
        </div>
      </div>

      {risk_quotations.map((quote, index) => (
        <div className="col-12 col-md-6" key={index}>
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">
                {quote.risk_information.product_name}
              </h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {quote.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {quote.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(quote.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {quote.amount_paid === null
                    ? '0.00 €'
                    : parseFloat(quote.amount_paid).toFixed(2)}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {quote.amount_paid === quote.amount
                    ? '0.00 €'
                    : (
                      Math.ceil(quote.amount * 100) / 100
                    - quote.amount_paid
                    ).toFixed(2) }

                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(quote.insurance_request.policy_effective_date)
                    .add(Math.floor(365 / quote.payment_frequency), 'days')
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {parseFloat(quote.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {quote.amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CompletedQuoteScreenInfoAncillariAuto;
