import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  greeniaPrivacyPolicy,
  modifyRequestDataBackoffice,
} from '../../services/axios-client/axeCommons';
import LoadingGif from '../../assets/images/loading.gif';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import ProgressBar from '../../common/components/ProgressBar';
import { validateItalianPostCode } from '../../common/validators';
import {
  genders,
  flagResponse,
  months,
  years,
  km_during_one_year,
  vehiclesAmountInFamily,
  meritClass,
  guideType,
  lastSixYears,
  insuranceTypes,
  inheritMeritClass,
} from '../../common/constants';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import PageLoading from '../../common/components/elements/PageLoading';
import getMaxAllowedBirthday from '../../common/helpers/getMaxAllowedBirthday';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import getAllowedLicenseYears from '../../common/helpers/getAllowedLicenseYears';
import getAllowedPurchaseYears from '../../common/helpers/getAllowedPurchaseYears';
import InputViolations, {
  calculateEmptyViolationsData,
} from '../../common/components/inputs/InputViolations';
import OffcanvasForm from '../../common/components/elements/OffcanvasForm';
import BersaniInfo from '../../common/components/elements/BersaniInfo';

// to remove
import {
  motoGetVehicleModels,
  motoGetVehicleVersions,
  motoSubmitAnswers,
  motoUpdateFormData,
  motoChangeIndex,
  motoGetInitialData,
  resetMotoForm,
} from '../../features/formsBackoffice/formsBackofficeActions';

import { generalAlertError } from '../../common/utils/alerts';
import { FormInputCheckbox } from '../../common/formInputs/FormInputCheckbox';

import {
  answersFieldsBySteps,
  answersValidationsSchema,
} from './BackofficeRcMoto.validations';
import PaymentTypePeriod from '../../common/components/inputs/PaymentTypePeriod';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { Label } from '../../common/formInputs/styles/common';
import { FormInputNumber } from '../../common/formInputs/FormInputNumber';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { Layout } from './components/Layout/Layout';
import FormReactSelectMotoBrand from '../../common/formInputs/FormReactSelectMotoBrand';
import FormReactSelectMotoModel from '../../common/formInputs/FormReactSelectMotoModel';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import InputSelectTableMoto from '../../common/components/inputs/InputTableMoto';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';

const titles = {
  1: 'Dati del Veicolo',
  2: 'Dati Personali',
  3: 'Dati Assicurativi',
  4: 'Calcolare',
};

function BackOfficeRcMoto() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const skipCheckGenderRef = useRef(false);
  const skipCheckInsuranceTypeRef = useRef(false);

  // Constants
  const steps = [
    'Dati del Veicolo',
    'Dati Personali',
    'Dati Assicurativi',
    'Calcolare',
  ];

  const state = useSelector((store) => store.formsBackoffice.moto);

  const {
    loading,
    isLoadingVehicleModels,
    isLoadingVehicleVersions,
    isSubmitting,
    index,
    states,
    municipalities,
    vehicleModels,
    dataPrivacy,
    brands,
    formData,
    vehicleVersions,
    driverForm,
    ownerForm,
    loadingQuotes,
  } = state;

  const title = titles[index];

  const bersaniComponentRef = useRef();
  const driverFormComponentRef = useRef();
  const ownerFormComponentRef = useRef();
  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(answersValidationsSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'motorcycle',
      source: 'greenia.it',
      contractor_is_owner: '1',
      contractor_is_driver: '1',
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '1',
      driving_license_year: '',
      moto_plate: '',
      moto_brand_code: '',
      moto_model_code: '',
      moto_version_code: '',
      prg_code_moto: '',
      start_immatricolation: '',
      start_immatricolation_month: '',
      inherit_merit_class: '',
      merit_class: '1',
      vehicle_purchased_month: '',
      vehicle_purchased_year: '',
      theft_protection_code: '4',
      privacies: [],
      children: '0',
      vehicle_parking: '1',
      vehicle_usage: '1',
      predicted_km: 10000,
      vehicles_owned: 2,
      policy_effective_date: '',
      other_drivers: '1',
      youngest_age_driver: 30,
      youngest_age_family_member: 30,
      modified_vehicle: 1,
      valid_driving_license: 1,
      first_insuranced_year: '',
      violations: 0,
      violations_data: calculateEmptyViolationsData(),
      violations_number: 0,
      business_name: '',
      vat_number: '',
      company_type: null,
      guide_type: 'free',
      insurance_type: '',
      complete_manually: false,
      renew_check: false,
      new_risks_check: false,
      existing_customer: false,
      active_customer_id: null,
      power: 0,
      weight: 0,
      payment_frequency: 1, // Anuuale = 1 || Semestrale = 2
    },
  });

  const answers = watch();

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const [skipBrandCodeEffect, setSkipBrandCodeEffect] = useState(false);
  const vehicleBrandCodeEffectDependencies = useRef();
  const vehicleBrandCodeDeps = [
    answers.moto_brand_code,
    answers.start_immatricolation,
  ];
  useEffect(() => {
    if (skipBrandCodeEffect) {
      setSkipBrandCodeEffect(false);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === undefined) {
      vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
      return;
    }

    if (
      vehicleBrandCodeEffectDependencies.current
      === JSON.stringify(vehicleBrandCodeDeps)
    ) {
      return;
    }

    vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
    setValue('moto_model_code', '');
  }, vehicleBrandCodeDeps);

  const [skipModelCodeEffect, setSkipModelCodeEffect] = useState(false);
  const vehicleModelCodeEffectDependencies = useRef();
  const vehicleModelCodeDeps = [answers.moto_model_code];
  useEffect(() => {
    if (skipModelCodeEffect) {
      setSkipModelCodeEffect(false);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === undefined) {
      vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);
      return;
    }

    if (
      vehicleModelCodeEffectDependencies.current
      === JSON.stringify(vehicleModelCodeDeps)
    ) {
      return;
    }

    vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);

    setValue('moto_version_code', null);
  }, vehicleModelCodeDeps);

  useEffect(() => {
    if (skipCheckInsuranceTypeRef.current === true) {
      skipCheckInsuranceTypeRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.insurance_type === 'N') {
      setValue('first_insuranced_year', new Date().getFullYear().toString());
      setValue('violations', '0');
    } else if (answers.insurance_type === 'B') {
      setValue(
        'first_insuranced_year',
        answers.first_insuranced_year,
        triggerValidation,
      );
      setValue('violations', '0', triggerValidation);
    }
  }, [answers.insurance_type]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.gender === 'G') {
      setValue('contractor_is_driver', '0');
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('civil_status_id', null);
      setValue('education_level_id', null);
      setValue('profession_id', null);
      setValue('driving_license_year', null);
      setValue('children', null);
      setValue('name', null);
      setValue('surname', null);
    } else if (
      ['M', 'F'].includes(answers.gender)
      && answers.date_of_birth === null
    ) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('civil_status_id', '2', triggerValidation);
      setValue('education_level_id', '3', triggerValidation);
      setValue('profession_id', '1', triggerValidation);
      setValue('driving_license_year', '', triggerValidation);
      setValue('children', '0', triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [answers.gender, answers.date_of_birth, answers.contractor_is_driver]);

  // Collect Owner Data
  const [owner, setOwner] = useState({
    isValid: false,
    name: '',
    surname: '',
    gender: '',
    fiscal_code: '',
    date_of_birth: '',
    country_of_birth_code: 'Z000',
    province_of_birth_code: null,
    commune_of_birth_code: null,
    born_abroad: false,
    residence_province_code: '',
    residence_commune_code: '',
    postal_code: '',
    address: '',
    house_number: '',
    civil_status_id: '2',
    education_level_id: '3',
    profession_id: '',
    valid_driving_license: '1',
    driving_license_year: '',
    business_name: '',
    vat_number: '',
    company_type: null,
  });
  // Collect Driver Data
  const [driver, setDriver] = useState({
    isValid: false,
    name: '',
    surname: '',
    gender: '',
    fiscal_code: '',
    date_of_birth: '',
    country_of_birth_code: 'Z000',
    province_of_birth_code: null,
    commune_of_birth_code: null,
    born_abroad: false,
    residence_province_code: '',
    residence_commune_code: '',
    postal_code: '',
    address: '',
    house_number: '',
    civil_status_id: '2',
    education_level_id: '3',
    profession_id: '',
    valid_driving_license: '1',
    driving_license_year: '',
  });
  const [bersani, setBersani] = useState({
    isValid: false,
    name: '',
    surname: '',
    date_of_birth: '',
    gender: '',
    commune_of_birth_code: '',
    vehicle_type: '',
    vehicle_plate: '',
  });

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: answers.name,
      surname: answers.surname,
      gender: answers.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (answers.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === answers.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === answers.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = answers.province_of_birth_code;
    }

    return generateFiscalCode(fData);
  };

  const getFiscalCodeOD = (data) => {
    const d = convertToItalianDate(data.date_of_birth).split('-');
    const fData = {
      name: data.name,
      surname: data.surname,
      gender: data.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (data.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === data.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === data.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = data.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  // FUNCTIONS
  const submitAnswers = () => {
    const answersData = {
      ...answers,
      owner: answers.contractor_is_owner === '0' ? owner : null,
      driver: answers.contractor_is_driver === '0' ? driver : null,
      bersani:
        answers.insurance_type === 'N' && answers.inherit_merit_class != 'N'
          ? bersani
          : null,
    };

    answersData.date_of_birth = answers.date_of_birth !== null
      ? convertToItalianDate(answers.date_of_birth)
      : null;
    answersData.policy_effective_date = convertToItalianDate(
      answers.policy_effective_date,
    );
    answersData.fiscal_code = answers.gender !== 'G' ? getFiscalCode(answers.date_of_birth) : null;
    if (answers.contractor_is_owner === '0') {
      answersData.owner.date_of_birth = owner.gender !== 'G' ? convertToItalianDate(owner.date_of_birth) : null;
      answersData.owner.fiscal_code = owner.gender !== 'G' ? getFiscalCodeOD(owner) : null;
    }
    if (answers.contractor_is_driver === '0') {
      answersData.driver.date_of_birth = convertToItalianDate(
        driver.date_of_birth,
      );
      answersData.driver.fiscal_code = getFiscalCodeOD(driver);
    }

    motoSubmitAnswers(answersData, navigate, requestToken);
  };

  const updateFormData = (answer, doNotRunVehicleInfoChangeChecks) => {
    if (doNotRunVehicleInfoChangeChecks !== true) {
      if (
        answer.moto_brand_code
        && answer.moto_brand_code != answers.moto_brand_code
      ) {
        answer.moto_model_code = '';
        answer.moto_version_code = null;
      } else if (
        (answer.moto_brand_code
          && answer.moto_brand_code != answers.moto_brand_code)
        || (answer.moto_model_code
          && answer.moto_model_code != answers.moto_model_code)
        || (answer.start_immatricolation
          && answer.start_immatricolation != answers.start_immatricolation)
      ) {
        answer.moto_version_code = null;
      }
    }
    updateAnswers({ ...answer });
  };

  const updateBersaniData = (data) => {
    setBersani({ ...bersani, ...data });
  };

  const updateOwnerFormData = (data) => {
    setOwner({ ...owner, ...data });
  };

  const updateDriverFormData = (data) => {
    setDriver({ ...driver, ...data });
  };

  const prevButton = () => {
    if (index > 1) {
      motoChangeIndex(index - 1);
    }
  };
  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...answers }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const nextButton = async () => {
    const res = await trigger(answersFieldsBySteps[index - 1], {
      shouldFocus: true,
    });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      if (answers.contractor_is_driver === '0') {
        if (!driver.isValid) {
          driverFormComponentRef.current.triggerErrors();
          return;
        }
      }

      if (answers.contractor_is_owner === '0') {
        if (!owner.isValid) {
          ownerFormComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 3) {
      if (
        answers.inherit_merit_class === 'S'
        || (answers.inherit_merit_class == 'A' && answers.insurance_type === 'N')
      ) {
        if (!bersani.isValid) {
          bersaniComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 4) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 4) {
      submitAnswers();
      return;
    }

    motoChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  let typeGuide;

  if (answers.other_drivers === '1') {
    typeGuide = guideType.filter((guide) => {
      if (
        answers.youngest_age_driver < 26
        || answers.youngest_age_family_member < 26
      ) {
        return guide.id === 'free';
      }
      return guide.id !== 'exclusive';
    });
  } else {
    typeGuide = guideType.filter((guide) => {
      if (answers.youngest_age_family_member < 26) {
        return guide.id !== 'expert';
      } if (answers.youngest_age_family_member >= 26) {
        return guide;
      }
      return false;
    });
  }

  useEffect(() => {
    motoGetInitialData();

    return () => {
      resetMotoForm();
    };
  }, []);

  const setDriverForm = (value) => {
    motoUpdateFormData({
      driverForm: value,
    });
  };

  const setOwnerForm = (value) => {
    motoUpdateFormData({
      ownerForm: value,
    });
  };

  useEffect(() => {
    if (answers.moto_brand_code) {
      motoGetVehicleModels(answers.moto_brand_code);
    }
  }, [answers.moto_brand_code]);

  useEffect(() => {
    if (answers.moto_model_code && answers.start_immatricolation) {
      motoGetVehicleVersions(
        answers.moto_model_code,
        answers.start_immatricolation,
      );
    }
  }, [
    answers.moto_brand_code,
    answers.moto_model_code,
    answers.start_immatricolation,
  ]);

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...answers.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateAnswers({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...answers.privacies];
    answersNewArray.push(item.id);
    updateAnswers({
      privacies: answersNewArray,
    });
  };

  useEffect(() => {
    const callAsync = async () => {
      skipCheckGenderRef.current = true;
      const {
        data: {
          data: {
            answers, owner, driver, bersani,
          },
        },
      } = await modifyRequestDataBackoffice(requestToken);

      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      updateAnswers(answers);
      setOwner(owner);
      setDriver(driver);
      setBersani(bersani);
    };

    callAsync();
  }, []);

  useEffect(() => {
    if (!answers.driving_license_year) {
      if (answers.date_of_birth) {
        const birthYear = answers.date_of_birth.split('-')[0];
        const regex = /^\d{4}$/;
        if (regex.test(parseInt(birthYear))) {
          const drivingLicenseYear = parseInt(birthYear) + 18;
          updateAnswers({
            driving_license_year: drivingLicenseYear.toString(),
          });
        }
      }
    }
  }, [answers.date_of_birth]);

  if (loading) {
    return <PageLoading />;
  }

  if (loadingQuotes) {
    return <QuoteGenerateLoading />;
  }

  return (
    <Layout>
      <div className="d-flex align-content-center justify-content-center">
        <ProgressBar activeStep={index} steps={steps} />
      </div>
      <div>
        {isSubmitting && (
          <div className="stopInteraction">
            <div className="black-cover" />
            <span>
              <div className="text-center">
                <img
                  className="loading-gif"
                  src={LoadingGif}
                  alt=":aoding indicator"
                />
                <br />
                Attendere prego
              </div>
            </span>
          </div>
        )}
        <div className=" row title-questionnaire">
          <div className="quote-h2 divider">
            <h2>{title}</h2>
          </div>
        </div>
        {index === 1 && (
          <>
            <div className="form-container">
              <FormInputText
                error={errors.moto_plate?.message}
                label="Targa della moto"
                onChange={(value) => updateFormData({ moto_plate: value.toUpperCase() })}
                value={answers.moto_plate}
                valid={isValid('moto_plate')}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormInputCheckbox
                  label=" Compila Manualmente?"
                  checked={answers.complete_manually}
                  onChange={() => updateFormData({
                    complete_manually: !answers.complete_manually,
                    renew_check: false,
                    insurance_type: '',
                    new_risks_check: false,
                  })}
                />

                <div style={{ width: '20px' }} />

                <FormInputCheckbox
                  label=" Rinnovo?"
                  checked={answers.renew_check}
                  onChange={() => updateFormData({
                    renew_check: !answers.renew_check,
                    insurance_type: !answers.renew_check ? 'B' : 'N',
                    new_risks_check: false,
                    complete_manually: false,
                  })}
                />

                <div style={{ width: '20px' }} />

                <FormInputCheckbox
                  label=" Nuovo rischio?"
                  checked={answers.new_risks_check}
                  onChange={() => updateFormData({
                    new_risks_check: !answers.new_risks_check,
                    renew_check: false,
                    insurance_type: '',
                    complete_manually: false,
                  })}
                />
              </div>
            </div>

            {answers.moto_plate !== ''
              && !errors.moto_plate?.message
              && (answers.start_immatricolation_month !== ''
                || answers.complete_manually === true) && (
                <div className="form-container">
                  <div className="row mt-2">
                    <Label>Data di prima immatricolazione</Label>
                    <div className="col-12 col-md-8">
                      <FormInputSelect
                        registration={register('start_immatricolation_month')}
                        error={errors.start_immatricolation_month?.message}
                        valid={isValid('start_immatricolation_month')}
                        placeholder="-Mese-"
                        options={months}
                      />
                    </div>

                    <div className="col-12 col-md-4">
                      <FormInputSelect
                        registration={register('start_immatricolation')}
                        error={errors.start_immatricolation?.message}
                        valid={isValid('start_immatricolation')}
                        placeholder="-Anno-"
                        options={years}
                      />
                    </div>
                  </div>
                  <FormReactSelectMotoBrand
                    label="Marca"
                    values={{
                      moto_brand_code: answers.moto_brand_code,
                    }}
                    error={errors.moto_brand_code?.message}
                    valid={isValid('moto_brand_code')}
                    onChange={(item) => updateFormData({
                      moto_brand_code: item.moto_brand_code,
                    })}
                    options={brands}
                  />
                </div>
            )}
            {answers.moto_brand_code ? (
              <>
                {answers.moto_brand_code && (
                  <div className="form-container">
                    {isLoadingVehicleModels ? (
                      <p className="text-center">Attendere prego...</p>
                    ) : (
                      <FormReactSelectMotoModel
                        label="Modello"
                        values={{
                          moto_model_code: answers.moto_model_code,
                        }}
                        error={errors.moto_model_code?.message}
                        valid={isValid('moto_model_code')}
                        onChange={(item) => {
                          updateFormData({
                            moto_model_code: item.moto_model_code,
                          });
                        }}
                        options={vehicleModels}
                      />
                    )}
                  </div>
                )}

                {answers.moto_brand_code && answers.moto_model_code ? (
                  isLoadingVehicleVersions ? (
                    <p className="text-center">Attendere prego...</p>
                  ) : answers.moto_model_code && vehicleVersions.length > 0 ? (
                    <InputSelectTableMoto
                      TableHeader={(
                        <div className="d-flex">
                          <div className="col-6">Descrizione</div>
                          <div className="col-6">Data Vehicolo</div>
                        </div>
                      )}
                      label="Versione"
                      name="moto_version_code"
                      id="moto_version_code"
                      options={vehicleVersions}
                      selected={answers.moto_version_code}
                      onSelectOption={(value) => updateFormData({
                        moto_version_code: value.id,
                        prg_code_moto: value.prg_code_moto,
                      })}
                    />
                  ) : (
                    <div className="alert-general-error">
                      Nessuna versione trovata per questo modello!
                    </div>
                  )
                ) : (
                  ''
                )}

                {answers.moto_version_code && (
                  <div className="form-container">
                    <FormInputNumber
                      registration={register('weight')}
                      error={errors.weight?.message}
                      valid={isValid('weight')}
                      label="Peso della moro"
                    />
                    <FormInputNumber
                      registration={register('power')}
                      error={errors.power?.message}
                      valid={isValid('power')}
                      label="Potenza in kW"
                    />
                    <FormInputSelect
                      label="Parcheggio moto"
                      registration={register('vehicle_parking')}
                      error={errors.vehicle_parking?.message}
                      valid={isValid('vehicle_parking')}
                      placeholder="-Seleziona-"
                      options={formData.parking_types}
                    />
                    <FormInputSelect
                      label="Antifurto"
                      registration={register('theft_protection_code')}
                      error={errors.theft_protection_code?.message}
                      valid={isValid('theft_protection_code')}
                      placeholder="-Seleziona-"
                      options={formData.theft_protections}
                    />
                    <FormInputSelect
                      label="Utilizzo"
                      registration={register('vehicle_usage')}
                      error={errors.vehicle_usage?.message}
                      valid={isValid('vehicle_usage')}
                      placeholder="-Seleziona-"
                      options={formData.vehicle_usage_types.filter(
                        (type) => type.vehicle_type === 'auto',
                      )}
                    />
                    <FormInputSelect
                      label="Km percorsi in un anno"
                      registration={register('predicted_km')}
                      error={errors.predicted_km?.message}
                      valid={isValid('predicted_km')}
                      placeholder="-Seleziona-"
                      options={km_during_one_year}
                    />
                    {answers.start_immatricolation !== '' && (
                      <div className="row mt-2">
                        <Label>Mese e anno in cui hai comprato la moto</Label>
                        <div className="col-12 col-md-8">
                          <FormInputSelect
                            registration={register('vehicle_purchased_month')}
                            error={errors.vehicle_purchased_month?.message}
                            valid={isValid('vehicle_purchased_month')}
                            placeholder="-Mese-"
                            options={months}
                          />
                        </div>

                        <div className="col-12 col-md-4">
                          <FormInputSelect
                            registration={register('vehicle_purchased_year')}
                            error={errors.vehicle_purchased_year?.message}
                            valid={isValid('vehicle_purchased_year')}
                            placeholder="-Anno-"
                            options={getAllowedPurchaseYears(
                              answers.vehicle_purchased_year,
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <FormInputSelect
                      label="Vehicoli nel nucleo familiare"
                      registration={register('vehicles_owned')}
                      error={errors.vehicles_owned?.message}
                      valid={isValid('vehicles_owned')}
                      placeholder="-Seleziona-"
                      options={vehiclesAmountInFamily}
                    />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
        {index === 2 && (
          <div className="form-container">
            {/* <div>
              <FormInputCheckbox
                registration={register("existing_customer")}
                error={errors.existing_customer?.message}
                label=" Cliente Esistente?"
              />
              {answers.existing_customer && (
                <div className="d-flex align-items-start justify-content-center gap-2">
                  <div className="position-relative">
                    <FormInputText
                      placeholder="Nome cliente..."
                      onChange={(value) => onSearchChange(value)}
                      value={searchClient}
                    />
                    {searchResults.length > 0 && (
                      <div
                        className="position-absolute"
                        style={{
                          top: "80%",
                          left: "0%",
                          right: "0%",
                          zIndex: 50,
                        }}
                      >
                        <ul className="list-group">
                          {searchResults.map((customer, index) => (
                            <li
                              className="list-group-item list-group-item-dark"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => getCustomerData(customer.id)}
                            >
                              {customer.name.toUpperCase() +
                                (customer.fiscal_code !== null
                                  ? " - (" + customer.fiscal_code + ")"
                                  : "")}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-questionnaire py-1 px-2 "
                    style={{
                      marginTop: 10,
                      display: "block",
                      width: "150px",
                      border: "3px solid",
                    }}
                  >
                    Cerca cliente
                  </button>
                </div>
              )}

              {selectedClient !== null ? (
                <ActiveClientModal
                  closeClientModal={closeClientModal}
                  client={selectedClient}
                  genders={genders}
                  confirmActiveClient={confirmActiveClient}
                  municipalities={municipalities}
                  states={states}
                  formData={formData}
                />
              ) : (
                ""
              )}
            </div> */}

            <FormInputRadio
              label="Il contraente è"
              options={genders}
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />
            {answers.gender === 'G' && (
              <>
                <FormInputText
                  registration={register('business_name')}
                  error={errors.business_name?.message}
                  valid={isValid('business_name')}
                  label="Ragione sociale"
                />
                <FormInputText
                  registration={register('vat_number')}
                  error={errors.vat_number?.message}
                  valid={isValid('vat_number')}
                  label="Partita Iva"
                />
                <FormInputSelect
                  label="Tipologia azienda"
                  registration={register('company_type')}
                  error={errors.company_type?.message}
                  valid={isValid('company_type')}
                  placeholder="-Seleziona-"
                  options={formData.company_types}
                />
              </>
            )}
            {answers.gender !== 'G' && (
              <>
                <FormInputDate
                  label="Data di nascita"
                  registration={register('date_of_birth')}
                  minDate="1935-01-01"
                  maxDate={getMaxAllowedBirthday()}
                  error={errors.date_of_birth?.message}
                  valid={isValid('date_of_birth')}
                />
                {answers.date_of_birth !== '' && (
                  <FormInputSelect
                    label="Anno patente"
                    registration={register('driving_license_year')}
                    error={errors.driving_license_year?.message}
                    valid={isValid('driving_license_year')}
                    placeholder="-Seleziona-"
                    options={getAllowedLicenseYears(answers.date_of_birth)}
                  />
                )}
                <FormReactSelectInputBirthPlace
                  states={states}
                  options={municipalities}
                  label="Luogo di nascita"
                  name="commune_of_birth_code"
                  values={{
                    commune_of_birth_code: answers.commune_of_birth_code,
                    province_of_birth_code: answers.province_of_birth_code,
                    born_abroad: answers.born_abroad,
                    country_of_birth_code: answers.country_of_birth_code,
                  }}
                  onChange={(value) => updateFormData({
                    commune_of_birth_code: value.commune_of_birth_code,
                    province_of_birth_code: value.province_of_birth_code,
                    born_abroad: value.born_abroad,
                    country_of_birth_code: value.country_of_birth_code,
                  })}
                  valid={isValid('commune_of_birth_code')}
                  error={errors.commune_of_birth_code?.message}
                />
              </>
            )}
            <FormInputAddressComplete
              municipalities={municipalities}
              formData={answers}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateFormData}
              addressId="autocomplete-address-contractor"
            />

            {answers.gender !== 'G' && (
              <>
                <FormInputRadio
                  label="Figli conviventi?"
                  options={flagResponse}
                  name="children"
                  registration={register('children')}
                  error={errors.children?.message}
                />
                <FormInputSelect
                  placeholder="Stato civile"
                  label="Stato civile"
                  registration={register('civil_status_id')}
                  error={errors.civil_status_id?.message}
                  valid={isValid('civil_status_id')}
                  options={formData.marital_statuses}
                />
                <FormInputSelect
                  placeholder="Titolo di studio"
                  label="Titolo di studio"
                  registration={register('education_level_id')}
                  error={errors.education_level_id?.message}
                  valid={isValid('education_level_id')}
                  options={formData.qualifications}
                />
                <FormInputSelect
                  placeholder="Professione"
                  label="Professione"
                  registration={register('profession_id')}
                  error={errors.profession_id?.message}
                  valid={isValid('profession_id')}
                  options={formData.professions}
                />
              </>
            )}

            {answers.gender !== 'G' && (
              <FormInputRadio
                label="Il contraente è anche il conducente abituale del veicolo?"
                options={flagResponse}
                name="contractor_is_driver"
                registration={register('contractor_is_driver')}
                error={errors.contractor_is_driver?.message}
              />
            )}

            {answers.contractor_is_driver === '0' ? (
              <>
                <OffcanvasForm
                  ref={driverFormComponentRef}
                  filled={driverForm}
                  setFilled={setDriverForm}
                  id="dati-driver"
                  title="Dati Del Conduttore"
                  data={driver}
                  onChange={updateDriverFormData}
                  genders={genders}
                  getMaxAllowedBirthday={getMaxAllowedBirthday}
                  municipalities={municipalities}
                  validateItalianPostCode={validateItalianPostCode}
                  marital_statuses={formData.marital_statuses}
                  qualifications={formData.qualifications}
                  professions={formData.professions}
                  company_types={formData.company_types}
                  isOwnerForm={false}
                  states={states}
                />
              </>
            ) : (
              ''
            )}

            {answers.gender !== 'G' && (
              <FormInputRadio
                label="Il contraente è anche proprietario della moto?"
                options={flagResponse}
                name="contractor_is_owner"
                registration={register('contractor_is_owner')}
                error={errors.contractor_is_owner?.message}
              />
            )}

            {answers.contractor_is_owner === '0' ? (
              <>
                <OffcanvasForm
                  ref={ownerFormComponentRef}
                  filled={ownerForm}
                  states={states}
                  setFilled={setOwnerForm}
                  id="dati-proprietario"
                  title="Dati Del Proprietario"
                  data={owner}
                  onChange={updateOwnerFormData}
                  genders={genders}
                  getMaxAllowedBirthday={getMaxAllowedBirthday}
                  municipalities={municipalities}
                  validateItalianPostCode={validateItalianPostCode}
                  marital_statuses={formData.marital_statuses}
                  qualifications={formData.qualifications}
                  professions={formData.professions}
                  company_types={formData.company_types}
                  isOwnerForm
                />
              </>
            ) : (
              ''
            )}
          </div>
        )}
        {index === 3 && (
          <>
            <div className="form-container">
              {answers.renew_check && (
                <FormInputSelect
                  label="Seleziona il tipo di assicurazione."
                  registration={register('insurance_type')}
                  error={errors.insurance_type?.message}
                  valid={isValid('insurance_type')}
                  placeholder="-Seleziona-"
                  options={insuranceTypes}
                  disabled
                />
              )}
              {!answers.renew_check && (
                <FormInputSelect
                  label="Seleziona il tipo di assicurazione."
                  registration={register('insurance_type')}
                  error={errors.insurance_type?.message}
                  valid={isValid('insurance_type')}
                  placeholder="-Seleziona-"
                  options={insuranceTypes}
                />
              )}

              <FormInputDate
                minDate={getAllowedPolicyEffectDate('min')}
                maxDate={getAllowedPolicyEffectDate('max')}
                registration={register('policy_effective_date')}
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
                label={
                  answers.insurance_type === 'N'
                    ? 'Data di inizio della copertura della polizza'
                    : 'Data di scadenza della tua attuale polizza'
                }
                paragraph="Date valide: da oggi a 30 giorni da oggi"
              />
              <FormInputRadio
                label="Ci sono altri conducenti?"
                options={flagResponse}
                name="other_drivers"
                registration={register('other_drivers')}
                error={errors.other_drivers?.message}
              />
              {answers.gender !== 'G' && (
                <>
                  {answers.other_drivers === '1' && (
                    <FormInputText
                      placeholder="(18 - 100)"
                      type="number"
                      min={18}
                      max={100}
                      label="Età del conducente più giovane"
                      registration={register('youngest_age_driver')}
                      error={errors.youngest_age_driver?.message}
                      valid={isValid('youngest_age_driver')}
                    />
                  )}
                  <FormInputText
                    placeholder="(18 - 100)"
                    type="number"
                    min={18}
                    max={100}
                    label="Età del membro più giovane della famiglia con la patente di guida"
                    registration={register('youngest_age_family_member')}
                    error={errors.youngest_age_family_member?.message}
                    valid={isValid('youngest_age_family_member')}
                  />
                </>
              )}
              {answers.insurance_type !== 'N' && (
                <>
                  <FormInputSelect
                    label="Prima assicurazione in 6 anni"
                    registration={register('first_insuranced_year')}
                    error={errors.first_insuranced_year?.message}
                    valid={isValid('first_insuranced_year')}
                    placeholder="-Seleziona-"
                    options={lastSixYears}
                  />
                  <FormInputRadio
                    label="Hai fatto sinistri negli ultimi 6 anni?"
                    name="violations"
                    placeholder="-Seleziona-"
                    options={flagResponse}
                    onChange={(value) => {
                      const update_data = {
                        violations: value,
                      };

                      if (value === 0) {
                        update_data.violations_number = 0;
                        update_data.violations_data = calculateEmptyViolationsData();
                      }

                      updateFormData(update_data);
                    }}
                    value={answers.violations}
                  />
                </>
              )}
            </div>
            {answers.violations === 1 && (
              <InputViolations
                title="Violations"
                onChange={(data) => {
                  updateFormData({
                    violations_data: data.data,
                    violations_number: data.violations_number,
                  });
                }}
                value={answers.violations_data}
              />
            )}

            <div className="form-container">
              {answers.insurance_type === 'N' && (
                <FormInputSelect
                  label="Puoi utilizzare la classe di merito di un altro veicolo già assicurato?"
                  registration={register('inherit_merit_class')}
                  error={errors.inherit_merit_class?.message}
                  valid={isValid('inherit_merit_class')}
                  placeholder="-Seleziona-"
                  options={inheritMeritClass}
                />
              )}

              {((answers.inherit_merit_class == 'A'
                && answers.insurance_type === 'N')
                || answers.inherit_merit_class == 'S') && (
                  <BersaniInfo
                    ref={bersaniComponentRef}
                    inheritMeritClass={answers.inherit_merit_class}
                    bersani={bersani}
                    updateBersaniData={updateBersaniData}
                    municipalities={municipalities}
                  />
              )}

              <FormInputSelect
                label={
                  answers.inherit_merit_class == 'S'
                    || (answers.inherit_merit_class == 'A'
                      && answers.insurance_type === 'N')
                    ? 'Classe di merito del veicolo già  assicurato'
                    : 'Classe di merito.'
                }
                registration={register('merit_class')}
                error={errors.merit_class?.message}
                valid={isValid('merit_class')}
                placeholder="-Seleziona-"
                options={meritClass}
              />

              <FormInputRadio
                label="Il conducente dichiara: di essere in possesso di patente italiana mai sospesa da 5 anni e con almeno 20 punti, di non aver mai ricevuto sanzioni per ubriachezza, di non aver modificato il veicolo."
                options={flagResponse}
                name="modified_vehicle_and_valid_driving_license"
                onChange={(value) => updateFormData({
                  modified_vehicle: value,
                  valid_driving_license: value,
                })}
                value={answers.modified_vehicle}
                error={errors.modified_vehicle?.message}
              />
              <FormInputSelect
                label="Tipo di guida"
                registration={register('guide_type')}
                error={errors.guide_type?.message}
                valid={isValid('guide_type')}
                placeholder="-Seleziona-"
                options={typeGuide}
              />
            </div>
          </>
        )}
        {index === 4 && (
          <>
            <div className="form-container">
              {answers.gender !== 'G' && (
                <>
                  <FormInputText
                    registration={register('name')}
                    error={errors.name?.message}
                    valid={isValid('name')}
                    label="Nome"
                  />
                  <FormInputText
                    registration={register('surname')}
                    error={errors.surname?.message}
                    valid={isValid('surname')}
                    label="Cognome"
                  />
                </>
              )}
              <FormInputText
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
                label="E-mail"
              />
              <FormInputText
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
                label="Telefono"
                paragraph="Numeri di rete fissa non accettati dalle aziende"
              />

              <PaymentTypePeriod
                selectedType={answers.payment_frequency}
                updateAnswers={updateAnswers}
              />

              <h5 className="text-center">Informativa Privacy e IVASS</h5>

              <div className="text-center text-decoration-underline mt-2">
                <a
                  onClick={() => greeniaPrivacyPolicy()}
                  style={{ cursor: 'pointer' }}
                >
                  <small className="text-center">Greenia Privacy Policy</small>
                </a>
              </div>
            </div>

            {dataPrivacy.map((item, i) => (
              <InputPrivacy
                required={item.required}
                label={item.content}
                name={item.type}
                id={item.type}
                key={i}
                checked={answers.privacies.includes(item.id)}
                onChange={(value) => privacyItemChangeHandler(value, item)}
              />
            ))}
          </>
        )}
      </div>
      {index >= 1 && index <= 4 && (
        <div className="footer-buttons p-5">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            <button className="btn btn-questionnaire" onClick={nextButton}>
              {index === 4 ? 'Vai ai preventivi' : 'Continua'}
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BackOfficeRcMoto;
