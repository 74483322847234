export default function SubProductCard({
  product, sub_product, onSelect,
}) {
  return (
    <div className="col-9 col-md-6 col-lg-5 col-xl-3">
      <div className="product cursor-pointer" onClick={onSelect}>
        <label className="h5">{product}</label>
        <br />
        <small className="h6">{sub_product}</small>
      </div>
    </div>
  );
}
