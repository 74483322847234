import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  EuropeAssistanceCollisioneInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader,
  RightSide,
  TextContainer,
  TitleBox,
} from './EuropeAssistanceCollisioneModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function EuropeAssistanceCollisioneModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <EuropeAssistanceCollisioneInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>

            <ul className="mt-3">
              <li>
                EA paga fino alla somma di € 3.000 con franchigia di € 200 per sinistro e anno assicurativo per i
                danni materiali e diretti subiti dal veicolo indicato in polizza causati da urto o collisione
                accidentale contro animali selvatici o randagi (esclusi uccelli, bovini, equini, suini domestici,
                capre e pecore)
              </li>
              <li>
                EA paga anche i pezzi di ricambio, e gli accessori non di serie presenti sul Veicolo Tale pagamento non potrà in nessun caso superare il valore commerciale del veicolo al momento
                del sinistro.
              </li>
              <li>
                La Garanzia è operativa solo se è stato richiesto l’intervento immediato di un organo di polizia o hai
                presentato regolare denuncia presso le autorità del luogo nel quale l’evento è accaduto e fornisci a
                Europ Assistance il verbale.
              </li>
            </ul>

          </div>
        </ModalContent>
      </ModalBody>
    </EuropeAssistanceCollisioneInfoModalStyles>
  );
}

export default EuropeAssistanceCollisioneModal;
