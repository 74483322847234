import styled from 'styled-components';

export const TutelaLegaleCardInfoStyles = styled.div`
  border: 2px solid #dbdde6;
  background: #fff;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
`;

export const TitleActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 900px) {
    h4 {
      font-size: 18px;
    }

  }
`;

export const InfoBoxContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;

  @media only screen and (max-width: 900px) {
    margin-top: 15px;
    padding-bottom: 15px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-align: start;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    margin-bottom: 0;
    text-align: end;
  }

  @media only screen and (max-width: 900px) {
    h4 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }

`;

export const RiskContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 1270px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RiskCard = styled.div`
  border: 2px solid #dbdde6;
  background: #fff;
  border-radius: 30px;
  height: 100%;
`;

export const RiskButton = styled.button`
  border-radius: 15px;
    /* background: #00a651; */
    border: 1px solid #00a651;
    padding: 10px 30px;
    /* color: #ffffff; */
    /* width: 75%; */
    transition: all 300ms ease-in-out;

    &:hover {
      /* background: transparent; */
      border: 1px solid #00a651;
      /* color: #00a651; */
    }
`;

export const RiskButtonRemove = styled.button`
  border-radius: 15px;
    /* background: #00a651; */
    border: 1px solid #DD4D44;
    padding: 10px 30px;
    /* color: #ffffff; */
    /* width: 75%; */
    transition: all 300ms ease-in-out;

    &:hover {
      /* background: transparent; */
      border: 1px solid #DD4D44;
      /* color: #00a651; */
    }
`;

export const RespCivileModal = styled.div`

  @media screen and (min-width: 800px) {
   .modal-dialog{
    max-width: 1000px;
   }
    
  }
`;

export const TutelaLegaleModal = styled.div`

  @media screen and (min-width: 800px) {
   .modal-dialog{
    max-width: 1000px;
   }
    
  }
`;

export const TutelaLegaleBody = styled.div`
padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  max-width: 800px;
  margin: 30px auto;

  @media only screen and (max-width: 900px) {
    margin: 10px auto;
  }
`;

export const TutelaText = styled.div`
color: #343434;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;

  @media only screen and (max-width: 900px) {
    font-size: 16px;
  }`;
