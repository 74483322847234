import styled from 'styled-components';

export const HomeQuotesStyles = styled.div`
  margin-bottom: 80px;
  .failed-quotations-block {
    margin-top: 120px;
  }
  .failed-quotations-block img {
    width: 100%;
    display: block;
    aspect-ratio: 6/3;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
`;

export const RiskContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 1270px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RiskCard = styled.div`
  border: 2px solid #dbdde6;
  background: #fff;
  border-radius: 30px;
  height: 100%;
`;
