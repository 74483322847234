import * as yup from 'yup';
import moment from 'moment';
import StorageService from '../../services/StorageService';

const Storage = new StorageService();
export const ancillariAutoSchema = yup.object({
  product: yup.string().required(),
  source: yup.string().required(),
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il nome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il cognome!')
        .matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona data di nascita!',
      )
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1935) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? born_abroad === false
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del contraente!',
        )
        : schema
      : schema)),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'),
  email: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci Email!')
    .email('Deve essere un email valido! Rimouvi spazi vuoti!')
    .test('brokerEmail', 'Non puoi impostare la tua email', (value) => value !== Storage.getUser().email),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? born_abroad === false
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del contraente!',
        )
        : schema
      : schema)),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  country_of_birth_code: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required(
        'Il campo è obbligatorio. Seleziona il stato estero!',
      )
      : schema)),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  house_number: yup.string().required('Il campo è obbligatorio.').matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  fiscal_code: yup.string().nullable(),
  residence_province_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  existing_customer: yup.boolean(),
  active_customer_id: yup.number().nullable(),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(
      new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')),
      'La data è nel passato',
    )
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  privacies: yup.array(yup.number()),
  vehicle_plate: yup
    .string()
    .required('Il campo è obbligatorio.')
    .matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/, 'La targa non e coretta'),
  imm_vehicle_year: yup.string().required('Il campo è obbligatorio.'),
  imm_vehicle_month: yup.string().required('Il campo è obbligatorio.'),
  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
    }),
  vat_number: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .max(11, 'La partiva iva non puo essere piu di 11 numeri!')
        .matches(/^[0-9]*$/, 'Solo numeri sono ammessi!')
        .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
    }),
  company_type: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup.string().required('Il campo è obbligatorio'),
    }),
  vehicle_brand_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_model_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_version_code: yup.string().required('Il campo è obbligatorio.'),
  other_power_supply: yup.string().required(),
  vehicle_parking: yup.string().required(),
  theft_protection_code: yup.string().required('Il campo è obbligatorio.'),
  vehicle_usage: yup.string().required(),
  predicted_km: yup.number().required(),
  vehicle_purchased_month: yup
    .string()
    .required('Il campo è obbligatorio.')
    .when(
      ['vehicle_purchased_year', 'imm_vehicle_month', 'imm_vehicle_year'],
      ([vehicle_purchased_year, imm_vehicle_month, imm_vehicle_year], schema) => (vehicle_purchased_year !== ''
        ? schema.test(
          'purchase-month-custom-validation',
          'Data di aquisto non puo essere prima della data di immatricolazione',
          (value) => `${imm_vehicle_year}-${imm_vehicle_month}`
              <= `${vehicle_purchased_year}-${value}`,
        )
        : schema),
    ),
  vehicle_purchased_year: yup.string().required('Il campo è obbligatorio.'),
  vehicles_owned: yup.number().required('Il campo è obbligatorio.'),
  tow_hook: yup.string().required('Il campo è obbligatorio.'),
  weight: yup.number().required('Il campo è obbligatorio'),
  power: yup.number().required('Il campo è obbligatorio'),
  vehicle_am_code: yup.string().required(),

});

export const answersFieldsBySteps = [
  [
    'vehicle_plate',
    'imm_vehicle_month',
    'imm_vehicle_year',
    'vehicle_brand_code',
    'vehicle_model_code',
    'vehicle_version_code',
    'other_power_supply',
    'vehicle_parking',
    'theft_protection_code',
    'vehicle_usage',
    'predicted_km',
    'vehicle_purchased_month',
    'vehicle_purchased_year',
    'vehicles_owned',
    'tow_hook',
    'weight',
    'power',
  ],
  [
    'name',
    'surname',
    'email',
    'fiscal_code',
    'address',
    'house_number',
    'postal_code',
    'phone',
    'gender',
    'date_of_birth',
    'country_of_birth_code',
    'province_of_birth_code',
    'commune_of_birth_code',
    'born_abroad',
    'residence_commune_code',
    'residence_province_code',
    'business_name',
    'vat_number',
    'company_type',
  ],
];
