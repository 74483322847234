import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  AncillariAutoFormFlowStyles,
  AncillariAutoFormStyles,
} from './styles/AncillariAutoFormFlow.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import { FormInputCheckbox } from '../../common/formInputs/FormInputCheckbox';
import { FormInputText } from '../../common/formInputs/FormInputText';
import ActiveClientModal from '../../common/components/elements/ActiveClientModal';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import {
  flagResponse,
  genders,
  km_during_one_year,
  lastfifteenYears,
  months,
  vehiclesAmountInFamily,
} from '../../common/constants';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../../common/formInputs/FormInputAddressComplete';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import {
  ancillariAutoSchema,
  answersFieldsBySteps,
} from './AncillariAutoFormFlow.validations';
import {
  modifyRequestData,
  updateActiveCustomer,
} from '../../services/axios-client/axeCommons';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import {
  ancillariAutoCancelFastQuote,
  ancillariAutoChangeIndex,
  ancillariAutoGetCustomerData,
  ancillariAutoGetInitialData,
  ancillariAutoGetQuickInfo,
  ancillariAutoGetVehicleInformation,
  ancillariAutoGetVehicleModels,
  ancillariAutoGetVehicleVersions,
  ancillariAutoSearchActiveClient,
  ancillariAutoSubmitAnswers,
  ancillariAutoUpdateFormData,
  resetAncillariAutoForm,
} from '../../features/forms/formsActions';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';
import generateFiscalCode, {
  decodeFiscalCode,
} from '../../common/helpers/generateFiscalCode';
import {
  generalAlertError,
  generalAlertErrorToast,
} from '../../common/utils/alerts';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { Label } from '../../common/formInputs/styles/common';
import ProgressBar from '../../common/components/ProgressBar';
import FormReactSelectBrand from '../../common/formInputs/FormReactSelectBrand';
import FormReactSelectModel from '../../common/formInputs/FormReactSelectModel';
import InputSelectTable from '../../common/components/inputs/InputTable';
import { FormInputNumber } from '../../common/formInputs/FormInputNumber';
import getAllowedPurchaseYears from '../../common/helpers/getAllowedPurchaseYears';
import { validateCarPlate } from '../../common/validators';
import { FastQuoteLoading } from '../../common/components/elements/FastQuoteLoading';
import RcAutoFastQuoteModal from '../../common/FormFunnels/Auto/RcAutoFastQuoteModal';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import PageLoading from '../../common/components/elements/PageLoading';

function AncillariAutoFormFlow() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchClient, setSearchClient] = useState('');
  const [proceedButton, setProceedButton] = useState(true);
  // const [skipQuickInfo, setSkipQuickInfo] = useState(true);

  const skipCheckGenderRef = useRef(null);
  const state = useSelector((store) => store.forms.ancillari_auto);
  const {
    loading,
    isLoadingFastQuote,
    isLoadingVehicleModels,
    isLoadingVehicleVersions,
    states,
    index,
    brands,
    vehicleModels,
    vehicleVersions,
    municipalities,
    formData,
    dataPrivacy,
    searchResults,
    selectedClientOldData,
    selectedClient,
    selectedClientId,
    fastQuoteVehicleInfo,
  } = state;
  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(ancillariAutoSchema),
    shouldFocusError: true,
    defaultValues: {
      product: 'ancillari_auto',
      source: 'greenia.it',
      policy_effective_date: '',
      privacies: [],
      name: '',
      surname: '',
      email: '',
      fiscal_code: '',
      address: '',
      house_number: '',
      postal_code: '',
      phone: '',
      gender: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_commune_code: '',
      residence_province_code: '',
      active_customer_id: null,
      existing_customer: false,
      business_name: '',
      vat_number: '',
      company_type: '',

      vehicle_plate: '',
      imm_vehicle_month: '',
      imm_vehicle_year: '',
      vehicle_brand_code: '',
      vehicle_model_code: '',
      vehicle_version_code: '',
      vehicle_am_code: '',
      other_power_supply: '9',
      vehicle_parking: '1',
      theft_protection_code: '4',
      vehicle_usage: '1',
      predicted_km: 10000,
      vehicle_purchased_month: '',
      vehicle_purchased_year: '',
      vehicles_owned: 2,
      tow_hook: '0',
      weight: 0,
      power: 0,
      complete_manually: false,
    },
  });

  // Constants
  const stepsQuote = ['Dati del Veicolo', 'Dati Personali'];

  const title = stepsQuote[index - 1];

  const formDataFlow = watch();

  const [skipBrandCodeEffect, setSkipBrandCodeEffect] = useState(false);
  const vehicleBrandCodeEffectDependencies = useRef();
  const vehicleBrandCodeDeps = [
    formDataFlow.vehicle_brand_code,
    formDataFlow.imm_vehicle_year,
  ];

  useEffect(() => {
    if (skipBrandCodeEffect) {
      setSkipBrandCodeEffect(false);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === undefined) {
      vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
      return;
    }

    if (
      vehicleBrandCodeEffectDependencies.current
      === JSON.stringify(vehicleBrandCodeDeps)
    ) {
      return;
    }

    vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
    setValue('vehicle_model_code', '');
  }, vehicleBrandCodeDeps);

  const [skipModelCodeEffect, setSkipModelCodeEffect] = useState(false);
  const vehicleModelCodeEffectDependencies = useRef();
  const vehicleModelCodeDeps = [formDataFlow.vehicle_model_code];
  useEffect(() => {
    if (skipModelCodeEffect) {
      setSkipModelCodeEffect(false);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === undefined) {
      vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);
      return;
    }

    if (
      vehicleModelCodeEffectDependencies.current
      === JSON.stringify(vehicleModelCodeDeps)
    ) {
      return;
    }

    vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);

    setValue('vehicle_version_code', null);
    setValue('vehicle_am_code', '');
  }, vehicleModelCodeDeps);

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      ancillariAutoUpdateFormData({
        searchResults: [],
      });
    }
  };

  const updateUserData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
      JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
      await updateActiveCustomer(selectedClientId, {
        ...selectedClient,
        date_of_birth:
          selectedClient.gender !== 'G'
            ? convertToItalianDate(selectedClient.date_of_birth)
            : null,
      });
    }

    if (selectedClient.gender === 'G') {
      updateUserData({
        born_abroad: false,
        fiscal_code: null,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: null,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        surname: null,
        address: selectedClient.address,
        date_of_birth: null,
        email: selectedClient.email,
        province_of_birth_code: null,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: null,
        commune_of_birth_code: null, // city_of_birth_code,
        business_name: selectedClient.business_name,
        vat_number: selectedClient.vat_number,
        company_type: selectedClient.company_type,
        active_customer_id: selectedClientId,
      });
    } else {
      updateUserData({
        born_abroad:
          selectedClient.born_abroad !== null
            ? selectedClient.born_abroad
            : false,
        fiscal_code: selectedClient.fiscal_code,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: selectedClient.name,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        surname: selectedClient.surname,
        address: selectedClient.address,
        date_of_birth: selectedClient.date_of_birth,
        email: selectedClient.email,
        province_of_birth_code: selectedClient.province_of_birth_code,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: selectedClient.country_of_birth_code,
        commune_of_birth_code: selectedClient.city_of_birth_code, // city_of_birth_code,
        business_name: selectedClient.business_name,
        vat_number: selectedClient.vat_number,
        company_type: selectedClient.company_type,
        active_customer_id: selectedClientId,
      });
    }

    setSearchClient('');
    ancillariAutoUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...formDataFlow.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateFormData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...formDataFlow.privacies];
    answersNewArray.push(item.id);
    updateFormData({
      privacies: answersNewArray,
    });
  };

  const closeClientModal = () => {
    setSearchClient('');
    ancillariAutoUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const getCustomerData = async (customerId) => {
    ancillariAutoGetCustomerData(customerId);
  };

  const searchActiveClient = (searchClient) => () => {
    if (searchClient.length === 0) return;
    ancillariAutoSearchActiveClient(searchClient);
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  const getQuickInfo = () => {
    ancillariAutoGetQuickInfo(formDataFlow.vehicle_plate, updateFormData);
  };

  const procceedWithFastQuote = () => {
    if (
      location.state === null
      && validateCarPlate(formDataFlow.vehicle_plate)
    ) {
      ancillariAutoGetVehicleInformation(
        formDataFlow.vehicle_plate,
        getQuickInfo,
      );
      setProceedButton(false);
    }
  };

  useEffect(() => {
    ancillariAutoGetInitialData();

    return () => {
      resetAncillariAutoForm();
    };
  }, []);

  useEffect(() => {
    if (
      location.state !== null
      && location.state.activeCustomerId !== undefined
    ) {
      getCustomerData(location.state.activeCustomerId);
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: formDataFlow.name,
      surname: formDataFlow.surname,
      gender: formDataFlow.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (formDataFlow.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === formDataFlow.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === formDataFlow.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = formDataFlow.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...formDataFlow }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const submitForm = () => {
    const { error, message } = validateDataPrivacy();

    if (error) {
      generalAlertError(message);
      return;
    }

    const _userData = { ...formDataFlow };

    if (_userData.born_abroad && _userData.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }

    if (
      _userData.born_abroad
      && (_userData.country_of_birth_code === null
        || _userData.country_of_birth_code === ''
        || _userData.country_of_birth_code == undefined)
    ) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }

    if (
      formDataFlow.date_of_birth !== ''
      && formDataFlow.date_of_birth !== null
    ) {
      _userData.date_of_birth = moment(formDataFlow.date_of_birth).format(
        'YYYY-MM-DD',
      );
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = moment(
      formDataFlow.policy_effective_date,
    ).format('YYYY-MM-DD');
    _userData.fiscal_code = formDataFlow.gender !== 'G'
      ? getFiscalCode(formDataFlow.date_of_birth)
      : null;
    delete _userData.existing_customer;
    delete _userData.complete_manually;
    delete _userData.persona_giuridica;
    ancillariAutoSubmitAnswers(_userData, navigate);
  };

  const prevButton = () => {
    if (index > 1) {
      ancillariAutoChangeIndex(index - 1);
    }
  };

  const nextButton = async () => {
    const res = await trigger(answersFieldsBySteps[index - 1], {
      shouldFocus: true,
    });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      if (
        formDataFlow.born_abroad
        && formDataFlow.country_of_birth_code === 'Z000'
      ) {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
        return;
      }

      if (
        formDataFlow.born_abroad
        && (formDataFlow.country_of_birth_code === null
          || formDataFlow.country_of_birth_code === ''
          || formDataFlow.country_of_birth_code == undefined)
      ) {
        window.scrollTo(0, 0);
        generalAlertErrorToast('Devi selezionare uno stato estero!');
        return;
      }
    }

    if (index === 2) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 2) {
      submitForm();
      return;
    }

    ancillariAutoChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const cancelFastquote = () => {
    ancillariAutoCancelFastQuote();
  };

  const confirmFastQuote = () => {
    if (fastQuoteVehicleInfo.frontend.persona_giuridica === true) {
      const commune_of_birth_code = null;
      const province_of_birth_code = null;
      const born_abroad = false;
      const country_of_birth_code = null;

      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      skipCheckGenderRef.current = true;
      const isAllowedYear = lastfifteenYears.find(
        (y) => y.id == fastQuoteVehicleInfo.frontend.imm_vehicle_year,
      );
      updateFormData(
        {
          vehicle_am_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_am_code,
          business_name: fastQuoteVehicleInfo.frontend.business_name,
          fiscal_code: fastQuoteVehicleInfo.frontend.fiscal_code,
          imm_vehicle_month: fastQuoteVehicleInfo.frontend.imm_vehicle_month,
          imm_vehicle_year:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.imm_vehicle_year,
          name: fastQuoteVehicleInfo.frontend.name,
          postal_code: fastQuoteVehicleInfo.frontend.postal_code,
          power:
            isAllowedYear === undefined
              ? formDataFlow.power
              : fastQuoteVehicleInfo.frontend.power,
          surname: fastQuoteVehicleInfo.frontend.surname,
          vat_number: fastQuoteVehicleInfo.frontend.vat_number,
          vehicle_brand_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_brand_code,
          vehicle_model_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_model_code,
          vehicle_version_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_version_code,
          weight:
            isAllowedYear === undefined
              ? formDataFlow.weight
              : fastQuoteVehicleInfo.frontend.weight,
          policy_effective_date:
            getAllowedPolicyEffectDate('min')
              <= fastQuoteVehicleInfo.frontend.policy_effective_date
            && fastQuoteVehicleInfo.frontend.policy_effective_date
              <= getAllowedPolicyEffectDate('max')
              ? fastQuoteVehicleInfo.frontend.policy_effective_date
              : '',
          date_of_birth: null,
          commune_of_birth_code,
          province_of_birth_code,
          born_abroad,
          country_of_birth_code,
          gender: 'G',
        },
        true,
      );
    } else {
      const fiscalObj = decodeFiscalCode(
        fastQuoteVehicleInfo.frontend.fiscal_code,
      );

      const isAllowedYear = lastfifteenYears.find(
        (y) => y.id == fastQuoteVehicleInfo.frontend.imm_vehicle_year,
      );

      const commune = municipalities.find(
        (municipality) => municipality.name.toLowerCase() === fiscalObj.birthplace.toLowerCase(),
      );

      let commune_of_birth_code = null;
      let province_of_birth_code = null;
      let born_abroad = false;
      let country_of_birth_code = 'Z000';

      if (fiscalObj.birthplaceProvincia !== 'EE') {
        if (commune) {
          commune_of_birth_code = commune.cadastral_code;
          province_of_birth_code = fiscalObj.birthplaceProvincia;
        }
      } else {
        born_abroad = true;
        let state = null;
        if (fiscalObj.birthplace === 'MACEDONIA DEL NORD') {
          state = states.find((s) => s.name === 'MACEDONIA');
        } else {
          state = states.find((s) => s.name === fiscalObj.birthplace);
        }

        if (state !== undefined) {
          country_of_birth_code = state.state_code;
        }
      }

      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      skipCheckGenderRef.current = true;
      updateFormData(
        {
          vehicle_am_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_am_code,
          business_name: fastQuoteVehicleInfo.frontend.business_name,
          fiscal_code: fastQuoteVehicleInfo.frontend.fiscal_code,
          imm_vehicle_month: fastQuoteVehicleInfo.frontend.imm_vehicle_month,
          imm_vehicle_year:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.imm_vehicle_year,
          name: fastQuoteVehicleInfo.frontend.name,
          postal_code: fastQuoteVehicleInfo.frontend.postal_code,
          power:
            isAllowedYear === undefined
              ? formDataFlow.power
              : fastQuoteVehicleInfo.frontend.power,
          surname: fastQuoteVehicleInfo.frontend.surname,
          vat_number: fastQuoteVehicleInfo.frontend.vat_number,
          vehicle_brand_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_brand_code,
          vehicle_model_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_model_code,
          vehicle_version_code:
            isAllowedYear === undefined
              ? ''
              : fastQuoteVehicleInfo.frontend.vehicle_version_code,
          weight:
            isAllowedYear === undefined
              ? formDataFlow.weight
              : fastQuoteVehicleInfo.frontend.weight,
          policy_effective_date:
            getAllowedPolicyEffectDate('min')
              <= fastQuoteVehicleInfo.frontend.policy_effective_date
            && fastQuoteVehicleInfo.frontend.policy_effective_date
              <= getAllowedPolicyEffectDate('max')
              ? fastQuoteVehicleInfo.frontend.policy_effective_date
              : '',
          date_of_birth: `${fiscalObj.year}-${
            fiscalObj.month < 10 ? `0${fiscalObj.month}` : fiscalObj.month
          }-${fiscalObj.day < 10 ? `0${fiscalObj.day}` : fiscalObj.day}`,
          commune_of_birth_code,
          province_of_birth_code,
          born_abroad,
          country_of_birth_code,
          gender: fiscalObj.gender,
        },
        true,
      );
    }

    ancillariAutoCancelFastQuote();
  };

  useEffect(() => {
    if (formDataFlow.vehicle_brand_code) {
      ancillariAutoGetVehicleModels(
        formDataFlow.vehicle_brand_code,
        formDataFlow.imm_vehicle_year,
      );
    }
  }, [formDataFlow.vehicle_brand_code, formDataFlow.imm_vehicle_year]);

  useEffect(() => {
    if (
      formDataFlow.vehicle_brand_code
      && formDataFlow.vehicle_model_code
      && formDataFlow.imm_vehicle_year
    ) {
      ancillariAutoGetVehicleVersions(
        formDataFlow.vehicle_model_code,
        formDataFlow.imm_vehicle_year,
      );
    }
  }, [
    formDataFlow.vehicle_brand_code,
    formDataFlow.vehicle_model_code,
    formDataFlow.imm_vehicle_year,
  ]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (formDataFlow.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('name', null);
      setValue('surname', null);
    } else if (
      ['M', 'F'].includes(formDataFlow.gender)
      && formDataFlow.date_of_birth === null
    ) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [formDataFlow.gender, formDataFlow.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
        && location.state.requestToken !== undefined
      ) {
        skipCheckGenderRef.current = true;
        const {
          data: {
            data: { userData },
          },
        } = await modifyRequestData(location.state.requestToken);

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        updateFormData(userData);
      }
    };

    callAsync();
  }, [location]);

  // const isValidPlate = async (plate) => {
  //   try {
  //     await ancillariAutoSchema.validateAt('vehicle_plate', { vehicle_plate: plate });
  //     return true;
  //   } catch (err) {
  //     return false;
  //   }
  // };

  useEffect(() => {
    if (formDataFlow.imm_vehicle_year !== '') {
      setProceedButton(false);
    }
  }, [formDataFlow.imm_vehicle_year]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <AncillariAutoFormFlowStyles>
      {fastQuoteVehicleInfo !== null && (
        <RcAutoFastQuoteModal
          cancelFastquote={cancelFastquote}
          confirmFastQuote={confirmFastQuote}
          answers={formDataFlow}
          fastQuoteVehicleInfo={fastQuoteVehicleInfo}
        />
      )}

      <div className="d-flex align-content-center justify-content-center">
        <ProgressBar activeStep={index} steps={stepsQuote} />
      </div>
      <AncillariAutoFormStyles className="w-50 mx-auto">
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
          <div className="row title-questionnaire">
            <div className="quote-h2 divider">
              <h2>{title}</h2>
            </div>
          </div>
        </CenteredDivTitle>

        {index === 1 && (
          <>
            <div className="form-container">
              <div id="vehiclePlateDiv">
                <FormInputText
                  error={errors.vehicle_plate?.message}
                  label="Targa dell' auto"
                  onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
                  value={formDataFlow.vehicle_plate}
                  valid={isValid('vehicle_plate')}
                />
              </div>
            </div>

            {isLoadingFastQuote === true && <FastQuoteLoading />}

            {formDataFlow.vehicle_plate !== ''
              && !errors.vehicle_plate?.message
              && (formDataFlow.imm_vehicle_month !== ''
                || formDataFlow.complete_manually === true) && (
                <div
                  className="form-container"
                  id="nextInputsImmatricolationBrand"
                >
                  <div className="row mt-2">
                    <Label>Data di prima immatricolazione</Label>
                    <div className="col-12 col-md-8">
                      <FormInputSelect
                        registration={register('imm_vehicle_month')}
                        error={errors.imm_vehicle_month?.message}
                        valid={isValid('imm_vehicle_month')}
                        placeholder="-Mese-"
                        options={months}
                      />
                    </div>

                    <div className="col-12 col-md-4">
                      <FormInputSelect
                        registration={register('imm_vehicle_year')}
                        error={errors.imm_vehicle_year?.message}
                        valid={isValid('imm_vehicle_year')}
                        placeholder="-Anno-"
                        options={lastfifteenYears}
                      />
                    </div>
                  </div>
                  <FormReactSelectBrand
                    label="Marca"
                    values={{
                      vehicle_brand_code: formDataFlow.vehicle_brand_code,
                    }}
                    error={errors.vehicle_brand_code?.message}
                    valid={isValid('vehicle_brand_code')}
                    onChange={(item) => updateFormData({
                      vehicle_brand_code: item.vehicle_brand_code,
                    })}
                    options={brands}
                  />
                </div>
            )}

            {formDataFlow.imm_vehicle_year ? (
              <>
                {formDataFlow.vehicle_brand_code && (
                  <div className="form-container" id="nextInputModel">
                    {isLoadingVehicleModels ? (
                      <p className="text-center">Attendere prego...</p>
                    ) : (
                      <FormReactSelectModel
                        label="Modello"
                        values={{
                          vehicle_model_code: formDataFlow.vehicle_model_code,
                        }}
                        error={errors.vehicle_model_code?.message}
                        valid={isValid('vehicle_model_code')}
                        onChange={(item) => updateFormData({
                          vehicle_model_code: item.vehicle_model_code,
                        })}
                        options={vehicleModels}
                      />
                    )}
                  </div>
                )}
                {formDataFlow.vehicle_brand_code
                && formDataFlow.vehicle_model_code ? (
                    isLoadingVehicleVersions ? (
                      <p className="text-center">Attendere prego...</p>
                    ) : formDataFlow.vehicle_model_code
                    && vehicleVersions.length > 0 ? (
                      <div id="versionCode">
                        <InputSelectTable
                          TableHeader={(
                            <div className="d-flex">
                              <div className="col-7">Descrizione</div>
                              <div className="col-1">CC</div>
                              <div className="col-4">Data Veicolo</div>
                            </div>
                        )}
                          label="Versione"
                          name="vehicle_version_code"
                          id="vehicle_version_code"
                          options={vehicleVersions}
                          selected={formDataFlow.vehicle_am_code}
                          onSelectOption={(value) => updateFormData({
                            vehicle_version_code: value.id,
                            vehicle_am_code: value.am_code,
                          })}
                        />
                      </div>
                      ) : (
                        <div className="alert-general-error">
                          Nessuna versione trovata per questo modello!
                        </div>
                      )
                  ) : (
                    ''
                  )}
                {formDataFlow.vehicle_version_code && (
                  <div className="form-container" id="restInputsStepOne">
                    <FormInputNumber
                      registration={register('weight')}
                      error={errors.weight?.message}
                      valid={isValid('weight')}
                      label="Peso dell'auto"
                    />
                    <FormInputNumber
                      registration={register('power')}
                      error={errors.power?.message}
                      valid={isValid('power')}
                      label="Potenza in kW"
                    />
                    <FormInputSelect
                      label="Altra alimentazione"
                      registration={register('other_power_supply')}
                      error={errors.other_power_supply?.message}
                      valid={isValid('other_power_supply')}
                      placeholder="-Seleziona-"
                      options={formData.vehicle_fuels}
                    />
                    <FormInputSelect
                      label="Parcheggio auto"
                      registration={register('vehicle_parking')}
                      error={errors.vehicle_parking?.message}
                      valid={isValid('vehicle_parking')}
                      placeholder="-Seleziona-"
                      options={formData.parking_types}
                    />
                    <FormInputSelect
                      label="Antifurto"
                      registration={register('theft_protection_code')}
                      error={errors.theft_protection_code?.message}
                      valid={isValid('theft_protection_code')}
                      placeholder="-Seleziona-"
                      options={formData.theft_protections}
                    />
                    <FormInputSelect
                      label="Utilizzo"
                      registration={register('vehicle_usage')}
                      error={errors.vehicle_usage?.message}
                      valid={isValid('vehicle_usage')}
                      placeholder="-Seleziona-"
                      options={formData.vehicle_usage_types.filter(
                        (type) => type.vehicle_type === 'auto',
                      )}
                    />
                    <FormInputSelect
                      label="Km percorsi in un anno"
                      registration={register('predicted_km')}
                      error={errors.predicted_km?.message}
                      valid={isValid('predicted_km')}
                      placeholder="-Seleziona-"
                      options={km_during_one_year}
                    />
                    {formDataFlow.imm_vehicle_year !== '' && (
                      <div className="row mt-2">
                        <Label>Mese e anno in cui hai comprato l'auto</Label>
                        <div className="col-12 col-md-8">
                          <FormInputSelect
                            registration={register('vehicle_purchased_month')}
                            error={errors.vehicle_purchased_month?.message}
                            valid={isValid('vehicle_purchased_month')}
                            placeholder="-Mese-"
                            options={months}
                          />
                        </div>

                        <div className="col-12 col-md-4">
                          <FormInputSelect
                            registration={register('vehicle_purchased_year')}
                            error={errors.vehicle_purchased_year?.message}
                            valid={isValid('vehicle_purchased_year')}
                            placeholder="-Anno-"
                            options={getAllowedPurchaseYears(
                              formDataFlow.imm_vehicle_year,
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <FormInputSelect
                      label="Auto nel nucleo familiare"
                      registration={register('vehicles_owned')}
                      error={errors.vehicles_owned?.message}
                      valid={isValid('vehicles_owned')}
                      placeholder="-Seleziona-"
                      options={vehiclesAmountInFamily}
                    />
                    <FormInputRadio
                      name="tow_hook"
                      label="Hai montato il gancio di traino?"
                      paragraph="(iscritta a rimorchiatori, caravan, ecc.)"
                      options={flagResponse}
                      registration={register('tow_hook')}
                      error={errors.tow_hook?.message}
                    />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
        {index === 2 && (
          <div className="form-container" id="datiPersonali">
            {formDataFlow.gender !== 'G' ? (
              <div>
                <FormInputCheckbox
                  registration={register('existing_customer')}
                  label=" Cliente Esistente?"
                />
                {formDataFlow.existing_customer && (
                  <div className="d-flex align-items-start justify-content-center gap-2">
                    <div className="position-relative">
                      <FormInputText
                        placeholder="Nome cliente..."
                        onChange={(value) => onSearchChange(value)}
                        value={searchClient}
                      />
                      {searchResults.length > 0 && (
                        <div
                          className="position-absolute"
                          style={{
                            top: '80%',
                            left: '0%',
                            right: '0%',
                            zIndex: 50,
                          }}
                        >
                          <ul className="list-group">
                            {searchResults.map((customer, index) => (
                              <li
                                className="list-group-item list-group-item-dark"
                                key={index}
                                style={{ cursor: 'pointer' }}
                                onClick={() => getCustomerData(customer.id)}
                              >
                                {customer.name.toUpperCase()
                                  + (customer.fiscal_code !== null
                                    ? ` - (${customer.fiscal_code})`
                                    : '')}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-questionnaire py-1 px-2 "
                      style={{
                        marginTop: 10,
                        display: 'block',
                        width: '150px',
                        border: '3px solid',
                      }}
                    >
                      Cerca cliente
                    </button>
                  </div>
                )}

                {selectedClient !== null ? (
                  <ActiveClientModal
                    closeClientModal={closeClientModal}
                    municipalities={municipalities}
                    states={states}
                    formData={formData}
                    client={selectedClient}
                    genders={genders}
                    confirmActiveClient={confirmActiveClient}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            <div className="form-container">
              <FormInputRadio
                options={genders}
                label="Il contraente è"
                name="gender"
                registration={register('gender')}
                error={errors.gender?.message}
              />
              {formDataFlow.gender === 'G' ? (
                <div>
                  <FormInputText
                    placeholder="Ragione Sociale"
                    label="Ragione Sociale"
                    registration={register('business_name')}
                    error={errors.business_name?.message}
                    valid={isValid('business_name')}
                  />
                  <FormInputText
                    placeholder="Partita Iva"
                    label="Partita Iva"
                    registration={register('vat_number')}
                    error={errors.vat_number?.message}
                    valid={isValid('vat_number')}
                  />
                  <FormInputSelect
                    label="Tipologia azienda"
                    registration={register('company_type')}
                    error={errors.company_type?.message}
                    valid={isValid('company_type')}
                    placeholder="-Seleziona-"
                    options={formData.company_types}
                  />
                </div>
              ) : (
                <div>
                  <FormInputText
                    placeholder="Nome"
                    label="Nome"
                    registration={register('name')}
                    error={errors.name?.message}
                    valid={isValid('name')}
                  />
                  <FormInputText
                    placeholder="Cognome"
                    label="Cognome"
                    registration={register('surname')}
                    error={errors.surname?.message}
                    valid={isValid('surname')}
                  />
                  <FormInputDate
                    label="Data di nascita"
                    registration={register('date_of_birth')}
                    minDate="1935-01-01"
                    maxDate="2021-01-01"
                    error={errors.date_of_birth?.message}
                    valid={isValid('date_of_birth')}
                  />
                  <FormReactSelectInputBirthPlace
                    states={states}
                    options={municipalities}
                    label="Luogo di nascita"
                    name="commune_of_birth_code"
                    values={{
                      commune_of_birth_code: formDataFlow.commune_of_birth_code,
                      province_of_birth_code:
                        formDataFlow.province_of_birth_code,
                      born_abroad: formDataFlow.born_abroad,
                      country_of_birth_code: formDataFlow.country_of_birth_code,
                    }}
                    onChange={(value) => updateUserData({
                      commune_of_birth_code: value.commune_of_birth_code,
                      province_of_birth_code: value.province_of_birth_code,
                      born_abroad: value.born_abroad,
                      country_of_birth_code: value.country_of_birth_code,
                    })}
                    valid={isValid('commune_of_birth_code')}
                    error={errors.commune_of_birth_code?.message}
                    valid_country_of_birth_code={isValid(
                      'country_of_birth_code',
                    )}
                    error_country_of_birth_code={
                      errors.country_of_birth_code?.message
                    }
                  />
                </div>
              )}

              <FormInputText
                registration={register('email')}
                label="E-mail"
                error={errors.email?.message}
                valid={isValid('email')}
              />
              <FormInputText
                registration={register('phone')}
                label="Phone"
                paragraph="Numeri di rete fissa non accettati dalle aziende"
                error={errors.phone?.message}
                valid={isValid('phone')}
              />
              <FormInputAddressComplete
                municipalities={municipalities}
                formData={formDataFlow}
                isValid={isValid}
                errors={errors}
                register={register}
                updateFormData={updateFormData}
                addressId="autocomplete-address-contractor"
              />

              {/* <FormInputSelect
              label="Tipo di lavoro"
              registration={register('work_type')}
              error={errors.work_type?.message}
              valid={isValid('work_type')}
              placeholder="-Seleziona-"
              options={workType}
            /> */}

              {/* <FormInputText
                error={errors.vehicle_plate?.message}
                label="Targa dell' auto"
                onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
                value={formDataFlow.vehicle_plate}
                valid={isValid('vehicle_plate')}
              />

              <div className="row mt-2">
                <Label>Data di prima immatricolazione</Label>
                <div className="col-12 col-md-8">
                  <FormInputSelect
                    registration={register('imm_vehicle_month')}
                    error={errors.imm_vehicle_month?.message}
                    valid={isValid('imm_vehicle_month')}
                    placeholder="-Mese-"
                    options={months}
                  />
                </div>

                <div className="col-12 col-md-4">
                  <FormInputSelect
                    registration={register('imm_vehicle_year')}
                    error={errors.imm_vehicle_year?.message}
                    valid={isValid('imm_vehicle_year')}
                    placeholder="-Anno-"
                    options={lastfifteenYears}
                  />
                </div>
              </div> */}

              <FormInputDate
                label="Data Decorrenza"
                registration={register('policy_effective_date')}
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
                minDate={getAllowedPolicyEffectDate('min')}
              />
            </div>

            <div className="terms">
              <h5 className="text-center">Informativa Privacy e IVASS</h5>

              {dataPrivacy.map((item, i) => (
                <InputPrivacy
                  required={item.required}
                  label={item.content}
                  name={item.type}
                  id={item.type}
                  key={i}
                  checked={formDataFlow?.privacies.includes(item.id)}
                  onChange={(value) => privacyItemChangeHandler(value, item)}
                />
              ))}
            </div>
          </div>
        )}

        {index == 1 && proceedButton === true && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <button
              className="btn btn-questionnaire "
              onClick={() => procceedWithFastQuote()}
            >
              Procedi
            </button>
          </div>
        )}

        {index >= 1 && index <= 4 && (
          <div className="footer-buttons">
            <div className="d-flex justify-content-between">
              {index !== 1 && (
                <button
                  className="btn btn-questionnaire back backtoStepOne backtoStepTwo backtoStepThree"
                  onClick={prevButton}
                >
                  Indietro
                </button>
              )}

              {proceedButton === false && (
                <button
                  type="button"
                  className="btn btn-questionnaire continueStepThree continueStepFour buttonFinal"
                  onClick={nextButton}
                  id="continueStep2"
                >
                  {index === 2 ? 'Vai ai preventivi' : 'Continua'}
                </button>
              )}
            </div>
          </div>
        )}
      </AncillariAutoFormStyles>
    </AncillariAutoFormFlowStyles>
  );
}

export default AncillariAutoFormFlow;
