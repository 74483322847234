import React, { useState } from 'react';
import { Range } from 'react-range';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol,
  LogoPriceContainer,
  QuotationCardStyles,
  RangeStyles,
  RightCol,
  SavedButton,
} from './QuotationCard.styles';
import LogoComponent from './smallerComponents/LogoComponent';
import CompanyQualityComponent from './smallerComponents/CompanyQualityComponent';
import PriceComponent from './smallerComponents/PriceComponent';
import CompanyInfoComponent from './smallerComponents/CompanyInfoComponent';
import QuoteInfoComponent from './smallerComponents/QuoteInfoComponent';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import { generalAlertError, generalAlertSuccessToast } from '../utils/alerts';

function QuotationCard({
  quote,
  requestInfo,
  selectQuotation,
  guaranteesPrima,
  set_informativi,
  docs,
  extensions,
  requestToken,
  selectableExtensions,
  profession,
  type,
}) {
  const [val, setVal] = useState({ values: [0] });

  const addDiscount = async () => {
    const response = await quotationsAxe.post(
      `/quotations/discount/update/${quote.id}`,
      {
        discount: val.values.toString(),
      },
    );

    if (response.status === 400 && response.data.code === 'is_selected') {
      generalAlertError(response.data.error);
      return;
    }
    if (response.status !== 200) {
      generalAlertError('Non Puoi Aggiungere Il Sconto!');
      return;
    }

    if (val.values != 0) {
      generalAlertSuccessToast(`Sconto Aggiunto: ${val.values}%`);
    }
    setVal({
      values: [0],
    });
  };

  return (
    <QuotationCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <div>
          {/* Company Quality Component */}
          <CompanyQualityComponent quote={quote} />
          <LogoPriceContainer>
            {/* Logo Component */}
            <LogoComponent quote={quote} />
            {/* Price Component */}
            <PriceComponent quote={quote} discount={val.values} />
          </LogoPriceContainer>
        </div>
        {(quote.company_id === 1 || quote.company_id === 17)
        && quote.max_discount !== null
        && quote.max_discount !== 0 ? (
          <RangeStyles>
            <div>
              <h5>Aggiungi Sconto</h5>
              <p>
                Massimo:
                {quote.max_discount}
                %
              </p>
            </div>

            <Range
              values={val.values}
              step={1}
              min={0}
              max={quote.max_discount}
              onChange={(values) => setVal({ values })}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%',
                    margin: '0 auto',
                  }}
                >
                  <div
                    className="range-responsive-slide"
                    ref={props.ref}
                    style={{
                      height: '15px',
                      width: '100%',
                      borderRadius: '140px',
                      alignSelf: 'center',
                      backgroundColor: '#DFF3EA',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  className="range-responsive-circle"
                  {...props}
                  style={{
                    ...props.style,
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#00A651',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                >
                  <div
                    className="range-responsive-text"
                    style={{
                      position: 'absolute',
                      top: '35px',
                      width: '100px',
                      color: '#00A551',
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '18px',
                    }}
                  >
                    {val.values[0]}
                    %
                  </div>
                </div>
              )}
            />
          </RangeStyles>
          ) : (
            ''
          )}
      </LeftCol>
      <RightCol>
        {/* Company Info Component */}
        <div>
          <CompanyInfoComponent
            quote={quote}
            guaranteesPrima={guaranteesPrima}
            set_informativi={set_informativi}
            docs={docs}
            extensions={extensions}
            requestToken={requestToken}
            selectableExtensions={selectableExtensions}
            profession={profession}
          />
          {/* Quote Info Component */}
          <QuoteInfoComponent
            quote={quote}
            requestInfo={requestInfo}
            profession={profession}
            type={type}
          />
        </div>
        <AquistaButtonsContainer>
          {requestInfo.processing_quotation_id !== quote.id ? (
            <AquistaButton
              onClick={() => selectQuotation(quote.id, addDiscount)}
              className="purchase-quote-nine-step"
            >
              Seleziona
            </AquistaButton>
          ) : (
            requestInfo.processing_quotation_id === quote.id && (
              <SavedButton>Aggiunto</SavedButton>
            )
          )}
        </AquistaButtonsContainer>
      </RightCol>
    </QuotationCardStyles>
  );
}

export default QuotationCard;
