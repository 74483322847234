import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { quotationTypes } from '../../../constants';
import quotationsAxe from '../../../../services/axios-client/quotationsAxeClient';

function CompletedScreenAncillariAuto({
  quoteStatus,
  customer,
  rc_quotation,
  risk_quotations,
}) {
  const [residenceInfo, setResidenceInfo] = useState(null);

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code == customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (quoteStatus === 7) {
      collectMunicipalities();
    }
  }, [quoteStatus]);
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica Cliente</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Compagnia:</b>
                    {' '}
                    {rc_quotation.company.name}
                  </li>
                  <li className="list-group-item">
                    <b>Nome e Cognome:</b>
                    {' '}
                    {customer.gender === 'G'
                      ? customer.business_name
                      : `${customer.name} ${customer.surname}`}
                  </li>
                  <li className="list-group-item">
                    <b>Email:</b>
                    {' '}
                    {customer.email}
                  </li>
                  <li className="list-group-item">
                    <b>Telefono:</b>
                    {' '}
                    {customer.phone}
                  </li>
                  <li className="list-group-item">
                    <b>Indirizo:</b>
                    {' '}
                    {customer.residencial_place}
                  </li>
                  <li className="list-group-item">
                    <b>Residenza:</b>
                    {' '}
                    {residenceInfo !== null && residenceInfo.name}
                  </li>
                  <li className="list-group-item">
                    <b>Citta:</b>
                    {' '}
                    {residenceInfo !== null
                      && residenceInfo.communal_territory.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="card border mb-2">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Preventivi</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Prodotto:</b>
                        {' '}
                        Ancillari Auto
                        <br />
                        <b>Compagnia:</b>
                        {' '}
                        {rc_quotation.company.name}
                      </div>
                      <div>
                        <Link
                          to={`/quotes/purchased/${rc_quotation.id}/quotation/ancillari-auto`}
                          className="btn btn-success text-white"
                        >
                          Vai Al Preventivo
                        </Link>
                      </div>
                    </div>
                  </li>
                  {risk_quotations.map((quote, index) => (
                    <React.Fragment key={index}>
                      {quote.company_id !== rc_quotation.company_id && (
                        <li className="list-group-item">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <b>Prodotto:</b>
                              {' '}
                              {
                                quotationTypes.find(
                                  (t) => t.id === quote.quote_type,
                                ).name
                              }
                              <br />
                              <b>Compagnia:</b>
                              {' '}
                              {quote.company.name}
                            </div>
                            <div>
                              {quote.product.id === 13 ? (
                                <>
                                  {quote.quote_type === 'tutela_legale' && <Link to={`/quotes/purchased/${quote.id}/quotation/tutela-legale`} className="btn btn-success text-white">Vai Al Preventivo</Link>}
                                  {quote.quote_type === 'infortuni_conducente' && <Link to={`/quotes/purchased/${quote.id}/quotation/infortuni-conducente`} className="btn btn-success text-white">Vai Al Preventivo</Link>}
                                  {quote.quote_type === 'cristalli' && <Link to={`/quotes/purchased/${quote.id}/quotation/cristalli`} className="btn btn-success text-white">Vai Al Preventivo</Link>}
                                  {quote.quote_type === 'collisione' && <Link to={`/quotes/purchased/${quote.id}/quotation/collisione`} className="btn btn-success text-white">Vai Al Preventivo</Link>}
                                </>
                              ) : <Link to={`/quotes/purchased/${quote.id}/quotation/${quote.product.slug}`} className="btn btn-success text-white">Vai Al Preventivo</Link>}

                            </div>
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedScreenAncillariAuto;
