import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Pagination from '../../common/components/elements/Pagination';
import { PurchasedQuotationsStyles } from './PurchasedQuotations.styles';
import { listQuotationsSemestralToPay, resetQuotationsSemestralToPay } from '../../features/quotations/quotationsActions';
import { generalAlertError } from '../../common/utils/alerts';
import { downloadFile } from '../../services/axios-client/axeHelpers';
// import { useTourContext } from '../../context/useTourContext';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackSemestralityQuotes } from '../../common/utils/joyrideCallbacks';
// import { semestralityQuotesToBePaidSteps } from '../../common/utils/joyrideSteps';

function SemestalityQuotesToBePaid() {
  const state = useSelector((store) => store.quotations.quotations_semestral);

  const {
    quotations, loading, page_number, total_pages, error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    plate: '',
    date_from: moment().startOf('month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });

  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('quoteSemestraleDaPagare') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('quoteSemestraleDaPagare'), loading]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('quoteSemestraleDaPagare');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   semestralityQuotesToBePaidSteps(quotations, setSteps);
  // }, [quotations]);

  const getQuotes = async () => {
    listQuotationsSemestralToPay(1, search);
  };

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    listQuotationsSemestralToPay(1, search);
  };

  const downloadExel = async () => {
    const res = await managementAxe.get('/broker/download/semestrale', {
      responseType: 'blob',
    });
    downloadFile(res.data);
  };

  const getSemestaleData = (date) => moment(date).add(6, 'months').subtract(14, 'days');

  useEffect(() => {
    getQuotes();

    return () => {
      resetQuotationsSemestralToPay();
    };
  }, []);
  return error === null ? (
    <PurchasedQuotationsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackSemestralityQuotes(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <h5 className="mb-4">Lista Quotazioni Semestrali da pagare</h5>
      {/* {localStorage.getItem('quoteSemestraleDaPagare') === 'done' && (
        <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
          <button className="btn btn-success px-2 py-1">Tutorial</button>
        </div>
      )} */}
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-md-7 col-lg-8 col-xl-8">
          {quotations.length !== 0 && <button className="btn btn-success quote-semestrale-da-pagare-one" onClick={() => downloadExel()}>Scarica Excel</button>}
          <div className="mt-3 quote-semestrale-da-pagare-two">
            {loading
              ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Attendere prego...</span>
                </div>
              )
              : (
                <ul className="list-group">
                  {quotations.length > 0
                    ? quotations.map((quote, index) => {
                      const semestraleData = getSemestaleData(quote.policy_effective_date);

                      return (
                        <li key={index} className="quote-semestrale-da-pagare-three list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-start align-items-xl-center">
                          <div className="ms-0 me-auto mt-1 mt-sm-0 mb-0">
                            {quote.customer && (
                              <div className="fw-bold">
                                Cliente:
                                {' '}
                                {quote.customer.gender === 'G' ? quote.customer.business_name : `${quote.customer.name} ${quote.customer.surname}`}
                              </div>
                            )}
                            {quote.product
                              && (
                                <>
                                  Prodotto:
                                  {' '}
                                  {quote.product.name}
                                  {' '}
                                  {quote.product_id == 5 ? `(${quote.infortuni && quote.infortuni.company})` : ''}
                                </>
                              )}
                            {quote.vehicle
                              && (
                                <>
                                  <br />
                                  Targa:
                                  {' '}
                                  {quote.vehicle.vehicle_plate}
                                </>
                              )}
                            <br />
                            <small>
                              <strong>
                                Decorrenza:
                                {' '}
                                {moment(quote.policy_effective_date).format('L')}
                              </strong>
                            </small>
                            <br />
                            <small>
                              <strong>
                                Scadenza (Semestrale):
                                {' '}
                                {semestraleData.format('L')}
                              </strong>
                            </small>
                          </div>
                          {quote.product_id == 1
                            && (
                              <>
                                {(quote.status === 7 && quote.payments.length === 2)
                                  && (
                                    <Link to={`/quotes/auto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-success px-4 py-2 rounded-3">
                                        Emessa
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 7 && quote.payments.length === 1)
                                  && (
                                    <Link to={`/quotes/auto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-warning px-4 py-2 rounded-3">
                                        Non Scaduto
                                      </span>
                                    </Link>
                                  )}
                                {quote.status === 4
                                  && (
                                    <Link to={`/quotes/auto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-danger px-4 py-2 rounded-3">
                                        Paga Semestralita
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 6)
                                  && (
                                    <Link to={`/quotes/auto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-secondary px-4 py-2 rounded-3">
                                        Pagato
                                      </span>
                                    </Link>
                                  )}
                              </>
                            )}

                          {quote.product_id == 3
                            && (
                              <>
                                {(quote.status === 7 && quote.payments.length === 2)
                                  && (
                                    <Link to={`/quotes/autocarro/purchase/${quote.request_token}`}>
                                      <span className="badge bg-success px-4 py-2 rounded-3">
                                        Emessa
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 7 && quote.payments.length === 1)
                                  && (
                                    <Link to={`/quotes/autocarro/purchase/${quote.request_token}`}>
                                      <span className="badge bg-warning px-4 py-2 rounded-3">
                                        Non Scaduto
                                      </span>
                                    </Link>
                                  )}
                                {quote.status === 4
                                  && (
                                    <Link to={`/quotes/autocarro/purchase/${quote.request_token}`}>
                                      <span className="badge bg-danger px-4 py-2 rounded-3">
                                        Paga Semestralita
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 6)
                                  && (
                                    <Link to={`/quotes/autocarro/purchase/${quote.request_token}`}>
                                      <span className="badge bg-secondary px-4 py-2 rounded-3">
                                        Pagato
                                      </span>
                                    </Link>
                                  )}
                              </>
                            )}

                          {quote.product_id == 2
                            && (
                              <>
                                {(quote.status === 7 && quote.payments.length === 2)
                                  && (
                                    <Link to={`/quotes/moto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-success px-4 py-2 rounded-3">
                                        Emessa
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 7 && quote.payments.length === 1)
                                  && (
                                    <Link to={`/quotes/moto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-warning px-4 py-2 rounded-3">
                                        Non Scaduto
                                      </span>
                                    </Link>
                                  )}
                                {quote.status === 4
                                  && (
                                    <Link to={`/quotes/moto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-danger px-4 py-2 rounded-3">
                                        Paga Semestralita
                                      </span>
                                    </Link>
                                  )}
                                {(quote.status === 6)
                                  && (
                                    <Link to={`/quotes/moto/purchase/${quote.request_token}`}>
                                      <span className="badge bg-secondary px-4 py-2 rounded-3">
                                        Pagato
                                      </span>
                                    </Link>
                                  )}
                              </>
                            )}
                        </li>
                      );
                    })
                    : (
                      <li className="list-group-item d-flex justify-content-between align-items-start quote-semestrale-no-result">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Nessuna quotazione trovata!
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
              )}
            <div className="mt-2">
              <Pagination total_pages={total_pages} current_page={page_number} onClick={listQuotationsSemestralToPay} search={search} />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters quote-semestrale-da-pagare-four">
          <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">
            <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Cerca quotazioni..." />
            <input type="text" name="plate" className="search-plate form-control mb-2" value={search.plate} onChange={handleChange} placeholder="Ricerca targa..." />
            <div className="w-100">
              <label htmlFor="date_from">Data scadenza da</label>
              <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" lang="it-IT" placeholder="Data creazione da" />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Data scadenza al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Data creazione al" lang="it-IT" />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
        </div>
      </div>
    </PurchasedQuotationsStyles>
  ) : <ErrorComponent error={error} />;
}

export default SemestalityQuotesToBePaid;
