import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  EuropeAssistencaInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer,
  TitleBox,
} from './EuropeAssistencaStradaleModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function EuropeAssistencaStradaleModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <EuropeAssistencaInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <ul className="mt-3">
              <li>
                Soccorso Stradale All Risk 50 km a/r anche per veicoli elettrici
              </li>
              <li>
                Depannage (smarrimento e/o rottura chiavi, foratura pneumatici, esaurimento batteria,
                montaggio catene, ricarica on site, consegna carburante on site)
              </li>
              <li>
                Auto in sostituzione max 7gg (compresa disponibilità AS elettrica) per guasto, incidente,
                incendio, furto parziale anche sabato/festivi
              </li>
              <li>
                Mobilità alternativa (accesso alla piattaforma del fornitore di EA per un max di Euro 100
                complessive che potranno essere utilizzate per un max di 7 gg consecutivi - prestazione
                alternativa ad AS)
              </li>
              <li>
                Recupero fuoristrada del veicolo (300 € per sinistro)
              </li>
              <li>
                Spese Albergo (100 € a persona al giorno per un max di 500 € per Sinistro)
              </li>
              <li>Taxi (50€ per sx per raggiungere albergo, autonoleggio, abitazione, luogo di lavoro a seguito di traino)</li>
              <li>
                Rientro o proseguimento del viaggio per guasto, incidente, incendio o furto
                parziale per i quali fosse necessario un fermo per la riparazione superiore alle 36
                ore in Italia o ai 4 giorni all’estero, oppure in caso di Furto o Rapina (350 € se
                dall’Italia o 1000 € se dall’estero, costo noleggio AS per max 2gg, taxi max 50 €)
              </li>
              <li>
                Recupero del veicolo riparato (200 € in Italia e 400 € Estero)
              </li>
              <li>
                SOS Incidente
              </li>
              <li>
                Rimpatrio autoveicolo dall’estero (rimpatrio del veicolo max 1.000 €)
              </li>
              <li>
                Mobilità in caso di Furto (AS per Furto totale fino 30 gg; Recupero veicolo dopo furto/rapina; richiesta documenti con Furto totale)
              </li>
              <li>
                Mobilità in caso di Incidente (Autista a seguito di Infortunio; Consulenza Medica; Rientro
                sanitario dall’estero; rientro Ass.to convalescente all’estero)
              </li>
            </ul>

          </div>
        </ModalContent>
      </ModalBody>
    </EuropeAssistencaInfoModalStyles>
  );
}

export default EuropeAssistencaStradaleModal;
