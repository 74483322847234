import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  quotations_purchased: {
    loading: true,
    error: null,
    quotations: [],
    page_number: 1,
    total_pages: 1,
  },
  quotations_semestral: {
    loading: true,
    error: null,
    quotations: [],
    page_number: 1,
    total_pages: 1,
  },
};

export const quotationsSlice = createSlice({
  name: 'quotations',
  initialState,
  reducers: {
    listQuotationsPurchasedPending: (state) => {
      state.quotations_purchased.loading = true;
      state.quotations_purchased.error = null;
    },
    listQuotationsPurchasedReject: (state, { payload }) => {
      state.quotations_purchased.loading = false;
      state.quotations_purchased.error = payload;
    },
    listQuotationsPurchasedSuccess: (state, { payload }) => {
      state.quotations_purchased.loading = false;
      state.quotations_purchased.quotations = payload.data;
      state.quotations_purchased.page_number = payload.current_page;
      state.quotations_purchased.total_pages = payload.total_pages;
    },
    quotationsPurchasedReset: (state) => {
      state.quotations_purchased = { ...initialState.quotations_being_worked };
    },
    listQuotationsSemestralToPayPending: (state) => {
      state.quotations_semestral.loading = true;
      state.quotations_semestral.error = null;
    },
    listQuotationsSemestralToPayReject: (state, { payload }) => {
      state.quotations_semestral.loading = false;
      state.quotations_semestral.error = payload;
    },
    listQuotationsSemestralToPaySuccess: (state, { payload }) => {
      state.quotations_semestral.loading = false;
      state.quotations_semestral.quotations = payload.data;
      state.quotations_semestral.page_number = payload.current_page;
      state.quotations_semestral.total_pages = payload.total_pages;
    },
    quotationsSemestralToPayReset: (state) => {
      state.quotations_semestral = {
        ...initialState.quotations_semestral,
      };
    },
  },
});

export default callerCreator(quotationsSlice.actions);
