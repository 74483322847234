import React, { useState } from 'react';
import {
  AdriaticCardStyles, CompanyInfoElements, CompanyInformationContainer, LeftCol, LogoContainer, LogoPriceContainer, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, AquistaButton, AquistaButtonsContainer, Price,
} from './AdriaticCard.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import { guideType } from '../../../constants';
import AdriaticInfoModal from '../Modal/AdriaticInfoModal';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';

function AdriaticCard(props) {
  const {
    quotation,
    requestInfo,
    selectQuotation,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal
        && (
          <AdriaticInfoModal
            quotation={quotation}
            setShowModal={setShowModal}
          />
        )}
      <AdriaticCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
        <LeftCol>
          {quotation.company_quality && (
            <div className="row mb-3">
              <div className="col-12">
                <div className={`p-2 badge ${quotation.company_quality.color === 'green' ? 'bg-success' : quotation.company_quality.color === 'yellow' ? 'bg-warning' : 'bg-danger'}`}>
                  <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                    Indice Di Qualità:
                    {' '}
                    <b className="fs-6">{parseFloat(quotation.company_quality.coefficiente).toFixed(2)}</b>
                  </p>
                </div>
              </div>
            </div>
          )}
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                {quotation.amount_before_discount !== null && (
                  <p>
                    &euro;
                    {Number(quotation.amount_before_discount).toLocaleString(
                      'it-IT',
                      { minimumFractionDigits: 2 },
                    )}
                  </p>
                )}
                <h2>
                  &euro;
                  {Number(quotation.amount).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                </h2>
              </Price>
              {quotation.payment_frequency === 2 && (
                <Price>
                  <h5>
                    Prezzo Semestrale:
                  </h5>
                  <h6>
                    Primo Pagamento
                  </h6>
                  <h2>
                    &euro;
                    {(Number(quotation.amount) / quotation.payment_frequency).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </Price>
              )}
            </PriceContainer>
          </LogoPriceContainer>
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori informazioni</p>
            </CompanyInfoElements>
          </CompanyInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                Adriatic Assicurazioni
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Emissione:
              </h6>
              <p>
                Tradizionale
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Guida:
              </h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Frequenza pagamento:
              </h6>
              <p>
                {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Risarcimento:
              </h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Massimale:
              </h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {requestInfo.processing_quotation_id !== quotation.id ? (
              <AquistaButton
                onClick={() => selectQuotation(quotation.id)}
                className="purchase-quote-nine-step"
              >
                Seleziona
              </AquistaButton>
            ) : requestInfo.processing_quotation_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
          </AquistaButtonsContainer>
        </RightCol>
      </AdriaticCardStyles>
    </>
  );
}

export default AdriaticCard;
