import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import quotationsAxe from '../../../../services/axios-client/quotationsAxeClient';
import { getQuotationTypeName } from '../../../constants';

function CompletedScreenProfession({
  quoteStatus,
  customer,
  rc_quotation,
  risk_quotations,
}) {
  const [residenceInfo, setResidenceInfo] = useState(null);

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code == customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (quoteStatus === 7) {
      collectMunicipalities();
    }
  }, [quoteStatus]);

  return (
    <div className="row">
      <div className="col-12 col-xl-12 ">
        <div className="card border mb-2 polizze-emessa-status-step-one">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Anagrafica Cliente</h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Nome e Cognome/Ragione Sociale:</b>
                {' '}
                {customer.gender === 'G'
                  ? customer.business_name
                  : `${customer.name} ${customer.surname}`}
              </li>
              <li className="list-group-item">
                <b>Email:</b>
                {' '}
                {customer.email}
              </li>
              <li className="list-group-item">
                <b>Telefono:</b>
                {' '}
                {customer.phone}
              </li>
              <li className="list-group-item">
                <b>Indirizo:</b>
                {' '}
                {customer.address}
                ,
                {' '}
                {customer.house_number}
                ,
                {' '}
                {customer.postal_code}
              </li>
              <li className="list-group-item">
                <b>Residenza:</b>
                {' '}
                {residenceInfo !== null && residenceInfo.name}
              </li>
              <li className="list-group-item">
                <b>Citta:</b>
                {' '}
                {residenceInfo !== null
                  && residenceInfo.communal_territory.name}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12">
        <div className="card border mb-2">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Preventivi</h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <b>Prodotto:</b>
                    {' '}
                    Responsabilita Civile
                    <br />
                    <b>Compagnia:</b>
                    {' '}
                    {rc_quotation.company.name}
                  </div>
                  <div>
                    <Link
                      to={`/quotes/purchased/${rc_quotation.id}/quotation/profession`}
                      className="btn btn-success text-white"
                    >
                      Vai Al Preventivo
                    </Link>
                  </div>
                </div>
              </li>
              {risk_quotations
                .filter((q) => q.company_id != rc_quotation.company_id)
                .map((risk_quotation) => (
                  <li className="list-group-item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <b>Prodotto:</b>
                        {' '}
                        {getQuotationTypeName(risk_quotation.quote_type)}
                        <br />
                        <b>Compagnia:</b>
                        {' '}
                        {risk_quotation.company.name}
                      </div>
                      <div>
                        <Link
                          to={`/quotes/purchased/${risk_quotation.id}/quotation/${risk_quotation.product.slug}`}
                          className="btn btn-success text-white"
                        >
                          Vai Al Preventivo
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedScreenProfession;
