import React from 'react';
import moment from 'moment';
import { BoxWrapper, ClientBox } from './CommonPurchase.styles';
import { houseTypes, ownerTypes, quotationTypes } from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';

function InfoBoxHouse({
  insuranceRequest,
  messagesModalData,
  rc_quotation,
  risk_quotations,
}) {
  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }

    if (risk_quotations && risk_quotations.length > 0) {
      for (let i = 0; i < risk_quotations.length; i++) {
        const item = risk_quotations[i];

        risksTotalAmount += parseFloat(item.amount);
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount;

    return total.toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6 className="mt-2">Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Tipo Abitazione:</h6>
              <h5>
                {
                  houseTypes.find(
                    (type) => type.id === insuranceRequest.house.house_type,
                  ).name
                }
              </h5>
              <h6 className="mt-2">Tipologia Propietario:</h6>
              <h5>
                {
                  ownerTypes.find(
                    (type) => type.id === insuranceRequest.house.owner_typology,
                  ).name
                }
              </h5>
              <h6 className="mt-2">La superficie:</h6>
              <h5>
                {insuranceRequest.house.square_meters}
                m
                <sup>2</sup>
              </h5>
              <h6 className="mt-2">Provincia Abitazione:</h6>
              <h5>
                {insuranceRequest.house.province.name}
                (
                {insuranceRequest.house.province.car_plate_symbol}
                )
              </h5>
              <h6 className="mt-2">Inizio Copertura:</h6>
              <h5>
                {moment(insuranceRequest.policy_effective_date).format(
                  'DD-MM-YYYY',
                )}
              </h5>
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(15)}
              >
                Informazioni Precontrattuali Casa
              </h6>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Frequenza Pagamento</th>
                    <th scope="col">Premio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rc_quotation.product.name}</td>
                    <td>
                      {rc_quotation.company_id !== null
                        ? rc_quotation.company.name
                        : rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>
                      {rc_quotation.payment_frequency === 1
                        ? 'Annuale'
                        : 'Semestrale'}
                    </td>
                    <td>
                      {parseFloat(rc_quotation.amount)}
                      {' '}
                      &euro;
                    </td>
                  </tr>

                  {risk_quotations
                    && risk_quotations.map((quote, index) => (
                      <tr key={index}>
                        <td>
                          {
                            quotationTypes.find(
                              (type) => type.id === quote.quote_type,
                            ).name
                          }
                        </td>
                        <td>{quote.company.name}</td>
                        <td>
                          {quote.payment_frequency === 1
                            ? 'Annuale'
                            : 'Semestrale'}
                        </td>
                        <td>
                          {quote.amount != 0 && (
                          <>
                            {parseFloat(quote.amount).toFixed(2)}
                            {' '}
                            &euro;
                          </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="fw-bold">Totale</td>
                    <td />
                    <td />
                    <td className="fw-bold">
                      &euro;
                      {getTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxHouse;
