import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers, faHelmetSafety, faUserTie, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import GreeniaLogo from '../../assets/images/greenia-logo.svg';
import ListaProdotti from '../../assets/images/lista-prodotti.png';
import ListaRinnovi from '../../assets/images/lista-rinnovi.png';
import Portagolgio from '../../assets/images/portagolgio.png';
import Tutorial from '../../assets/images/tutorial.png';
import User from '../../assets/images/user.png';
import message from '../../assets/images/message.png';
import { SidebarStyles } from './Sidebar.styles';
import BellIcon from '../../assets/images/icons/BellIcon';
import {
  unReadNotifications,
} from '../../features/utilities/utilitiesActions';
import useAuth from '../../hooks/useAuth';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError } from '../utils/alerts';
import { BACKOFFICE_PANEL_URL } from '../../config';

export default function Sidebar({ showSidebar, setShowSidebar }) {
  const { isUserSectionAB, hasBackoffice } = useAuth();
  const { notificationsCount, messagesCount } = useSelector(
    (store) => store.utilities,
  );

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const loginToPanel = async () => {
    const res = await axe.post('/backoffice/signin');

    if (res.status !== 200) {
      generalAlertError("Qualcosa e andato storto! Riprova o chiama l'amministratore dell sistema!");
    }
    window.open(`${BACKOFFICE_PANEL_URL}/broker-back-office/login/${res.data.data.token}`, '_blank');
  };

  useEffect(() => {
    unReadNotifications();
    setInterval(() => {
      unReadNotifications();
    }, 60000);
  }, []);

  return (
    <>
      <SidebarStyles>

        <div className="col-lg-3 " id="sidear-wrapper">
          <nav
            id="sidebarMenu"
            className={`sidebar ${!showSidebar ? 'd-none' : ''} d-lg-block broker-home-one`}
          >
            <Link
              onClick={closeSidebar}
              className="navbar-brand mt-2 mt-lg-0"
              to="/"
            >
              <div className="d-flex justify-content-center">
                <img src={GreeniaLogo} className="img-fluid" width={150} alt="Greenia Logo" />
              </div>
            </Link>
            <div onClick={closeSidebar} className="hide d-lg-none">
              {/* &lsaquo; */}
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div className="mt-md-4 mt-2">
              <ul>
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/notifications"
                    className="list-group-item list-group-item-action py-2 ripple "
                  >
                    <span
                      className={
                        notificationsCount > 0 ? 'text-danger' : 'text-lightgrey'
                      }
                    >
                      <BellIcon width="24px" height="20px" />
                    </span>

                    <span className="item">
                      Notifiche
                      {' '}
                      {notificationsCount > 0 ? (
                        <span className="notification-badge">
                          <small>{notificationsCount}</small>
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    onClick={closeSidebar}
                    to="/quote"
                    className="list-group-item list-group-item-action py-2 ripple "
                  >
                    <img
                      className="item-img"
                      src={ListaProdotti}
                      alt="Products List"
                    />
                    <span className="item ">Nuovo preventivo</span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    onClick={closeSidebar}
                    to="/preventivi"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={Portagolgio} alt="preventivi" />
                    <span className="item">Preventivi</span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    onClick={closeSidebar}
                    to="/quotazioni-aquistati"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={Portagolgio} alt="Preventivi acquistati" />
                    <span className="item">Polizze Emesse</span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    onClick={closeSidebar}
                    to="/quote-semestrale-da-pagare"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={Portagolgio} alt="Preventivi acquistati" />
                    <span className="item">Pratiche semestrali</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/renewals"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={ListaRinnovi} alt="renewals" />
                    <span className="item">Rinnovi</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/clients"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <FontAwesomeIcon
                      icon={faUsers}
                      style={{ color: '#737476' }}
                    />
                    <span className="item">Clienti</span>
                  </Link>
                </li>
                {/* <li>
                <Link
                  onClick={closeSidebar}
                  to="/research-type"
                  className="list-group-item list-group-item-action py-2 ripple"
                >
                  <img className="item-img" src={researchIcon} alt="analysis" />
                  <span className="item">Analisi Rui</span>
                </Link>
              </li> */}
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/post-vendita"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    {' '}
                    <FontAwesomeIcon
                      icon={faHelmetSafety}
                      style={{ color: '#737476' }}
                    />
                    <span className="item">Post Vendita</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/messages"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    {' '}
                    <img className="item-img" src={message} alt="Messages" />
                    <span className="item">
                      Messaggi
                      {`(${messagesCount})`}
                    </span>
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    onClick={closeSidebar}
                    to="/profile"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={User} alt="profile" />
                    <span className="item">Profilo</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={closeSidebar}
                    to="/tutorial"
                    className="list-group-item list-group-item-action py-2 ripple"
                  >
                    <img className="item-img" src={Tutorial} alt="video" />
                    <span className="item">Tutorial</span>
                  </Link>
                </li>
                {isUserSectionAB && (
                  <li>
                    <Link
                      onClick={closeSidebar}
                      to="/brokers"
                      className="list-group-item list-group-item-action py-2 ripple"
                    >
                      <img className="item-img" src={User} alt="Setup network" />
                      <span className="item">Gestisci rete</span>
                    </Link>
                  </li>
                )}
                {hasBackoffice && (
                  <li>
                    <div
                      onClick={() => {
                        loginToPanel();
                        closeSidebar();
                      }}
                      to="/tutorial"
                      className="list-group-item list-group-item-action py-2 ripple"
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon className="item-img" icon={faUserTie} alt="" color="#737476" />
                      <span className="item fw-bold">Accedi A Backoffice</span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </SidebarStyles>
    </>
  );
}
