import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  RiskContainer,
  SaluteQuotesStyles,
} from './styles/SaluteResultPage.styles';
import SaluteInfoCard from '../../common/components/redesignComponents/Cards/SaluteInfoCard';
import { GuaranteesDefaultStateStyles } from '../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../../common/constants';
import { confirmAlert, generalAlertError } from '../../common/utils/alerts';
import { EstimateContainer } from '../../common/components/redesignComponents/common/Redesign.styles';
import SaluteModalButtons from './components/SaluteModalButtons';
import SaluteModal from './components/SaluteModal';
import QuoteGenerateLoading from '../../common/components/elements/QuoteGenerateLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import {
  addSaluteQuoteToCart,
  removeSaluteQuoteFromCart,
  saluteQuotesGetQuotes,
} from '../../features/insuranceRequests/insuranceRequestsActions';
import CustomModalSalute from '../../common/components/elements/CustomModalSalute';
import SaluteCard from '../../common/components/redesignComponents/Cards/SaluteCard';
import PageLoading from '../../common/components/elements/PageLoading';

function SaluteResultPage() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    error,
    insuranceRequest,
    customer,
    quotations,
    addingQuote,
    processingQuote,
    riskQuotations,
    toSelect,
    quote_id_to_add,
  } = useSelector((store) => store.insuranceRequests.salute_quotes);

  const [showModal, setShowModal] = useState(false);
  const refresh = () => saluteQuotesGetQuotes(requestToken);

  const modifyData = () => {
    navigate('/quote/salute', {
      state: { requestToken },
    });
  };

  const selectQuotation = (quoteId, closeButton) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addSaluteQuoteToCart(
      quoteId,
      refresh,
      modifyData,
      closeButton,
      setShowModal,
      quote_id_to_add,
    ));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeSaluteQuoteFromCart(quoteId, refresh, () => {}));
  };

  useEffect(() => {
    saluteQuotesGetQuotes(requestToken);
  }, [requestToken]);

  const handleRequestPurchase = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/salute/purchase/${requestToken}`);
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };

  if (loading) {
    return <QuoteGenerateLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <SaluteQuotesStyles>
      <div className="resultsPage">
        <div className="row">
          <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-home-first-step">
            <SaluteInfoCard
              customer={customer}
              insuranceRequest={insuranceRequest}
              modifyData={modifyData}
            />
            {showModal && (
              <CustomModalSalute
                show={showModal}
                onClose={() => setShowModal(false)}
                title="Seleziona una di questa quotaziona prima!"
              >
                {toSelect.map((tp, index) => (
                  <React.Fragment key={index}>
                    {addingQuote && <PageLoading />}
                    {addingQuote === false && quotations[tp]
                      ? quotations[tp].map((quote, index) => (
                        <div className="mt-3" key={index}>
                          <SaluteCard
                            requestInfo={insuranceRequest}
                            quote={quote}
                            selectQuotation={selectQuotation}
                          />
                        </div>
                      ))
                      : addingQuote === false && (
                      <p>Nessuna Quotazione Trovata.</p>
                      )}
                  </React.Fragment>
                ))}
              </CustomModalSalute>
            )}
          </div>
          <div className="col-12 col-xxl-8 result-home-second-step">
            <GuaranteesDefaultStateStyles>
              {processingQuote === null ? (
                <h5 className="fw-semibold pt-2 ps-lg-2">
                  Seleziona Le Quotazioni Che Vuoi Aquistare
                </h5>
              ) : (
                <div className="container mt-4">
                  <ul className="list-unstyled">
                    {processingQuote !== null && (
                      <li>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5>
                            {processingQuote.company.name}
                            {' '}
                            <span className="text-success">
                              (
                              {processingQuote.product_name}
                              )
                            </span>
                          </h5>
                          <h5 className="fw-bold">
                            {parseFloat(processingQuote.amount).toFixed(2)}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    )}
                    {quotationTypes
                      .filter((t) => t.id !== 'res_civile')
                      .map((type, index) => (
                        <React.Fragment key={index}>
                          {riskQuotations && riskQuotations[type.id]
                            ? riskQuotations[type.id].map((quote, i) => (
                              <li key={i}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {quote.company !== null
                                      ? quote.company.name
                                      : quote.preventivas_company
                                        .company_name}
                                    {' '}
                                    <span className="text-success">
                                      (
                                      {
                                          quotationTypes.find(
                                            (item) => item.id === quote.quote_type,
                                          ).name
                                        }
                                      )
                                    </span>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(quote.amount).toFixed(2)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            ))
                            : ''}
                        </React.Fragment>
                      ))}
                    <hr />
                    <li>
                      <div className="d-flex justify-content-end align-items-center">
                        <h5 className="me-5 fw-bold">Totale:</h5>
                        <h5 className="fw-bold">
                          {' '}
                          {getTotal()}
                          {' '}
                          €
                        </h5>
                      </div>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                      onClick={() => handleRequestPurchase()}
                    >
                      Aquista
                    </button>
                  </div>
                </div>
              )}
            </GuaranteesDefaultStateStyles>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <EstimateContainer>
              <div className="d-flex gap-2 justify-content-end ">
                <div>
                  {/* {localStorage.getItem('quoteNewResult') === 'done' && (
                      <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                        <button className="btn btn-success px-2 py-1">Tutorial</button>
                      </div>
                    )} */}
                </div>
              </div>
            </EstimateContainer>
          </div>
        </div>
        <RiskContainer className="mb-3 pb-3 result-home-third-step">
          {quotationTypes.map((type, index) => (
            <React.Fragment key={index}>
              {quotations && quotations[type.id] && (
                <SaluteModalButtons
                  insuranceRequest={insuranceRequest}
                  processingQuote={processingQuote}
                  type={type}
                  removeItemFromCart={removeItemFromCart}
                  riskQuotations={riskQuotations}
                />
              )}
            </React.Fragment>
          ))}
        </RiskContainer>
      </div>
      {quotationTypes.map((type, index) => (
        <SaluteModal
          insuranceRequest={insuranceRequest}
          type={type}
          key={index}
          addingQuote={addingQuote}
          quotations={quotations}
          selectQuotation={selectQuotation}
        />
      ))}
    </SaluteQuotesStyles>
  );
}

export default SaluteResultPage;
