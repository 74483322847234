import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer, InfoItem, TutelaLegaleCardInfoStyles, TitleActionBox,
} from './TutelaLegaleInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function TutelaLegaleInfoCard({ modificaDati, customer, insuranceRequest }) {
  return (
    <TutelaLegaleCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>tutela legale</b>
        </h4>
        {modificaDati && (
          <ModificaDatiButton onClick={modificaDati}>
            <p>Modifica Dati</p>
            <img src={editIcon} alt="Modifica Dati" />
          </ModificaDatiButton>
        )}
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.gender !== 'G' ? `${customer.name} ${customer.surname}` : customer.business_name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Residenza:</h4>
          <p>{`${customer.province.name}, ${customer.province.car_plate_symbol}`}</p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>{moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}</p>
        </InfoItem>
      </InfoBoxContainer>
    </TutelaLegaleCardInfoStyles>
  );
}

export default TutelaLegaleInfoCard;
