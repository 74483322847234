import React from 'react';
import {
  CompanyInfoElements,
  CompanyInformationContainer,
} from '../RiskCard.styles';
import infoIcon from '../../../../../assets/redesign_icons/info-icon.svg';

function RiskCompanyInfoComponent({ quote, openModal, openModalEurope }) {
  return (
    <>
      {quote.quote_type === 'assistenza_stradale'
        && quote.company_id === 16 && (
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori Informazioni</p>
            </CompanyInfoElements>
          </CompanyInformationContainer>
      )}
      {quote.quote_type === 'assistenza_stradale' && quote.company_id === 8 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModalEurope()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
      {quote.quote_type === 'tutela_legale' && quote.company_id === 16 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModal()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
      {quote.quote_type === 'tutela_legale' && quote.company_id === 8 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModalEurope()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
      {quote.quote_type === 'tutela_legale'
        && quote.company_id === 15
        && quote.information && (
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori informazioni</p>
            </CompanyInfoElements>
          </CompanyInformationContainer>
      )}
      {quote.quote_type === 'infortuni_conducente'
        && quote.company_id === 16 && (
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori Informazioni</p>
            </CompanyInfoElements>
          </CompanyInformationContainer>
      )}
      {quote.quote_type === 'cristalli' && quote.company_id === 16 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModal()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
      {quote.quote_type === 'cristalli' && quote.company_id === 8 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModalEurope()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
      {quote.quote_type === 'collisione' && quote.company_id === 8 && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModalEurope()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori Informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
    </>
  );
}

export default RiskCompanyInfoComponent;
