import React from 'react';
import {
  LeftCol,
  LogoPriceContainer,
  QuotationCardResultStyles,
} from './QuotationCardResult.styles';
import LogoComponentResult from './smallerComponents/LogoComponentResult';
import PriceComponentResult from './smallerComponents/PriceComponentResult';
import QuoteInfoComponentResult from './smallerComponents/QuoteInfoComponentResult';

function QuotationCardResult({ processingQuote, requestInfo, profession }) {
  return (
    <QuotationCardResultStyles
      className={processingQuote.is_aquista === 1 ? 'selected' : ''}
    >
      <LeftCol>
        <LogoPriceContainer>
          {/* LOGO Component */}
          <LogoComponentResult quote={processingQuote} />
          {/* Price Component */}
          <PriceComponentResult quote={processingQuote} />
        </LogoPriceContainer>
        <QuoteInfoComponentResult
          quote={processingQuote}
          requestInfo={requestInfo}
          profession={profession}
        />
      </LeftCol>
    </QuotationCardResultStyles>
  );
}

export default QuotationCardResult;
