import React from 'react';
import { PaginationList, PaginationStyles } from './Pagination.styles';

function Pagination({
  links, search, search_by, onClick, total_pages, current_page, classNameNav,
}) {
  const pageChange = (page) => {
    if (total_pages !== undefined) {
      onClick(page, search, search_by);
      return;
    }
    const nr = page.split('=')[1];
    onClick(nr, search, search_by);
  };

  if (total_pages !== undefined) {
    const pages = [];
    for (let i = 1; i <= total_pages; i++) {
      if (Math.abs(current_page - i) <= 5) {
        pages.push(i);
      }
    }

    return total_pages !== 1
      ? (
        <PaginationStyles className={classNameNav}>
          <PaginationList>
            {pages.map((page) => (
              <li key={page} className={`${page === parseInt(current_page) ? 'active-link' : ''} `}>
                <button onClick={() => pageChange(page)}>{page}</button>
              </li>
            ))}
          </PaginationList>
        </PaginationStyles>
      ) : null;
  }

  return (
    links && links.length !== 3
      ? (
        <PaginationStyles>
          <PaginationList>
            {links.map((link, index) => (
              link.url ? (
                <li key={index} className={`${link.active ? 'active-link' : ''}`}>
                  <button dangerouslySetInnerHTML={{ __html: link.label }} onClick={() => pageChange(link.url)} />
                </li>
              ) : (
                <li className=" disabled-link" key={index}>
                  <button dangerouslySetInnerHTML={{ __html: link.label }} />
                </li>
              )
            ))}
          </PaginationList>
        </PaginationStyles>
      ) : null
  );
}

export default Pagination;
