import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label } from './styles/common';

function FormReactSelectHouseProvinceInput({
  label, children, options, onChange, values, error, valid, disabled,
}) {
  const [prepOptions, setPrepOptions] = useState([]);
  const [inputText, setInputText] = useState('');

  const defaultBorder = '#CBCBCD';

  const filterMunicipalities = (inputValue) => {
    if (inputValue.length >= 3) {
      return prepOptions.filter((i) => i.label.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    return [];
  };

  const promiseOptions = (inputValue) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterMunicipalities(inputValue));
    }, 500);
  });

  const handleChange = (value) => {
    if (value.house_commune_code !== undefined || value.house_commune_code !== '') {
      const commune = options.find((opt) => opt.cadastral_code === value.house_commune_code);
      if (commune !== undefined) {
        onChange({
          house_commune_code: commune.cadastral_code,
          house_province_code: commune.communal_territory.car_plate_symbol,
        });
      }
    }

    if (value.house_commune_code === null || value.house_commune_code === '' || value.house_commune_code === undefined) {
      onChange({
        house_commune_code: '',
        house_province_code: '',
      });
    }
  };

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.house_commune_code && inputText) {
      const startingWithOptions = prepOptions.filter((option) => option.label.toLowerCase().startsWith(inputText.toLowerCase()));
      const firstStartingOption = startingWithOptions[0];
      const findCarPlateSymbol = options.find((opt) => opt.cadastral_code === firstStartingOption.value);
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          house_commune_code: firstStartingOption.value,
          house_province_code: findCarPlateSymbol.communal_territory.car_plate_symbol,
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i) => ({ value: i.cadastral_code, label: `${i.name} (${i.communal_territory.car_plate_symbol})` })));
  }, [options]);

  return (
    <div className="d-flex align-items-start justify-content-between flex-wrap" style={{ columnGap: 20 }}>
      <FormGroup className="position-relative" style={{ flex: 2 }}>
        <Label>{label}</Label>
        <AsyncSelect
          onMenuClose={handleMenuClose}
          onInputChange={handleInputChange}
          loadOptions={promiseOptions}
          defaultOptions
          isSearchable={!disabled}
          placeholder=""
          value={options.filter((opt) => opt.cadastral_code === values.house_commune_code).map((o) => ({ value: o.cadastral_code, label: `${o.name} (${o.communal_territory.car_plate_symbol})` }))[0]}
          onChange={(val) => {
            handleChange({
              house_commune_code: val === null || val === undefined ? '' : val.value,
            });
          }}
          loadingMessage={() => 'Caricando communi...'}
          clearValue
          isClearable={!disabled}
          noOptionsMessage={() => (disabled ? null : 'Digita almeno tre lettere del comune!')}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderWidth: '2px !important',
              borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
              padding: '0.05rem 0.75rem',
              fontWeight: 'bold',
              borderRadius: '0.375rem',
              backgroundColor: '#FBFAFA',
              '&:hover': defaultBorder,
              boxShadow: 'none',
              textAlign: 'center',
            }),
          }}
        />
        <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
      </FormGroup>
      {children !== undefined
        && (
          <div style={{ flex: 1, flexBasis: 50 }}>
            {children}
          </div>
        )}
    </div>
  );
}

export default FormReactSelectHouseProvinceInput;
