import React from 'react';
import AdriaticResultCard from '../Cards/AdriaticResultCard';
import PrimaResultCard from '../Cards/PrimaResultCard';
import GreeniaResultCard from '../Cards/GreeniaResultCard';
import PreventivassResultCardLinearAndDallbogg from '../Cards/PreventivassResultCardLinearAndDallbogg';
import PreventivassResultCard from '../Cards/PreventivassResultCard';
import EurohercResultCard from '../Cards/EurohercResultCard';

function ProcessingQuoteSelectedAuto({ processingQuote, requestInfo, refresh }) {
  return (
    <>
      {(processingQuote && processingQuote.company_id === 1)
        && (
          <div className="col-12 mb-2">
            <AdriaticResultCard processingQuote={processingQuote} requestInfo={requestInfo} />
          </div>
        )}
      {processingQuote && processingQuote.company_id === 2
        && (
          <div className="col-12 mb-2">
            <PrimaResultCard requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {(processingQuote && processingQuote.company_id === 11)
        && (
          <div className="col-12 mb-2">
            <GreeniaResultCard requestInfo={requestInfo} processingQuote={processingQuote} refresh={refresh} />
          </div>
        )}
      {(processingQuote && processingQuote.company_id === 12)
        && (
          <div className="col-12 mb-2">
            <GreeniaResultCard requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {(processingQuote && processingQuote.company_id === 17)
        && (
          <div className="col-12 mb-2">
            <EurohercResultCard processingQuote={processingQuote} requestInfo={requestInfo} />
          </div>
        )}
      {(processingQuote && processingQuote.company_id === 13)
        && (
          <div className="col-12 mb-2">
            <GreeniaResultCard requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {(processingQuote && (processingQuote.company_id === 9 || processingQuote.company_id === 10))
        && (
          <div className="col-12 mb-2">
            <PreventivassResultCardLinearAndDallbogg requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {(processingQuote && processingQuote.added_manually === 1)
        && (
          <div className="col-12 mb-2">
            <PreventivassResultCard requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {(processingQuote && processingQuote.company_id === null)
        && (
          <div className="col-12 mb-2">
            <PreventivassResultCard requestInfo={requestInfo} processingQuote={processingQuote} />
          </div>
        )}
      {processingQuote === null && <div className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default ProcessingQuoteSelectedAuto;
