import moment from 'moment';
import React from 'react';
import { getQuotationTypeName } from '../../constants';

function CompletedQuoteScreenInfoAuto({ rc_quotation, risk_quotations }) {
  const get_amount_to_pay = () => {
    let primaGuaranteesPrices = 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    const amount_to_pay = parseFloat(
      rc_quotation.amount / rc_quotation.payment_frequency
        + rc_quotation.brokerage_amount
        + (rc_quotation.quotation_services
        && rc_quotation.quotation_services.length > 0
          ? rc_quotation.quotation_services[0].amount_gross
          : 0),
    );

    return (
      amount_to_pay
      + primaGuaranteesPrices / rc_quotation.payment_frequency
    ).toFixed(2);
  };

  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card border mb-2 polizze-emessa-status-step-three">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              Dati
              {' '}
              {getQuotationTypeName(rc_quotation.quote_type)}
            </h2>
          </div>
          <div className="card-body">
            {rc_quotation.company_id != 2 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company ? rc_quotation.company.name : ''}
                  {rc_quotation.preventivas_company
                    ? rc_quotation.preventivas_company.company_name
                    : ''}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rc_quotation.payment_frequency === 1
                    ? 'Annuo'
                    : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rc_quotation.amount_paid === null
                    ? '0'
                    : rc_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    ? '0'
                    : (
                      Math.ceil(rc_quotation.amount * 100) / 100
                        - rc_quotation.amount_paid
                    ).toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .add(
                      Math.floor(365 / rc_quotation.payment_frequency),
                      'days',
                    )
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Brokeraggio:</b>
                  {' '}
                  {rc_quotation.brokerage_amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id == 2 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  Ipitq ma
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rc_quotation.payment_frequency === 1
                    ? 'Annuo'
                    : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rc_quotation.amount_paid === null
                    && get_amount_to_pay().toLocaleString()}
                  {rc_quotation.amount_paid !== null
                    && rc_quotation.amount_paid !== rc_quotation.amount
                    && get_amount_to_pay().toLocaleString()}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    && (
                      get_amount_to_pay() * rc_quotation.payment_frequency
                    ).toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    ? '0'
                    : get_amount_to_pay().toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .add(
                      Math.floor(365 / rc_quotation.payment_frequency),
                      'days',
                    )
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {get_amount_to_pay() * rc_quotation.payment_frequency}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id === null ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.preventivas_company.company_name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {rc_quotation.guarantees && rc_quotation.guarantees.length !== 0 && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Garanzie</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {rc_quotation.guarantees.map((guarantee, index) => (
                  <li className="list-group-item" key={index}>
                    <b>Garanzia: </b>
                    {' '}
                    {rc_quotation.quotation_data_prima.quotation_data_prima_guarantees.find(
                      (g) => g.slug === guarantee.guarantees_slug,
                    ) !== undefined
                      && rc_quotation.quotation_data_prima.quotation_data_prima_guarantees.find(
                        (g) => g.slug === guarantee.guarantees_slug,
                      ).name}
                    <br />
                    <b>Premio Totale: </b>
                    {' '}
                    {guarantee.amount}
                    {' '}
                    &euro;
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {risk_quotations.map((risk_quotation) => (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">
                Dati
                {' '}
                {getQuotationTypeName(risk_quotation.quote_type)}
              </h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {risk_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {risk_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(
                    risk_quotation.insurance_request.policy_effective_date,
                  )
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {risk_quotation.payment_frequency === 1
                    ? 'Annuo'
                    : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {risk_quotation.amount_paid === null
                    ? '0'
                    : risk_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {risk_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {risk_quotation.amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CompletedQuoteScreenInfoAuto;
