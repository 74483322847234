import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  BadgeOptional,
  OptionalDocumentBox,
  PurchaseStyles,
  RedirectToPrimaAlert,
  UploadDocumentsContainer,
  UploadWrapper,
} from './Purchase.styles';
import SignatureType from '../../common/components/redesignComponents/Purchase/SignatureType';
import {
  confirmSelectedForBackofficeQuote,
  continueWithSignatureMoto,
  motoGetBackofficeUsers,
  purchaseInsuranceRequest,
  resetMotoPurchase,
  skipSignatureMoto,
} from '../../features/purchases/purchasesActions';
import {
  confirmAlertForBackofficeBrokerQuote,
  generalAlertSuccess,
  generalAlertError,
  confirmAlert,
} from '../../common/utils/alerts';
import OptionalDocumentModal from '../../common/components/elements/OptionalDocumentModal';
import InfoBoxMoto from '../../common/components/redesignComponents/Purchase/common/InfoBoxMoto';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import {
  apiDeleteOptionalDocument,
  apiDownloadDocument,
  apiDownloadOptionalDocument,
  deleteDocument,
} from '../../services/axios-client/axeCommons';
import uploadIcon from '../../assets/redesign_icons/upload-icon.svg';
import { NumberCurrent, SkipSignedDocs } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import NonSignableDocumentComponent from './common/NonSignableDocumentComponent';
import NonSIgnableDocumentRequestComponent from './common/NonSIgnableDocumentRequestComponent';
import CompletedScreenMoto from '../../common/components/elements/PurchaseAuto/CompletedScreenMoto';

function PurchaseMotoRequest() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const state = useSelector((store) => store.purchases.moto);
  const {
    loading,
    quote,
    insuranceRequest,
    quoteStatus,
    backofficeUsers,
    rc_quotation,
    tutela_quotation,
    nonSignableDocs,
    requestedOptionalDocumentsNonSignable,
    signableDocs,
    requestedOptionalDocumentsSignable,
  } = state;

  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState(false);

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const statues = [
    {
      id: 1,
      name: 'Attesa Documenti',
    },
    {
      id: 2,
      name: 'Problemi Con i Documenti',
    },
    {
      id: 3,
      name: 'Attesa Firma Documenti',
    },
    {
      id: 4,
      name: 'Attesa Pagamento',
    },
    {
      id: 5,
      name: 'Attesa Smarcatura',
    },
    {
      id: 6,
      name: 'In Attesa Emissione',
    },
    {
      id: 7,
      name: 'Polizza Emessa',
    },
    {
      id: 8,
      name: 'Semestrale',
    },
  ];

  const collectQuoteData = () => {
    purchaseInsuranceRequest(requestToken, 'moto', setSupportMessage);
  };

  const continueWithoutSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignatureMoto(rc_quotation.id, navigate));
  };

  const continueWithSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignatureMoto(rc_quotation.id, collectQuoteData));
  };

  const checkIfAllNonSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
      ...rc_quotation.documents.filter((d) => d.signable_document === 0),
    ];

    const nonSignaleDocTypeIds = nonSignableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (!nonSignaleDocTypeIds.includes(document.document_type_id)) {
        allUploaded = false;
      }
    }

    if (allDocs.length === 0 || nonSignaleDocTypeIds.length !== allDocs.length) {
      return false;
    }

    return allUploaded;
  };

  useEffect(() => {
    if (rc_quotation && rc_quotation.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [rc_quotation]);

  useEffect(() => {
    collectQuoteData();
    motoGetBackofficeUsers();

    return () => {
      resetMotoPurchase();
    };
  }, []);

  useEffect(() => {
    if (loading === false && rc_quotation.prima_expired === true) {
      navigate('/quote/motorcycle', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    } else if (
      loading === false
      && rc_quotation.prima_link !== undefined
      && rc_quotation.prima_link !== null
    ) {
      if (quoteStatus !== 7) {
        window.open(`/redirect?url=${encodeURIComponent(rc_quotation.prima_link)}`);
      }
    }
  }, [loading, rc_quotation]);

  const navigateBack = () => {
    navigate(`/quotes/moto/${insuranceRequest.request_token}`);
  };

  const acceptSelectedForBackoffice = () => {
    confirmSelectedForBackofficeQuote(rc_quotation.id, collectQuoteData);
  };

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const removeOptionalDocument = async (documentId) => {
    try {
      const response = await apiDeleteOptionalDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    if (rc_quotation !== null && rc_quotation.should_redirect === 1) {
      navigate(`/quotes/${insuranceRequest.request_token}`);
      generalAlertError(
        'Se vuoi selezionare un altro preventivo devi fare modifica dati!',
      );
    } else if (
      rc_quotation !== null
      && rc_quotation.preventivas_company_id !== null
      && rc_quotation.selected_for_backoffice === null
    ) {
      confirmAlertForBackofficeBrokerQuote(
        'Se confermi di procedere con questa quota e cambi idea devi fare modifica dati!',
        () => acceptSelectedForBackoffice(),
        navigate,
        insuranceRequest.request_token,
      );
    }
  }, [rc_quotation]);

  if (loading) {
    return <PageLoading />;
  }

  const documentRequestsThatNeedSignature = [
    ...insuranceRequest.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
    ...quote.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
  ];

  return (
    <PurchaseStyles>
      {quoteStatus !== 7 && (
        <>
          <div className="container mt-0 gap-2 align-items-center justify-content-start mb-3">
            <div className="px-2 py-1 d-flex align-items-center gap-2">
              <div className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success" onClick={() => navigateBack()}>
                <div id="back-button">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
              </div>
            </div>
            {/* <AutoStepper quoteStatus={quoteStatus} /> */}
            {rc_quotation && (
              <InfoBoxMoto
                quote={quote}
                rc_quotation={rc_quotation}
                tutela_quotation={tutela_quotation}
                insuranceRequest={insuranceRequest}
                statues={statues}
                quoteStatus={quoteStatus}
                messagesModalData={{
                  backofficeUsers,
                  setSupportMessage,
                  supportMessage,
                  collectData: collectQuoteData,
                }}
              />
            )}
          </div>
          {rc_quotation && (
            <div className="container mt-0">
              {rc_quotation && (rc_quotation.company_id === 2 && rc_quotation.prima_link !== null && !rc_quotation.indirect_prima_quote_process) ? (
                <RedirectToPrimaAlert>
                  Verrai reindirizzato alla piattaforma Prima.it
                </RedirectToPrimaAlert>
              ) : (
                <>
                  {quoteStatus === 1
                    || quoteStatus === 2
                    || quoteStatus === 5 ? (
                      <>
                        {(nonSignableDocs.length > 0 || requestedOptionalDocumentsNonSignable.length > 0) && (
                        <div className="row mt-3">
                          <div className="col-12">
                            <UploadDocumentsContainer className={checkIfAllNonSignableDocsAreUploaded() === false ? 'document-all-uploaded-border' : ''}>
                              <div className="d-flex align-items-center justify-content-start">
                                <NumberCurrent>1</NumberCurrent>
                                <h2>
                                  Carica i
                                  {' '}
                                  <b>documenti</b>
                                  {' '}
                                  del cliente
                                </h2>
                              </div>
                              {nonSignableDocs.length > 0 && (
                                <UploadWrapper>
                                  {nonSignableDocs.map((docType, index) => (
                                    <NonSignableDocumentComponent
                                      key={index}
                                      insuranceRequest={insuranceRequest}
                                      documentType={docType}
                                      downloadDocument={downloadDocument}
                                      removeDocument={removeDocument}
                                      rc_quotation={rc_quotation}
                                      collectQuoteData={collectQuoteData}
                                    />
                                  ))}
                                  {requestedOptionalDocumentsNonSignable.map((document_request, index) => (
                                    <NonSIgnableDocumentRequestComponent
                                      key={index}
                                      document_request={document_request}
                                      removeOptionalDocument={removeOptionalDocument}
                                      downloadOptionalDocument={downloadOptionalDocument}
                                      collectQuoteData={collectQuoteData}
                                    />
                                  ))}
                                  <OptionalDocumentBox>
                                    <BadgeOptional>Facoltativo</BadgeOptional>
                                    <img src={uploadIcon} alt="" />
                                    <button
                                      className="bg-secondary"
                                      onClick={() => openOptionalDocumentModal()}
                                    >
                                      Richiedi di aggiungere un documento
                                    </button>
                                  </OptionalDocumentBox>
                                </UploadWrapper>
                              )}

                            </UploadDocumentsContainer>
                          </div>
                        </div>
                        )}
                        <div className="documents-status-step-five">
                          {rc_quotation.sign_later === null && (
                          <div className="row mt-3 ">
                            <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                              <SkipSignedDocs className="skip-doc">
                                <p className="text-center">Vuoi Firmare Dopo?</p>
                                <div className="d-flex justify-content-center align-items-center gap-3">
                                  <button className="btn btn-success border py-1 px-4" onClick={() => continueWithoutSignDocs()}>Si</button>
                                  <button className="btn btn-danger border py-1 px-4" onClick={() => continueWithSignDocs()}>No</button>
                                </div>
                              </SkipSignedDocs>
                            </div>
                          </div>
                          )}
                          {rc_quotation.sign_later === 1 && <div className="alert alert-success mt-3 border rounded-4">Hai scelto di firmare i documenti più tardi.</div>}
                          {rc_quotation.sign_later === 0 && (
                          <div className="row mt-3">
                            <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                              <SignatureType
                                quote={rc_quotation}
                                insuranceRequest={insuranceRequest}
                                collectQuoteData={collectQuoteData}
                                documentRequestsThatNeedSignature={documentRequestsThatNeedSignature}
                                signableDocs={signableDocs}
                                requestedOptionalDocumentsSignable={requestedOptionalDocumentsSignable}
                              />
                            </div>
                          </div>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  {quoteStatus === 4 && (
                    <WaitingForPaymentStatus
                      quote={quote}
                      rc_quotation={rc_quotation}
                      tutela_quotation={tutela_quotation}
                      insuranceRequest={insuranceRequest}
                      collectQuoteData={collectQuoteData}
                      assistenza_quotation={null}
                      cristalli_quotation={null}
                      infortuni_conducente_quotation={null}
                      rivalsa_quotation={null}
                    />
                  )}
                  {quoteStatus === 6 && (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6 offset-3">
                          <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">
                              In Attesa Emissione
                            </h4>
                            <p>
                              Quando l'agente di backoffice carica la polizza,
                              riceverai una notifica e il contratto potrà essere
                              scaricato.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
      {showOptionalDocumentModal === true && (
        <OptionalDocumentModal
          quotationId={quote.id}
          closeModal={() => setShowOptionalDocumentModal(false)}
          collectQuoteData={collectQuoteData}
        />
      )}
      {quoteStatus === 7 && (
        <>
          <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
            {/* completedQUoteMotto */}
          </div>
          {/* <CompletedMotoQuoteScreen
            quote={quote}
            quoteStatus={quoteStatus}
            downloadDocument={downloadDocument}
            downloadOptionalDocument={downloadOptionalDocument}
            rc_quotation={rc_quotation}
            tutela_quotation={tutela_quotation}
            insuranceRequest={insuranceRequest}
          /> */}

          <CompletedScreenMoto
            quote={quote}
            quoteStatus={quoteStatus}
            insuranceRequest={insuranceRequest}
            rc_quotation={rc_quotation}
            tutela_quotation={tutela_quotation}
          />
        </>
      )}
    </PurchaseStyles>
  );
}

export default PurchaseMotoRequest;
