import { Paragraph } from './FormInputText.styles';
import { FormGroup, Label } from './styles/common';

export function FormInputText({
  label,
  paragraph,
  type,
  placeholder,
  min,
  max,
  registration,
  valid,
  error,
  value,
  onChange,
  inputId,
  onWheel,
  trim = true,
  classNameInput,
}) {
  if (registration === undefined) {
    const handleChange = (e) => {
      onChange(trim ? e.target.value.trim() : e.target.value);
    };

    return (
      <FormGroup>
        {label !== undefined && <Label>{label}</Label>}
        {paragraph !== undefined && <Paragraph>{paragraph}</Paragraph>}
        <input
          placeholder={placeholder}
          id={inputId}
          className={`form-control text-center ${classNameInput} ${
            error ? 'is-invalid' : ''
          } ${valid ? 'is-valid' : ''}`}
          type={type}
          min={min}
          max={max}
          onWheel={onWheel}
          value={value}
          onChange={handleChange}
        />
        <div className="invalid-feedback">{error}</div>
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      {label !== undefined && <Label>{label}</Label>}
      {paragraph !== undefined && <Paragraph>{paragraph}</Paragraph>}
      <input
        id={inputId}
        placeholder={placeholder}
        className={`form-control text-center ${classNameInput} ${
          error ? 'is-invalid' : ''
        } ${valid ? 'is-valid' : ''}`}
        type={type}
        onWheel={onWheel}
        min={min}
        max={max}
        {...registration}
      />
      <div className="invalid-feedback">{error}</div>
    </FormGroup>
  );
}
