import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  GlobalAssistencaStradaleInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader,
  RightSide,
  TextContainer,
  TitleBox,
} from './GlobalAssistencaStradaleModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function GlobalAssistencaStradaleModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <GlobalAssistencaStradaleInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <p className="mt-3">
              <b>Assistenza: (Italia/Estero):</b>
              {' '}
              in caso il veicolo sia immobilizzato a causa di un qualsiasi evento come sopra definito
              verrà inviato un carro attrezzi per trainarlo: all’officina autorizzata dalla casa costruttrice più vicina al luogo del Sinistro, all’officina
              convenzionata più vicina al luogo del Sinistro o all'officina scelta dall'Assicurato (purché quest'ultima sia entro un raggio di 40 km dal
              luogo del fermo).
            </p>
            <p>
              <b>Invio Officina Mobile:</b>
              {' '}
              a seguito di immobilizzo del veicolo, dovuto a situazioni
              o guasti risolvibili sul posto del fermo in un tempo non superiore ai 30 minuti di manodopera).
            </p>
            <p>
              <b>Veicolo Sostitutivo in Italia/Estero:</b>
              {' '}
              in seguito ad immobilizzo del veicolo,  che richieda riparazioni di durata pari o superiore alle 8 ore :  - fino a 7 giorni consecutivi in caso di danni al Veicolo;  fino a 10 giorni consecutivi: per furto del Veicolo.
            </p>
            <p>
              <b>Sistemazione in albergo: </b>
              {' '}
              qualora il veicolo risultasse immobilizzato a più di 50 km dall’abitazione dell’assicurato; fino ad un massimo di 4 notti e fino ad € 80,00 per notte e per persona
            </p>
            <p>
              <b>Prosecuzione Viaggio o Rientro Passeggieri : </b>
              {' '}
              fino ad € 500,00 per evento; 1 notte in albergo fino ad € 80,00 a persona
            </p>
            <p>
              <b>Riconsegna del Veicolo in Italia:</b>
              {' '}
              qualora il luogo di riparazione del veicolo fosse distante, in Italia, oltre 50km dalla residenza dell’Assicurato:
            </p>
            <p>
              <b>Rimpatrio del Veicolo dall’Estero: </b>
              {' '}
              a seguito di incidente purché con riparazioni di durata pari o superiore a 32 ore di manodopera qualora il veicolo sia all'Estero.
            </p>
            <p>
              <b> Abbandono Legale: </b>
              {' '}
              se la riparazione è antieconomica si procede con l’organizzazione dell’abbandono legale (a carico della Società il costo dell'abbandono legale e del deposito).
            </p>
            <p>
              <b>Recupero del Veicolo riparato </b>
              {' '}
              da parte dell’assicurato in caso di traino organizzato da C.O. e purché con riparazioni di durata pari o superiore a 32 ore di manodopera
            </p>
            <p>
              <b>Autista a disposizione </b>
              {' '}
              (al di fuori del comune di residenza dell’assicurato): in caso di infortunio dell’assicurato, conducente del veicolo, per incidente stradale;
            </p>
            <p>
              <b>Invio di pezzi di ricambio </b>
              {' '}
              sul posto solo all’estero: i costi dei pezzi di ricambio. Prestazione non valida per le auto/moto d’epoca.
            </p>
          </div>
        </ModalContent>
      </ModalBody>
    </GlobalAssistencaStradaleInfoModalStyles>
  );
}

export default GlobalAssistencaStradaleModal;
