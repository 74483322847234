import React, { useEffect, useImperativeHandle } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { flagResponse } from '../../constants';
import getAllowedLicenseYears from '../../helpers/getAllowedLicenseYears';
import { FormContainer } from './OffcanvasForm.styles';
import { FormInputRadio } from '../../formInputs/FormInputRadio';
import { FormInputText } from '../../formInputs/FormInputText';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import { FormInputDate } from '../../formInputs/FormInputDate';
import FormReactSelectInputBirthPlace from '../../formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../../formInputs/FormInputAddressComplete';

const schema = yup.object({
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  gender: yup
    .string()
    .required('Il campo è obbligatorio.'),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1935) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
      ? !born_abroad
        ? schema.required(
          'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
        )
        : schema
      : schema)),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio.'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio.')
    .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
  address: yup
    .string()
    .required('Il campo è obbligatorio.'),
  house_number: yup
    .string()
    .required('Il campo è obbligatorio.'),
  civil_status_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  education_level_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  profession_id: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  business_name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender === 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  vat_number: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender === 'G'
      ? schema.required('Il campo è obbligatorio.').matches(/^[0-9]{11}$/, 'Il codice fiscale non è valido')
      : schema)),
  company_type: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender === 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
  driving_license_year: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio.')
      : schema)),
});

function OffcanvasForm(props, ref) {
  const {
    isOwnerForm, company_types, data, title,
  } = props;

  const {
    register,
    formState: { errors, touchedFields, isValid: isFormValid },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: data,
  });

  const formData = watch();

  useEffect(() => {
    setValue('isValid', isFormValid);
    props.onChange({
      isValid: isFormValid,
    });
  }, [isFormValid]);

  useEffect(() => {
    props.onChange(formData);
  }, [formData]);

  useImperativeHandle(ref, () => ({
    triggerErrors() {
      trigger(undefined, {
        shouldFocus: true,
      });
    },
  }));

  useEffect(() => {
    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (formData.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('commune_of_birth_code', null);
      setValue('civil_status_id', null);
      setValue('education_level_id', null);
      setValue('profession_id', null);
      setValue('driving_license_year', null);
    } else if (['M', 'F'].includes(formData.gender) && formData.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('civil_status_id', '2', triggerValidation);
      setValue('education_level_id', '3', triggerValidation);
      setValue('profession_id', '1', triggerValidation);
      setValue('driving_license_year', '', triggerValidation);
    }
  }, [formData.gender]);

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  return (
    <FormContainer>
      <div className="title">{title}</div>
      <div className="form-inputs">
        <div className="row">
          <FormInputRadio
            label="Genere"
            options={props.genders.filter((gender) => isOwnerForm || gender.value !== 'G')}
            name="gender"
            registration={register('gender')}
            error={errors.gender?.message}
            valid={isValid('gender')}
          />
        </div>
        {formData.gender === 'G' && (
          <>
            <FormInputText
              label="Ragione sociale"
              registration={register('business_name')}
              error={errors.business_name?.message}
              valid={isValid('business_name')}
            />
            <FormInputText
              label="Partita Iva"
              registration={register('vat_number')}
              error={errors.vat_number?.message}
              valid={isValid('vat_number')}
            />
            <FormInputSelect
              label="Tipologia azienda"
              placeholder="-Seleziona-"
              options={company_types}
              registration={register('company_type')}
              error={errors.company_type?.message}
              valid={isValid('company_type')}
            />
          </>
        )}

        {formData.gender !== 'G' && (
          <>
            <FormInputText
              label="Nome"
              registration={register('name')}
              error={errors.name?.message}
              valid={isValid('name')}
            />

            <FormInputText
              registration={register('surname')}
              error={errors.surname?.message}
              valid={isValid('surname')}
              label="Cognome"
            />

            <FormInputDate
              label="Data di nascita"
              registration={register('date_of_birth')}
              error={errors.date_of_birth?.message}
              valid={isValid('date_of_birth')}
              minDate="1935-01-01"
              maxDate={props.getMaxAllowedBirthday()}
            />

            <FormReactSelectInputBirthPlace
              states={props.states}
              options={props.municipalities}
              label="Luogo di nascita"
              name="commune_of_birth_code"
              values={{
                commune_of_birth_code: formData.commune_of_birth_code,
                province_of_birth_code: formData.province_of_birth_code,
                born_abroad: formData.born_abroad,
                country_of_birth_code: formData.country_of_birth_code,
              }}
              onChange={(value) => updateFormData({
                commune_of_birth_code: value.commune_of_birth_code,
                province_of_birth_code: value.province_of_birth_code,
                born_abroad: value.born_abroad,
                country_of_birth_code: value.country_of_birth_code,
              })}
              valid={isValid('commune_of_birth_code')}
              error={errors.commune_of_birth_code?.message}
            />
          </>
        )}

        <FormInputAddressComplete
          municipalities={props.municipalities}
          formData={data}
          isValid={isValid}
          errors={errors}
          register={register}
          updateFormData={updateFormData}
          addressId={`autocomplete-address-${isOwnerForm ? 'owner' : 'driver'}`}
        />

        {formData.gender !== 'G' && (
          <>
            <FormInputSelect
              placeholder="Stato civile"
              label="Stato civile"
              registration={register('civil_status_id')}
              error={errors.civil_status_id?.message}
              valid={isValid('civil_status_id')}
              options={props.marital_statuses}
            />
            <FormInputSelect
              placeholder="Titolo di studio"
              label="Titolo di studio"
              name="education_level_id"
              registration={register('education_level_id')}
              error={errors.education_level_id?.message}
              valid={isValid('education_level_id')}
              options={props.qualifications}
            />
            <FormInputSelect
              placeholder="Professione"
              label="Professione"
              registration={register('profession_id')}
              error={errors.profession_id?.message}
              valid={isValid('profession_id')}
              options={props.professions}
            />
          </>
        )}
        <FormInputRadio
          label="Il conducente dichiara: di essere in possesso di patente italiana mai sospesa da 5 anni e con almeno 20 punti, di non aver mai ricevuto sanzioni per ubriachezza, di non aver modificato il veicolo."
          options={flagResponse}
          name="valid_driving_license"
          registration={register('valid_driving_license')}
          error={errors.valid_driving_license?.message}
          valid={isValid('valid_driving_license')}
        />
        {formData.gender !== 'G' && formData.date_of_birth !== '' && (
          <FormInputSelect
            label="Anno patente"
            placeholder="-Seleziona-"
            options={getAllowedLicenseYears(formData.date_of_birth)}
            registration={register('driving_license_year')}
            error={errors.driving_license_year?.message}
            valid={isValid('driving_license_year')}
          />
        )}
      </div>
    </FormContainer>
  );
}

export default React.forwardRef(OffcanvasForm);
