import React from 'react';
import ProcessingKaskoQuote from './ProcessingKaskoQuote';
import ProcessingCollisioneQuote from './ProcessingCollisioneQuote';
import ProcessingEventiPoliticiQuote from './ProcessingEventiPoliticiQuote';
import ProcessingEventiNaturaliQuote from './ProcessingEventiNaturaliQuote';
import ProcessingBonusProtettoQuote from './ProcessingBonusProtettoQuote';
import ProcessingFurtoIncendioQuote from './ProcessingFurtoIncendioQuote';
import ProcessingTutelaPrimaQuote from './ProcessingTutelaPrimaQuote';
import ProcessingAssistenzaPrimaQuote from './ProcessingAssistenzaPrimaQuote';
import ProcessingInfortuniPrimaQuote from './ProcessingInfortuniPrimaQuote';
import ProcessingCristalliPrimaQuote from './ProcessingCristalliPrimaQuote';
import ProcessingRivalsaPrimaQuote from './ProcessingRivalsaPrimaQuote';

function SelectedQuotesCardsPrima({ processingQuote, type }) {
  const createIdForModal = () => {
    if (type.id === 'infortuni_conducente') {
      return 'infortuni_conducente_prima';
    }

    if (type.id === 'assistenza_stradale') {
      return 'assistenza_stradale_prima';
    }

    if (type.id === 'tutela_legale') {
      return 'tutela_legale_prima';
    }

    if (type.id === 'cristalli') {
      return 'cristalli_prima';
    }

    if (type.id === 'furto_incendio') {
      return 'furto_incendio_prima';
    }

    if (type.id === 'eventi_naturali') {
      return 'eventi_naturali_prima';
    }

    if (type.id === 'collisione') {
      return 'collisione_prima';
    }

    return type.id;
  };

  return (
    <>
      {processingQuote !== null && type.id === 'tutela_legale' && (
        <ProcessingTutelaPrimaQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'assistenza_stradale' && (
        <ProcessingAssistenzaPrimaQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'infortuni_conducente' && (
        <ProcessingInfortuniPrimaQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'cristalli' && (
        <ProcessingCristalliPrimaQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'protezione_rivalse' && (
        <ProcessingRivalsaPrimaQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'kasko' && (
        <ProcessingKaskoQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'collisione' && (
        <ProcessingCollisioneQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'eventi_sociopolitici' && (
        <ProcessingEventiPoliticiQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'eventi_naturali' && (
        <ProcessingEventiNaturaliQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'bonus_protetto' && (
        <ProcessingBonusProtettoQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}

      {processingQuote !== null && type.id === 'furto_incendio' && (
        <ProcessingFurtoIncendioQuote
          processingQuote={processingQuote}
          type={type}
          createIdForModal={createIdForModal}
        />
      )}
    </>
  );
}

export default SelectedQuotesCardsPrima;
