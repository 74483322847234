import React from 'react';
import {
  RiskButton,
  RiskButtonRemove,
} from '../Cards/ProfessionInfoCard.styles';
import {
  updateGuaranteesAutoAction,
  updateGuaranteesMotoAction,
} from '../../../../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert } from '../../../utils/alerts';

function ButtonComponentLogic({
  type,
  processingQuote,
  removeItemFromCart,
  requestInfo,
  riskQuotations,
}) {
  const addGuarantees = (coverages) => {
    if (processingQuote.product_id === 2) {
      updateGuaranteesMotoAction(
        requestInfo.request_token,
        coverages,
        processingQuote.id,
      );
    } else {
      updateGuaranteesAutoAction(
        requestInfo.request_token,
        coverages,
        processingQuote.id,
      );
    }
  };

  const updateCoverages = (coverageSlug) => {
    const cov = processingQuote.guarantees.find(
      (c) => c.guarantees_slug === coverageSlug,
    );
    const coverages = [];
    if (cov === undefined) {
      processingQuote.guarantees.map((c) => coverages.push(c.guarantees_slug));
      coverages.push(coverageSlug);
    } else {
      processingQuote.guarantees
        .filter((cv) => cv.guarantees_slug !== coverageSlug)
        .map((c) => coverages.push(c.guarantees_slug));
    }

    addGuarantees(coverages);
  };

  const removeGuarantee = (coverageSlug) => {
    confirmAlert('Sei Sicuro Di Rimuovere Questo Rischio?', () => updateCoverages(coverageSlug));
  };

  return (
    <div className="d-flex justify-content-end ms-auto gap-2">
      {type.id === 'res_civile' && processingQuote !== null && (
        <RiskButtonRemove
          className="btn btn-danger"
          onClick={() => removeItemFromCart(processingQuote.id)}
        >
          Rimuovi
        </RiskButtonRemove>
      )}

      {riskQuotations
        && riskQuotations[type.id] !== undefined
        && riskQuotations[type.id].map((quote, idx) => (
          <React.Fragment key={idx}>
            {type.id === quote.quote_type && (
              <RiskButtonRemove
                className="btn btn-danger"
                onClick={
                  processingQuote.company_id === 2
                    ? () => removeGuarantee(type.id)
                    : () => removeItemFromCart(quote.id)
                }
              >
                Rimuovi
              </RiskButtonRemove>
            )}
          </React.Fragment>
        ))}

      {riskQuotations
        && riskQuotations[type.id] === undefined
        && processingQuote !== null
        && type.id !== 'res_civile' && (
          <RiskButton
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target={`#${type.id}`}
          >
            Aggiungi
          </RiskButton>
      )}

      {requestInfo.processing_quotation_id === null
        && type.id !== 'res_civile' && (
          <div className="alert alert-warning py-2 px-2">
            Seleziona Prima La Responsibilita Civile
          </div>
      )}

      {type.id === 'res_civile' && (
        <RiskButton
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target={`#${type.id}`}
        >
          Aggiungi
        </RiskButton>
      )}
    </div>
  );
}

export default ButtonComponentLogic;
