import React, { useRef } from 'react';
import { RespCivileModal } from '../Cards/ProfessionInfoCard.styles';
import { QuoteLoader } from '../../../../screens/RcQuotes.styles';
import PreventivassCardNoAquista from '../../elements/quote/cards/PreventivassCardNoAquista';
import RiskCard from '../Cards/RiskCard';
import GlobalAssistanceCard from '../Cards/GlobalAssistanceCard';
import QuotationCard from '../../../reusable/QuotationCard';

function ModalQuotes({
  type,
  addingQuote,
  sortBy,
  sortQuotes,
  handleCurrentQuotesDisplay,
  currentQuotes,
  requestInfo,
  counter,
  quotations,
  selectQuotation,
  guaranteesPrima,
  preventivas_quotes,
  processingQuote,
  riskQuotes,
  refresh,
}) {
  const closeButtonRef = useRef(null);

  const closeButton = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click(); // Close the modal

      closeButtonRef.current = null;
    }
  };

  const selectQuote = (quoteId, addDiscount) => {
    selectQuotation(quoteId, closeButton, addDiscount);
  };

  return (
    <RespCivileModal
      className="modal fade"
      id={type.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {type.name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="buttonClose"
              ref={closeButtonRef}
            />
          </div>
          <div className="modal-body">
            {addingQuote && (
              <div className="d-flex align-items-center justify-content-between flex-column">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Aggiungendo...</span>
                  </div>
                </div>
                <div className="ms-2">Aggiungendo...</div>
              </div>
            )}
            {addingQuote === false && (
              <>
                <div className="row mb-4">
                  {type.id === 'res_civile' && requestInfo.product_id == 1 && (
                    <>
                      <div className="col-12 mb-2">
                        <div className="dropdown purchase-quote-fourth-step">
                          <button
                            className="btn btn-success dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Ordinato Per:
                            {' '}
                            {sortBy === 'amount'
                              ? 'Premio'
                              : 'Indice Di Qualità'}
                          </button>
                          <ul className="dropdown-menu mt-2">
                            <li
                              style={{ cursor: 'pointer' }}
                              onClick={() => sortQuotes(
                                sortBy === 'amount' ? 'coefficente' : 'amount',
                              )}
                            >
                              <a className="dropdown-item">
                                {sortBy === 'amount'
                                  ? 'Indice Di Qualità'
                                  : 'Premio'}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex align-items-stretch justify-content-center gap-2 mb-3">
                        <button
                          className={`btn purchase-quote-fifth-step ${
                            currentQuotes === 'diretta'
                              ? 'btn-success'
                              : 'btn-outline-success'
                          }`}
                          onClick={() => handleCurrentQuotesDisplay('diretta')}
                        >
                          Compagnie Dirette
                        </button>
                        <button
                          className={`btn purchase-quote-sixth-step ${
                            currentQuotes === 'indiretta'
                              ? 'btn-success'
                              : 'btn-outline-success'
                          }`}
                          onClick={() => handleCurrentQuotesDisplay('indiretta')}
                        >
                          Compagnie Indirette
                        </button>
                        <button
                          className={`btn purchase-quote-seventh-step ${
                            currentQuotes === 'quotazioni'
                              ? 'btn-success'
                              : 'btn-outline-success'
                          }`}
                          onClick={() => handleCurrentQuotesDisplay('quotazioni')}
                        >
                          Preventivi
                        </button>
                      </div>
                    </>
                  )}
                  {type.id === 'res_civile'
                    && requestInfo.product_id == 1
                    && requestInfo.with_preventivas === null && (
                      <div className="d-flex align-items-center justify-content-center mb-2 preventivas-counter">
                        <QuoteLoader>{counter}</QuoteLoader>
                        {' '}
                        <span className="ms-2">
                          Recuperando più preventivi...
                        </span>
                      </div>
                  )}
                </div>
                <div className="row purchase-quote-eight-step">
                  {addingQuote === false
                  && quotations
                  && quotations[type.id]
                  && type.id === 'res_civile'
                  && (currentQuotes === 'diretta' || currentQuotes === 'indiretta')
                    ? quotations[type.id].map((quote, index) => (currentQuotes === 'diretta' ? (
                      <React.Fragment key={index}>
                        {((quote.company_id === null
                              && quote.added_manually === 1)
                              || (quote.company_id !== null
                                && quote.added_manually == 0)) && (
                                <div className="col-12 mb-2">
                                  <QuotationCard
                                    quote={quote}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    guaranteesPrima={guaranteesPrima}
                                    refresh={refresh}
                                  />
                                </div>
                        )}
                        {quote.product_id === 10 && (
                        <div className="col-12 mb-2">
                          <QuotationCard
                            quote={quote}
                            selectQuotation={selectQuote}
                            requestInfo={requestInfo}
                          />
                        </div>
                        )}
                      </React.Fragment>
                    ) : currentQuotes === 'indiretta' ? (
                      <React.Fragment key={index}>
                        {quote.company_id === null
                              && quote.added_manually == 0 && (
                                <div className="col-12 mb-2">
                                  <QuotationCard
                                    requestInfo={requestInfo}
                                    quote={quote}
                                    selectQuotation={selectQuote}
                                  />
                                </div>
                        )}
                      </React.Fragment>
                    ) : (
                      ''
                    )))
                    : ''}
                  {currentQuotes === 'quotazioni'
                    ? preventivas_quotes.map((quote, index) => (
                      <div className="col-12 mb-2" key={index}>
                        <PreventivassCardNoAquista
                          requestInfo={requestInfo}
                          quotation={quote}
                        />
                      </div>
                    ))
                    : ''}
                  {currentQuotes === 'quotazioni'
                  && (quotations === null || quotations[type.id] === null)
                  && type.id === 'res_civile' ? (
                    <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>
                    ) : (
                      ''
                    )}

                  {type.id === 'assistenza_stradale'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'eventi_naturali'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'eventi_socio_politici'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'tutela_legale'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <div className="col-12 mb-2" key={index}>
                        <RiskCard
                          quote={quote}
                          type={type}
                          selectQuotation={selectQuote}
                          requestInfo={requestInfo}
                          riskQuotes={riskQuotes}
                        />
                      </div>
                    ))
                    : ''}

                  {type.id === 'infortuni_conducente'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'rivalsa'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'cristalli'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id === 16
                              || quote.company_id === 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'furto_incendio'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17) ? (
                                  <div className="col-12 mb-2">
                                    <RiskCard
                                      quote={quote}
                                      type={type}
                                      selectQuotation={selectQuote}
                                      requestInfo={requestInfo}
                                      riskQuotes={riskQuotes}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'collisione'
                    && quotations
                    && quotations[type.id] && (
                      <div>
                        {processingQuote !== null ? (
                          quotations[type.id].map((quote, index) => (
                            <React.Fragment key={index}>
                              {(processingQuote.company_id === 1
                                && quote.company_id === 1)
                              || (processingQuote.company_id === 17
                                && quote.company_id === 17)
                              || quote.company_id == 8 ? (
                                <div className="col-12 mb-2">
                                  <RiskCard
                                    quote={quote}
                                    type={type}
                                    selectQuotation={selectQuote}
                                    requestInfo={requestInfo}
                                    riskQuotes={riskQuotes}
                                  />
                                </div>
                                ) : (
                                  ''
                                )}
                            </React.Fragment>
                          ))
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </div>
                  )}

                  {type.id === 'rct_cose_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rct_veicoli_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rct_postuma_autofficine_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rct_postuma_12_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rct_postuma_dm_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rct_furto_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rco_quote' && quotations && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'rc_prodotto_quote'
                  && quotations
                  && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {type.id === 'do_quote' && quotations && quotations[type.id]
                    ? quotations[type.id].map((quote, index) => (
                      <React.Fragment key={index}>
                        {processingQuote !== null ? (
                          <div className="col-12 mb-2">
                            <GlobalAssistanceCard
                              quotation={quote}
                              selectQuotation={selectQuote}
                              requestInfo={requestInfo}
                            />
                          </div>
                        ) : (
                          <h5 className="fw-bold">
                            Nessuna Quotazione Trovata
                          </h5>
                        )}
                      </React.Fragment>
                    ))
                    : ''}

                  {quotations
                    && quotations[type.id]
                    && quotations[type.id].length === 0 && (
                      <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger px-4"
              data-bs-dismiss="modal"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </RespCivileModal>
  );
}

export default ModalQuotes;
