import React, { useState } from 'react';
import {
  AquistaButton,
  AquistaButtonsContainer,
  CompanyInfoElements,
  CompanyInformationContainer,
  LeftCol, Price, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, SaluteCardStyles,
} from './SaluteCard.styles';
import { LogoContainer, LogoPriceContainer } from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function SaluteCard({
  requestInfo,
  quote,
  selectQuotation,
}) {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(!showModal);
  };
  return (
    <SaluteCardStyles>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {quote.amount.toFixed(2).toLocaleString()}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori informazioni</p>
            </CompanyInfoElements>
            {/* {docs[profession.profession] !== '' && (
            <CompanyInfoElements
              onClick={() => downloadResourceDocs(4, profession.profession)}
              title={`${docs[profession.profession]}`}
            >
              <img src={preventiveIcon} alt="" />
              <p>Questionario</p>
            </CompanyInfoElements>
            )} */}
            {/* {set_informativi[profession.profession] !== '' && (
            <CompanyInfoElements
              onClick={() => downloadResourceDocs(8, profession.profession)}
              title={`${set_informativi[profession.profession]}`}
            >
              <img src={preContractIcon} alt="" />
              <p>Informativa Precontrattuale</p>
            </CompanyInfoElements>
            )} */}
          </CompanyInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {requestInfo.product.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Nome Prodotto:
              </h6>
              <p>
                {quote.product_name}
              </p>
            </QuoteInformationItem>
            {quote.risk_massimale && (
            <QuoteInformationItem>
              <h6>
                Massimale:
              </h6>
              <p>
                {Number(quote.risk_massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.')).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                &euro;
              </p>
            </QuoteInformationItem>
            )}
            {quote.massimale && (
            <QuoteInformationItem>
              <h6>
                Massimale:
              </h6>
              <p>
                {Number(quote.massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.')).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                &euro;
              </p>
            </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {requestInfo.processing_quotation_id !== quote.id ? (
              <AquistaButton
                onClick={() => selectQuotation(quote.id)}
                className="purchase-quote-nine-step"
              >
                Seleziona
              </AquistaButton>
            ) : requestInfo.processing_quotation_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </SaluteCardStyles>
  );
}

export default SaluteCard;
