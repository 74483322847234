import React from 'react';
import { guideType } from '../../constants';
import {
  QuoteInformationResultContainer,
  QuoteInformationResultItem,
} from '../QuotationCardResult.styles';

function QuoteInfoComponentResult({ quote, requestInfo, profession }) {
  return (
    <>
      {quote.company_id === 1 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>Adriatic Assicurazioni</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}
      {(quote.company_id === 9
        || quote.company_id === 10
        || quote.company_id === 17
        || quote.added_manually === 1
        || (quote.added_manually === 0 && quote.company_id === null)) && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>
                {quote.company
                  ? quote.company.name
                  : quote.preventivas_company.company_name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}
      {quote.company_id === 2 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>Ipitq ma</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Diretta</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}
      {(quote.company_id === 11
        || quote.company_id === 12
        || quote.company_id === 13
        || quote.company_id === 14) && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Massimale:</h6>
              <p>
                {quote.autocarro_massimale.match(/[\d.,]+/)
                  ? quote.autocarro_massimale
                    .match(/[\d.,]+/)[0]
                    .replace(/\./g, '')
                  : quote.autocarro_massimale}
                {' '}
                &euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Isotermici:</h6>
              <p>{quote.isotermici}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}
      {quote.company_id === null && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.preventivas_company.company_name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}

      {quote.company_id === 15 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>Tutela Legale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>Annuo</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer className="ms-3 ms-sm-0">
            <QuoteInformationResultItem>
              <h6>Tempo medio di emissione:</h6>
              <p>2 giorni</p>
            </QuoteInformationResultItem>
            {quote.massimale != 0 && (
              <QuoteInformationResultItem>
                <h6>Massimale:</h6>
                <p>
                  {quote.massimale}
                  &euro;
                </p>
              </QuoteInformationResultItem>
            )}
          </QuoteInformationResultContainer>
        </>
      )}

      {quote.company_id === 4 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>RC Professionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Professione:</h6>
              <p>{profession.profession_desc}</p>
            </QuoteInformationResultItem>
            {profession.billed_maximum != 0 && (
              <QuoteInformationResultItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(profession.billed_maximum).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                  &euro;
                </p>
              </QuoteInformationResultItem>
            )}
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer className="ms-3 ms-sm-0">
            <QuoteInformationResultItem>
              <h6>Franchigia:</h6>
              <p>1,000 &euro;</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Retroattività:</h6>
              <p>{profession.retroactivity_name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Scaglione fatturato:</h6>
              <p>
                {profession.billed}
                &euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Tempo medio di emissione:</h6>
              <p>2 giorni</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </>
      )}

      {requestInfo.product_id == '12' && quote.company_id === 8 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>{requestInfo.product.name}</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationResultItem>
            {quote.massimale && (
              <QuoteInformationResultItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationResultItem>
            )}

            {quote.risk_massimale && (
              <QuoteInformationResultItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.risk_massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationResultItem>
            )}
          </QuoteInformationResultContainer>
        </>
      )}

      {quote.company_id === 16 && quote.product_id == 10 && (
        <>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Compagnia:</h6>
              <p>Global Assistance</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationResultItem>
            {(quote.massimale != 0 || quote.massimale != null) && (
              <QuoteInformationResultItem>
                <h6>Massimale:</h6>
                <p>
                  {new Intl.NumberFormat().format(quote.massimale)}
                  €
                </p>
              </QuoteInformationResultItem>
            )}
          </QuoteInformationResultContainer>
        </>
      )}

      {requestInfo.product_id == '11' && quote.company_id === 8 && (
      <>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>Compagnia:</h6>
            <p>{quote.company.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Prodotto:</h6>
            <p>{requestInfo.product.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Nome Prodotto:</h6>
            <p>{quote.product_name}</p>
          </QuoteInformationResultItem>
          {quote.massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}

          {quote.risk_massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.risk_massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}
        </QuoteInformationResultContainer>
      </>
      )}

      {requestInfo.product_id == '13' && quote.company_id === 8 && (
      <>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>Compagnia:</h6>
            <p>{quote.company.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Prodotto:</h6>
            <p>{requestInfo.product.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Nome Prodotto:</h6>
            <p>{quote.product_name}</p>
          </QuoteInformationResultItem>
          {quote.massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}

          {quote.risk_massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.risk_massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}
        </QuoteInformationResultContainer>
      </>
      )}
      {requestInfo.product_id == '13' && quote.company_id === 16 && (
      <>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>Compagnia:</h6>
            <p>{quote.company.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Prodotto:</h6>
            <p>{requestInfo.product.name}</p>
          </QuoteInformationResultItem>
          <QuoteInformationResultItem>
            <h6>Nome Prodotto:</h6>
            <p>{quote.product_name}</p>
          </QuoteInformationResultItem>
          {quote.massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}

          {quote.risk_massimale && (
          <QuoteInformationResultItem>
            <h6>Massimale:</h6>
            <p>
              {Number(
                quote.risk_massimale
                  .replace(/[,.](?=\d{3})/g, '')
                  .replace(',', '.'),
              ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
              &euro;
            </p>
          </QuoteInformationResultItem>
          )}
        </QuoteInformationResultContainer>
      </>
      )}

    </>
  );
}

export default QuoteInfoComponentResult;
