import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeftLong,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import {
  PurchaseStyles, UploadDocumentsContainer, UploadWrapper,
} from './Purchase.styles';
import {
  deleteDocument,
  apiDownloadDocument,
} from '../../services/axios-client/axeCommons';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  confirmAlert, generalAlertError, generalAlertSuccess,
} from '../../common/utils/alerts';
import {
  continueWithSignatureProfession, professionSelectSignMethod, purchaseInsuranceRequest, resetProfessionPurchase,
  skipSignatureProfession,
  tutelaLegaleGetBackofficeUsers,
} from '../../features/purchases/purchasesActions';
import SignatureTypeProfessione from '../../common/components/redesignComponents/Purchase/SignatureTypeProfessione';

import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import { SaveBox } from './common/SaveBox';
import { NumberCurrent, SkipSignedDocs } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import NonSignableDocumentComponent from './common/NonSignableDocumentComponent';
import InfoBoxTutelaLegale from '../../common/components/redesignComponents/Purchase/common/InfoBoxTutelaLegale';
import TutelaCompletedScreen from '../CompletedQuotes/TutelaCompletedScreen';

const statues = [
  {
    id: 1,
    name: 'Attesa Documenti',
  },
  {
    id: 2,
    name: 'Problemi Con i Documenti',
  },
  {
    id: 3,
    name: 'Attesa Firma Documenti',
  },
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 5,
    name: 'Attesa Smarcatura',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];

function PurchaseTutelaLegaleRequest() {
  const { requestToken } = useParams();
  const navigate = useNavigate();

  const state = useSelector((store) => store.purchases.tutela_legale);
  const {
    loading, quote, insuranceRequest, quoteStatus, signMethod, backofficeUsers, rc_quotation, nonSignableDocs, signableDocs,
    requestedOptionalDocumentsSignable, risk_quotations,
  } = state;

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken, 'tutela-legale', setSupportMessage);
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);

    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const continueWithoutSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignatureProfession(rc_quotation.id, navigate));
  };

  const continueWithSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignatureProfession(rc_quotation.id, collectQuoteData));
  };

  useEffect(() => {
    collectQuoteData();
    tutelaLegaleGetBackofficeUsers();
    return () => {
      resetProfessionPurchase();
    };
  }, []);

  //! MANUAL => 0, DIGITAL => 1
  const selectedMethod = async () => {
    professionSelectSignMethod(signMethod, rc_quotation.id, collectQuoteData);
  };

  useEffect(() => {
    if (signMethod !== null) {
      selectedMethod();
    }
  }, [signMethod]);

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  const navigateBack = () => {
    navigate(`/quotes/tutela-legale/${insuranceRequest.request_token}`);
  };

  useEffect(() => {
    if (loading === false && rc_quotation.is_expired === true) {
      navigate('/quote/tutela-legale', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError('È necessario compilare nuovamente il preventivo!', 'Link e scaduto!');
    }
  }, [loading, quote]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      {quoteStatus !== 7
        && (
          <>
            <div className="container mt-0 d-flex gap-2 align-items-center justify-content-start mb-3">
              <div className="px-2 py-1 d-flex align-items-center gap-2">
                <div className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success" onClick={() => navigateBack()}>
                  <div id="back-button">
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </div>
                  <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
                </div>
              </div>
            </div>
            <div className="container mt-0">
              <InfoBoxTutelaLegale
                rc_quotation={rc_quotation}
                insuranceRequest={insuranceRequest}
                statues={statues}
                quoteStatus={quoteStatus}
                messagesModalData={{
                  backofficeUsers, setSupportMessage, supportMessage, collectData: collectQuoteData,
                }}
                risk_quotations={risk_quotations}
              />
              {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5
                ? (
                  <>
                    <div className="documents-status-step-four">
                      <SaveBox
                        insuranceRequest={insuranceRequest}
                        quotationId={rc_quotation.id}
                        refresh={collectQuoteData}
                      />

                      <div className="row mt-3">
                        <div className="col-12">
                          <UploadDocumentsContainer>
                            <div className="d-flex align-items-center justify-content-start">
                              <NumberCurrent>1</NumberCurrent>
                              <h2>
                                Carica i
                                {' '}
                                <b>documenti</b>
                                {' '}
                                del cliente
                              </h2>
                            </div>
                            <UploadWrapper>
                              {nonSignableDocs.map((docType, index) => (
                                <NonSignableDocumentComponent
                                  key={index}
                                  insuranceRequest={insuranceRequest}
                                  documentType={docType}
                                  downloadDocument={downloadDocument}
                                  removeDocument={removeDocument}
                                  rc_quotation={rc_quotation}
                                  collectQuoteData={collectQuoteData}
                                />
                              ))}
                            </UploadWrapper>
                          </UploadDocumentsContainer>
                        </div>
                      </div>
                    </div>
                    <div className="documents-status-step-five">
                      {rc_quotation.sign_later === null && (
                        <div className="row mt-3">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SkipSignedDocs>
                              <p className="text-center">Vuoi Firmare Dopo?</p>
                              <div className="d-flex justify-content-center align-items-center gap-3">
                                <button className="btn btn-success border py-1 px-4" onClick={() => continueWithoutSignDocs()}>Si</button>
                                <button className="btn btn-danger border py-1 px-4" onClick={() => continueWithSignDocs()}>No</button>
                              </div>
                            </SkipSignedDocs>
                          </div>
                        </div>
                      )}
                      {rc_quotation.sign_later === 1 && <div className="alert alert-success mt-3 border rounded-4">Hai scelto di firmare i documenti più tardi.</div>}
                      {rc_quotation.sign_later === 0 && (
                        <div className="row mt-3">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SignatureTypeProfessione
                              quote={rc_quotation}
                              insuranceRequest={insuranceRequest}
                              collectQuoteData={collectQuoteData}
                              signableDocs={signableDocs}
                              requestedOptionalDocumentsSignable={requestedOptionalDocumentsSignable}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )
                : ''}

              {quoteStatus === 4
                && <WaitingForPaymentStatus quote={quote} rc_quotation={rc_quotation} risk_quotations={risk_quotations} insuranceRequest={insuranceRequest} collectQuoteData={collectQuoteData} hideBrokerageAndService />}

              {quoteStatus === 6
                && (
                  <div className="card-body mt-3">
                    <div className="row">
                      <div className="col-6 offset-3">
                        <div
                          className="alert alert-success"
                          role="alert"
                        >
                          <h4 className="alert-heading">
                            In Attesa Emissione
                          </h4>
                          <p>
                            Quando l'agente di backoffice carica la
                            polizza, riceverai una notifica e il
                            contratto potrà essere scaricato.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      {quoteStatus === 7
        && (
          <>
            <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
              {/* <CompletedTutelaLegaleQuoteScreen
              quote={rc_quotation}
              rc_quotation={rc_quotation}
              tutela_quotation={tutela_quotation}
              quoteStatus={quoteStatus}
              customer={insuranceRequest.customer}
              profession={insuranceRequest.professional}
              documents={[...insuranceRequest.documents, ...rc_quotation.documents]}
              downloadDocument={downloadDocument}
              collectQuoteData={collectQuoteData}
            /> */}
            </div>

            <TutelaCompletedScreen
              quoteStatus={quoteStatus}
              customer={insuranceRequest.customer}
              rc_quotation={rc_quotation}
            />

          </>
        )}
    </PurchaseStyles>
  );
}

export default PurchaseTutelaLegaleRequest;
