import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import Joyride from 'react-joyride';
import { greeniaPrivacyPolicy, modifyRequestData, updateActiveCustomer } from '../services/axios-client/axeCommons';
import CenteredPageTitle from '../common/components/CenteredPageTitle';
import PageLoading from '../common/components/elements/PageLoading';
import { engineerCodes, flagResponse, genders } from '../common/constants';
import convertToItalianDate from '../common/helpers/convertToItalianDate';
import toUniqueArrayObjects from '../common/helpers/toUniqueArrayObjects';
import InputPrivacy from '../common/components/inputs/InputPrivacy';
import ActiveClientModal from '../common/components/elements/ActiveClientModal';
import { useDebouncedEffect } from '../common/utils/useDebouncedEffect';
import generateFiscalCode from '../common/helpers/generateFiscalCode';
import getAllowedPolicyEffectDate from '../common/helpers/getAllowedPolicyEffectDate';
import {
  professionGetCustomerData,
  professionGetInitialData,
  professionSearchActiveClient,
  professionSubmitAnswers,
  professionUpdateFormData,
  resetProfessionForm,
} from '../features/forms/formsActions';
import { generalAlertError, generalAlertErrorToast } from '../common/utils/alerts';

import StorageService from '../services/StorageService';
import { FormInputCheckbox } from '../common/formInputs/FormInputCheckbox';
import { FormInputText } from '../common/formInputs/FormInputText';
import { FormInputRadio } from '../common/formInputs/FormInputRadio';
import { FormInputSelect } from '../common/formInputs/FormInputSelect';
import { FormInputDate } from '../common/formInputs/FormInputDate';
import FormReactSelectInputBirthPlace from '../common/formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../common/formInputs/FormInputAddressComplete';
import { ProfessionFormStyles } from './RcProfessionQuotes.styles';
import { CenteredDivTitle } from '../common/components/CenteredPageTitle.styles';
import { getAvailableProducts } from '../features/auth/authActions';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackRcProfession } from '../common/utils/joyrideCallbacks';
// import { RcProfessionSteps } from '../common/utils/joyrideSteps';

const Storage = new StorageService();

function RcProfession() {
  const navigate = useNavigate();
  const location = useLocation();
  const skipCheckGenderRef = useRef(null);

  const state = useSelector((store) => store.forms.profession);
  const {
    loading,
    states,
    municipalities,
    professions,
    selectedProfession,
    formData,
    dataPrivacy,
    searchResults,
    selectedClientOldData,
    selectedClient,
    selectedClientId,
  } = state;

  const highRiskBool = useRef(
    selectedProfession !== null
    && engineerCodes.includes(selectedProfession?.code),
  );

  const calculateRetroActivityBool = () => {
    const firstCondition = selectedProfession !== null
      && selectedProfession?.code === 'personale_sanitario'
      && selectedProfession?.medical_retroactivity_options
      && selectedProfession?.medical_retroactivity_options.length > 0;

    const secondCondition = selectedProfession !== null
      && selectedProfession?.retroactivity_fees
      && selectedProfession?.retroactivity_fees.length > 0;

    return firstCondition || secondCondition;
  };

  const retroactivityBool = useRef(calculateRetroActivityBool());

  const calculateBilledBool = () => (
    selectedProfession !== null
    && selectedProfession?.options
    && selectedProfession?.options.length !== 0
  );

  const billedBool = useRef(calculateBilledBool());

  useEffect(() => {
    highRiskBool.current = selectedProfession !== null
      && engineerCodes.includes(selectedProfession?.code);
    retroactivityBool.current = calculateRetroActivityBool();
    billedBool.current = calculateBilledBool();
  }, [
    selectedProfession !== null
    && engineerCodes.includes(selectedProfession?.code),
    calculateRetroActivityBool(),
    calculateBilledBool(),
  ]);

  const schema = yup.object({
    product: yup.string().required(),
    source: yup.string().required(),
    name: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Inserisci il nome!').matches(
          /^[a-zA-Z ]+$/,
          'Non sono ammessi numeri o caratteri speciali!',
        )
        : schema)),
    surname: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required('Il campo è obbligatorio. Inserisci il cognome!')
          .matches(
            /^[a-zA-Z ]+$/,
            'Non sono ammessi numeri o caratteri speciali!',
          )
        : schema)),
    date_of_birth: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required(
          'Il campo è obbligatorio. Seleziona data di nascita!',
        )
          .test('valid-date', 'La data di nascita non è valida', (value) => {
            if (!value) return true;
            const date = moment(value, 'YYYY-MM-DD', true);
            const year = date.year();
            const currentYear = new Date().getFullYear();

            if (year > currentYear - 18 || year < 1935) {
              return false;
            }
            return date.isValid();
          })
        : schema)),
    commune_of_birth_code: yup
      .string()
      .nullable()
      .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
        ? !born_abroad
          ? schema.required(
            'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
          )
          : schema
        : schema)),
    gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
    phone: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci numero di telefono!')
      .matches(
        /^[3]{1}[0-9]{8,9}$/,
        'Il numero non e corretto',
      ),
    email: yup.string().required('Il campo è obbligatorio. Inserisci Email!')
      .email('Deve essere un email valido! Rimouvi spazi vuoti!')
      .test('brokerEmail', 'Non puoi impostare la tua email', (value) => value !== Storage.getUser().email),
    residence_commune_code: yup
      .string()
      .required(
        'Il campo è obbligatorio. Seleziona provincia di residenza!',
      ),
    province_of_birth_code: yup
      .string()
      .nullable()
      .when(['gender', 'born_abroad'], ([gender, born_abroad], schema) => (gender !== 'G'
        ? !born_abroad
          ? schema.required(
            'Il campo è obbligatorio. Inserisci luogo di nascita del propietario!',
          )
          : schema
        : schema)),
    born_abroad: yup
      .boolean()
      .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
    country_of_birth_code: yup
      .string()
      .nullable()
      .when('gender', ([gender], schema) => (gender !== 'G'
        ? schema.required(
          'Il campo è obbligatorio. Seleziona il stato estero!',
        )
        : schema)),
    address: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci indirizzo!'),
    house_number: yup.string().required('Il campo è obbligatorio.').matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
    postal_code: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci codice postale!')
      .matches(/^[0-9]{5}$/, 'Il codice postale non e coretto'),
    fiscal_code: yup.string().nullable(),
    residence_province_code: yup
      .string()
      .required(
        'Il campo è obbligatorio. Seleziona provincia di residenza!',
      ),
    existing_customer: yup.boolean(),
    active_customer_id: yup.number().nullable(),
    policy_effective_date: yup
      .date()
      .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
      .required('Il campo è obbligatorio. Seleziona data decorrenza!')
      .min(new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')), 'La data è nel passato')
      .max(
        new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
        'La data è nel futuro',
      ),
    business_name: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup
          .string()
          .required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
      }),
    vat_number: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup
          .string()
          .max(11, 'La partiva iva non puo essere piu di 11 numeri!')
          .matches(/^[0-9]*$/, 'Solo numeri sono ammessi!')
          .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
      }),
    company_type: yup
      .string()
      .nullable()
      .when('gender', {
        is: 'G',
        then: () => yup
          .string()
          .required('Il campo è obbligatorio.'),
      }),
    profession: yup
      .string()
      .required('Il campo è obbligatorio. Seleziona professione!'),
    profession_code: yup.string().required(),
    profession_desc: yup.string().required(),
    billed: yup.lazy(() => {
      if (billedBool.current === true) {
        return yup
          .string()
          .required(
            'Il campo è obbligatorio. Inserisci fatturate del contraente!',
          );
      }
      return yup.string();
    }),
    billed_maximum: yup.lazy(() => {
      if (billedBool.current === true) {
        return yup
          .string()
          .required(
            'Il campo è obbligatorio. Inserisci fatturato massimo del contraente!',
          );
      }
      return yup.string();
    }),
    retroactivity: yup.lazy(() => {
      if (retroactivityBool.current === true) {
        return yup.string().required('Il campo è obbligatorio.');
      }
      return yup.string();
    }),
    retroactivity_name: yup.lazy(() => {
      if (retroactivityBool.current === true) {
        return yup.string().required('Il campo è obbligatorio.');
      }
      return yup.string();
    }),
    high_risk: yup.lazy(() => {
      if (highRiskBool.current) {
        return yup.string().required('Il campo è obbligatorio.');
      }
      return yup.string();
    }),
    ext_reviewer: yup.string(),
    extensions: yup.array(yup.string()),
    privacies: yup.array(yup.number()),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      product: 'profession',
      source: 'greenia.it',
      name: '',
      surname: '',
      date_of_birth: '',
      commune_of_birth_code: null,
      gender: '',
      phone: Storage.getUser().phone ? Storage.getUser().phone : '',
      email: '',
      residence_commune_code: '',
      province_of_birth_code: null,
      born_abroad: false,
      country_of_birth_code: 'Z000',
      address: '',
      house_number: '',
      postal_code: '',
      fiscal_code: '',
      residence_province_code: '',
      existing_customer: false,
      active_customer_id: null,
      policy_effective_date: '',

      business_name: '',
      vat_number: '',
      company_type: null,
      profession: '',
      profession_code: '',
      profession_desc: '',
      billed: '',
      billed_maximum: '',
      retroactivity: '',
      retroactivity_name: '',
      high_risk: '',
      ext_reviewer: 'no',
      extensions: [],
      privacies: [],
    },
  });

  const userData = watch();

  const updateUserData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const [searchClient, setSearchClient] = useState('');

  const onSearchChange = (value) => {
    setSearchClient(value);

    if (value.length === 0) {
      professionUpdateFormData({
        searchResults: [],
      });
    }
  };

  const getCustomerData = async (customerId) => {
    professionGetCustomerData(customerId);
  };

  const closeClientModal = () => {
    setSearchClient('');
    professionUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newQuoteProfession') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newQuoteProfession')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('newQuoteProfession');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    professionGetInitialData();

    return () => {
      resetProfessionForm();
    };
  }, []);

  useEffect(() => {
    if (location.state !== null && location.state.activeCustomerId !== undefined) {
      getCustomerData(location.state.activeCustomerId);
    }
  }, []);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (userData.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('name', null);
      setValue('surname', null);
    } else if (['M', 'F'].includes(userData.gender) && userData.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [userData.gender, userData.date_of_birth]);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: userData.name,
      surname: userData.surname,
      gender: userData.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (userData.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === userData.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === userData.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = userData.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const confirmActiveClient = async (selectedClient) => {
    if (
      JSON.stringify(selectedClientOldData) !== JSON.stringify(selectedClient)
    ) {
      await updateActiveCustomer(selectedClientId, {
        ...selectedClient,
        date_of_birth: selectedClient.gender !== 'G' ? convertToItalianDate(selectedClient.date_of_birth) : null,
      });
    }

    if (selectedClient.gender === 'G') {
      updateUserData({
        born_abroad: false,
        fiscal_code: null,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: null,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        surname: null,
        address: selectedClient.address,
        date_of_birth: null,
        email: selectedClient.email,
        province_of_birth_code: null,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: null,
        commune_of_birth_code: null, // city_of_birth_code,
        business_name: selectedClient.business_name,
        vat_number: selectedClient.vat_number,
        company_type: selectedClient.company_type,
        active_customer_id: selectedClientId,
      });
    } else {
      updateUserData({
        born_abroad:
          selectedClient.born_abroad !== null
            ? selectedClient.born_abroad
            : false,
        fiscal_code: selectedClient.fiscal_code,
        gender: selectedClient.gender,
        house_number: selectedClient.house_number,
        name: selectedClient.name,
        phone: selectedClient.phone,
        postal_code: selectedClient.postal_code,
        surname: selectedClient.surname,
        address: selectedClient.address,
        date_of_birth: selectedClient.date_of_birth,
        email: selectedClient.email,
        province_of_birth_code: selectedClient.province_of_birth_code,
        residence_commune_code: selectedClient.residence_city_code, // residence_city_code
        residence_province_code: selectedClient.residence_province_code,
        country_of_birth_code: selectedClient.country_of_birth_code,
        commune_of_birth_code: selectedClient.city_of_birth_code, // city_of_birth_code,
        business_name: selectedClient.business_name,
        vat_number: selectedClient.vat_number,
        company_type: selectedClient.company_type,
        active_customer_id: selectedClientId,
      });
    }

    setSearchClient('');
    professionUpdateFormData({
      selectedClient: null,
      selectedClientOldData: null,
      selectedClientId: null,
      searchResults: [],
    });
  };

  const searchActiveClient = (searchClient) => () => {
    if (searchClient.length === 0) return;
    professionSearchActiveClient(searchClient);
  };

  useDebouncedEffect(searchActiveClient(searchClient), [searchClient], 400);

  const handleChangeProfession = (value) => {
    const profession = professions.find(
      (item) => parseInt(item.id) === parseInt(value),
    );
    const newArrayExtensions = [];
    if (profession) {
      profession.extensions.forEach((extension) => {
        if (extension.price === 0.0) {
          newArrayExtensions.push(extension.code);
        }
      });
    }
    professionUpdateFormData({
      selectedProfession: profession,
    });
    updateUserData({
      profession: value,
      profession_code: profession?.code,
      profession_desc: profession?.name,
      extensions: newArrayExtensions,
    });
    Storage.saveSelectedProfession(profession);
  };

  const handleChangeScope = () => {
    // const scope = selectedProfession?.profession_scopes.find(
    //   (item) => parseInt(item.id) === parseInt(value),
    // );
  };

  const handleChangeSpecialization = () => {
    // const specialization = selectedProfession?.medical_specializations.find(
    //   (item) => parseInt(item.id) === parseInt(value),
    // );
  };
  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...userData }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitForm = (userData) => {
    const { error, message } = validateDataPrivacy();

    if (error) {
      generalAlertError(message);
      return;
    }

    const _userData = { ...userData };

    if (_userData.born_abroad && _userData.country_of_birth_code === 'Z000') {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Non puoi selezionare Italia come stato estero!');
      return;
    }

    if (_userData.born_abroad && (_userData.country_of_birth_code === null || _userData.country_of_birth_code === '' || _userData.country_of_birth_code == undefined)) {
      window.scrollTo(0, 0);
      generalAlertErrorToast('Devi selezionare uno stato estero!');
      return;
    }

    if (userData.date_of_birth !== '' && userData.date_of_birth !== null) {
      _userData.date_of_birth = convertToItalianDate(userData.date_of_birth);
    } else {
      _userData.date_of_birth = null;
    }
    _userData.policy_effective_date = convertToItalianDate(
      moment(userData.policy_effective_date).format('YYYY-MM-DD'),
    );
    _userData.fiscal_code = userData.gender !== 'G' ? getFiscalCode(userData.date_of_birth) : null;
    _userData.high_risk = userData.high_risk ? 'si' : 'no';

    professionSubmitAnswers(_userData, navigate);
  };

  useEffect(() => {
    if (selectedProfession !== null && userData.retroactivity !== '') {
      const retro = selectedProfession.retroactivity_fees.find(
        (fee) => fee.id == userData.retroactivity,
      );

      if (retro === undefined) {
        updateUserData({
          retroactivity: '',
          retroactivity_name: '',
        });

        return;
      }

      updateUserData({
        retroactivity_name: retro.name,
      });
    }
  }, [selectedProfession, userData.retroactivity]);

  // renderer
  const showOptions = (options) => {
    const billedAmountOptions = [];
    let billedAmountOptionsMax = [];
    options.forEach((option) => {
      if (billedAmountOptions.indexOf(option.fatturato) < 0) {
        billedAmountOptions.push(option.fatturato);
      }
      if (userData.billed !== '') {
        if (billedAmountOptionsMax.indexOf(option.massimale) < 0 && option.fatturato === userData.billed) {
          billedAmountOptionsMax.push(option.massimale);
        }
      }
    });

    if (userData.billed_maximum !== '') {
      if (!billedAmountOptionsMax.includes(Number.parseInt(userData.billed_maximum))) {
        setValue('billed_maximum', '', {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }

    billedAmountOptionsMax = billedAmountOptionsMax.map((v) => ({
      id: v,
      name: `${v} €`,
    }));

    return (
      <>
        <FormInputSelect
          label="Fatturato anno in corso?"
          options={billedAmountOptions}
          registration={register('billed')}
          error={errors.billed?.message}
          valid={isValid('billed')}
        />
        <FormInputSelect
          label="Seleziona Massimale"
          registration={register('billed_maximum')}
          options={billedAmountOptionsMax}
          error={errors.billed_maximum?.message}
          valid={isValid('billed_maximum')}
        />
      </>
    );
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...userData.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateUserData({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...userData.privacies];
    answersNewArray.push(item.id);
    updateUserData({
      privacies: answersNewArray,
    });
  };

  useEffect(() => {
    const callAsync = async () => {
      if (
        location.state !== null
        && location.state.requestToken !== undefined
      ) {
        skipCheckGenderRef.current = true;
        const {
          data: { data: { userData } },
        } = await modifyRequestData(location.state.requestToken);
        updateUserData(userData);
        handleChangeProfession(parseInt(userData.profession));
      }
    };

    if (professions.length !== 0) {
      callAsync();
    }
  }, [professions]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {/* <Joyride
        steps={RcProfessionSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackRcProfession(data, RcProfessionSteps, setState)}
        showSkipButton
        showProgress
        disableScrolling
      /> */}
      <ProfessionFormStyles onSubmit={handleSubmit(submitForm)}>
        <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3">
          <CenteredPageTitle title="Completa i dati" />
        </CenteredDivTitle>
        {/* {localStorage.getItem('newQuoteProfession') === 'done' && (
          <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
            <button className="btn btn-success px-2 py-1">Tutorial</button>
          </div>
        )} */}

        <div className="profession-tutorial">

          {userData.gender !== 'G' ? (
            <div>
              <FormInputCheckbox
                registration={register('existing_customer')}
                label=" Cliente Esistente?"
              />
              {userData.existing_customer && (
                <div className="d-flex align-items-start justify-content-center gap-2">
                  <div className="position-relative">
                    <FormInputText
                      placeholder="Nome cliente..."
                      onChange={(value) => onSearchChange(value)}
                      value={searchClient}
                    />
                    {searchResults.length > 0 && (
                      <div
                        className="position-absolute"
                        style={{
                          top: '80%', left: '0%', right: '0%', zIndex: 50,
                        }}
                      >
                        <ul className="list-group">
                          {searchResults.map((customer, index) => (
                            <li
                              className="list-group-item list-group-item-dark"
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() => getCustomerData(customer.id)}
                            >
                              {customer.name.toUpperCase() + (customer.fiscal_code !== null ? ` - (${customer.fiscal_code})` : '')}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-questionnaire py-1 px-2 "
                    style={{
                      marginTop: 10,
                      display: 'block',
                      width: '150px',
                      border: '3px solid',
                    }}
                  >
                    Cerca cliente
                  </button>
                </div>
              )}

              {selectedClient !== null ? (
                <ActiveClientModal
                  closeClientModal={closeClientModal}
                  municipalities={municipalities}
                  states={states}
                  formData={formData}
                  client={selectedClient}
                  genders={genders}
                  confirmActiveClient={confirmActiveClient}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          <div className="form-container">
            <FormInputRadio
              options={genders}
              label="Il contraente è"
              name="gender"
              registration={register('gender')}
              error={errors.gender?.message}
            />

            {userData.gender === 'G' ? (
              <div>
                <FormInputText
                  placeholder="Ragione Sociale"
                  label="Ragione Sociale"
                  registration={register('business_name')}
                  error={errors.business_name?.message}
                  valid={isValid('business_name')}
                />
                <FormInputText
                  placeholder="Partita Iva"
                  label="Partita Iva"
                  registration={register('vat_number')}
                  error={errors.vat_number?.message}
                  valid={isValid('vat_number')}
                />
                <FormInputSelect
                  label="Tipologia azienda"
                  registration={register('company_type')}
                  error={errors.company_type?.message}
                  valid={isValid('company_type')}
                  placeholder="-Seleziona-"
                  options={formData.company_types}
                />
              </div>
            ) : (
              <div>
                <FormInputText
                  placeholder="Nome"
                  label="Nome"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
                <FormInputText
                  placeholder="Cognome"
                  label="Cognome"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
                <FormInputDate
                  label="Data di nascita"
                  registration={register('date_of_birth')}
                  minDate="1935-01-01"
                  maxDate="2021-01-01"
                  error={errors.date_of_birth?.message}
                  valid={isValid('date_of_birth')}
                />
                <FormReactSelectInputBirthPlace
                  states={states}
                  options={municipalities}
                  label="Luogo di nascita"
                  name="commune_of_birth_code"
                  values={{
                    commune_of_birth_code: userData.commune_of_birth_code,
                    province_of_birth_code: userData.province_of_birth_code,
                    born_abroad: userData.born_abroad,
                    country_of_birth_code: userData.country_of_birth_code,
                  }}
                  onChange={(value) => updateUserData({
                    commune_of_birth_code: value.commune_of_birth_code,
                    province_of_birth_code: value.province_of_birth_code,
                    born_abroad: value.born_abroad,
                    country_of_birth_code: value.country_of_birth_code,
                  })}
                  valid={isValid('commune_of_birth_code')}
                  error={errors.commune_of_birth_code?.message}
                  valid_country_of_birth_code={isValid('country_of_birth_code')}
                  error_country_of_birth_code={errors.country_of_birth_code?.message}
                />
                <FormInputSelect
                  placeholder="Stato civile"
                  label="Stato civile"
                  registration={register('civil_status_id')}
                  options={formData.marital_statuses}
                  error={errors.civil_status_id?.message}
                  valid={isValid('civil_status_id')}
                />
                <FormInputSelect
                  placeholder="Titolo di studio"
                  label="Titolo di studio"
                  registration={register('education_level_id')}
                  options={formData.qualifications}
                  error={errors.education_level_id?.message}
                  valid={isValid('education_level_id')}
                />
              </div>
            )}

            <FormInputAddressComplete
              municipalities={municipalities}
              formData={userData}
              isValid={isValid}
              errors={errors}
              register={register}
              updateFormData={updateUserData}
              addressId="autocomplete-address-contractor"
            />

            <FormInputDate
              minDate={getAllowedPolicyEffectDate('min')}
              maxDate={getAllowedPolicyEffectDate('max')}
              registration={register('policy_effective_date')}
              label="Data di inizio della copertura della polizza"
              paragraph="Date valide: da oggi a 30 giorni da oggi"
              error={errors.policy_effective_date?.message}
              valid={isValid('policy_effective_date')}
            />
            <FormInputSelect
              label="Che lavoro fai ?"
              placeholder="- Seleziona -"
              options={professions.filter((p) => p.id !== 13)}
              value={userData.profession}
              onChange={handleChangeProfession}
              error={errors.profession?.message}
              valid={isValid('profession')}
            />

            {selectedProfession !== null
              && selectedProfession?.profession_scopes.length > 0 && (
                <FormInputSelect
                  label="Ambito di polizza"
                  value=""
                  options={selectedProfession?.profession_scopes}
                  onChange={handleChangeScope}
                />
            )}
            {selectedProfession !== null
              && selectedProfession?.profession_scopes.length > 0
              && selectedProfession?.medical_specializations.length > 0 && (
                <FormInputSelect
                  label="Specializzazione"
                  value=""
                  options={selectedProfession?.medical_specializations}
                  onChange={handleChangeSpecialization}
                />
            )}
            {selectedProfession !== null
              && selectedProfession?.code === 'personale_sanitario'
              && selectedProfession?.medical_retroactivity_options
              && selectedProfession?.medical_retroactivity_options.length > 0 && (
                <FormInputSelect
                  label="Retroattività"
                  registration={register('retroactivity')}
                  options={selectedProfession?.medical_retroactivity_options}
                  error={errors.retroactivity?.message}
                  valid={isValid('retroactivity')}
                />
            )}
            {selectedProfession !== null
              && engineerCodes.includes(selectedProfession?.code) && (
                <FormInputRadio
                  label="Svolge attività ad alto rischio quale Soil Engeneers (ingegneria del
                    suolo), pilling (palificazione di sostegno), cladding (rivestimenti),
                    ponti, gallerie, dighe o piscine e i relativi fatturati superano il
                    50% del fatturato totale?"
                  options={flagResponse}
                  name="high_risk"
                  registration={register('high_risk')}
                  error={errors.high_risk?.message}
                />
            )}
            {selectedProfession !== null
              && selectedProfession?.retroactivity_fees
              && selectedProfession?.retroactivity_fees.length > 0 && (
                <FormInputSelect
                  label="Retroattività"
                  options={toUniqueArrayObjects(
                    selectedProfession?.retroactivity_fees,
                    'id',
                  )}
                  registration={register('retroactivity')}
                  error={errors.retroactivity?.message}
                  valid={isValid('retroactivity')}
                />
            )}
            {selectedProfession !== null && (
              <div>
                <label className="f-label">Estensioni</label>
                {selectedProfession?.extensions.map(
                  (extension, i) => extension.price === 0.0 && (
                    <p key={i} className="text-center">
                      -
                      {' '}
                      {extension.name}
                    </p>
                  ),
                )}
              </div>
            )}
            {/* <InputExtensions label={"Estensioni"} profession={selectedProfession} extensionExists={extensionExists} addExtension={addExtension} removeExtension={removeExtension} /> */}
            {selectedProfession !== null
              && selectedProfession?.options
              && selectedProfession?.options.length !== 0
              && showOptions(selectedProfession?.options)}

            {userData?.retroactivity !== ''
              && selectedProfession?.options.length === 0 && (
                <h4 className="text-center text-danger">
                  Non puoi creare un preventivo con il valore selezionato
                </h4>
            )}
            <FormInputText
              registration={register('email')}
              label="E-mail"
              error={errors.email?.message}
              valid={isValid('email')}
            />

            <FormInputText
              registration={register('phone')}
              label="Phone"
              paragraph="Numeri di rete fissa non accettati dalle aziende"
              error={errors.phone?.message}
              valid={isValid('phone')}
            />

          </div>
        </div>

        <div className="second-profession-step">
          <h5 className="text-center mt-2">Informativa Privacy e IVASS</h5>
          <div className="text-center text-decoration-underline mt-2 mb-2">
            <a onClick={() => greeniaPrivacyPolicy()} style={{ cursor: 'pointer' }}>
              <small className="text-center">Greenia Privacy Policy</small>
            </a>
          </div>
          {dataPrivacy.map((privacy, i) => (
            <InputPrivacy
              required={privacy.required}
              label={privacy.content}
              name={privacy.type}
              id={privacy.type}
              key={i}
              checked={userData?.privacies?.includes(privacy.id)}
              onChange={(value) => privacyItemChangeHandler(value, privacy)}
            />
          ))}
        </div>

        <div className="footer-buttons button-profession-step">
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-questionnaire">
              Vai a preventivo
            </button>
          </div>
        </div>
      </ProfessionFormStyles>
    </>
  );
}

export default function () {
  const { loading, availableProducts } = useSelector((store) => store.auth.user_products);

  useEffect(() => {
    getAvailableProducts();
  }, []);

  if (loading === false && !availableProducts.includes(4)) {
    return <Navigate to="/quote" />;
  }

  return <RcProfession />;
}
