import React from 'react';
import { lastTenYears, violationsResponsability } from '../../constants';

function ViolationsTable({ violationData }) {
  return (
    <table className="table table-bordered table-striped table-striped-columns mt-2">
      <thead>
        <tr>
          <th>
            <small>Responsabilita</small>
          </th>
          {lastTenYears.map((year, index) => (
            <th key={index}>
              <small>{year.value}</small>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {violationsResponsability.map((violation, index) => (
          <tr key={index}>
            <td>
              <small style={{ fontSize: '.775em' }}>{violation.name}</small>
            </td>
            {violationData[violation.id].map((item, index) => (
              <td key={index} className="text-center">
                <small>{item.value}</small>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ViolationsTable;
