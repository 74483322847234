import React, { useEffect } from 'react';
import ErrorBg from '../assets/images/Error-bg.svg';

function ErrorComponentFallback() {
  const redirect = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    setTimeout(() => {
      redirect();
    }, 3000);
  }, []);
  return (
    <div className="container position-relative">
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div>
          <h1 className="h1 fw-bold m-0 text-center">Opps!</h1>
          <h2 className="h5 fw-semibold my-2 text-center">
            Ci Stiamo Lavorando! Verrai reindirizzato alla pagina iniziale!
          </h2>
        </div>
        <img src={ErrorBg} alt="Greenia" className="img-fluid w-50" />
        <div>
          <button
            className="btn btn-success rounded btn-lg"
            onClick={() => redirect()}
          >
            Home
          </button>
          <button
            className="btn btn-outline-success btn-lg ms-2"
            onClick={() => window.location.reload()}
          >
            Ricarica Pagina
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorComponentFallback;
