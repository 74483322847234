import React from 'react';
import QuotationCardResult from '../../../reusable/QuotationCardResult';

function ProcessingQuoteSelectedProfession({
  processingQuote,
  profession,
  requestInfo,
}) {
  return (
    <>
      {processingQuote && (
        <div className="col-12 mb-2">
          <QuotationCardResult
            processingQuote={processingQuote}
            profession={profession}
            requestInfo={requestInfo}
          />
        </div>
      )}
      {processingQuote === null && (
        <div className="alert alert-secondary">Seleziona Quotazione!</div>
      )}
    </>
  );
}

export default ProcessingQuoteSelectedProfession;
