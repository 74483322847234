import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { generalAlertError } from '../../common/utils/alerts';
import {
  listQuotationsPurchasedRequests,
  resetQuotationsPurchasedRequests,
} from '../../features/quotations/quotationsActions';
import { PurchasedQuotationsStyles } from './PurchasedQuotations.styles';
import Pagination from '../../common/components/elements/Pagination';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
import { quotationTypes } from '../../common/constants';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackPurchasedQuotes } from '../../common/utils/joyrideCallbacks';
// import { purchaseQuotationsSteps } from '../../common/utils/joyrideSteps';

function PurchasedQuotations() {
  const state = useSelector((store) => store.quotations.quotations_purchased);

  const {
    loading,
    quotations: savedQuotes,
    page_number,
    total_pages,
    error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    plate: '',
    date_from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });
  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('quotazioniAquistati') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('quotazioniAquistati')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('quotazioniAquistati');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   purchaseQuotationsSteps(savedQuotes, setSteps);
  // }, [savedQuotes]);

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    listQuotationsPurchasedRequests(1, search);
  };

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  useEffect(() => {
    listQuotationsPurchasedRequests(1, search);

    return () => {
      resetQuotationsPurchasedRequests();
    };
  }, []);

  return error === null ? (
    <PurchasedQuotationsStyles>
      {/* <Joyride
            steps={steps}
            continuous
            showBeacon={showBeacon}
            locale={{
              back: 'Indietro',
              close: 'Chiudi',
              last: 'Fine',
              next: 'Prossima',
              open: 'Apri il modale',
              restart: 'Riavvia il tour ',
              skip: 'Salta',
            }}
            styles={{
              options: joyrideOptions,
              buttonNext: {
                backgroundColor: '#00a651',
                outline: 'none',
              },
              buttonBack: {
                color: '#00a651',
                outline: 'none',
              },
              beaconInner: {
                backgroundColor: '#00a651',
              },
              beaconOuter: {
                borderColor: '#00a651',
                backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
              },
              buttonSkip: {
                outline: 'none',
              },
              buttonClose: {
                outline: 'none',
              },
            }}
            run={run}
            callback={(data) => handleCallbackPurchasedQuotes(data, steps, setState)}
            showSkipButton
            showProgress
          /> */}
      <h5 className="mb-4">Lista Polizze Emesse</h5>
      {/* {localStorage.getItem('quotazioniAquistati') === 'done' && (
            <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
              <button className="btn btn-success px-2 py-1">Tutorial</button>
            </div>
          )} */}
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-8 col-md-7 col-lg-8">
          <div className="mt-3 quotazioni-aquistati-one">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div className="spinner-border" role="status" />
                <span>Attendere prego...</span>
              </div>
            ) : (
              <ul className="list-group">
                {savedQuotes.length > 0 ? (
                  savedQuotes.map((quote, index) => (
                    <li
                      key={index}
                      className="quotazioni-aquistati-two list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-start align-items-xl-center"
                    >
                      <div className="me-auto mb-0">
                        {quote.customer && (
                          <div className="fw-bold">
                            Cliente:
                            {' '}
                            {quote.customer.gender === 'G'
                              ? quote.customer.business_name
                              : `${quote.customer.name} ${quote.customer.surname}`}
                          </div>
                        )}
                        {quote.product && (
                          <>
                            Prodotto:
                            {' '}
                            {quote.quote_type !== 'res_civile'
                              ? quotationTypes.find(
                                (type) => type.id === quote.quote_type,
                              ).name
                              : quote.product.name}
                            {' '}
                            {quote.product_id == 5
                              ? `(${
                                quote.infortuni && quote.infortuni.company
                              })`
                              : ''}
                          </>
                        )}
                        {quote.vehicle && (
                          <>
                            <br />
                            Targa:
                            {' '}
                            {quote.vehicle.vehicle_plate}
                          </>
                        )}
                        {quote.moto && (
                          <>
                            <br />
                            Targa:
                            {' '}
                            {quote.moto.moto_plate}
                          </>
                        )}
                        {quote.professional && (
                          <>
                            <br />
                            Professione:
                            {' '}
                            {quote.professional.profession_desc}
                          </>
                        )}
                        {quote.infortuni && (
                          <>
                            <br />
                            Infortuni:
                            {' '}
                            {quote.infortuni.pack_type}
                            {' '}
                            -
                            {quote.infortuni.name}
                          </>
                        )}
                        <br />
                        <small>
                          <strong>
                            Data Emissione:
                            {' '}
                            {new Date(
                              quote.emission_date !== null
                                ? quote.emission_date
                                : quote.created_at,
                            ).toLocaleDateString('it-IT', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                          </strong>
                        </small>
                      </div>

                      <div className="me-4 my-2">
                        <span className="badge text-bg-dark">
                          {quote.quotation_status.name}
                        </span>
                      </div>

                      {quote.product_id == 1
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/auto`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 1
                        && quote.quote_type === 'assistenza_stradale' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/assistenza-stradale`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 1
                        && quote.quote_type === 'cristalli' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/cristalli`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                      {quote.product_id == 1
                        && quote.quote_type === 'collisione' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/collisione`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                      {quote.product_id == 1
                        && quote.quote_type === 'infortuni_conducente' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/infortuni-conducente`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 2
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/moto`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 3
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/autocarro`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 5 && (
                        <Link
                          to={`/quotes/metlife/purchase/${quote.request_token}`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}
                      {quote.product_id == 4
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/profession`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {quote.product_id == 6 && (
                        <Link
                          to={`/quotes/infodrive/purchase/${quote.request_token}`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}
                      {quote.product_id == 8
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/travel`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}

                      {(quote.product_id == 9
                        || quote.quote_type === 'tutela_legale') && (
                        <Link
                          to={`/quotes/purchased/${quote.quotation_id}/quotation/tutela-legale`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}

                      {quote.product_id == 10 && (
                        <Link
                          to={`/quotes/purchased/${quote.quotation_id}/quotation/commercial`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}

                      {quote.product_id == 11 && (
                        <Link
                          to={`/quotes/purchased/${quote.quotation_id}/quotation/casa`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}
                      {quote.product_id == 12 && (
                        <Link
                          to={`/quotes/purchased/${quote.quotation_id}/quotation/salute`}
                        >
                          <span className="badge btn-to-quote px-4 py-2 rounded-3">
                            Dati di polizza
                          </span>
                        </Link>
                      )}
                      {quote.product_id == 13
                        && quote.quote_type === 'res_civile' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/ancillari-auto`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                      {quote.product_id == 13
                        && quote.quote_type === 'cristalli' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/cristalli`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                      {quote.product_id == 13
                        && quote.quote_type === 'collisione' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/collisione`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                      {quote.product_id == 13
                        && quote.quote_type === 'infortuni_conducente' && (
                          <Link
                            to={`/quotes/purchased/${quote.quotation_id}/quotation/infortuni-conducente`}
                          >
                            <span className="badge btn-to-quote px-4 py-2 rounded-3">
                              Dati di polizza
                            </span>
                          </Link>
                      )}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item d-flex justify-content-between align-items-start quotazioni-no-result">
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Nessuna quotazione trovata!</div>
                    </div>
                  </li>
                )}
              </ul>
            )}
            <div className="mt-2">
              <Pagination
                total_pages={total_pages}
                current_page={page_number}
                onClick={listQuotationsPurchasedRequests}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 col-lg-3 col-xl-3 p-3 quotation-filters quotazioni-aquistati-three">
        <form
          onSubmit={searchHandler}
          className="d-flex align-items-end justify-content-center flex-column"
        >
          <input
            type="text"
            name="search"
            className="search-input form-control mb-2"
            value={search.search}
            onChange={handleChange}
            placeholder="Ricerca quotazioni aquistati..."
          />
          <input
            type="text"
            name="plate"
            className="search-plate form-control mb-2"
            value={search.plate}
            onChange={handleChange}
            placeholder="Ricerca targa..."
          />
          <div className="w-100">
            <label htmlFor="date_from">Data emissione da</label>
            <input
              type="date"
              name="date_from"
              onChange={handleChange}
              value={search.date_from}
              className="date-input form-control mb-2"
              placeholder="Data emissione da"
              lang="it-IT"
            />
          </div>
          <div className="w-100">
            <label htmlFor="date_to">Data emissione al</label>
            <input
              type="date"
              name="date_to"
              className="date-input form-control mb-0"
              onChange={handleChange}
              value={search.date_to}
              min={search.date_from}
              placeholder="Data emissione al"
              lang="it-IT"
            />
          </div>
          <button type="submit" className="search-btn mt-2">
            Cerca
          </button>
        </form>
      </div>
    </PurchasedQuotationsStyles>
  ) : (
    <ErrorComponent error={error} />
  );
}

export default PurchasedQuotations;
