import React, { useEffect, useState } from 'react';
import {
  ButtonContainer,
  ImageContainer,
  InputCircle,
  InputContainer,
  InputEmail,
  InputText,
  MainTitle,
  NumberCurrent,
  SelectEmailContainer,
  SendEmailContainer,
  SignatureDigitalContainer,
  SignatureDigitalSentContainer,
  SignatureInfoWrapper,
  SignaturePaperContainer,
  SignatureTypeWrapper,
  TextContainer,
  TitleButtonsWrapper,
  WarningContainer,
} from './SignatureType.styles';
import warning from '../../../../assets/redesign_icons/warning-icon.svg';
import arrowGreen from '../../../../assets/redesign_icons/arrow-down.svg';
import {
  autoReminderToSignDocs,
  autoResendInvalidSignedDocs,
  autoSelectSignMethod,
  // autoUpdateState,
  autocarroReminderToSignDocs,
  autocarroResendInvalidSignedDocs,
  autocarroSelectSignMethod,
  // autocarroUpdateState,
} from '../../../../features/purchases/purchasesActions';
import AutoDocuments from './Documents/AutoDocuments';

function SignatureType({
  quote,
  insuranceRequest,
  collectQuoteData,
  requestedOptionalDocumentsSignable,
  signableDocs,
}) {
  const [digitalSign, setDigitalSign] = useState(null);

  const [signatureMail, setSignatureMail] = useState('');
  const [selectedMail, setSelectedMail] = useState(null);

  const checkIfAllSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 1),
      ...quote.documents.filter((d) => d.signable_document === 1),
    ];

    const signaleDocTypeIds = signableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (signaleDocTypeIds.includes(document.document_type_id) && document.signed_document_filename === null) {
        allUploaded = false;
      }
    }

    return allUploaded;
  };

  // const checkAreAnySignedDocsUploaded = (quote) => {
  //   if (quote.documents.find((doc) => doc.signable_document === 1 && doc.signed_document_filename)) {
  //     return true;
  //   }

  //   if (insuranceRequest.documents.find((doc) => doc.signable_document === 1 && doc.signed_document_filename)) {
  //     return true;
  //   }

  //   if (quote.document_requests.find((doc) => doc.document.signable_document === 1 && doc.document.signed_document_filename)) {
  //     return true;
  //   }

  //   if (insuranceRequest.document_requests.find((doc) => doc.document.signable_document === 1 && doc.document.signed_document_filename)) {
  //     return true;
  //   }

  //   return false;
  // };

  // const selectSignatureMethod = (method) => {
  //   if (method === 0) {
  //     if (quote.product_id == 1) {
  //       autoUpdateState({
  //         signMethod: method,
  //       });
  //       if (quote.initial_signature_type === null) {
  //         autoSelectSignMethod(method, quote.id, collectQuoteData, signatureMail);
  //       }

  //       if (quote.initial_signature_type === 1) {
  //         setDigitalSign(true);
  //       }

  //       if (quote.initial_signature_type === 0 && checkAreAnySignedDocsUploaded(quote) === false) {
  //         setDigitalSign(digitalSign === null ? false : digitalSign === false ? null : !digitalSign);
  //       }
  //     }

  //     if (quote.product_id == 3) {
  //       autocarroUpdateState({
  //         signMethod: method,
  //       });
  //       if (quote.initial_signature_type === null) {
  //         autocarroSelectSignMethod(method, quote.id, collectQuoteData, signatureMail);
  //       }

  //       if (quote.initial_signature_type === 1) {
  //         setDigitalSign(true);
  //       }

  //       if (quote.initial_signature_type === 0 && checkAreAnySignedDocsUploaded(quote) === true) {
  //         setDigitalSign(digitalSign === null ? false : digitalSign === false ? null : !digitalSign);
  //       }
  //     }
  //   }
  //   if (method === 1) {
  //     if ((quote.initial_signature_type === 0 || quote.initial_signature_type === null) && checkAreAnySignedDocsUploaded(quote) === true) {
  //       setDigitalSign(false);
  //     }

  //     if ((quote.initial_signature_type === 0 || quote.initial_signature_type === null) && checkAreAnySignedDocsUploaded(quote) === false) {
  //       setDigitalSign(!digitalSign);
  //     }
  //   }
  // };

  // ? SelectedMail = 1 -> Use Current Customer Mail
  // ? SelecedMail = 2 -> Use new Email
  const handleSelectedMail = (val) => {
    if (val === 1) {
      setSelectedMail(val);
      setSignatureMail(insuranceRequest.customer.email);
    } else {
      setSignatureMail('');
      setSelectedMail(2);
    }
  };

  //! MANUAL => 0, DIGITAL => 1
  const selectedMethod = () => {
    if (quote.product_id == 1) {
      autoSelectSignMethod(
        digitalSign === true ? 1 : 0,
        quote.id,
        collectQuoteData,
        signatureMail,
      );
    }
    if (quote.product_id == 3) {
      autocarroSelectSignMethod(
        digitalSign === true ? 1 : 0,
        quote.id,
        collectQuoteData,
        signatureMail,
      );
    }
  };

  const remindClientToSign = () => {
    if (quote.product_id == 1) {
      autoReminderToSignDocs(quote.id, collectQuoteData);
    }

    if (quote.product_id == 3) {
      autocarroReminderToSignDocs(quote.id, collectQuoteData);
    }
  };

  const resendInvalidSignedDocs = () => {
    if (quote.product_id == 1) {
      autoResendInvalidSignedDocs(quote.id, collectQuoteData);
    }

    if (quote.product_id == 3) {
      autocarroResendInvalidSignedDocs(quote.id, collectQuoteData);
    }
  };

  useEffect(() => {
    if (quote.initial_signature_type === 0) {
      setDigitalSign(false);
    }

    if (quote.initial_signature_type === 1) {
      setDigitalSign(true);
    }
  }, [quote]);

  return (
    <SignatureTypeWrapper className={checkIfAllSignableDocsAreUploaded() === false ? 'document-signed-uploaded' : ''}>
      <TitleButtonsWrapper>
        <div className="d-flex align-items-center justify-content-start">
          <NumberCurrent>2</NumberCurrent>
          <MainTitle>
            {' '}
            Firma i documenti del
            {' '}
            <b>cliente</b>
          </MainTitle>
        </div>
        <ButtonContainer>
          {/* <ButtonDigital className={digitalSign === true ? 'active-signature' : ''} onClick={quote.status === 5 ? () => { } : () => selectSignatureMethod(1)}>Firma Digitale</ButtonDigital> */}
          {/* <ButtonManual className={digitalSign === false ? 'active-signature' : ''} onClick={quote.status === 5 ? () => { } : () => selectSignatureMethod(0)}>Cartacea</ButtonManual> */}
        </ButtonContainer>
      </TitleButtonsWrapper>
      {digitalSign === null ? (
        <SignatureInfoWrapper>
          <TextContainer>
            <h3>Seleziona uno dei tipi di firma sullo switcher sopra.</h3>
            <WarningContainer>
              <img src={warning} />
              {' '}
              <p>
                Fai attenzione, quando continui con uno dei tipi di firma non
                puoi modificarlo in seguito
              </p>
            </WarningContainer>
          </TextContainer>
          <ImageContainer>
            <img src={arrowGreen} alt="" />
          </ImageContainer>
        </SignatureInfoWrapper>
      ) : (
        ''
      )}
      {digitalSign === false && (
        <SignaturePaperContainer>
          <AutoDocuments
            signable={1}
            insuranceRequest={insuranceRequest}
            quote={quote}
            collectQuoteData={collectQuoteData}
            requestedOptionalDocumentsSignable={
              requestedOptionalDocumentsSignable
            }
            signableDocs={signableDocs}
          />
        </SignaturePaperContainer>
      )}
      {(digitalSign || quote.initial_signature_type === 1) && (
        <>
          {quote.initial_signature_type === 1
          && (quote.documents.find((doc) => doc.is_signed_valid === 0)
            || insuranceRequest.documents.find(
              (doc) => doc.is_signed_valid === 0,
            )) ? (
              <SignatureDigitalSentContainer>
                <WarningContainer>
                  <img src={warning} />
                  {' '}
                  <p>In uno o più documenti la firma non è valida.</p>
                </WarningContainer>
                <button onClick={() => resendInvalidSignedDocs()}>
                  Inviare per rifirmare
                </button>
              </SignatureDigitalSentContainer>
            ) : (
              ''
            )}
          {quote.initial_signature_type === 1
          && (quote.documents.find((doc) => doc.is_signed_valid === null)
            || insuranceRequest.documents.find(
              (doc) => doc.is_signed_valid === null,
            )) ? (
              <SignatureDigitalSentContainer>
                <WarningContainer>
                  <img src={warning} />
                  {' '}
                  <p>E stata inviata un'e-mail al cliente!</p>
                </WarningContainer>
                <button onClick={() => remindClientToSign()}>
                  Ricorda di firmare
                </button>
              </SignatureDigitalSentContainer>
            ) : (
              ''
            )}

          {digitalSign === true
          && (quote.initial_signature_type === 0
            || quote.initial_signature_type === null) ? (
              <>
                <SignatureDigitalContainer>
                  <h3>
                    Con la Firma digitale firmerai i tuoi documenti tramite email.
                    Inserisci una nuova email o usa quella che hai
                  </h3>
                  <SelectEmailContainer>
                    <InputContainer onClick={() => handleSelectedMail(1)}>
                      <InputCircle
                        className={selectedMail === 1 ? 'selected-input' : ''}
                      />
                      <InputText>
                        Usa
                        {insuranceRequest.customer.email}
                      </InputText>
                    </InputContainer>
                    <InputContainer onClick={() => handleSelectedMail(2)}>
                      <InputCircle
                        className={selectedMail === 2 ? 'selected-input' : ''}
                      />
                      <InputText>Usa altro email</InputText>
                    </InputContainer>
                  </SelectEmailContainer>
                  {selectedMail === 2 && (
                  <InputEmail
                    type="text"
                    onChange={(e) => setSignatureMail(e.target.value)}
                    placeholder="Scrivi qui la tua email..."
                  />
                  )}
                </SignatureDigitalContainer>
                <SendEmailContainer>
                  <WarningContainer>
                    <img src={warning} />
                    {' '}
                    <p>
                      Se scegli di utilizzare il tipo digitale, non potrai
                      modificarlo in seguito.
                    </p>
                  </WarningContainer>
                  <button onClick={() => selectedMethod()}>
                    Procedi con la Firma Digitale
                  </button>
                </SendEmailContainer>
              </>
            ) : (
              ''
            )}
        </>
      )}
    </SignatureTypeWrapper>
  );
}

export default SignatureType;
