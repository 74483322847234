import styled from 'styled-components';

export const TutelaLegaleQuotesStyles = styled.div`
  margin-bottom: 80px;
  .failed-quotations-block {
    margin-top: 120px;
  }
  .failed-quotations-block img {
    width: 100%;
    display: block;
    aspect-ratio: 6/3;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
`;

export const TutelaLegaleFormStyles = styled.form`
  width: 50%;
  margin: 0 auto;

 
    @media (min-width: 300px) and (max-width: 1000px) {
      width: 100%;
  }
  @media (min-width: 1001px) and (max-width: 1300px) {
      width: 80%;
  }
`;
