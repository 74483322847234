import React from 'react';
import { PriceContainer, PriceResult } from '../QuotationCardResult.styles';

function PriceComponentResult({ quote }) {
  return (
    <PriceContainer>
      <PriceResult>
        <h5>Prezzo annuo:</h5>
        {quote.amount_before_discount !== null
          && parseFloat(quote.amount_before_discount)
            > parseFloat(quote.amount) && (
            <p>
              &euro;
              {Number(quote.amount_before_discount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </p>
        )}
        <h2>
          &euro;
          {Number(quote.amount).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
          })}
        </h2>
      </PriceResult>
      {quote.payment_frequency === 2 && (
        <PriceResult>
          <h5>Prezzo Semestrale:</h5>
          <h6>Primo Pagamento</h6>
          <h2>
            &euro;
            {(Number(quote.amount) / quote.payment_frequency).toLocaleString(
              'it-IT',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )}
          </h2>
        </PriceResult>
      )}
    </PriceContainer>
  );
}

export default PriceComponentResult;
