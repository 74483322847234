import styled from 'styled-components';

export const QuotationCardStyles = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.selected {
    border: 2px solid #00a551;
  }

  @media only screen and (max-width: 1200px) {
    padding: 15px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftCol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const RightCol = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }
`;

export const LogoContainer = styled.div`
  width: 135px;
  height: 135px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 110px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 70px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
`;

export const Price = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #00a551;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 9999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const CompanyInformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 15px;

  @media only screen and (min-width: 768px) {
    gap: 15px;
  }
`;

export const CompanyInfoElements = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    color: #1a1921;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }

  img {
    max-width: 20px;
  }

  @media only screen and (max-width: 1200px) {
    img {
      max-width: 15px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export const QuoteInformationContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;

  @media only screen and (max-width: 1200px) {
    gap: 15px;
    margin-top: 15px;
  }
`;

export const QuoteInformationItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const AquistaButtonsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
  }
`;

export const AquistaButton = styled.button`
  border-radius: 15px;
  background: #00a651;
  border: 1px solid #00a651;
  padding: 10px 60px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #00a651;
    color: #00a651;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 10px;
  }
`;

export const SavedButton = styled.div`
    padding: 8px 60px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(100,200,100);
    background-color: rgb(100,200,100);
    color: white;
    cursor: default;
`;

export const RangeStyles = styled.div`
  max-width: 300px;
  width: 100%;

  h5 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0px;
    text-align: center;
  }

  p {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
  }
`;
