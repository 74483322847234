import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  EuropeCristalliInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer, TitleBox,
} from './EuropeAssistanceCristalliModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function EuropeAssistanceCristalliModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <EuropeCristalliInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>

            <p className="mt-3">
              Rottura Cristalli causata da fatto involontario o causale (accidentale, fortuito, imprevedibile, imprevisto, inaspettato, inatteso, occasionale)
            </p>
            <p>
              <b>A)</b>
              {' '}
              Riparazione presso Centro Convenzionato (CarGlass – DoctorGass)
            </p>
            <p>
              No franchigia
            </p>
            <p>
              Massimale per evento e anno assicurativo
            </p>
            <p>
              <b>B)</b>
              {' '}
              Riparazione presso centro o tecnico di fiducia Assicurato
            </p>
            <p>
              {'Si franchigia => € 190,00 sul totale fattura'}
            </p>
            <p>
              Massimale per evento e anno assicurativo
              carenza di 60gg per la Garanzia Cristalli limitatamente ai casi in cui la polizza NON è sottoscritta
              contestualmente a sostituzione / rinnovo Polizza R.C.A.
            </p>

          </div>
        </ModalContent>
      </ModalBody>
    </EuropeCristalliInfoModalStyles>
  );
}

export default EuropeAssistanceCristalliModal;
