/* eslint-disable react/no-unstable-nested-components */
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useMatch,
} from 'react-router-dom';
import 'moment/locale/it';
import { Provider } from 'react-redux';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { store } from '../features/store';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import 'react-day-picker/dist/style.css';
import './App.css';

import NotFound from '../common/components/NotFound';
import Login from '../screens/Login';
import RcProfession from '../screens/RcProfession';
import DashboardTheme from './theme/style-screens/DashboardTheme';
import RcQuotes from '../screens/RcQuotes';
import RcMotoQuotes from '../screens/RcMotoQuotes';
import RcProfessionQuotes from '../screens/RcProfessionQuotes';
import useAuth from '../hooks/useAuth';
import Profile from '../screens/Profile';
import Tutorial from '../screens/Tutorial';
import ClaimsManagementReports from '../screens/ClaimsManagementReports';
import Message from '../screens/Message';
import MessageThread from '../screens/MessageThread';
import BrokerHome from '../screens/BrokerHome/BrokerHome';
import LatestUpdatesPage from '../screens/LatestUpdatesPage/LatestUpdatesPage';
import PurchasedQuotations from '../screens/PurchasedQuotations/PurchasedQuotations';
import Notifications from '../screens/Notifications';
import VehicleProduct from '../screens/VehicleProduct';
import AccidentsPage from '../screens/AccidentsPage/AccidentsPage';
import AccidentPage from '../screens/AccidentPage/AccidentPage';
import TicketPage from '../screens/TicketPage';
import NetworkAnalisys from '../screens/ResearchScreens/NetworkAnalisys';
import ResearchType from '../screens/ResearchScreens/ResearchType';
import ComparativeAnalisys from '../screens/ResearchScreens/ComparativeAnalisys';
import CollaboratorsAnalisys from '../screens/ResearchScreens/CollaboratorsAnalisys';
import ListActiveCustomers from '../screens/ActiveCustomers/ListActiveCustomers';
import ActiveCustomerDetails from '../screens/ActiveCustomers/ActiveCustomerDetails';
import ReadGmail from '../screens/ActiveCustomers/ReadGmail';
import CustomerInformationPage from '../screens/ActiveCustomers/CustomerInformationPage';
import RenewalsPage from '../screens/RenewalsPage';
import Brokers from '../screens/BrokersPage/Brokers';
import NewBroker from '../screens/BrokersPage/NewBroker';
import EditBroker from '../screens/BrokersPage/EditBroker';
import BrokerQuotations from '../screens/BrokersPage/BrokerQuotations';
import { BrokersReports } from '../screens/BrokersPage/BrokersReports';
import ClientsListOfSezE from '../screens/ActiveCustomers/ClientsListOfSezE';
import { CancellationsPage } from '../screens/CancellationsPage/CancellationsPage';
import { CancellationPage } from '../screens/CancellationPage/CancellationPage';
import { ReplacmentsPage } from '../screens/ReplacementsPage/ReplacementsPage';
import { ReplacementPage } from '../screens/ReplacementPage/ReplacementPage';
import { ModificationsPage } from '../screens/ModificationsPage/ModificationsPage';
import { ModificationPage } from '../screens/ModificationPage/ModificationPage';
import { SendDocumentsPage } from '../screens/SendDocumentsPage/SendDocumentsPage';
import AuthenticateBackOffice from '../screens/BackOffice/AuthenticateBackOffice';
import BackOfficeRcVehicle from '../screens/BackOffice/BackOfficeRcVehicle';
import BackOfficeRcMoto from '../screens/BackOffice/BackOfficeRcMoto';
import BackOfficeRcVan from '../screens/BackOffice/BackOfficeRcVan';
import BackOfficeRcProfession from '../screens/BackOffice/BackOfficeRcProfession';
import BackOfficeInfortuni from '../screens/BackOffice/BackOfficeInfortuni';
import BackOfficeInfodrive from '../screens/BackOffice/BackOfficeInfodrive';
import BackOfficeTravel from '../screens/BackOffice/BackOfficeTravel';
import BackOfficeVehicleQuotes from '../screens/BackOffice/results/BackOfficeVehicleQuotes';
import BackOfficeMotoQuotes from '../screens/BackOffice/results/BackOfficeMotoQuotes';
import BackofficeRcProfessionQuotes from '../screens/BackOffice/results/BackOfficeRcProfessionQuotes';
import BackOfficeInfortuniQuotes from '../screens/BackOffice/results/BackOfficeInfortuniQuotes';
import BackOfficeInfodriveQuotes from '../screens/BackOffice/results/BackOfficeInfodriveQuotes';
import BackOfficeTravelQuotes from '../screens/BackOffice/results/BackOfficeTravelQuotes';
import { ForgotPassword } from '../screens/ForgotPassword';
import { ResetPassword } from '../screens/ResetPassword';
import { CreateReminder } from '../screens/Reminder/CreateReminder';
import { UpdateReminder } from '../screens/Reminder/UpdateReminder';
import { ContactCustomer } from '../screens/CommonCustomers/ContactCustomer';
import TravelFormFlow from '../screens/TravelProduct/TravelFormFlow';
import TravelResultPage from '../screens/TravelProduct/TravelResultPage';
import WidgetsScreen from '../screens/BrokerHome/WidgetsScreen';
import SelectPostVenditaAction from '../screens/SelectPostVenditaAction';
import SelectPostVenditaType from '../screens/SelectPostVenditaType';
import { ChangePassword } from '../screens/Auth/ChangePassword';
import SemestalityQuotesToBePaid from '../screens/PurchasedQuotations/SemestalityQuotesToBePaid';
import { Redirector } from '../screens/Redirector';
// import Commercial from '../screens/Commercial';
// import PurchaseCommercial from '../screens/purchase/PurchaseCommercial';
// import RcCommercialQuotes from '../screens/RcCommercialQuotes';
import { TourProvider } from '../context/useTourContext';
// import CommercialCompanyData from '../screens/Commercial/CommercialCompanyData';
// import CommercialQuestionnaire from '../screens/Commercial/CommercialQuestionnaire';
import PurchaseProfessionalRequest from '../screens/purchase/PurchaseProfessionalRequest';
import PurchaseAutoRequest from '../screens/purchase/PurchaseAutoRequest';
import PurchaseAutocarroRequest from '../screens/purchase/PurchaseAutocarroRequest';
import PurchaseTravelRequest from '../screens/purchase/PurchaseTravelRequest';
import PurchaseMotoRequest from '../screens/purchase/PurchaseMotoRequest';
import ComponentRouteWrapper from '../screens/ComponentRouteWrapper';
import RcTutelaLegale from '../screens/RcTutelaLegale';
import RcTutelaLegaleQuotes from '../screens/RcTutelaLegaleQuotes';
import PurchaseTutelaLegaleRequest from '../screens/purchase/PurchaseTutelaLegaleRequest';
import AutoCompletedScreen from '../screens/CompletedQuotes/AutoCompletedScreen';
import ProfessionCompletedScreen from '../screens/CompletedQuotes/ProfessionCompletedScreen';
import TravelCompletedScreen from '../screens/CompletedQuotes/TravelCompletedScreen';
import TutelaLegaleCompletedScreen from '../screens/CompletedQuotes/TutelaLegaleCompletedScreen';
import MotoCompletedScreen from '../screens/CompletedQuotes/MotoCompletedScreen';
import Quote from '../screens/Quote';
// import CommercialCompletedScreen from '../screens/CompletedQuotes/CommercialCompletedScreen'
import AssistenzaStradaleCompletedScreen from '../screens/CompletedQuotes/AssistenzaStradaleCompletedScreen';
import CristalliCompletedScreen from '../screens/CompletedQuotes/CristalliCompletedScreen';
import InfortuniConducenteCompletedScreen from '../screens/CompletedQuotes/InfortuniConducenteCompletedScreen';
import CollisioneCompletedScreen from '../screens/CompletedQuotes/CollisioneCompletedScreen';
import HomeFormFlow from '../screens/HouseProduct/HomeFormFlow';
import HomeResultPage from '../screens/HouseProduct/HomeResultPage';
import PurchaseHomeRequest from '../screens/HouseProduct/PurchaseHomeRequest';
import HomeCompletedScreen from '../screens/CompletedQuotes/HomeCompletedScreen';
import SaluteFormFlow from '../screens/SaluteProduct/SaluteFormFlow';
import SaluteResultPage from '../screens/SaluteProduct/SaluteResultPage';
import RequestListPage from '../screens/RequestsPage/RequestListPage';
import BackOfficeTutelaLegaleQuotes from '../screens/BackOffice/results/BackOfficeTutelaLegaleQuotes';
import BackOfficeHomeQuotes from '../screens/BackOffice/results/BackOfficeHomeQuotes';
import PurchaseSaluteRequest from '../screens/SaluteProduct/PurchaseSaluteRequest';
import SaluteCompletedScreen from '../screens/CompletedQuotes/SaluteCompletedScreen';
import BackOfficeSaluteQuotes from '../screens/BackOffice/results/BackOfficeSaluteQuotes';
import BackOfficeSalute from '../screens/BackOffice/BackOfficeSalute';
import ErrorComponentFallback from '../screens/ErrorComponentFallback';
import AncillariAutoFormFlow from '../screens/AncillariAutoProduct/AncillariAutoFormFlow';
import AncillariAutoResultPage from '../screens/AncillariAutoProduct/AncillariAutoResultPage';
import PurchaseAncillariAutoRequest from '../screens/AncillariAutoProduct/PurchaseAncillariAutoRequest';
import AncillariAutoCompletedScreen from '../screens/CompletedQuotes/AncillariAutoCompletedScreen';

const backofficeRoutes = [
  <Route
    path="/backoffice/:backOfficeAccessToken/:requestToken/:resultpage?"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <AuthenticateBackOffice />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/auto"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeRcVehicle />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/moto"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeRcMoto />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/autocarro"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeRcVan />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/profession"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeRcProfession />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infortuni"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeInfortuni />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infodrive"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeInfodrive />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/travel"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeTravel />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/salute"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeSalute />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/tutela-legale/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeTutelaLegaleQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/casa/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeHomeQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/salute/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeSaluteQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/auto/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeVehicleQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/moto/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeMotoQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/autocarro/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeVehicleQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/profession/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackofficeRcProfessionQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infortuni/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeInfortuniQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infodrive/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeInfodriveQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/backoffice/quote/:requestToken/travel/results"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <BackOfficeTravelQuotes />
      </ErrorBoundary>
    )}
  />,
  <Route
    path="/redirect"
    element={(
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorComponentFallback error={error} />}
      >
        <Redirector />
      </ErrorBoundary>
    )}
  />,
];

function App() {
  const { isUserLoggedIn, isUserSectionAB } = useAuth();
  const matchBackoffice = useMatch('/backoffice/*');

  if (!isUserLoggedIn) {
    return (
      <Routes>
        <Route
          exact
          path="/"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <Navigate to="/login" />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/login"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <Login />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/auth/forgot"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ForgotPassword />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/auth/reset/:token"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ResetPassword />
            </ErrorBoundary>
          )}
        />

        {backofficeRoutes.map((c) => c)}

        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  if (matchBackoffice) {
    return (
      <Routes>
        {backofficeRoutes.map((c, index) => React.cloneElement(c, { key: index }))}
      </Routes>
    );
  }

  return (
    <DashboardTheme>
      <Routes>
        <Route
          path="/"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="dashboard_screen"
                returnComponent={<BrokerHome />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/dashboard"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="dashboard_screen"
                returnComponent={<BrokerHome />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quote"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_products"
                returnComponent={<Quote />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quote/:vehicle"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="funnel"
                returnComponent={<VehicleProduct />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quote/profession"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_professione_form"
                returnComponent={<RcProfession />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path='/quote/commercial'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location='quotation_commercial_form'
                returnComponent={<Commercial />}
              />
            </ErrorBoundary>
          }
        /> */}
        {/* <Route
          path='/quote/commercial/:vatNumber/report'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <CommercialCompanyData />
            </ErrorBoundary>
          }
        /> */}
        {/* <Route
          path='/quote/commercial/:vatNumber/questionnaire/:company_id'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <CommercialQuestionnaire />
            </ErrorBoundary>
          }
        /> */}

        {/* HOUSE PRODUCT */}
        <Route
          path="/quote/casa"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="funnel_casa"
                returnComponent={<HomeFormFlow />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/casa/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="casa_result"
                returnComponent={<HomeResultPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/casa/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_casa_purchase"
                returnComponent={<PurchaseHomeRequest />}
              />
            </ErrorBoundary>
          )}
        />

        {/* SALUTE PRODUCT */}
        <Route
          path="/quote/salute"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="funnel_salute"
                returnComponent={<SaluteFormFlow />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/salute/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="salute_result"
                returnComponent={<SaluteResultPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/salute/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_salute_purchase"
                returnComponent={<PurchaseSaluteRequest />}
              />
            </ErrorBoundary>
          )}
        />

        {/* ANCILLARI AUTO PRODUCT */}
        <Route
          path="/quote/ancillari-auto"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="funnel_ancillari_auto"
                returnComponent={<AncillariAutoFormFlow />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/ancillari-auto/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="ancillari_auto_result"
                returnComponent={<AncillariAutoResultPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/ancillari-auto/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_ancillari_auto_purchase"
                returnComponent={<PurchaseAncillariAutoRequest />}
              />
            </ErrorBoundary>
          )}
        />

        {/* <Route path="/quote/metlife" element={<Metlife />} />
        <Route path="/quote/metlife/:pack" element={<Metlife />} /> */}
        {/* <Route path="/quote/infodrive" element={<InfoDriveFlow />} /> */}
        <Route
          path="/quote/travel"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_travel_form"
                returnComponent={<TravelFormFlow />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/travel/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_travel_result"
                returnComponent={<TravelResultPage />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route path="/quotes/travel/purchase/:quotationId" element={<PurchaseTravel />} /> */}
        <Route
          path="/quotes/travel/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_travel_purchase"
                returnComponent={<PurchaseTravelRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/travel"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_travel_completed_screen"
                returnComponent={<TravelCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path="/quotes/infodrive/:requestToken"
          element={<InfodriveQuotes />}
        />
        <Route
          path="/quotes/infodrive/purchase/:quotationId"
          element={<PurchaseInfodrive />}
        /> */}
        <Route
          path="/quotes/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_auto_result"
                returnComponent={<RcQuotes />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/moto/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_moto_result"
                returnComponent={<RcMotoQuotes />}
              />
            </ErrorBoundary>
          )}
        />

        {/* <Route
          path="/quotes/metlife/:requestToken"
          element={<MetlifeQuote />}
        /> */}

        <Route
          path="/quotes/profession/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_professione_result"
                returnComponent={<RcProfessionQuotes />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path='/quotes/commercial/:requestToken'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location='quotation_commercial_result'
                returnComponent={<RcCommercialQuotes />}
              />
            </ErrorBoundary>
          }
        /> */}
        {/* <Route
          path='/quotes/purchased/:quoteId/quotation/commercial'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location='quotation_commercial_completed_screen'
                returnComponent={<CommercialCompletedScreen />}
              />
            </ErrorBoundary>
          }
        /> */}
        {/* tutela legale */}
        <Route
          path="/quote/tutela-legale"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_tutela_legale_form"
                returnComponent={<RcTutelaLegale />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/tutela-legale/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_tutela_legale_result"
                returnComponent={<RcTutelaLegaleQuotes />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/tutela-legale/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_tutela_legale_purchase"
                returnComponent={<PurchaseTutelaLegaleRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/tutela-legale"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_tutela_legale_completed_screen"
                returnComponent={<TutelaLegaleCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/assistenza-stradale"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_assistenza_stradale_completed_screen"
                returnComponent={<AssistenzaStradaleCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/cristalli"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_cristalli_completed_screen"
                returnComponent={<CristalliCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/infortuni-conducente"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_infortuni_conducente_completed_screen"
                returnComponent={<InfortuniConducenteCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path="/quotes/metlife/purchase/:quotationId"
          element={<PurchaseInfortuni />}
        /> */}
        <Route
          path="/quotes/auto/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_auto_purchase"
                returnComponent={<PurchaseAutoRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/auto"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_auto_completed_screen"
                returnComponent={<AutoCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quotes/purchased/:quoteId/quotation/casa"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_casa_completed_screen"
                returnComponent={<HomeCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quotes/purchased/:quoteId/quotation/salute"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_salute_completed_screen"
                returnComponent={<SaluteCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quotes/purchased/:quoteId/quotation/ancillari-auto"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_ancillari_auto_completed_screen"
                returnComponent={<AncillariAutoCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quotes/purchased/:quoteId/quotation/autocarro"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_autocarro_completed_screen"
                returnComponent={<AutoCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/quotes/purchased/:quoteId/quotation/moto"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_moto_completed_screen"
                returnComponent={<MotoCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/collisione"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_collisione_completed_screen"
                returnComponent={<CollisioneCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/moto/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_moto_purchase"
                returnComponent={<PurchaseMotoRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/autocarro/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_autocarro_purchase"
                returnComponent={<PurchaseAutocarroRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/profession/purchase/:requestToken"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_profession_purchase"
                returnComponent={<PurchaseProfessionalRequest />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotes/purchased/:quoteId/quotation/profession"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="quotation_profession_completed_screen"
                returnComponent={<ProfessionCompletedScreen />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path='/quotes/commercial/purchase/:insuranceRequestToken'
          element={
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location='quotation_commercial_purchase'
                returnComponent={<PurchaseCommercial />}
              />
            </ErrorBoundary>
          }
        /> */}
        <Route
          path="/profile"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="broker_profile"
                returnComponent={<Profile />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/tutorial"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="tutorial"
                returnComponent={<Tutorial />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quotazioni-aquistati"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="purchased_quotation_list"
                returnComponent={<PurchasedQuotations />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/quote-semestrale-da-pagare"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="semestrality_quotation_list"
                returnComponent={<SemestalityQuotesToBePaid />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/post-vendita"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="post_vendita_actions"
                returnComponent={<SelectPostVenditaAction />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/after-sale"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="after_sale_list"
                returnComponent={<ClaimsManagementReports />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/after-sale/select"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="select_after_sale"
                returnComponent={<SelectPostVenditaType />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/accidents"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="accidents_list"
                returnComponent={<AccidentsPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/accidents/:accidentId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="accident_single_page"
                returnComponent={<AccidentPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/cancellations"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="cancellations_list"
                returnComponent={<CancellationsPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/cancellations/:cancellationId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="cancellation_single_page"
                returnComponent={<CancellationPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/replacements"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="replacements_list"
                returnComponent={<ReplacmentsPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/replacements/:replacementId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="replacement_single_page"
                returnComponent={<ReplacementPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/modifications"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="modifications_list"
                returnComponent={<ModificationsPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/modifications/:modificationId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="modification_single_page"
                returnComponent={<ModificationPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/send-documents"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="send_documents_list"
                returnComponent={<SendDocumentsPage />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/notifications"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="notifications"
                returnComponent={<Notifications />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/renewals"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="renewals_list"
                returnComponent={<RenewalsPage />}
              />
            </ErrorBoundary>
          )}
        />
        {/* Active Customers */}
        <Route
          path="/clients"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="clients_list"
                returnComponent={<ListActiveCustomers />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/clients/rete"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="clients_of_sez_E_list"
                returnComponent={<ClientsListOfSezE />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/clients/:activeClientId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="active_client_single_page"
                returnComponent={<ActiveCustomerDetails />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/clients/email/:gmailId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ReadGmail />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/clients-not-active/:customerId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="non_active_client_single_page"
                returnComponent={<CustomerInformationPage />}
              />
            </ErrorBoundary>
          )}
        />
        {/* Messages Routes */}
        <Route
          path="/messages"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <Message />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/messages/:threadId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="thread_single_page"
                returnComponent={<MessageThread />}
              />
            </ErrorBoundary>
          )}
        />

        {/** Latest Updates Routes */}
        <Route
          path="/latest-updates"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="latest_update"
                returnComponent={<LatestUpdatesPage />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/preventivi"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="preventivi"
                returnComponent={<RequestListPage />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/tickets/:ticketId"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="ticket_single_page"
                returnComponent={<TicketPage />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/research-type"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="research_type"
                returnComponent={<ResearchType />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/network-analisys"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="network_analisys"
                returnComponent={<NetworkAnalisys />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/comparative-analisys"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="comparative_analisys"
                returnComponent={<ComparativeAnalisys />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/collaborators-analisys"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="collaborators_analisys"
                returnComponent={<CollaboratorsAnalisys />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/reminders/create"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="create_reminder"
                returnComponent={<CreateReminder />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/reminders/:reminderId/edit"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="update_reminder"
                returnComponent={<UpdateReminder />}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/customers/:type/:customerId/contact"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="contact_customer"
                returnComponent={<ContactCustomer />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/widgets"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="widgets"
                returnComponent={<WidgetsScreen />}
              />
            </ErrorBoundary>
          )}
        />

        <Route
          path="/security/change/password"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <ComponentRouteWrapper
                location="change_password"
                returnComponent={<ChangePassword />}
              />
            </ErrorBoundary>
          )}
        />
        {/* <Route
          path="/work-in-progress"
          element={(
            <ComponentRouteWrapper
              location="work-in-progress"
              returnComponent={<ErrorComponentFallback />}
            />
          )}
        /> */}

        <Route
          path="/redirect"
          element={(
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <ErrorComponentFallback error={error} />
              )}
            >
              <Redirector />
            </ErrorBoundary>
          )}
        />

        {isUserSectionAB && (
          <>
            <Route
              path="/brokers"
              element={(
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <ErrorComponentFallback error={error} />
                  )}
                >
                  <ComponentRouteWrapper
                    location="brokers_list"
                    returnComponent={<Brokers />}
                  />
                </ErrorBoundary>
              )}
            />
            <Route
              path="/brokers/new"
              element={(
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <ErrorComponentFallback error={error} />
                  )}
                >
                  <ComponentRouteWrapper
                    location="create_broker"
                    returnComponent={<NewBroker />}
                  />
                </ErrorBoundary>
              )}
            />
            <Route
              path="/brokers/:brokerId"
              element={(
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <ErrorComponentFallback error={error} />
                  )}
                >
                  <ComponentRouteWrapper
                    location="edit_broker"
                    returnComponent={<EditBroker />}
                  />
                </ErrorBoundary>
              )}
            />
            <Route
              path="/brokers/:brokerId/quotations"
              element={(
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <ErrorComponentFallback error={error} />
                  )}
                >
                  <ComponentRouteWrapper
                    location="broker_quotations"
                    returnComponent={<BrokerQuotations />}
                  />
                </ErrorBoundary>
              )}
            />
            <Route
              path="/brokers-reports"
              element={(
                <ErrorBoundary
                  fallbackRender={({ error }) => (
                    <ErrorComponentFallback error={error} />
                  )}
                >
                  <ComponentRouteWrapper
                    location="broker_reports"
                    returnComponent={<BrokersReports />}
                  />
                </ErrorBoundary>
              )}
            />
          </>
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardTheme>
  );
}

function AppWrapper() {
  return (
    <TourProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </TourProvider>
  );
}

export default AppWrapper;
