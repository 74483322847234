import React, { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditBrokerStyles, MapContainerStyles } from './EditBroker.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import { invoiceTypes, genders } from '../../common/constants';
import LoadingGif from '../../assets/images/loading.gif';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/images/purchase/download.png';
import {
  finishUploadingDocumentForSectionEBroker, getSectionEBroker, getSectionEBrokerDocuments, getSectionEBrokerReset, updateSectionEBroker, uploadingDocumentForSectionEBroker,
} from '../../features/brokers/brokersActions';
import FileUpload from '../../common/components/inputs/FileUpload';
import { generalAlertSuccessToast } from '../../common/utils/alerts';
import { apiDownloadDocument } from '../../services/axios-client/axeCommons';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackEditBroker } from '../../common/utils/joyrideCallbacks';
// import { editBrokerSteps } from '../../common/utils/joyrideSteps';

const schema = yup.object({
  name: yup
    .string()
    .required('Il campo è obbligatorio')
    .min(3, 'Il campo deve contenere almeno 3 caratteri')
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.max(50)
      : schema.max(191))),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio')
        .min(3, 'Il campo deve contenere almeno 3 caratteri')
        .max(191)
      : schema)),
  email: yup.string()
    .required('Il campo è obbligatorio')
    .max(255)
    .email('Email deve essere valida'),
  invoice_type: yup.string()
    .required('Devi specificare la tipologia di ritenuta'),
  phone: yup.string().required('Il campo è obbligatorio').max(255),
  pec: yup.string().required('Il campo è obbligatorio').max(191),
  ivass_registration_number: yup.string().required('Il campo è obbligatorio').max(255),
  city: yup.string().required('Il campo è obbligatorio').max(191),
  vat_number: yup.string().required('Il campo è obbligatorio').matches(/^[0-9]{11}$/, 'Il codice fiscale non è valido'),
  comission: yup.number().typeError('Il campo deve essere un numero').required('Il campo è obbligatorio').min(0)
    .max(100),
  age: yup.number().integer('Il campo deve essere un numero').typeError('Il campo deve essere un numero')
    .required('Il campo è obbligatorio')
    .min(0)
    .max(200),
  gender: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),
});

function EditBroker() {
  const { brokerId } = useParams();

  const state = useSelector((store) => store.brokers.edit_broker_section_e);

  const {
    loading, error, broker, updating, uploading_document, documents,
  } = state;

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      name: '',
      surname: '',
      phone: '',
      email: '',
      pec: '',
      city: '',
      age: '',
      ivass_registration_number: '',
      vat_number: '',
      comission: '',
      gender: '',
      invoice_type: '',
      address: '',
      latitude: 41.902782,
      longitude: 12.496366,
    },
  });

  const answers = watch();

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitData = (data) => {
    updateSectionEBroker(brokerId, data);
  };

  useEffect(() => {
    getSectionEBroker(brokerId);

    return () => {
      getSectionEBrokerReset();
    };
  }, []);

  useEffect(() => {
    if (broker !== null) {
      updateAnswers(broker);

      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      });

      loader.load()
        .then((google) => {
          const myLatLng = {
            lat: broker.latitude,
            lng: broker.longitude,
          };

          const map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 15,
          });

          new google.maps.Marker({
            position: myLatLng,
            map,
            title: 'Hello Greenia!',
          });

          const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
            fields: ['formatted_address', 'geometry', 'name'],
            types: ['address'],
            componentRestrictions: { country: 'it' },
          });

          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) {
              setValue('address', '');
            } else {
              const map = new google.maps.Map(document.getElementById('map'), {
                zoom: 15,
                center: place.geometry.location,
              });

              new google.maps.Marker({
                position: place.geometry.location,
                map,
              });

              setValue('address', place.formatted_address);
              setValue('latitude', place.geometry.location.lat());
              setValue('longitude', place.geometry.location.lng());
            }
          });
        });

      return () => {
        if (loader) {
          loader.reset();
          delete window.google;
          Loader.instance = undefined;
        }
      };
    }

    return () => { };
  }, [broker]);

  const uploadDocument = (documentTypeId) => (file) => {
    let fileInfo;
    let baseURL = '';
    const uploadedFile = file[0];
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      baseURL = reader.result;

      uploadingDocumentForSectionEBroker();

      const response = await managementAxe.post(`/broker/section-e/brokers/${brokerId}/document/upload`, {
        document_type_id: documentTypeId,
        original_filename: fileInfo,
        document: baseURL.replace('data:application/pdf;base64,', ''),
      });

      finishUploadingDocumentForSectionEBroker();

      if (response.status !== 200) {
        return;
      }

      getSectionEBrokerDocuments(brokerId);

      generalAlertSuccessToast('File caricato');
    };
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('editBroker') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('editBroker')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('editBroker');
  //   setState({ run: true, tourActive: true });
  // };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  const uploadedDocumentTypes = documents.map((d) => d.document_type_id);

  return (
    <EditBrokerStyles>
      {/* <Joyride
        steps={editBrokerSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackEditBroker(data, editBrokerSteps, setState)}
        showSkipButton
        showProgress
      /> */}
      {/* <div className="d-flex justify-content-end">
        {localStorage.getItem('editBroker') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
            <button className="btn btn-success text-white px-4 ">Tutorial</button>
          </div>
        )}
      </div> */}
      <h1 className="screen-title">Modifica Intermediario</h1>

      <form onSubmit={handleSubmit(submitData)}>

        <div className="edit-broker-one">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <FormInputRadio
                name="gender"
                label="Genere *"
                options={genders}
                registration={register('gender')}
                error={errors.gender?.message}
                valid={isValid('gender')}
              />
            </div>
          </div>

          {answers.gender !== 'G' && (
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <FormInputText
                  label="Nome *"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <FormInputText
                  label="Cognome *"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
              </div>
            </div>
          )}

          {answers.gender === 'G' && (
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <FormInputText
                  label="Ragione Sociale *"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Telefono *"
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Email *"
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Pec *"
                registration={register('pec')}
                error={errors.pec?.message}
                valid={isValid('pec')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Citta *"
                registration={register('city')}
                error={errors.city?.message}
                valid={isValid('city')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Eta *"
                registration={register('age')}
                error={errors.age?.message}
                valid={isValid('age')}
              />
            </div>
            <div className="col-md-6 col-lg-6" />
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Numero di iscrizione Ivass *"
                registration={register('ivass_registration_number')}
                error={errors.ivass_registration_number?.message}
                valid={isValid('ivass_registration_number')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Partita Iva *"
                registration={register('vat_number')}
                error={errors.vat_number?.message}
                valid={isValid('vat_number')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Comissione (%) *"
                registration={register('comission')}
                error={errors.comission?.message}
                valid={isValid('comission')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputSelect
                label="Tipologia ritenuta d'acconto *"
                options={invoiceTypes}
                placeholder=" - Seleziona - "
                registration={register('invoice_type')}
                error={errors.invoice_type?.message}
                valid={isValid('invoice_type')}
              />
            </div>
          </div>

        </div>
        <br />

        <div className="row edit-broker-two">
          <h3>Detagli</h3>
          <label className="custom-label">Indirizzo</label>
          <input
            name="address"
            id="autocomplete"
            placeholder="Inserisci Indirizzo..."
            className={`form-control ${errors.address?.message ? 'is-invalid' : ''}`}
            {...register('address')}
          />
          <div className="invalid-feedback">
            {errors.address?.message}
          </div>
          <MapContainerStyles>
            <div id="map" />
          </MapContainerStyles>
        </div>

        {updating === true
          && (
            <div className="text-center">
              <img className="loading-gif" src={LoadingGif} />
              <br />
              Attendere prego
            </div>
          )}
        {updating === false
          && <button className="submit-button edit-broker-three" type="submit">Modifica Intermediario</button>}
      </form>

      <br />
      <hr />
      <br />

      <h1 className="screen-title">Documenti</h1>

      <div className="uploaded-documents-container edit-broker-four">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Documenti Caricati</h4>
          </div>
          <div className="card-body px-0">
            {documents.length === 0
              && <p>Nessun documento caricato.</p>}
            {documents.map((document) => (
              <div key={document.id} className="uploaded-document">
                <div className="d-flex justify-content-center">
                  <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                  <div className="filename">{document.original_filename}</div>
                </div>
                <div>
                  <div className="badge badge-success">{document.document_type.name}</div>
                  <img src={downloadIMG} className="clickable img-fluid me-2" width="30" alt="DOWNLOAD" onClick={() => downloadDocument(document.id)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="file-uploads-container">
        {uploading_document === true
          && (
            <div className="text-center">
              <img className="loading-gif" src={LoadingGif} />
              <br />
              Attendere prego
            </div>
          )}

        {uploading_document === false && (
          <>
            {uploadedDocumentTypes.includes(9) === false
              && (
                <FileUpload
                  multiple={false}
                  label={"CARICA CARTA D'IDENTITA"}
                  handleFile={uploadDocument(9)}
                />
              )}

            {uploadedDocumentTypes.includes(10) === false
              && (
                <FileUpload
                  multiple={false}
                  label="CARICA CODICE FISCALE"
                  handleFile={uploadDocument(10)}
                />
              )}
            {uploadedDocumentTypes.includes(11) === false
              && (
                <FileUpload
                  multiple={false}
                  label="CARICA CONTRATTO DI COLLABORAZIONE"
                  handleFile={uploadDocument(11)}
                />
              )}
            {uploadedDocumentTypes.includes(14) === false
              && (
                <FileUpload
                  multiple={false}
                  label={"CARICA L'ATTO DI NOTERIETA"}
                  handleFile={uploadDocument(14)}
                />
              )}
            {uploadedDocumentTypes.includes(15) === false
              && (
                <FileUpload
                  multiple={false}
                  label={'CARICA Aggiornamento Professionale'.toUpperCase()}
                  handleFile={uploadDocument(15)}
                />
              )}
            {uploadedDocumentTypes.includes(16) === false
              && (
                <FileUpload
                  multiple={false}
                  label={'CARICA Certificato del Casellario dei Carichi Pendenti'.toUpperCase()}
                  handleFile={uploadDocument(16)}
                />
              )}
            {uploadedDocumentTypes.includes(25) === false
              && (
                <FileUpload
                  multiple={false}
                  label={'CARICA Visura camerale'.toUpperCase()}
                  handleFile={uploadDocument(25)}
                />
              )}
          </>
        )}
      </div>
    </EditBrokerStyles>
  );
}

export default EditBroker;
