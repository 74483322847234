import React, { useEffect, useState } from 'react';
import {
  ImageContainer, MainTitle, NumberCurrent, SignatureInfoWrapper, SignaturePaperContainer, SignatureTypeWrapper, TextContainer, TitleButtonsWrapper, WarningContainer,
} from './SignatureType.styles';
import warning from '../../../../assets/redesign_icons/warning-icon.svg';
import arrowGreen from '../../../../assets/redesign_icons/arrow-down.svg';
import SaluteDocuments from './Documents/SaluteDocuments';

function SignatureTypeSalute({
  quote,
  insuranceRequest,
  collectQuoteData,
  signableDocs,
  requestedOptionalDocumentsSignable,
}) {
  const [selected, setSelected] = useState(null);
  const [digitalSign, setDigitalSign] = useState(false);

  const checkIfAllSignableDocsAreUploaded = () => {
    let allUploaded = true;
    const allDocs = [
      ...insuranceRequest.documents.filter((d) => d.signable_document === 1),
      ...quote.documents.filter((d) => d.signable_document === 1),
    ];

    const signaleDocTypeIds = signableDocs.map((d) => d.id);

    for (let i = 0; i < allDocs.length; i++) {
      const document = allDocs[i];

      if (signaleDocTypeIds.includes(document.document_type_id) && document.signed_document_filename === null) {
        allUploaded = false;
      }
    }

    return allUploaded;
  };

  useEffect(() => {
    if (quote.initial_signature_type !== null) {
      setSelected(quote.initial_signature_type);
    }
  }, [quote]);

  useEffect(() => {
    if (quote.initial_signature_type === 0) {
      setDigitalSign(false);
    }

    if (quote.initial_signature_type === 1) {
      setDigitalSign(true);
    }
  }, [quote]);

  return (
    <SignatureTypeWrapper className={checkIfAllSignableDocsAreUploaded() === false ? 'document-signed-uploaded' : ''}>
      <TitleButtonsWrapper>
        <div className="d-flex align-items-center justify-content-start">
          <NumberCurrent>2</NumberCurrent>
          <MainTitle>
            {' '}
            Firma i documenti del
            {' '}
            <b>cliente</b>
          </MainTitle>
        </div>
        {/* <ButtonContainer>
        <ButtonManual className={selected === 0 ? 'active-signature' : ''} onClick={quote.initial_signature_type === 1 || quote.initial_signature_type === 0 ? () => { } : () => selectSignatureMethod(0)}>Cartacea</ButtonManual>
      </ButtonContainer> */}
      </TitleButtonsWrapper>
      {selected === null && digitalSign === false ? (
        <SignatureInfoWrapper>
          <TextContainer>
            <h3>Seleziona uno dei tipi di firma sullo switcher sopra.</h3>
            <WarningContainer>
              <img src={warning} />
              {' '}
              <p>
                Fai attenzione, quando continui con uno dei tipi di firma non
                puoi modificarlo in seguito
              </p>
            </WarningContainer>
          </TextContainer>
          <ImageContainer>
            <img src={arrowGreen} alt="" />
          </ImageContainer>
        </SignatureInfoWrapper>
      ) : (
        ''
      )}
      {quote.initial_signature_type === 0 && (
      <SignaturePaperContainer>
        <SaluteDocuments
          signable={1}
          insuranceRequest={insuranceRequest}
          quote={quote}
          collectQuoteData={collectQuoteData}
          requestedOptionalDocumentsSignable={
            requestedOptionalDocumentsSignable
          }
          signableDocs={signableDocs}
        />
      </SignaturePaperContainer>
      )}
    </SignatureTypeWrapper>
  );
}

export default SignatureTypeSalute;
