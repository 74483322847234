import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  report: {
    loading: true,
    error: null,
    commercial_report: null,
  },
  questionnaire: {
    loading: true,
    error: null,
    company: null,
    request_token: null,
    generatingQuotes: false,
    old_request_token: null,
    modify_data: null,
    errorMessage: null,
  },
  update_report: {
    loading: true,
    error: null,
  },
};

export const commercialReportSlice = createSlice({
  name: 'commercialReport',
  initialState,
  reducers: {
    getCommercialReportPending: (state) => {
      state.report.loading = true;
      state.report.error = null;
    },
    getCommercialReportRejected: (state, { payload }) => {
      state.report.loading = false;
      state.report.error = payload;
    },
    getCommercialReportSuccess: (
      state,
      { payload },
    ) => {
      state.report.loading = false;
      state.report.commercial_report = payload;
    },
    commercialReportReset: (state) => {
      state.report = { ...initialState.commercial_report };
    },
    getQuestionarioPending: (state) => {
      state.questionnaire.loading = true;
      state.questionnaire.error = null;
    },
    getQuestionarioRejected: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.error = payload;
    },
    getQuestionarioSuccess: (
      state,
    ) => {
      state.questionnaire.loading = false;
    },
    getCompanyQuestionarioStatusPending: (state) => {
      state.questionnaire.loading = true;
      state.questionnaire.error = null;
    },
    getCompanyQuestionarioStatusRejected: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.error = payload;
    },
    getCompanyQuestionarioStatusSuccess: (state, { payload = null }) => {
      state.questionnaire.loading = false;
      if (payload) {
        state.questionnaire.company = payload;
      }
    },
    getCompanyQuestionarioCommercialRequestTokenSuccess: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.request_token = payload.request_token;
      state.questionnaire.errorMessage = payload.errorMessage;
    },
    setGeneratingQuotes: (state, { payload }) => {
      state.questionnaire.generatingQuotes = payload;
    },
    resetQuestionnaireRequestToken: (state) => {
      state.questionnaire = { ...state.questionnaire, request_token: null };
    },
    updateCommercialReportPending: (state) => {
      state.update_report.loading = true;
      state.update_report.error = null;
    },
    updateCommercialReportRejected: (state, { payload }) => {
      state.update_report.loading = false;
      state.update_report.error = payload;
    },
    updateCommercialReportSuccess: (state) => {
      state.update_report.loading = false;
    },
    setQuestionarioOldRequestToken: (state, { payload }) => {
      state.questionnaire.old_request_token = payload;
    },
    resetQuestionnaireOldRequestToken: (state) => {
      state.questionnaire.old_request_token = null;
    },
    updateQuestionnaireModifyData: (state, { payload }) => {
      state.questionnaire.modify_data = payload;
    },
    resetQuestionnaireModifyData: (state) => {
      state.questionnaire.modify_data = null;
    },
  },
});

export default callerCreator(commercialReportSlice.actions);
