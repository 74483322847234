import React from 'react';
import moment from 'moment';
import {
  HomeCardInfoStyles, InfoBoxContainer, TitleActionBox, InfoItem,
} from './HomeInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';
import { houseTypes, ownerTypes } from '../../../constants';

function HomeInfoCard({ customer, insuranceRequest, modifyData }) {
  return (
    <HomeCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>casa</b>
        </h4>
        {modifyData && (
          <ModificaDatiButton onClick={() => modifyData()}>
            <p>Modifica Dati</p>
            <img src={editIcon} alt="Modifica Dati" />
          </ModificaDatiButton>
        )}

      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.gender !== 'G' ? `${customer.name} ${customer.surname}` : customer.business_name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Tipo Abitazione:</h4>
          <p>
            {houseTypes.find((type) => type.id === insuranceRequest.house.house_type).name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Tipologia Propietario:</h4>
          <p>
            {ownerTypes.find((type) => type.id === insuranceRequest.house.owner_typology).name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>La superficie:</h4>
          <p>
            {insuranceRequest.house.square_meters}
            m
            <sup>2</sup>
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Provincia Abitazione:</h4>
          <p>
            {insuranceRequest.house.comune.name}
            (
            {insuranceRequest.house.comune.car_plate_symbol}
            )
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>
            {moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}
          </p>
        </InfoItem>
      </InfoBoxContainer>
    </HomeCardInfoStyles>
  );
}

export default HomeInfoCard;
