import React from 'react';
import moment from 'moment';
import {
  AncillariAutoInfoStyles, InfoBoxContainer, InfoItem, TitleActionBox,
} from './AncillariAutoInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function AncillariAutoInfoCard({
  customer, insuranceRequest, modifyData, vehicle,
}) {
  return (
    <AncillariAutoInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>Ancillari Auto</b>
        </h4>
        {modifyData && (
          <ModificaDatiButton onClick={() => modifyData()}>
            <p>Modifica Dati</p>
            <img src={editIcon} alt="Modifica Dati" />
          </ModificaDatiButton>
        )}

      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.gender !== 'G' ? `${customer.name} ${customer.surname}` : customer.business_name}
          </p>
        </InfoItem>

        <InfoItem>
          <h4>Targa:</h4>
          <p>
            {vehicle.vehicle_plate}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Data Imatricolazione:</h4>
          <p>
            {`${vehicle.vehicle_month}-${vehicle.vehicle_year}`}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>
            {moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}
          </p>
        </InfoItem>
      </InfoBoxContainer>
    </AncillariAutoInfoStyles>
  );
}

export default AncillariAutoInfoCard;
