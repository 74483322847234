import React from 'react';
import { RiskCard } from '../styles/HomeResultPage.styles';
import {
  RiskButton,
  RiskButtonRemove,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import QuotationCardResult from '../../../common/reusable/QuotationCardResult';
import RiskCardSelected from '../../../common/components/redesignComponents/Cards/RiskCardSelected';

function HouseModalButtons({
  insuranceRequest,
  processingQuote,
  type,
  riskQuotations,
  removeItemFromCart,
}) {
  return (
    <RiskCard
      className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${
        insuranceRequest.processing_quotation_id === null
        && type.id !== 'res_civile'
          ? 'bg-secondary bg-opacity-25'
          : ''
      } ${
        insuranceRequest.processing_quotation_id !== null
        && type.id === 'res_civile'
          ? 'bg-success bg-opacity-25'
          : ''
      } ${
        riskQuotations && riskQuotations[type.id] !== undefined
          ? 'bg-success bg-opacity-25'
          : ''
      }`}
    >
      <div className="p-3 me-auto">
        <h5>
          {type.id === 'res_civile' && insuranceRequest.product.id === 11
            ? 'Assistenza casa e famiglia'
            : type.name}
        </h5>
      </div>

      {processingQuote !== null && type.id === 'res_civile' && (
        <QuotationCardResult
          processingQuote={processingQuote}
          requestInfo={insuranceRequest}
        />
      )}

      {riskQuotations
        && riskQuotations[type.id] !== undefined
        && riskQuotations[type.id].map((quote, idx) => (
          <React.Fragment key={idx}>
            {processingQuote !== null
              && processingQuote.company_id !== 2
              && type.id === quote.quote_type && (
                <div className="col-12 mb-2">
                  <RiskCardSelected
                    quote={quote}
                    requestInfo={insuranceRequest}
                    type={type}
                  />
                </div>
            )}
          </React.Fragment>
        ))}

      <div className="d-flex justify-content-end ms-auto gap-2 mt-2">
        {processingQuote && processingQuote.company_id != 8 && (
          <>
            {riskQuotations
              && riskQuotations[type.id] !== undefined
              && riskQuotations[type.id].map((quote, idx) => (
                <React.Fragment key={idx}>
                  {type.id === quote.quote_type && (
                    <RiskButtonRemove
                      className="btn btn-danger"
                      onClick={() => removeItemFromCart(quote.id)}
                    >
                      Rimuovi
                    </RiskButtonRemove>
                  )}
                </React.Fragment>
              ))}

            {riskQuotations
              && riskQuotations[type.id] === undefined
              && processingQuote !== null
              && type.id !== 'res_civile' && (
                <RiskButton
                  className="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target={`#${type.id}`}
                >
                  Aggiungi
                </RiskButton>
            )}
          </>
        )}

        {insuranceRequest.processing_quotation_id === null
          && type.id !== 'res_civile' && (
            <div className="alert alert-warning py-2 px-2">
              Seleziona Prima Assistenza Casa e Famiglia
            </div>
        )}

        {type.id === 'res_civile' && (
          <RiskButton
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target={`#${type.id}`}
          >
            Aggiungi
          </RiskButton>
        )}

        {processingQuote !== null && (
          <>
            {type.id === 'res_civile' && (
              <RiskButtonRemove
                className="btn btn-danger"
                onClick={() => removeItemFromCart(processingQuote.id)}
              >
                Rimuovi
              </RiskButtonRemove>
            )}
          </>
        )}
      </div>
    </RiskCard>
  );
}

export default HouseModalButtons;
