import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  GlobalAssistencaTutelaInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader,
  RightSide,
  TextContainer,
  TitleBox,
} from './GlobalAssistanceTutelaModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function GlobalAssistanceTutelaModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <GlobalAssistencaTutelaInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>

            <ul className="mt-3">
              <li>
                L’esercizio di pretese al risarcimento danni a persone e/o cose subiti per fatti illeciti di terzi connessi alla circolazione stradale;
              </li>
              <li>
                Controversie contrattuali per presunte inadempienze proprie o della controparte relative al veicolo, con valore in lite superiore ad € 200;
              </li>
              <li>
                Difesa penale per delitti colposi o contravvenzioni connessi ad incidente stradale;
              </li>
              <li>
                Nel caso in cui vengano contestati al conducente reati di omicidio stradale o lesioni personali gravi o gravissime;
              </li>
              <li>
                Difesa penale per delitti dolosi;
              </li>
              <li>
                Dissequestro del veicolo sequestrato in seguito ad incidente stradale;
              </li>
              <li>
                Opposizione avverso sanzioni amministrative di ritiro, sospensione, revoca della patente per incidente stradale;
              </li>
              <li>
                Controversie contrattuali con Compagnie di Assicurazioni Infortuni: fino al sotto limite di € 1.000 (con valore in lite superiore ad € 200);
              </li>
              <li>
                Opposizione avverso sanzioni amministrative in caso di furto del veicolo, furto della targa o clonazione della targa del veicolo: fino al sotto limite di € 1.000;
              </li>
              <li>
                Ricorso per violazioni al Codice della Strada: fino al sotto limite di € 5.000;
              </li>
              <li>
                Ricorso per errata variazione dei punti, in caso di Contraente persona fisica alla guida del veicolo assicurato;
              </li>
              <li>
                Indennità autoscuola: € 1.000,00 per evento, illimitato per anno;
              </li>
              <li>
                Indennità zero punti: € 1.000,00 per evento, illimitato per anno.
              </li>

            </ul>
          </div>
        </ModalContent>
      </ModalBody>
    </GlobalAssistencaTutelaInfoModalStyles>
  );
}

export default GlobalAssistanceTutelaModal;
