import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  GlobalAssistencaInfortuniInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer, TitleBox,
} from './GlobalAssistanceInfortuniModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function GlobalAssistanceInfortuniModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <GlobalAssistencaInfortuniInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <p className="mt-3">
              Morte
            </p>
            <br />
            <p>
              Invalidità permanente, franchigia 3%
            </p>

            <p>
              <b>Rimborso Spese Mediche</b>
              {' '}
              da Ricovero (max € 2.000 sx/anno), o Indennità da Ricovero (€ 50 al dì per max 30 gg)
            </p>

            <p>
              <b>Indennizzo flat: </b>
              {' '}
              {'€ 1.000 per IP < 3% purché l’infortunio venga accertato da PS con prognosi di almeno 15 gg o immobilizzo per gessatura (*)'}
            </p>

            <p>
              <b>Raddoppio </b>
              {' '}
              della somma assicurata per commorienza del Conducente e coniuge (o convivente more-uxorio) in presenza di loro figli minorenni (con il limite massimo di 250.000 €).
            </p>

          </div>
        </ModalContent>
      </ModalBody>
    </GlobalAssistencaInfortuniInfoModalStyles>
  );
}

export default GlobalAssistanceInfortuniModal;
