import { backOfficeAxe } from '../../services/axios-client/backofficeClient';
import { generalAlertError } from '../../common/utils/alerts';
import caller from './formsBackofficeSlice';
import { quotationsBackofficeAxe } from '../../services/axios-client/quotationsBackofficeAxeClient';

const getFormData = () => quotationsBackofficeAxe.get('/form-data/service-models');

const getMunicipalities = () => quotationsBackofficeAxe.get('/form-data/communes');

const getPrivacyTerms = () => quotationsBackofficeAxe.get('/form-data/data-privacy');

const getVehicleBrands = () => quotationsBackofficeAxe.get('/form-data/vehicle-makes');

const getMotoBrands = () => quotationsBackofficeAxe.get('/form-data/get-moto-brands');

const getStates = () => quotationsBackofficeAxe.get('/form-data/states');

const backOfficeGetStates = () => quotationsBackofficeAxe.get('/form-data/states');
const backOfficeGetVehicleBrands = () => quotationsBackofficeAxe.get('/form-data/vehicle-makes');
const backOfficeGetPrivacyTerms = () => quotationsBackofficeAxe.get('/form-data/data-privacy');
const backOfficeGetMunicipalities = () => quotationsBackofficeAxe.get('/form-data/communes');
const backOfficeGetFormData = () => quotationsBackofficeAxe.get('/form-data/service-models');
const getTravelStates = () => quotationsBackofficeAxe.get('/form-data/travel-states');

const getProfessions = () => quotationsBackofficeAxe.get('/form-data/professions');

const getVehicleActivities = () => quotationsBackofficeAxe.get('/form-data/vehicle-activities');

/**
 * Auto Form
 */

export const autoSubmitAnswers = async (
  answersData,
  navigate,
  requestToken,
) => {
  caller.autoFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/vehicles/quote/${requestToken}`,
    answersData,
  );

  caller.autoFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.request_token}/auto/results`);
};

export const autoChangeIndex = async (newIndex) => {
  caller.autoFormDataUpdated({ index: newIndex });
};

export const autoGetInitialData = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [getStates(), getVehicleBrands(), getFormData(), getPrivacyTerms()],
  );

  caller.autoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.autoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const motoGetInitialData = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [getStates(), getMotoBrands(), getFormData(), getPrivacyTerms()],
  );

  caller.motoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.motoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autoGetInitialDataBackOffice = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [
      backOfficeGetStates(),
      backOfficeGetVehicleBrands(),
      backOfficeGetFormData(),
      backOfficeGetPrivacyTerms(),
    ],
  );

  caller.autoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await backOfficeGetMunicipalities();

  caller.autoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autoUpdateFormData = (data) => {
  caller.autoFormDataUpdated(data);
};

export const autoGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsBackofficeAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const motoGetVehicleModels = async (make_id) => {
  caller.motoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsBackofficeAxe.get(
    `/form-data/get-moto-models/${make_id}`,
    {},
  );

  caller.motoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const motoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.motoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsBackofficeAxe.get('/form-data/get-moto-version', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.motoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.motoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const motoUpdateFormData = (data) => {
  caller.motoFormDataUpdated(data);
};

export const autoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsBackofficeAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.autoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const resetAutoForm = () => {
  caller.resetAutoForm();
};

/**
 * Autocarro Form
 */

export const autocarroSubmitAnswers = async (
  answersData,
  navigate,
  requestToken,
) => {
  caller.autocarroFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/vehicles/quote/${requestToken}`,
    answersData,
  );

  caller.autocarroFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.request_token}/autocarro/results`);
};

export const autocarroChangeIndex = async (newIndex) => {
  caller.autocarroFormDataUpdated({ index: newIndex });
};

export const autocarroGetInitialData = async () => {
  const [
    statesRes,
    brandsRes,
    formDataRes,
    dataPrivacyRes,
    vehicleActivitiesRes,
  ] = await Promise.all([
    getStates(),
    getVehicleBrands(),
    getFormData(),
    getPrivacyTerms(),
    getVehicleActivities(),
  ]);

  caller.autocarroFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    vehicleActivities: vehicleActivitiesRes.data.data.map((act) => ({
      id: act.code,
      name: act.description,
    })),
  });

  const municipalitiesRes = await getMunicipalities();

  caller.autocarroFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autocarroUpdateFormData = (data) => {
  caller.autocarroFormDataUpdated(data);
};

export const autocarroGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.autocarroFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsBackofficeAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.autocarroFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const autocarroGetVehicleVersions = async (
  model_id,
  imm_vehicle_year,
) => {
  caller.autocarroFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsBackofficeAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.autocarroFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.autocarroFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const resetAutocarroForm = () => {
  caller.resetAutocarroForm();
};

// Moto Form

export const motoSubmitAnswers = async (
  answersData,
  navigate,
  requestToken,
) => {
  caller.motoFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/moto/quote/${requestToken}`,
    answersData,
  );

  caller.motoFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.request_token}/moto/results`);
};

export const motoChangeIndex = async (newIndex) => {
  caller.motoFormDataUpdated({ index: newIndex });
};

export const resetMotoForm = () => {
  caller.resetMotoForm();
};

/**
 * Profession Form
 */

export const professionGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes, professionsRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
    getProfessions(),
  ]);

  caller.professionFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    professions: professionsRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.professionFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const professionUpdateFormData = (data) => {
  caller.professionFormDataUpdated(data);
};

export const professionSubmitAnswers = async (data, navigate, requestToken) => {
  caller.professionFormDataUpdated({
    loading: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/professions/quote/${requestToken}`,
    data,
  );
  navigate(
    `/backoffice/quote/${res.data.data.request_token}/profession/results`,
  );
};

export const resetProfessionForm = () => {
  caller.resetProfessionForm();
};

/**
 * Infortuni Form
 */

export const infortuniGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.infortuniFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.infortuniFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const infortuniUpdateFormData = (data) => {
  caller.infortuniFormDataUpdated(data);
};

export const infortuniChangeIndex = async (newIndex) => {
  caller.infortuniFormDataUpdated({ index: newIndex });
};

export const infortuniSubmitAnswers = async (data, navigate, requestToken) => {
  caller.infortuniFormDataUpdated({
    loading: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/infortuni/${requestToken}/quote`,
    data,
  );
  caller.infortuniFormDataUpdated({
    loading: false,
  });

  if (res.status !== 200) {
    generalAlertError('Something went wrong');
    return;
  }

  const token = res.data.data;
  navigate(`/backoffice/quote/${token}/infortuni/results`);
};

export const resetInfortuniForm = () => {
  caller.resetInfortuniForm();
};

/*
  InfoDrive
*/

export const infodriveGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes, brandsRes] = await Promise.all(
    [getStates(), getFormData(), getPrivacyTerms(), getVehicleBrands()],
  );

  caller.infodriveFromDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
    brands: brandsRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.infodriveFromDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const infodriveFromDataUpdated = (data) => {
  caller.infodriveFromDataUpdated(data);
};

export const infoDriveChangeIndex = async (newIndex) => {
  caller.infodriveFromDataUpdated({ index: newIndex });
};

export const infoDriveSubmitAnswers = async (data, navigate, requestToken) => {
  caller.infodriveFromDataUpdated({
    isSubmitting: true,
    loading: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/infodrive/${requestToken}/quote`,
    data,
  );

  caller.infodriveFromDataUpdated({
    isSubmitting: false,
    loading: false,
  });

  if (!res.data.data) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.data}/infodrive/results`);
};

export const infoDriveGetVehicleModels = async (make_id) => {
  caller.infodriveFromDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await backOfficeAxe.get('/vehicle-models', {
    params: { make_id },
  });
  caller.infodriveFromDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const resetInfodriveForm = () => {
  caller.resetInfoDriveForm();
};

/**
 * Travel Form
 */

export const travelGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.travelFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.travelFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });

  const travelStatesRes = await getTravelStates();

  caller.travelFormDataUpdated({
    travel_states: travelStatesRes.data.data,
  });
};

export const resetTravelForm = () => {
  caller.resetTravelForm();
};

export const travelUpdateFormData = (data) => {
  caller.travelFormDataUpdated(data);
};

export const travelSubmitAnswers = async (data, navigate, requestToken) => {
  caller.travelFormDataUpdated({
    loading: true,
  });
  const res = await quotationsBackofficeAxe.post(
    `/quotations/travel/quote/${requestToken}`,
    data,
  );

  caller.travelFormDataUpdated({
    loading: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.request_token}/travel/results`);
};

/**
 * Salute Form
 */

export const saluteGetInitialData = async () => {
  const [statesRes, formDataRes, dataPrivacyRes] = await Promise.all([
    getStates(),
    getFormData(),
    getPrivacyTerms(),
  ]);

  caller.saluteFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.saluteFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });

  // const travelStatesRes = await getTravelStates();

  // caller.saluteFormDataUpdated({
  //   travel_states: travelStatesRes.data.data,
  // });
};

export const resetSaluteForm = () => {
  caller.resetSaluteForm();
};

export const saluteUpdateFormData = (data) => {
  caller.saluteFormDataUpdated(data);
};

export const saluteSubmitAnswers = async (data, navigate, requestToken) => {
  caller.saluteFormDataUpdated({
    loading: true,
  });
  const res = await quotationsBackofficeAxe.post(
    `/quotations/salute/quote/${requestToken}`,
    data,
  );

  caller.saluteFormDataUpdated({
    loading: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/backoffice/quote/${res.data.request_token}/salute/results`);
};
