import {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Header from '../../../common/components/Header';
import Sidebar from '../../../common/components/Sidebar';
import { DashboardThemeStyles, SmallChat } from './DashboardTheme.styles';
import OffcanvasSidebar from '../../../common/components/OffcanvasSidebar';
import { GeneralChat } from '../../../common/components/chats/GeneralChat/GeneralChat';
import { ChatContext } from './DashboardTheme.context';
import contactSupport from '../../../assets/redesign_icons/contactSuport-white.svg';
import PlatformChat from '../../../common/components/platformaChat/PlatformChat';
import managementAxe from '../../../services/axios-client/managementServisAxeClient';
import { generalAlertSuccess } from '../../../common/utils/alerts';

export default function DashboardTheme(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();
  const location = useLocation();
  const [showChat, setShowChat] = useState(false);

  // Define the routes where the image should NOT be displayed
  const excludedRoutes = ['/messages'];

  // Use matchPath to define patterns for routes with parameters
  const hideOnRoutesWithParams = [
    matchPath('/messages/:threadId', location.pathname),
    matchPath('/tickets/:ticketId', location.pathname),
    // Add more routes with parameters here
  ];
  const shouldHideImage = excludedRoutes.includes(location.pathname)
    || hideOnRoutesWithParams.some(Boolean);

  const chatValue = useMemo(() => ({ setOpenChat, chatRef }), [chatRef]);

  const toggleChat = () => {
    setShowChat(!showChat); // Toggle the chat visibility
  };

  const redirectToLogin = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const checkUserSessionStatus = async () => {
    const response = await managementAxe.get('/auth/check-user-session');

    if (response.status !== 200) {
      return;
    }

    if (response.data.clear_session === 1) {
      generalAlertSuccess('La versione della piattaforma e stata aggiornata! Accedi di nuovo!', () => redirectToLogin());
    }
  };

  useEffect(() => {
    checkUserSessionStatus();
    setInterval(() => {
      checkUserSessionStatus();
    }, 60000);
  }, []);

  return (

    <DashboardThemeStyles>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="page-content">
        <Header setShowSidebar={setShowSidebar} />
        <OffcanvasSidebar />

        <div className="page-content-items">
          {!shouldHideImage && (

            <>
              {!showChat && (
                <SmallChat>
                  <img
                    src={contactSupport}
                    width={50}
                    alt="Bottom right"
                    className="cursor-pointer"
                    onClick={toggleChat}
                  />
                  <p className="cursor-pointer" onClick={toggleChat}>Apri Chat</p>
                </SmallChat>
              )}

              {showChat && <PlatformChat onClose={toggleChat} />}
            </>
          )}
          <ChatContext.Provider value={chatValue}>{props.children}</ChatContext.Provider>
        </div>
        <GeneralChat ref={chatRef} openChat={openChat} setOpenChat={setOpenChat} />
      </div>
    </DashboardThemeStyles>

  );
}
