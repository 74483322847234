import styled from 'styled-components';

export const SimpleModalStyles = styled.div`
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;

  .provider-modal-container {
    background-color: white;
    width: 50%;
    max-height: 70%;
    position: absolute;
    top: calc(30% - 100px);
    left: calc(40% - 50px);
    z-index: 99999;
    border-radius: 10px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding: 12px;
    &.mobile {
      width: 100%;
      top: 50;
      left: 0;
    }
    &.tablet {
      left: calc(20% - 50px);
      width: 70%;
    }
  }
  .provider-modal-close-button {
    position: absolute;
    right: 4px;
  }

  .provider-modal-header {
    display: flex;
    justify-content: center;
  }
  .provider-modal-body {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }
`;
