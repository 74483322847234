import styled from 'styled-components';

export const AncillariAutoFormFlowStyles = styled.div``;

export const AncillariAutoFormStyles = styled.form`
  width: 50%;
  margin: 0 auto;

 
    @media (min-width: 300px) and (max-width: 1000px) {
      width: 100%;
  }
  @media (min-width: 1001px) and (max-width: 1300px) {
      width: 80%;
  }`;
