import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  EuropeTutelaInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader, RightSide, TextContainer, TitleBox,
} from './EuropeAssistanceTutelaModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function EuropeAssistanceTutelaModal({ setShowModal }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <EuropeTutelaInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>

          <div className="card-extra-information">
            <h3>Informazioni</h3>

            <ul className="mt-3">
              <li>
                Richieste danni per fatto illecito di terzi compresa costituzione di parte civile
              </li>
              <li>
                Richieste danni anche come pedoni, conducenti di biciclette, passeggeri di veicoli a
                motori natanti (x persone assicurate)
              </li>
              <li>
                Difesa penale per reati colposi anche ai sensi L. 41/2016
              </li>
              <li>
                Difesa penale per rati dolosi da incidente stradale (solo con derubricazione,
                proscioglimento o assoluzione passata in giudicato oppure archiviazione notizia di reato,
                remissione di querela)
              </li>
              <li>
                Controversie contrattuali per il veicolo assicurato
              </li>
              <li>
                Ricorso e/o opposizione contro provvedimento sequestro veicolo x inc. stradale
              </li>
              <li>
                Opposizione o ricorso per sanzioni amm.vo di ritiro, sospensione revoca patente
                inseguito a incidente stradale
              </li>
              <li>
                Anticipo cauzione fino a max € 10.000 per libertà provvisoria per evento all’estero
              </li>
              <li>
                Spese per interprete all’estero per procedimenti penali
              </li>
              <li>
                Rimborso spese per rifacimento documenti a seguito distruzione in caso di incidente
                stradale
              </li>
            </ul>
          </div>
        </ModalContent>
      </ModalBody>
    </EuropeTutelaInfoModalStyles>
  );
}

export default EuropeAssistanceTutelaModal;
