import React from 'react';
import {
  LeftCol, LogoContainer, LogoPriceContainer, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, AquistaButton, AquistaButtonsContainer, Price,
  GlobalCardStyles,
} from './GlobalAssistanceCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';

function GlobalAssistanceCard(props) {
  const {
    quotation,
    requestInfo,
    selectQuotation,
  } = props;
  return (
    <>
      <GlobalCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
        <LeftCol>
          {/* {quotation.company_quality && (
            <div className="row mb-3">
              <div className="col-12">
                <div className={`p-2 badge ${quotation.company_quality.color === 'green' ? 'bg-success' : quotation.company_quality.color === 'yellow' ? 'bg-warning' : 'bg-danger'}`}>
                  <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                    Indice Di Qualità:
                    {' '}
                    <b className="fs-6">{parseFloat(quotation.company_quality.coefficiente).toFixed(2)}</b>
                  </p>
                </div>
              </div>
            </div>
          )} */}
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  {Number(quotation.amount).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                  €
                </h2>
              </Price>
              {/* {quotation.payment_frequency === 2 && (
                <Price>
                  <h5>
                    Prezzo Semestrale:
                  </h5>
                  <h6>
                    Primo Pagamento
                  </h6>
                  <h2>
                    &euro;
                    {(Number(quotation.amount) / quotation.payment_frequency).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h2>
                </Price>
              )} */}
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                Global Assistance
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Emissione:
              </h6>
              <p>
                Tradizionale
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Frequenza pagamento:
              </h6>
              <p>
                {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {quotation.product_name}
              </p>
            </QuoteInformationItem>
            {quotation.massimale != 0 && (
              <QuoteInformationItem>
                <h6>
                  Pagamento massimale:
                </h6>
                <p>
                  {new Intl.NumberFormat().format(quotation.massimale)}
                  €
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </LeftCol>
        {selectQuotation && (
        <RightCol>
          <AquistaButtonsContainer>
            {requestInfo.processing_quotation_id !== quotation.id ? (
              <AquistaButton
                onClick={() => selectQuotation(quotation.id)}
                className="purchase-quote-nine-step"
              >
                Seleziona
              </AquistaButton>
            ) : requestInfo.processing_quotation_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
          </AquistaButtonsContainer>
        </RightCol>
        )}
      </GlobalCardStyles>
    </>
  );
}

export default GlobalAssistanceCard;
